import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from '../staff.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { NgbDateAdapter, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/shared/services/dateService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class EditStaffComponent implements OnInit {

  form: FormGroup;
  staffId : any;
  operation : any;
  
  constructor(public fb: FormBuilder,
    private toastr: ToastrService,
    private service: StaffService,
    private route: ActivatedRoute,
    private router: Router,
    private dateAdapter: NgbDateAdapter<string>,
    private ngbCalendar: NgbCalendar,
    private translationService : TranslateService) {
      
      this.form = this.fb.group({
        id : ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        first_name: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        last_name: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        email: ['', [Validators.required, Validators.email, Validators.maxLength(30), this.noWhitespaceValidator]],
        phone_number: ['', [Validators.required, this.noWhitespaceValidator]],
        address: ['', [Validators.required, Validators.maxLength(300), this.noWhitespaceValidator]],
        postal_code: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
        city : ['',Validators.required],
        joining_date: ['', [Validators.required]],
        is_admin: [0],
        permission: this.fb.group({
          dashboard_screen: [0],
          dashboard: [0],
          product_screen: [0],
          product: [0],
          catalogue_screen: [0],
          catalogue: [0],
          client_screen: [0],
          client: [0],
          staff_screen: [0],
          staff: [0],
          template_screen: [0],
          template: [0],
          project_screen: [0],
          project: [0],
          timeline_screen: [0],
          timeline:[0],
          faq_screen: [0],
          faq: [0],
          document: [0],
          document_screen: [0]
        })
  
      });
     }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.staffId = parseInt(params['sId'], 10);
      this.operation = params['op'];
  });
  this.viewStaff();
  }

  mapDate(date?: any): any {
    if (!date) {
      return '';
    }
    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  viewStaff() {
    let payload = {
      id : this.staffId
    }
    this.service.viewStaff(payload).subscribe((data: any) => {
      console.log("response of viewStaff ", data);
      if (data.status === 200) {
        this.prepForm(data.body.staffRec);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }
  

  prepForm(data : any){
    console.log(data,'prep data----------');
    
    this.form.patchValue({
      id:data.id,
      first_name : data.firstname,
      last_name : data.lastname,
      email : data.email,
      phone_number : data.phone_number,
      address : data.address,
      postal_code : data.postal_code,
      city : data?.city,
      joining_date : this.dateAdapter.toModel(this.toDate(data.joining_date)),
      is_admin : data.is_admin,
      permission : {
          dashboard_screen: Number(data.UserPermissions[0].dashboard_screen),
          dashboard: Number(data.UserPermissions[0].dashboard),
          product_screen: Number(data.UserPermissions[0].product_screen),
          product: Number(data.UserPermissions[0].product),
          catalogue_screen: Number(data.UserPermissions[0].catalogue_screen),
          catalogue: Number(data.UserPermissions[0].catalogue),
          client_screen: Number(data.UserPermissions[0].client_screen),
          client: Number(data.UserPermissions[0].client),
          staff_screen: Number(data.UserPermissions[0].staff_screen),
          staff: Number(data.UserPermissions[0].staff),
          template_screen: Number(data.UserPermissions[0].template_screen),
          template: Number(data.UserPermissions[0].template),
          project_screen: Number(data.UserPermissions[0].project_screen),
          project: Number(data.UserPermissions[0].project),
          timeline_screen: Number(data.UserPermissions[0].timeline_screen),
          timeline: Number(data.UserPermissions[0].timeline),
          faq_screen: Number(data.UserPermissions[0].faq_screen),
          faq: Number(data.UserPermissions[0].faq),
          document: Number(data.UserPermissions[0].document),
          document_screen:Number(data.UserPermissions[0].document_screen)
      }
    })
  }

  updateStaff() {
    // let date = this.mapDate(this.form.value.joining_date);
    let date = this.form.value.joining_date.split("-").reverse().join("-");
    date = this.formatDate(date);

    this.form.patchValue({joining_date : date});
    console.log("PAYLOAD ", this.form.value);
    this.service.updateStaff(this.form.value).subscribe((data: any) => {
      console.log("response ", data);
      if (data.status === 200) {
        // this.toastr.success('Staff updated successfully', '');
        this.toastr.success(this.translationService.instant('editStaff.staffUpdatedMsgTsFile'));

        this.router.navigate([APP_ROUTES.listStaff]).then(() => { });
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  updateForm(control: any, event: any) {
    let value = this.form.get('permission').get(control).value;
    if (value) {
      this.form.get('permission').get(control).setValue(1);
    }
    else {
      this.form.get('permission').get(control).setValue(0);
    }

    if(!event.target.checked){
      this.form.patchValue({
        is_admin : false
      });
    }
    console.log(this.form.get('permission').value,'permissions data 199');
    

  }

  toDate(dateStr: string): any {
    const [year, month, day] = dateStr.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
  }

  doNothing(){}

  isAdmin(control: any, event: any){
    console.log("event.target.checked ",event.target.checked);
    if(event.target.checked){
      this.form.patchValue({
        permission : {
            dashboard_screen: 1,
            dashboard: 1,
            product_screen: 1,
            product: 1,
            catalogue_screen: 1,
            catalogue: 1,
            client_screen: 1,
            client: 1,
            staff_screen: 1,
            staff: 0,
            template_screen: 1,
            template: 1,
            project_screen: 1,
            project: 1,
            timeline_screen: 1,
            timeline: 1,
            faq_screen: 1,
            faq: 1,
            document:1,
            document_screen:1
        }
      });
    }
    else{
      this.form.patchValue({
        permission : {
            dashboard_screen: 1,
            dashboard: 0,
            product_screen: 1,
            product: 0,
            catalogue_screen: 1,
            catalogue: 0,
            client_screen: 1,
            client: 0,
            staff_screen: 1,
            staff: 0,
            template_screen: 1,
            template: 0,
            project_screen: 1,
            project: 0,
            timeline_screen: 1,
            timeline: 0,
            faq_screen: 1,
            faq: 0,
            document: 0,
            document_screen:1
        }
      });
    }
  }

  formatDate(str : any) : any{
    let [year,month,day] = str.split("-");
    if(day.length == 1){
      day = '0' + day;
    }
    if(month.length == 1){
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }

}
