import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/shared/services/dateService';
import { BaseService } from 'src/app/shared/services/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class EditProjectComponent implements OnInit {

  templateList : any;
  pId : any;
  APP_ROUTES = APP_ROUTES;
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Template?';
  confirmText="Confirm";
  cancelText="Cancel";
  private unsubscribe$ = new Subject();
  selectedTemplates = [];
  totalUnits = 0;

  form = this.fb.group({
    id : ['', [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
    location: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
    description: ['', [Validators.required, Validators.maxLength(300), this.noWhitespaceValidator]],
    date: ['',[Validators.required,]],
    image: ['',[]],
    projectTemplates: this.fb.array([]) ,
  });

  projectTemplateControls = this.form.get("projectTemplates") as FormArray;
  imgBaseUrl = environment.imgBaseUrl;
  operation : any;
  permissions : any;
  
  constructor(private service : ProjectService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sharedService : SharedService,
    private ngbCalendar: NgbCalendar,
     private dateAdapter: NgbDateAdapter<string> ,
     private baseService : BaseService,
     private translationService : TranslateService) { }

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.spinner.show();

    this.route.queryParams.subscribe(params => {
      this.pId = params['pId'];
      this.operation = params['op'];
  });

    this.getTemplateList();
    this.getProjectDetails(this.pId);

    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete";
        this.popoverMessage="Delete Template?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";
      }
      else{
        this.popoverTitle="Löschen";
        this.popoverMessage="Vorlage löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Stornieren";
      }
    });
  }

  getTemplateList() {

    this.service.getTemplatesList().subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.templateList = data.body.templatesRec;
        console.log("templateList", this.templateList)
        // setTimeout(() => {
        //   this.toastr.success('Templates fetched successfully', '');
        // }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });

  }

  getProjectDetails(id : any) {

    let payload = {
      id : id
    };

    this.service.getProjectDetails(payload).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        console.log("response ", data);
        let formData = data.body.projectRec;
        this.createForm(formData);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });

  }

  createForm(data : any){
    this.form.patchValue({
      id : data.id,
      name: data.name,
      location: data.location,
      description: data.description,
      date: this.dateAdapter.toModel(this.toDate(data.complete_project_date)),
      image: data.image,
    });

    
    // for( var val of data.ProjectTemplates){
    //   if(val.Template){
    //     var newObj = this.addProjectTemplates(val);
    //     this.projectTemplateControls.push(newObj);
    //   }
    // }

    data.ProjectTemplates.forEach((obj, index) => {
      console.log("index" ,index); // 0, 1, 2
      console.log("value" , obj); // 9, 2, 5
      if(obj.Template){
        var newObj = this.addProjectTemplates(obj);

        // initialise the selected temp arr
        let selectedTempArrObj = {
          oldTempId: '',
          olTempName: '',
          newTempId: obj.Template.id,
          newTempName: obj.Template.name,
          formIndex: index
        };
        
        console.log(`selectedTempArrObj[${index}]`,selectedTempArrObj)
        this.selectedTemplates.push(selectedTempArrObj);


        this.projectTemplateControls.push(newObj);
      }
      
  });

    console.log("FINAL VALUE", this.form.value)

  }

  addProjectTemplates(obj : any): FormGroup {

    this.totalUnits = this.totalUnits + obj.units;

    let templateObj = {
      id : obj.Template.id,
      name : obj.Template.name
    }
    return this.fb.group({
      id : [obj.id ? obj.id : ""],
      units: [obj.units, [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,\s]*$/), this.noWhitespaceValidator]],
      template: [templateObj, [Validators.required]],
    })
  }

  toDate(dateStr: string): any {
    const [year, month, day] = dateStr.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10)
    };
    return obj;
  }

  removeUnit(str: Boolean,obj:any,i:number) {
    
    if(str){
      console.log("str",str,"obj",obj.value.id,"Index",i)
      if(obj.value.id){
        this.deleteTemplate(obj.value.id , i);
      }
      else{
        this.projectTemplateControls.removeAt(i);
      }

      // Delete the value form selected tems arr also
      let indexForDelete;
      for (let temp of this.selectedTemplates) {
        if (temp.formIndex == i) {
          indexForDelete = this.selectedTemplates.indexOf(temp);
          break;
        };
      };
      if (indexForDelete !== -1) {
        this.selectedTemplates.splice(indexForDelete, 1);
      }

      // This method updates the formIndex values in the selectedTemplateList
      this.updateSelectedArrayAfterDelete();
    }
    
  }

  deleteTemplate(id : any , i : any) {
    let payload = {
      id : id
    };
    this.service.deleteTemplate(payload).subscribe((data: any) => {
      if (data.status === 200) {
        this.projectTemplateControls.removeAt(i);
        setTimeout(() => {
          // this.toastr.success('Template Deleted Successfully', '');
          this.toastr.success(this.translationService.instant('editProject.templatedDeletedMsgTsFile'));
        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }
  

  getLastIndexOfArray(index : any) : Boolean{
    if(this.projectTemplateControls.controls.length - 1 == index){
      return true;
    }
    return false;
  }

  newProjectTemplate(): FormGroup {
    return this.fb.group({
      id : ['',[]],
      units: [0, [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,\s]*$/)]],
      template: ['', [Validators.required]],
    })
  }
   
  addProjectTemplate() {
    this.projectTemplateControls.push(this.newProjectTemplate());
  }

  byTypeId(a, b) {
    return a.id === parseInt(b.id, 10);
  }

  mapDate(date?: any): any {
    if (!date) {
      return '';
    }
    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  onUpdate() {

    // let date = this.mapDate(this.form.value.date);
    let date = this.form.value.date.split("-").reverse().join("-");
    console.log("DDDDDDddddd",date);
    this.form.patchValue({
      date : date
    });
    console.log("Form", JSON.stringify(this.form.value));
    this.service.updateProduct(this.form.value).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.router.navigate([APP_ROUTES.listProject]).then(() => {});
        setTimeout(() => {
          // this.toastr.success('Project Updated Successfully', '');
          this.toastr.success(this.translationService.instant('editProject.projectUpdatedMsgTsFile'));
        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });

  }

  goToProjectList(){
    this.router.navigate([APP_ROUTES.listProject]).then(() => { });
  }

  uploadImage(event: any): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }
        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'))
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then(resp => {
        this.handleImageAPI(resp);
      })
      .catch(err => {
        this.toastr.error(err, '');
      });
  }

  handleImageAPI(result: any): void {
    let input = new FormData();
    input.append('id', result.file);

    this.service
      .uploadImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          // this.imageUrl = resp1.body.imageUrl;
          // this.imageName = resp1.body.filename;
          this.form.patchValue({
            image: resp1.body.filename
          });
        },
        err => {
          // this.toastr.error('Something went wrong with image upload', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      );
  }

  checkIfTempAlreadySelected(selectedTemplate : any , currentIndex : any){

    let tempIsSelectedAgain = false;

    let formArr = this.projectTemplateControls;
    console.log("formArr",formArr)
    console.log("SELECTED TEMP ", selectedTemplate, " currentIndex ",currentIndex);
    // for(var obj of formArr.value){
    //   if(selectedTemplate.id == obj.template.id && index !==currentIndex){
    //     this.toastr.error(`Templated ${obj.template.name} already selected at ${index+1} position`, '');
    //   }
    //   console.log("FORMGRP ", obj)
    // }

    formArr.value.forEach((obj, index) => {
      console.log("index" ,index); // 0, 1, 2
      console.log("value" , obj); // 9, 2, 5
      if(selectedTemplate.id == obj.template.id && index !==currentIndex){
        tempIsSelectedAgain = true;
        this.projectTemplateControls.at(currentIndex).patchValue({
          template : "",
        });
        this.toastr.error(`Template ${obj.template.name.toLowerCase( )} is already selected at ${index+1} position`, '');
      }
  });

  return tempIsSelectedAgain;

  }

  onOptionsSelected(value: any, index: any, event: any) {

    console.log("Event", event.target.selectedOptions[0]["data-sanilJaspal"]);
    let selectedTemplate = event.target.selectedOptions[0]["data-sanilJaspal"];
    console.log("the selected value is " + value, "Index is ", index, "Obj ", selectedTemplate);

    // check if the value is already selected in any other form control if yes then
    // give an error and patch empty value to projectid of the current formcontrol
    let tempIsSelectedAgain = false;
    tempIsSelectedAgain = this.checkIfTempAlreadySelected(selectedTemplate , index);
    console.log("tempIsSelectedAgain " ,tempIsSelectedAgain);
    if(tempIsSelectedAgain){
      return;
    }

    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

    let valueFromTempList;
    let obj;
    let indexForDelete: number;

    if (this.selectedTemplates.length > 0) {

      // Check if modified on same index
      for (let temp of this.selectedTemplates) {
        if (temp.formIndex == index) {
          valueFromTempList = temp;
          indexForDelete = this.selectedTemplates.indexOf(temp);
          break;
        };
      };
    };

    // If mofified on same index then delete the prev obj and push new obj to arr
    if (valueFromTempList) {
      let oldTempId = valueFromTempList.newTempId;
      let olTempName = valueFromTempList.newTempName;

      obj = {
        oldTempId: oldTempId,
        olTempName: olTempName,
        newTempId: selectedTemplate.id,
        newTempName: selectedTemplate.name,
        formIndex: index
      };

      if (indexForDelete !== -1) {
        this.selectedTemplates.splice(indexForDelete, 1);
        console.log("ARR after del ", this.selectedTemplates)
      }
    }
    else {

      obj = {
        oldTempId: '',
        olTempName: '',
        newTempId: selectedTemplate.id,
        newTempName: selectedTemplate.name,
        formIndex: index
      };

    }
    console.log("obj before push ", obj)
    this.selectedTemplates.push(obj);
    console.log("ARR after push ", this.selectedTemplates)
  }

  updateSelectedArrayAfterDelete(){
    // forarr ka loop ghumao
    // har element ko get kro selected arr me se and oska formIndex upfate kro

    console.log("BEFORE SelectedTemplates",this.selectedTemplates)

    this.projectTemplateControls.value.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete" ,index); 
      console.log("value updateSelectedArrayAfterDelete" , obj); 
      // obj units template -> id name
      this.updateIndexesInSelectedTemplates(obj.template,index);
    });

    console.log("AFTER SelectedTemplates",this.selectedTemplates)

  }

  updateIndexesInSelectedTemplates(temp : any , currentIndex : any){
    this.selectedTemplates.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete selectedTemplates" ,index); 
      console.log("value updateSelectedArrayAfterDelete selectedTemplates" , obj); 
      // obj units template -> id name

      if(obj.newTempId == temp.id){
        obj.formIndex = currentIndex;
        // return
      }

    });
  }

  doNothing(){
    return;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  editProject() {
    this.router.navigate([APP_ROUTES.editProject], { queryParams: { pId: this.pId } }).then(() => { });
  }

}
