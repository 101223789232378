import { TranslateService } from '@ngx-translate/core';
import { UpdateProductComponent } from './update-product/update-product.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../product.service';
import { ToastrService } from 'ngx-toastr';
import {
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomValidators } from 'ngx-custom-validators';
import { CategoryService } from '../../add-category/category.service';
import { CustomizeFeatureComponent } from '../customize-feature/customize-feature.component';
import { MultipleCategoriesComponent } from '../multiple-categories/multiple-categories.component';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  APP_ROUTES = APP_ROUTES;
  pId: any;
  productDetail: any;
  productFeature: any;
  prevImgPresent: Boolean;
  imgUrl: any;
  imageName: any;
  private unsubscribe$ = new Subject();
  imgBaseUrl = environment.imgBaseUrl;
  noImage = false;
  step1 = false;
  step2 = false;
  breadcrumbs: any;
  containsFeatures = true;
  productImageList: any[] = [];
  imgarray: string[] = [];
  imageUrl: any;
  selectedFiles: any;
  @ViewChild('imagePreview') imagepreviewmodal: any;
  previewImage: string;
  imageFileName: string;
  imageuploadlimit = 6;
  imagesizelimit = 5;
  featureList: any[] = [];
  selectall: boolean = false;
  catPatchIds: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private service: ProductService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private translationService: TranslateService,
    private categoryService: CategoryService
  ) {
    this.imageuploadlimit = this.service.imageuploadlimit;
    this.imagesizelimit = this.service.imagesizelimit;
  }

  featureForm = new FormArray([]);
  updatedPriceFeatureList: any[] = [];

  // cropped image
  @ViewChild('cropImageModal') cropModal: any;
  imageChangedEvent: any = '';
  croppedImage: any;
  fileName: string;
  imageFormat: string;
  imageIndex: any;
  imageWidth: any = 0;
  imageHeight: any = 0;
  isImageScaleDown: any = false;
  imageOrigin: any;

  getPureStringArr(fullobj: any) {
    let obj = fullobj?.CatalogueFeature;
    let arr2 = [];
    if (obj.control_value) {
      // console.log("Obj",obj)
      let arr = obj?.control_value?.split(',');
      let arr1 =
        obj?.control_valueimg !== null ? obj?.control_valueimg?.split(',') : [];
      if (obj.control_type === 'dropDown') {
        arr.forEach((element, i) => {
          arr2.push({
            val: element ? element : '',
            img: arr1?.length ? (arr1[i] ? this.imgBaseUrl + arr1[i] : '') : '',
          });
        });
      }
      if (obj.control_type === 'input') {
        arr2.push({
          val: fullobj.control_value ? fullobj.control_value : '',
          img: obj.image ? obj.image : '',
        });
      }
    }
    return arr2;
  }
  productForm = this.fb.group({
    product_name: ['', [Validators.required, Validators.maxLength(80)]],
    type: ['', [Validators.required]],
    description: [
      '',
      [
        Validators.required,
        Validators.maxLength(300),
        this.noWhitespaceValidator,
      ],
    ],
    price: [
      0,
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[0-9.,]*$/),
      ],
    ],
    price_wall: [0],
    price_running_meter: [0],

    floor: [0,[Validators.required]],
    wall : [0,[Validators.required]],
    running_meter : [0,[Validators.required]],

    catalogue_id: ['', Validators.required],
    image: [''],
    imageUrl: [''],
    product_id: [''],
    products_feature: [''],
    quantity: [0],
    priceType: [''],
    tempPrice: [0],
    is_extra: [false],
  });
  isModalOpened: any = false;
  category_ids_arr: any[] = [];
  nodes2 = [];
  editProdPayload: any;
  openCatModal() {
    this.isModalOpened = true;
    const modalRef = this.modalService.open(MultipleCategoriesComponent, {
      size: 'lg',
      windowClass: '',
      keyboard: true,
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.nodes = this.nodes2;
    modalRef.componentInstance.patchValue = this.productForm;
    modalRef.componentInstance.catIds = this.catPatchIds;
    modalRef.result.then(
      (childNodes) => {
        if (childNodes) {
          console.log('childNodes', childNodes);
          const result = [];
          childNodes.forEach((ele) => {
            console.log('ele ==>', ele);
            if (ele.children.length === 0) {
              result.push(ele);
            }
          });
          console.log('result', result);

          // (result) => {
          //   if(result){
          localStorage.setItem('cat_ids', JSON.stringify(result));
          this.category_ids_arr = result;
          let ids = this.category_ids_arr.map((item) => String(item?.id));
          this.productForm.patchValue({ catalogue_id: ids || [] });
          this.getAllFeatureList(ids, true);
        }
        this.category_ids_arr.forEach((x) => {
          console.log(x, 'itemm');
        });
        console.log(this.category_ids_arr.length, 'aaaa');

        // console.log(result, 'modal resu;t');
      },
      (reason) => {
        console.log(`Dismissed in if el`);
      }
    );
  }

  processNestedArrays(data: any[], cat?: any) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        item['isChecked'] = false;
        if (item?.children?.length > 0) {
          item['isIntermediate'] = false;
          item.hasChildren = true;
        }
        this.processNestedArrays(item?.children, item);
      }
    }
  }

  getCategories(): void {
    this.categoryService.getCategories().subscribe(
      (data: any) => {
        if (data.status === 200) {
          // console.log('response ', data);
          this.nodes2 = data.body.mainCat;
          this.processNestedArrays(this.nodes2);
          this.nodes2 = [...this.nodes2];
          //  setTimeout(()=>{
          //   this.treeNative.treeModel.doForAll((node)=>{

          //    if([14,26,25,5,33].includes(node?.data?.id)){
          //    node.setActiveAndVisible(true)
          //    node.setIsSelected(true)
          //   }
          //   })
          //   },500)
          // console.log('Final data -->', this.nodes2);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  checkPriceValue(priceControl: any) {
    console.log(priceControl);
    if( priceControl=='price'){
      this.productForm.patchValue({ floor: 1,running_meter:0,wall:0 });
    }
    if( priceControl=='pricewall'){
      this.productForm.patchValue({ wall: 1 ,floor: 0 ,running_meter:0 });
    }
    if( priceControl=='priceruning'){
      this.productForm.patchValue({ running_meter: 1 ,wall: 0,floor:0});
    }
    console.log(this.productForm.value,'value form');
    this.updateProductValidation(priceControl);
  }
  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((params) => {
      this.pId = params['pId'];
    });
    this.editProdPayload = JSON.parse(localStorage.getItem('singleProd'));
    this.getCategories();
    this.getProductDetails();
    this.step1 = true;
    localStorage.removeItem('cat_ids');
  }

  getProductDetails() {
    // let payload = {
    //     "id":this.editProdPayload.id,
    //     "product_name": this.editProdPayload.product_name,
    //     "type": this.editProdPayload.type,
    //     "description": this.editProdPayload.description,
    //     "price": this.editProdPayload.price,
    //     "price_wall": this.editProdPayload.price_wall,
    //     "price_running_meter": this.editProdPayload.price_running_meter,
    //     "catalogues": this.editProdPayload.ProductCategories.map(item=> item?.id),
    //     "image": this.editProdPayload.image,
    //     "quantity": this.editProdPayload.quantity,
    //     "priceType": this.editProdPayload.priceType || '',
    //     "tempPrice": this.editProdPayload.price,
    //     "is_extra": this.editProdPayload.is_extra
    // };
    let payload = {
      id: this.pId,
    };

    this.service.getProductDetails(payload).subscribe(
      (data: any) => {
        this.spinner.hide();
        // this.spinner.show();

        // setTimeout(() => {
        //   /** spinner ends after 5 seconds */
        //   this.spinner.hide();
        // }, 1000);

        if (data.status === 200) {
          this.productDetail = data.body.productRec;
          this.productForm.patchValue({
            product_name: this.productDetail.product_name,
            type: this.productDetail.type,
            description: this.productDetail.description,
            price: this.productDetail.price,
            catalogue_id: this.productDetail.catalogue_id,
            image: this.productDetail.image
              ? this.productDetail.image?.split(',')
              : [],
            product_id: this.productDetail.id,
            price_wall: this.productDetail.price_wall,
            price_running_meter: this.productDetail.price_running_meter,
            is_extra: this.productDetail.is_extra,
            floor: this.productDetail.floor,
            wall : this.productDetail.wall,
            running_meter : this.productDetail.running_meter,
            
          });
          console.log( this.productForm.value , '====after PTCH');
          
          this.productImageList = this.productForm.controls['image'].value;
          console.log(this.productImageList, 'imglit');

          let pricetype = '';
          let selectedPrice = ''
          if (this.productDetail.type === 'flat') {
            this.productForm
              .get('quantity')
              .patchValue(this.productDetail.quantity);
            this.productForm.get('price').patchValue(this.productDetail.price);
          } else {
            // this.productForm.patchValue({
            //   price: this.productDetail.price,
            //   price_wall: this.productDetail.price_wall,
            //   price_running_meter: this.productDetail.price_running_meter,
            // });
            let p = this.productForm.controls['price'].value;
            let wp = this.productForm.controls['price_wall'].value;
            let rp = this.productForm.controls['price_running_meter'].value;

            if ((p > 0 && !(wp && rp))|| this.productDetail.floor) {
              this.productForm.controls['priceType'].patchValue('price');
              this.productForm.patchValue({
                price: this.productDetail.price,
                price_wall: 0,
                price_running_meter: 0,
              });
              pricetype = 'price';
            }
            if ((wp > 0 && !(p && rp))||this.productDetail.wall) {
              this.productForm.controls['priceType'].patchValue('pricewall');
              this.productForm.patchValue({
                price: 0,
                price_wall: this.productDetail.price_wall,
                price_running_meter: 0,
              });
              pricetype = 'pricewall';
            }
            if ((rp > 0 && !(p && wp))|| this.productDetail.running_meter) {
              this.productForm.controls['priceType'].patchValue('priceruning');
              this.productForm.patchValue({
                price: 0,
                price_wall: 0,
                price_running_meter: this.productDetail.price_running_meter,
              });
              pricetype = 'priceruning';
            }
          }
          this.hideShowQuantity(pricetype);
          if (this.productDetail.image) {
            this.prevImgPresent = true;
            this.imgUrl = this.productDetail.image;
          } else {
            this.prevImgPresent = false;
            this.noImage = true;
          }

          // this.productFeature = data.body.productFeatureGroup;
          this.productFeature = this.productDetail?.ProductFeatures;

          if (this.productFeature?.length <= 0) {
            this.containsFeatures = false;
          }
          this.breadcrumbs = data.body.breadcrumbs;
          let ids = [];
          this.catPatchIds = [];
          if (Array.isArray(this.productDetail.ProductCategories)) {
            ids =
              this.productDetail.ProductCategories.map(
                (item) => item?.catalogue_id
              ) || [];
            this.catPatchIds = ids;
            this.productForm.patchValue({ catalogue_id: ids || [] });
          } else {
            ids = [this.productDetail.catalogue_id] || [];
            this.catPatchIds = ids;
            this.productForm.patchValue({ catalogue_id: ids || [] });
          }
          // this.getFeatureList(ids)
          this.getAllFeatureList(ids, false);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        this.spinner.hide();
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  returnArrayFromString(item: string): Array<any> {
    return item?.split(',');
  }

  hideShowQuantity(type?: any): void {
    if (this.productForm.get('type').value === 'flat') {
      // this.productForm.get('quantity').setValue('');
      this.productForm
        .get('quantity')
        .setValidators([
          Validators.required,
          CustomValidators.digits,
          CustomValidators.gt(0),
        ]);
      this.productForm.get('quantity').updateValueAndValidity();
      if (this.productForm.get('price').value > 0) {
        this.productForm
          .get('tempPrice')
          .setValue(this.productForm.get('price').value);
      }
    } else if (this.productForm.get('type').value === 'variant') {
      this.productForm.get('quantity').setValue(0);
      this.productForm.get('quantity').setValidators([]);
      this.productForm.get('quantity').updateValueAndValidity();
    }
    console.log(type,'---type line 430 ------------------');
    
    this.updateProductValidation(type);
  }

  updateProductValidation(type?: any): void {
    if (this.productForm.controls?.type.value === 'variant') {
      if (type === 'price') {
        // this.productForm.get('price').setValue(0);
        this.productForm.get('price_wall').setValue(0);
        this.productForm.get('price_running_meter').setValue(0);
        this.productForm
          .get('price')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price').updateValueAndValidity();
        this.productForm.get('price_wall').setValidators([]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price_running_meter').setValidators([]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
      }
      if (type === 'pricewall') {
        this.productForm.get('price').setValue(0);
        // this.productForm.get('price_wall').setValue(0);
        this.productForm.get('price_running_meter').setValue(0);
        this.productForm
          .get('price_wall')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price').setValidators([]);
        this.productForm.get('price').updateValueAndValidity();
        this.productForm.get('price_running_meter').setValidators([]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
      }
      if (type === 'priceruning') {
        this.productForm.get('price').setValue(0);
        this.productForm.get('price_wall').setValue(0);
        // this.productForm.get('price_running_meter').setValue(0);
        this.productForm
          .get('price_running_meter')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
        this.productForm.get('price_wall').setValidators([]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price').setValidators([]);
        this.productForm.get('price').updateValueAndValidity();
      }
    } else {
      this.productForm.get('price').setValue(0);
      this.productForm
        .get('price')
        .setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[0-9,.]*$/),
        ]);
      this.productForm.get('price').updateValueAndValidity();
      this.productForm.get('price_wall').setValue(0);
      this.productForm.get('price_wall').setValidators([]);
      this.productForm.get('price_wall').updateValueAndValidity();
      this.productForm.get('price_running_meter').setValue(0);
      this.productForm.get('price_running_meter').setValidators([]);
      this.productForm.get('price_running_meter').updateValueAndValidity();

      if (this.productForm.get('tempPrice').value) {
        this.productForm
          .get('price')
          .setValue(this.productForm.get('tempPrice').value);
      }
    }
  }

  updateProduct(): void {
    let imglist = this.productImageList.map((img: any) => img).toString();
    this.productForm.patchValue({ image: imglist });
    if (this.productForm.valid) {
      console.log(this.productForm.value);
      let p = this.productForm.controls['price'].value;
      let wp = this.productForm.controls['price_wall'].value;
      let rp = this.productForm.controls['price_running_meter'].value;

      if (p !== null && p > 0) {
        this.productForm.controls['price_running_meter'].patchValue(0);
        this.productForm.controls['price_wall'].patchValue(0);
      } else if (wp !== null && wp > 0) {
        this.productForm.controls['price'].patchValue(0);
        this.productForm.controls['price_running_meter'].patchValue(0);
      } else if (rp !== null && rp > 0) {
        this.productForm.controls['price'].patchValue(0);
        this.productForm.controls['price_wall'].patchValue(0);
      }
      this.productForm.updateValueAndValidity();
      let payload = {
        product_name: this.productForm.value.product_name,
        type: this.productForm.value.type,
        description: this.productForm.value.description,
        price: this.productForm.value.price,
        image: this.productForm.value.image,
        id: this.productForm.value.product_id,
        quantity: this.productForm.value.quantity,
        price_wall: this.productForm.value.price_wall,
        price_running_meter: this.productForm.value.price_running_meter,
        is_extra: this.productForm.value.is_extra,
        catalogues: this.productForm.value.catalogue_id,
        priceType: this.productForm.value.priceType,
        tempPrice: this.productForm.value.tempPrice,
        floor:  this.productForm.value.floor,
        wall:  this.productForm.value.wall,
        running_meter:  this.productForm.value.running_meter
      };

      this.service.updateProduct(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            // this.toastr.success('Product Updated Successfully', '');
            this.toastr.success(
              this.translationService.instant(
                'addProduct.productUpdatedMsgTsFile'
              )
            );
            this.showProductListing();
            // if (this.containsFeatures === false) {
            //   this.showProductListing();
            // } else {
            //   this.step1 = false;
            //   this.step2 = true;
            // }
          } else {
            this.toastr.error(data.body.message, '');
          }
        },
        (err) => {
          console.log('error', err);
          if (err.error.error_code == 1) {
            this.toastr.error(
              this.translationService.instant('addProduct.prodNotExist')
            );
          } else {
            // this.toastr.error('Something went wrong. Please try again later', '');
            this.toastr.error(
              this.translationService.instant('login.genricErrorTsFile')
            );
          }
        }
      );
    } else {
      this.productForm.markAllAsTouched();
      // if (!this.productForm.controls['image'].value) {
      //   this.toastr.error('please choose image of the product')
      // }
    }
  }

  // customizeFeature(obj: any) {
  //   console.log('inside customizeFeature obj ', obj);
  //   const modalRef = this.modalService.open(UpdateProductComponent, {
  //     size: 'lg',
  //     windowClass: '',
  //   });
  //   modalRef.componentInstance.obj = obj;
  //   if (obj.control_type === 'input') {
  //     modalRef.componentInstance.showInput = true;
  //   } else {
  //     modalRef.componentInstance.showInput = false;
  //   }
  //   modalRef.result.then(
  //     () => {
  //       this.getProductDetails();
  //     },
  //     (reason) => {
  //       console.log(`Dismissed `);
  //     }
  //   );
  // }

  customizeFeature(obj: any, index: any) {
    // console.log("OBJ",obj);
    let el;
    if (this.featureForm.controls.length > 0) {
      this.featureForm.controls.forEach((element, index) => {
        // console.log("element",element.value);
        if (obj.id === element?.value?.featureId) {
          // console.log("Form element being returned is =>",element);
          el = element;
          this.featureForm.removeAt(index);
        }
      });
    }
    // console.log("form",el);
    if (el) {
      // console.log("INSIDE FORM",el);
      const modalRef = this.modalService.open(CustomizeFeatureComponent, {
        size: 'lg',
        windowClass: '',
      });
      if (el.value.controlType === 'input') {
        modalRef.componentInstance.showInput = true;
      } else {
        modalRef.componentInstance.showInput = false;
      }

      modalRef.componentInstance.mainForm = el;
      modalRef.componentInstance.ctrlval = obj.control_type;
      modalRef.result.then(
        (result) => {
          console.log('result 1 ell ', result);
          if (result) {
            obj['priceUpdatedList'] = null;
            obj['priceUpdatedList'] = result.value ? result.value : result;
          }
          // el.patchValue({
          //   controlValue : result
          // });
          this.featureForm.push(el);
          // console.log("featureForm result for INPUT YOOOOOOOOO",JSON.stringify(this.featureForm.value));
        },
        (reason) => {
          this.featureForm.push(el);
          console.log(`Dismissed in if el`);
        }
      );
    } else {
      if (obj.control_type === 'input') {
        console.log('SEEEEEEEEEEEEE ', obj);
        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: [
            obj.control_value,
            [
              Validators.required,
              Validators.maxLength(30),
              Validators.pattern(/^[0-9]*$/),
            ],
          ],
          price: [
            '0',
            [
              Validators.required,
              Validators.maxLength(10),
              Validators.pattern(/^[0-9,.]*$/),
              ,
            ],
          ],

          featureSurcharge: [obj.surcharge],
          catalogue_id: [obj.catalogue_id],
        });
        // console.log("mainForm",JSON.stringify(mainForm.value));

        const modalRef = this.modalService.open(CustomizeFeatureComponent, {
          size: 'xl',
          windowClass: '',
        });
        modalRef.componentInstance.showInput = true;
        modalRef.componentInstance.mainForm = mainForm;
        modalRef.componentInstance.ctrlval = obj.control_type;
        modalRef.result.then(
          (result) => {
            console.log('result 2 without ell', result);
            obj['priceUpdatedList'] = null;
            obj['priceUpdatedList'] = result;
            mainForm.patchValue({
              // controlValue: result,
              price: result.price,
            });

            console.log(`mainForm.patchValue `, mainForm);

            this.featureForm.push(mainForm);
            // console.log("featureForm result for INPUT",JSON.stringify(this.featureForm.value));
          },
          (reason) => {
            console.log(`Dismissed in `);
          }
        );
      } else {
        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: new FormArray([]),
          featureSurcharge: [obj.surcharge],
          catalogue_id: [obj.catalogue_id],
        });
        let combinedarr = [];
        let controlValue = mainForm.get('controlValue') as FormArray;
        let arr = obj?.control_value?.split(',') || [];
        let arr1 =
          obj?.control_valueimg !== null
            ? obj?.control_valueimg?.split(',')
            : [];
        arr.forEach((element, i) => {
          combinedarr.push({ val: element, img: arr1[i] });
        });
        console.log(combinedarr);

        for (var val of combinedarr) {
          if (val) {
            let innerForm = this.fb.group({
              value: [val.val],
              price: [
                '0',
                [
                  Validators.required,
                  Validators.maxLength(10),
                  Validators.pattern(/^[0-9,.]*$/),
                ],
              ],
              value_img: [val.img],
            });
            controlValue.push(innerForm);
          }
        }

        // console.log("mainForm",JSON.stringify(mainForm.value));

        const modalRef = this.modalService.open(CustomizeFeatureComponent, {
          size: 'lg',
          windowClass: '',
        });
        modalRef.componentInstance.mainForm = mainForm;
        modalRef.componentInstance.showInput = false;
        modalRef.componentInstance.ctrlval = obj.control_type;
        // modalRef.result.then((result) => {
        //   if (result) {
        //   console.log("result ",result.value);
        //   }
        //   }).catch(err => {
        //     console.error(err)
        // });

        modalRef.result.then(
          (result) => {
            console.log('result 3 ----', result.value);

            if (result.value) {
              this.featureForm.push(result);
              if (result.value) {
                obj['priceUpdatedList'] = null;
                obj['priceUpdatedList'] = result.value;
              }
            }

            console.log('HELLO ', JSON.stringify(this.featureForm.value));
          },
          (reason) => {
            console.log(`Dismissed `, JSON.stringify(this.featureForm.value));
          }
        );
      }
    }
  }

  checkIfObjectExists(obj: any): FormGroup {
    let condition = false;
    if (this.featureForm.controls.length > 0) {
      this.featureForm.controls.forEach((element, index) => {
        console.log('element', element.value);
        if (obj.id === element.value.featureId) {
          console.log('Form element being returned is =>', element);
          return element;
        }
      });
    } else {
      return null;
    }
  }
  // uploadImage(event: any): void {
  //   const promise = new Promise((resolve, reject) => {
  //     if (event.target.files && event.target.files[0]) {
  //       const contentType = event.target.files[0].type;
  //       const size = event.target.files[0].size;
  //       if (Number((size / 1048576).toFixed(1)) > 5) {
  //         reject('Please select file less than 5 mb');
  //       }
  //       const allowedArr = ['png', 'jpeg', 'jpg'];
  //       const file = event.target.files[0];
  //       const extension = file.name.split('.').pop();

  //       if (allowedArr.includes(extension)) {
  //         const reader = new FileReader();

  //         reader.onload = (): void => {
  //           // this.imgURL = reader.result;
  //           let payload = {
  //             file,
  //             contentType,
  //           };
  //           resolve(payload);
  //         };
  //         reader.readAsDataURL(file);
  //       } else {
  //         reject('Please upload a valid document.');
  //       }
  //     } else {
  //       reject('Please select a file');
  //     }
  //   });
  //   promise
  //     .then((resp) => {
  //       this.handleImageAPI(resp);
  //     })
  //     .catch((err) => {
  //       this.toastr.error(err, '');
  //     });
  // }

  // handleImageAPI(result: any): void {
  //   let input = new FormData();
  //   input.append('id', result.file);

  //   this.service
  //     .uploadImage(input)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(
  //       (resp1: any) => {
  //         this.imgUrl = resp1.body.imageUrl;
  //         console.log(this.imgUrl, 'imgurl');

  //         this.noImage = false;
  //         this.imageName = resp1.body.filename;
  //         this.prevImgPresent = false;
  //         this.productForm.patchValue({
  //           image: resp1.body.filename,
  //           imageUrl: resp1.body.imageUrl,
  //         });
  //       },
  //       (err) => {
  //         // this.toastr.error('Something went wrong with image upload', '');
  //         this.toastr.error(
  //           this.translationService.instant('login.genricErrorTsFile')
  //         );
  //       }
  //     );
  // }

  // uploadImage(event: any): void {
  //   this.spinner.show();
  //   this.imgarray = [];
  //   this.selectedFiles = event.target.files;

  //   // if (this.selectedFiles.length > 6) {
  //   //   alert('please selct');
  //   //   return;
  //   // }

  //   const promise = new Promise((resolve, reject) => {
  //     if (this.selectedFiles && this.selectedFiles[0]) {
  //       const numberofselectedfiles = this.selectedFiles.length;

  //       // if (numberofselectedfiles < 7) {
  //       for (let i = 0; i < numberofselectedfiles; i++) {
  //         const contentType = this.selectedFiles[i].type;
  //         const size = this.selectedFiles[i].size;
  //         if (Number((size / 1048576).toFixed(1)) > this.imagesizelimit) {
  //           reject(this.translationService.instant('toastmsg.imagesizelimit', { value: this.imagesizelimit }));
  //           event.target.value = null;
  //           this.spinner.hide();

  //         }
  //         const allowedArr = ['png', 'jpeg', 'jpg'];

  //         const file = this.selectedFiles[i];

  //         const extension = file.name.split('.').pop();

  //         if (allowedArr.includes(extension)) {
  //           const reader = new FileReader();

  //           reader.onload = (e: any): void => {
  //             this.imgarray.push(e.target.result);

  //             // this.imgURL = reader.result;
  //             let payload = {
  //               file,
  //               contentType,
  //             };

  //             resolve(payload);
  //           };
  //           reader.readAsDataURL(this.selectedFiles[i]);
  //         } else {
  //           reject(this.translationService.instant('toastmsg.uploadvaliddoc'));
  //           event.target.value = null;
  //           this.spinner.hide();

  //         }
  //       }
  //       // } else {
  //       //   reject('Please select maximum 6 files');
  //       //   event.target.value = null;
  //       // }
  //     } else {
  //       reject(this.translationService.instant('toastmsg.selectfile'));
  //       event.target.value = null;
  //       this.spinner.hide();

  //     }
  //   });
  //   promise
  //     .then((resp) => {
  //       if (this.productImageList.length >= this.imageuploadlimit) {
  //         event.target.value = null;
  //         this.spinner.hide();

  //         return this.toastr.error(this.translationService.instant('toastmsg.imageuploadlimit', { value: this.imageuploadlimit }), '')
  //       }

  //       if (this.productImageList.length < this.imageuploadlimit + 1)
  //         this.handleImageAPI(resp, this.selectedFiles);
  //     })
  //     .catch((err) => {
  //       this.spinner.hide();

  //       this.toastr.error(err, '');
  //     });
  // }

  uploadImage(event: any, index: any) {
    console.log('-----------------Index------------- ', index);
    this.uploadImage2(event, index, true);
  }
  uploadImage2(event: any, index: any, dimesnionCheck?: Boolean): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        this.fileName = '';
        this.imageFormat = '';
        this.fileName = event.target.files[0]?.name;
        this.imageFormat = event.target.files[0]?.type;
        this.imageIndex = null;
        this.imageIndex = index;

        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }

        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType,
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then((resp) => {
        this.imageOrigin = event.target.files[0];
        const isSizeExceed = event.target.files[0].size >= 5 * 1024 * 1024;
        this.isImageScaleDown = isSizeExceed ? true : false;
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        img.onload = () => {
          this.imageWidth = isSizeExceed
            ? +img.width - (+img.width / 100) * 50
            : img.width;
          this.imageHeight = isSizeExceed
            ? +img.height - (+img.height / 100) * 50
            : img.height;
        };
        // this.imageChangedEvent=event;
        this.cd.detectChanges();
        this.open();
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
  }
  open() {
    this.cd.detectChanges();
    this.modalService
      .open(this.cropModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  hideSpinner() {
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.cd.detectChanges();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.hideSpinner();
    const imageBlob = this.dataURItoBlob(event.base64 || '');
    const imageFile = new File([imageBlob], this.fileName, {
      type: this.imageFormat || 'image/png',
    });
    this.croppedImage = imageFile;
    console.log(imageFile);
    console.log(
      `Cropped image size before compression: ${
        imageFile.size >= 1024 * 1024
          ? (imageFile.size / (1024 * 1024)).toFixed(2) + ' MB.'
          : (imageFile.size / 1024).toFixed(0) + ' KB.'
      }`
    );
  }
  dataURItoBlob(dataURI: string) {
    let url = dataURI.toString();
    const byteString = window.atob(
      url.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: this.imageFormat || 'image/png',
    });
    return blob;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  imageCroppedStarted(event: any) {
    setTimeout(() => {
      this.spinner.show();
    }, 50);
  }

  imageLoadFailed(event: any) {
    this.hideSpinner();
    this.closeModal();
    this.modalService.dismissAll();
    console.log('loading failed...');
  }

  closeModal(){
    this.hideSpinner();
    this.modalService.dismissAll();
    this.imageChangedEvent = null;
    this.croppedImage = null;
    var input:any = document.getElementsByTagName('input');
    for (let index = 0; index < input.length; index++) {
      const element = input[index];
      if(element.type === 'file'){
        element.value = '';
      }
     }
    return;
  }
  saveImage(modal?: any) {
    console.log(this.croppedImage, 'cropped image');
    this.handleImageAPI( this.imageIndex,this.croppedImage);
  }

  removeProductImage(num: number) {
    // this.imgarray.splice(num, 1);
    this.productImageList.splice(num, 1);
  }
  handleImageAPI(result: any, files: any): void {
    // this.imgarray = [];
    let input = new FormData();

    // for (let file of files) {
      input.append('id', files);
    // }

    this.service
      .uploadmutipleImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);

          this.imgarray = [];
          this.imageUrl = resp1.body.imageUrl;
          this.imgarray = resp1.body.uploadedfiles;
          if (this.productImageList.length >= this.imageuploadlimit)
            return this.toastr.error(
              this.translationService.instant('toastmsg.imageuploadlimit', {
                value: this.imageuploadlimit,
              }),
              ''
            );
          this.imgarray
            .map((el: any) => el?.filename)
            .forEach(
              (item) =>
                this.productImageList.length < this.imageuploadlimit &&
                this.productImageList.push(item)
            );

          // this.imageName = resp1.body.filename;
          this.productForm.patchValue({
            image: resp1.body.filename,
          });
          this.closeModal()
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(
            this.translationService.instant('toastmsg.somethingwrong'),
            ''
          );
        }
      );
  }
  backToStep1() {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      document.getElementById('productFormtop')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 300);
  }

  goToStep2() {
    this.step2 = true;
    this.step1 = false;
    setTimeout(() => {
      document.getElementById('inst')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 300);
  }

  showProductListing() {
    this.router.navigate([APP_ROUTES.listProduct]).then(() => {});
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  openImageModal(img: string) {
    this.previewImage = '';
    this.imageFileName = '';
    this.imageFileName = img;
    this.previewImage = this.imgBaseUrl + img;
    this.modalService
      .open(this.imagepreviewmodal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  selectAllFeatureToggle() {
    this.featureList.forEach((element) => {
      if (!this.selectall) {
        element.isSelected = true;
      } else {
        element.isSelected = false;
      }
    });
  }
  toggleSelectedFeature(obj: any) {
    obj.isSelected = !obj.isSelected;
    let selectedItems = this.featureList.filter((x) => x?.isSelected).length;
    let unSelectedItems = this.featureList.filter((x) => !x?.isSelected).length;
    if (this.featureList?.length === selectedItems) {
      this.selectall = true;
    }
    if (this.featureList?.length >= unSelectedItems && unSelectedItems > 0) {
      this.selectall = false;
    }
    console.log(obj);
  }

  getAllFeatureList(id: any, patchVal?: boolean) {
    let payload = {
      catalogues: id,
    };
    this.spinner.show();
    this.service.getAllListCatFeature(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.spinner.hide();
          this.featureList = [];
          this.selectall = false;
          // this.featureList = data.body.catFeatureList;
          data.body.catFeatureList?.forEach((item) => {
            item?.cat_feature?.forEach((feat) => {
              feat['cat_id'] = item?.cait_id;
              feat['cat_name'] = item?.cat_name || '';
              this.featureList.push(feat);
            });
          });
          if (patchVal) {
            if (this.featureList.length > 0) {
              this.productForm.controls['is_extra'].patchValue(true);
            } else {
              this.productForm.controls['is_extra'].patchValue(false);
            }
          }
          this.featureList.sort((a, b) =>
            a.label_name.localeCompare(b.label_name)
          );
          this.featureList?.forEach(async (element) => {
            element.isSelected = false;
            this.productFeature.forEach(async (item) => {
              if (String(item.feature_id) === String(element.id)) {
                element.isSelected = true;
                element['item'] = this.productFeature.filter(
                  (item) => String(item.feature_id) === String(element.id)
                );
                await this.prepareInnerForm(element, element['item'], true);

                // console.log( element['priceUpdatedList'], 'element[procceUpdateList]');
              }
            });
            let selectedItems = this.featureList.filter(
              (x) => x?.isSelected
            ).length;
            let unSelectedItems = this.featureList.filter(
              (x) => !x?.isSelected
            ).length;
            if (this.featureList?.length === selectedItems) {
              this.selectall = true;
            }
            if (
              this.featureList?.length >= unSelectedItems &&
              unSelectedItems > 0
            ) {
              this.selectall = false;
            }
          });

          console.log(this.featureList, 'bind featlist');
        } else {
          this.toastr.error(data.body.message, '');
          this.spinner.hide();
        }
      },
      (err) => {
        console.log('error', err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  getFeatureList(id: any) {
    let payload = {
      catalogue_id: id,
    };

    this.categoryService.getFeatureList(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.featureList = data.body.catFeatureList;
          this.featureList?.forEach(async (element) => {
            element.isSelected = false;
            this.productFeature.forEach(async (item) => {
              if (String(item.feature_id) === String(element.id)) {
                element.isSelected = true;
                element['item'] = this.productFeature.filter(
                  (item) => String(item.feature_id) === String(element.id)
                );
                await this.prepareInnerForm(element, element['item'], true);

                // console.log( element['priceUpdatedList'], 'element[procceUpdateList]');
              }
            });
            let selectedItems = this.featureList.filter(
              (x) => x?.isSelected
            ).length;
            let unSelectedItems = this.featureList.filter(
              (x) => !x?.isSelected
            ).length;
            if (this.featureList?.length === selectedItems) {
              this.selectall = true;
            }
            if (
              this.featureList?.length >= unSelectedItems &&
              unSelectedItems > 0
            ) {
              this.selectall = false;
            }
          });
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  saveProductFeatures() {
    this.clearFormArray(this.featureForm);
    let selectedList: any[] = this.featureList?.filter(
      (feature: any) => feature?.isSelected
    );
    debugger;
    if (this.productDetail?.is_extra) {
      if (selectedList?.length <= 0) {
        this.toastr.error(
          this.translationService.instant('addProduct.atleastOneExtra')
        );
        return;
      }
    }
    if (selectedList?.length > 0)
      for (var obj of selectedList) {
        console.log('obj lopp', obj);
        // return
        if (obj['priceUpdatedList']) {
          let obj1;
          if (Array.isArray(obj['priceUpdatedList'])) {
            obj1 = obj['priceUpdatedList'].filter(
              (item) => item?.featureId === obj?.id
            )[0];
          } else {
            obj1 = obj['priceUpdatedList'];
          }
          console.log(obj1, '*******************************');

          if (obj1.controlType === 'dropDown') {
            let mainForm = this.fb.group({
              featureId: [obj1.featureId],
              featureName: [obj1.featureName],
              controlType: [obj1.controlType],
              controlValue: new FormArray([]),
              featureSurcharge: [obj1.featureSurcharge],
              catalogue_id: [obj.catalogue_id],
            });

            let controlValue = mainForm?.get('controlValue') as FormArray;

            for (var val of obj1['controlValue']) {
              if (val) {
                let innerForm = this.fb.group({
                  value: [val.value],
                  price: [String(val?.price || 0) || '0'],
                  value_img: [val.value_img],
                });
                controlValue.push(innerForm);
              }
            }
            this.featureForm.push(mainForm);
          } else {
            console.log(obj1, 'obj 1 for input');

            let mainForm = this.fb.group({
              featureId: [obj1.featureId],
              featureName: [obj1.featureName],
              controlType: [obj1.controlType],
              controlValue: [obj1?.controlType],
              price: [String(obj1.price || '0') || '0'],
              featureSurcharge: [obj1.featureSurcharge],
              catalogue_id: [obj.catalogue_id],
            });
            this.featureForm.push(mainForm);
          }
        } else {
          this.prepareInnerForm(obj);
        }
        // if (obj.surcharge === 0) {

        //   console.log('inside sircahgre');

        //   // console.log("Inside if ",obj);
        //   // if(obj.control_type === 'dropDown' ){
        //   //   let mainForm = this.fb.group({
        //   //     featureId: [obj.id],
        //   //     featureName: [obj.label_name],
        //   //     featureControlType : [obj.control_type],
        //   //     controlValue: new FormArray([]),
        //   //     featureSurcharge: [obj.surcharge]
        //   //   });

        //   //   let controlValue = mainForm.get("controlValue") as FormArray;

        //   //     for(var val of obj.control_value.split(','))
        //   //     {
        //   //       if(val){
        //   //         let innerForm = this.fb.group({
        //   //           value: [val],
        //   //           price: ["0"]
        //   //         });
        //   //         controlValue.push(innerForm);
        //   //       }
        //   //     }

        //   //     this.featureForm.push(mainForm);

        //   // }

        //   // else {
        //   //   let mainForm = this.fb.group({
        //   //     featureId: [obj.id],
        //   //     featureName: [obj.label_name],
        //   //     featureControlType : [obj.control_type],
        //   //     controlValue: [obj.control_value],
        //   //     featureSurcharge: [obj.surcharge]
        //   //   });

        //   //   this.featureForm.push(mainForm);
        //   // }
        //   this.prepareInnerForm(obj);
        // } else {
        //   console.log('outside sircahgre');
        //   this.prepareInnerForm(obj);
        //   // // console.log("this.featureForm.value",this.featureForm.value);
        //   // // debugger;
        //   // let condition = false;
        //   // // console.log("Inside else ",obj);
        //   // if (this.featureForm.controls.length > 0) {
        //   //   this.featureForm.controls.forEach((element, index) => {
        //   //     // console.log("element",element.value);
        //   //     if (obj.id === element.value.featureId) {
        //   //       condition = true;
        //   //     }
        //   //   });
        //   //   if (!condition) {
        //   //     // console.log("YESSSSSSSSSSSSS obj added -->", obj.label_name)
        //   //     this.prepareInnerForm(obj);
        //   //   }
        //   // } else {
        //   //   // console.log("NOOOOOOOOOOO")
        //   //   this.prepareInnerForm(obj);
        //   // }
        // }
      }

    // console.log("saveProductFeatures result ",JSON.stringify(this.featureForm.value));
    this.productForm.patchValue({
      products_feature: selectedList?.length > 0 ? this.featureForm.value : [],
    });
    // console.log("saveProductFeatures productForm result ",JSON.stringify(this.productForm.value));
    let payload = {
      products: this.productForm.value,
    };

    console.log('final output =>', this.productForm.value);
    // this.productForm.reset();
    // return
    this.updateFeaturesToProduct(this.productForm.value);

    // this.router.navigate([APP_ROUTES.editProduct], { queryParams: { pId: this.pId} }).then(() => { });
  }

  updateFeaturesToProduct(payload: any) {
    console.log('addFeaturesToProduct =>', payload);
    this.service.updateFeaturesToProduct(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          setTimeout(() => {
            // this.toastr.success('Product updated successfully.', '');

            this.toastr.success(
              this.translationService.instant(
                'addProduct.productUpdatedMsgTsFile'
              )
            );
            this.backToStep1();
          }, 1000);

          // this.router.navigate([APP_ROUTES.editProduct], { queryParams: { pId: this.pId} }).then(() => { });
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  prepareInnerForm(
    obj: any,
    selectedItem?: any,
    isPatch?: boolean
  ): Promise<any> {
    console.log(obj, 'elelmte ');
    console.log(selectedItem, 'itemed skee');
    console.log(obj, 'revero obj ****');
    return new Promise((resolve, reject) => {
      if (obj.control_type === 'dropDown') {
        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: new FormArray([]),
          featureSurcharge: [obj.surcharge],
          catalogue_id: [obj.catalogue_id],
        });
        let priceList: any[] = [];

        console.log(selectedItem, 'itemm r');
        if (selectedItem) {
          priceList = selectedItem?.map((item) => String(item.price));
          console.log(priceList, 'olooos r');
        }
        let controlValue = mainForm.get('controlValue') as FormArray;

        // for (var val of obj.control_value.split(',')) {
        //   if (val) {
        //     let innerForm = this.fb.group({
        //       value: [val],
        //       price: ['0'],
        //     });
        let combinedarr = [];
        let arr = obj?.control_value?.split(',') || [];
        let arr1 =
          obj?.control_valueimg !== null
            ? obj?.control_valueimg?.split(',')
            : [];
        arr.forEach((element, i) => {
          combinedarr.push({
            val: element,
            img: arr1[i],
            price: !selectedItem
              ? 'item' in obj
                ? obj?.item[i].price
                : ''
              : priceList[i],
          });
        });
        console.log(combinedarr);
        if (!selectedItem) {
          combinedarr.forEach((item) => delete item?.price);
        }
        for (var val of combinedarr) {
          if (val) {
            let innerForm = this.fb.group({
              value: [val.val],
              price: [String(val?.price || 0) || '0'],
              value_img: [val.img],
            });
            controlValue.push(innerForm);
          }
        }
        this.featureForm.push(mainForm);
        if (isPatch) {
          obj['priceUpdatedList'] = this.featureForm.value;
        }
        resolve(this.featureForm);
      } else {
        console.log(obj, 'for input **(())))))');

        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: [obj.control_value],
          price: [
            String('item' in obj ? obj?.item[0]?.price : '0' || '0') || '0',
          ],
          featureSurcharge: [obj.surcharge],
          catalogue_id: [obj.catalogue_id],
        });
        this.featureForm.push(mainForm);
        if (isPatch) {
          obj['priceUpdatedList'] = this.featureForm.value;
        }
        resolve(this.featureForm);
      }
    });
  }
  getPureString(obj: any) {
    if (obj) {
      let arr = obj.split(',');
      let arr2 = [];
      for (var val of arr) {
        if (val === '') {
          // console.log("Var is empty")
        } else {
          arr2.push(val);
        }
      }
      // console.log("arr2",arr2)
      return arr2;
    }
  }
}
