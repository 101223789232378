import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageProfileService } from './manage-profile.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-admin-profile',
  templateUrl: './manage-admin-profile.component.html',
  styleUrls: ['./manage-admin-profile.component.scss']
})
export class ManageAdminProfileComponent implements OnInit {

  form: FormGroup;
  
  constructor( private fb: FormBuilder,public adminProfile:ManageProfileService,private toastr: ToastrService,private translationService: TranslateService) {
    this.form = this.fb.group({
      // name: ['', [Validators.required, Validators.maxLength(255)]],
      firstname: ['', [Validators.required, Validators.maxLength(255)]],
      lastname: ['', [Validators.required, Validators.maxLength(255)]],
      email: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city :  ['', [Validators.required]],
      postal_code : ['', [Validators.required]],
    });
   }

  ngOnInit(): void {
    this.getProfile()
  }
  getProfile(){
    this.adminProfile.getAdminProfile().subscribe((res:any)=>{
      
      const profile= res.body.userRec
      console.log(profile,'res data')

      this.form.patchValue({
        // name: profile.name,
        firstname: profile.firstname,
        lastname: profile.lastname,
        email:profile.email,
        phone_number:profile.phone_number,
        address: profile.address,
        city: profile.city
      })
    })
  }
  submit(){
    this.adminProfile.updateAdminProfile(this.form.value).subscribe((res:any)=>{
      this.getProfile()
      this.toastr.success(
        this.translationService.instant('privacy.addedsucess'),
        '',
        { timeOut: 3000 }
      );
      console.log(res)
    })
    console.log(this.form.value,'data')
  }
}
