import { TranslateService } from '@ngx-translate/core';
import { PreAuthPagesService } from './../../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr'
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
  public ChangePasswordForm: FormGroup;
  public isSubmitting: boolean = false;
  public isDisabled: boolean = false;
  public formSubmitSuccess: boolean = false;
  resetToken: any;

  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private service: PreAuthPagesService,
    private translationService : TranslateService) { }

  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token');
    this.checkResetToken(this.resetToken);
    this.initForm();
  }

  checkResetToken(resetToken: any) {

    this.service.checkResetToken(resetToken).subscribe((response: any) => {
      console.log(" response of checkResetToken api ", response)
      if (response.status === 200) {
        console.log("to changePassword");
      }
    }, err => {
      console.log("to login");
      this.toastr.error(err.error.message, '');
      this.router.navigate([APP_ROUTES.login]).then(() => {
        // for some async task
      });
    });
  }

  private initForm(): void {
    this.ChangePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(6), this.passwordMatcher.bind(this)]]
    });
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (this.ChangePasswordForm && (control.value !== this.ChangePasswordForm.controls.newPassword.value)) {
      return { passwordNotMatch: true };
    }
    return null;
  }

  // private passwordMatcher2(control: FormControl): { [s: string]: boolean } {
  //   if (this.ChangePasswordForm && (control.value !== this.ChangePasswordForm.controls.confirmNewPassword.value)) {
  //     return { passwordNotMatch: true };
  //   }
  //   return null;
  // }


  change(e: number): void {
    this.formSubmitSuccess = false;
    if (this.ChangePasswordForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;
    let payload = {
      token: this.resetToken,
      password: this.ChangePasswordForm.value.newPassword,
      confirmation_password : this.ChangePasswordForm.value.confirmNewPassword
    }
    if(payload.password !== payload.confirmation_password){
      // this.toastr.error("Passwords do not match, please enter again", '');
      this.toastr.error(this.translationService.instant('change-password.changePasswordTsFile'));
      this.ChangePasswordForm.reset();
      return;
    }

    this.service.changePassword(payload).subscribe((data: any) => {
      if (data.status === 200) {
        this.formSubmitSuccess = true;
        this.ChangePasswordForm.reset();
        setTimeout(() => {
          // this.toastr.success(data.body.message, '');
          this.toastr.success(this.translationService.instant('change-password.passChangedMsgTsFile'));
        }, 1000);
        this.router.navigate([APP_ROUTES.login]).then(() => {
        });
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });
  }

  get formControls() {
    return this.ChangePasswordForm.controls;
}

}
