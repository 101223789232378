import { TranslateService } from '@ngx-translate/core';
// import {Injectable} from '@angular/core';
// import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

// /**
//  * This Service handles how the date is represented in scripts i.e. ngModel.
//  */
// @Injectable()
// export class CustomAdapter extends NgbDateAdapter<string> {

//   readonly DELIMITER = '-';

//   fromModel(value: string | null): NgbDateStruct | null {
//     if (value) {
//       let date = value.split(this.DELIMITER);
//       return {
//         day : parseInt(date[0], 10),
//         month : parseInt(date[1], 10),
//         year : parseInt(date[2], 10)
//       };
//     }
//     return null;
//   }

//   toModel(date: NgbDateStruct | null): string | null {
//     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
//   }

// }

// /**
//  * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
//  */
// @Injectable()
// export class CustomDateParserFormatter extends NgbDateParserFormatter {

//   readonly DELIMITER = '/';

//   parse(value: string): NgbDateStruct | null {
//     if (value) {
//       let date = value.split(this.DELIMITER);
//       return {
//         day : parseInt(date[0], 10),
//         month : parseInt(date[1], 10),
//         year : parseInt(date[2], 10)
//       };
//     }
//     return null;
//   }

//   format(date: NgbDateStruct | null): string {
//     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
//   }
// }



import { environment } from 'src/environments/environment';
import { ProjectService } from './../project.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { TemplateService } from '../../template/template.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { NgbDateAdapter, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/shared/services/dateService';


@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class AddProjectComponent implements OnInit {

  todayDate: any;
  minDate : any;
  form: FormGroup;
  templateList: any;
  private unsubscribe$ = new Subject();
  imageUrl: any;
  imageName: any;
  imgBaseUrl = environment.imgBaseUrl;
  APP_ROUTES = APP_ROUTES;
  selectedTemplates = [];
  totalUnits = 0;

  constructor(public fb: FormBuilder,
    private tempService: TemplateService,
    private toastr: ToastrService,
    private service: ProjectService,
    private router: Router,
    private ngbCalendar: NgbCalendar,
     private dateAdapter: NgbDateAdapter<string>,
     private translationService : TranslateService ) {

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
      location: ['', [Validators.required, Validators.maxLength(80), this.noWhitespaceValidator]],
      description: ['', [Validators.required, Validators.maxLength(300), this.noWhitespaceValidator]],
      date: [this.getTodayDate(), [Validators.required]],
      image: ['', []],
      projectTemplates: this.fb.array([]),
    });

  }

  ngOnInit(): void {
    this.todayDate = this.getTodayDate();
    console.log("DATE", this.todayDate)
    this.minDate = this.getMinDate();
    this.getTemplateList();
    this.addUnitArray();

    (this.form.get('projectTemplates') as FormArray).valueChanges.subscribe(values => {
      console.log(" valueChanges ", values);
      this.updateTotalUnitsCount(values)
    });
  }

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  updateTotalUnitsCount(value: any) {

        // alert(val.units + " IS numbers");
        let noOfUnits = 0;
        let formArr = this.unitArrayControls();
        formArr.value.forEach((obj, index) => {
          if (!isNaN(parseInt(obj.units, 10))) {
            noOfUnits += parseInt(obj.units, 10);
          };
          
        });
        this.totalUnits = noOfUnits;

    // alert("Total units = " + this.totalUnits);
  }

  unitArrayControls(): FormArray {
    return this.form.get("projectTemplates") as FormArray;
  }

  newUnitArray(): FormGroup {
    return this.fb.group({
      units: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,\s]*$/), this.noWhitespaceValidator]],
      template: ['', [Validators.required]],
    })
  }

  addUnitArray() {
    this.unitArrayControls().push(this.newUnitArray());
  }

  getTodayDate(): any {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;

    const date = moment().format('YYYY-MM-DD');
    const [year, month, day] = date.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
    // return this.mapDate(obj);
  }

  getMinDate(): any {

    const date = moment().format('YYYY-MM-DD');
    const [year, month, day] = date.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
  }

  toDate(dob: string): any {
    const [year, month, day] = dob.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
  }

  mapDate(date?: any): any {
    if (!date) {
      return '';
    }
    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  removeUnit(i: number) {
    this.unitArrayControls().removeAt(i);

    let indexForDelete;
    for (let temp of this.selectedTemplates) {
      if (temp.formIndex == i) {
        indexForDelete = this.selectedTemplates.indexOf(temp);
        break;
      };
    };
    if (indexForDelete !== -1) {
      this.selectedTemplates.splice(indexForDelete, 1);
    }

    // This method updates the formIndex values in the selectedTemplateList
    this.updateSelectedArrayAfterDelete();
  }

  getLastIndexOfArray(index: any): Boolean {
    if (this.unitArrayControls().controls.length - 1 == index) {
      return true;
    }
    return false;
  }

  getTemplateList() {

    this.service.getTemplatesList().subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.templateList = data.body.templatesRec;
        console.log("templateList", this.templateList)
        // setTimeout(() => {
        //   this.toastr.success('Templates fetched successfully', '');
        // }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });

  }

  formatDate(str : any) : any{
    let [year,month,day] = str.split("-");
    if(day.length == 1){
      day = '0' + day;
    }
    if(month.length == 1){
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }

  onSubmit() {
    let date  =  this.form.value.date.day + '-' +
    this.form.value.date.month + '-' +
    this.form.value.date.year;
    date = date.split("-").reverse().join("-");
    date = this.formatDate(date);
    // let date = this.mapDate(this.form.value.date); 
    // let date = this.mapDate(this.form.value.date);
    console.log("DDDDDDddddd", date);
    this.form.patchValue({
      date: date,
      name: this.form.value.name.trim(),
      location: this.form.value.location.trim()
    });
    console.log("Form", JSON.stringify(this.form.value));

    this.service.addProject(this.form.value).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.router.navigate([APP_ROUTES.listProject]).then(() => { });
        setTimeout(() => {
          // this.toastr.success('Project added successfully', '');
          this.toastr.success(this.translationService.instant('addProject.projectAddedMsgTsFile'));
        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  uploadImage(event: any): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }
        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then(resp => {
        this.handleImageAPI(resp);
      })
      .catch(err => {
        this.toastr.error(err, '');
      });
  }

  handleImageAPI(result: any): void {
    let input = new FormData();
    input.append('id', result.file);

    this.service
      .uploadImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.imageUrl = resp1.body.imageUrl;
          this.imageName = resp1.body.filename;
          this.form.patchValue({
            image: resp1.body.filename
          });
        },
        err => {
          // this.toastr.error('Something went wrong with image upload', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
        }
      );
  }

  checkIfTempAlreadySelected(selectedTemplate: any, currentIndex: any) {

    let tempIsSelectedAgain = false;

    let formArr = this.unitArrayControls();
    console.log("formArr", formArr)
    console.log("SELECTED TEMP ", selectedTemplate, " currentIndex ", currentIndex);
    // for(var obj of formArr.value){
    //   if(selectedTemplate.id == obj.template.id && index !==currentIndex){
    //     this.toastr.error(`Templated ${obj.template.name} already selected at ${index+1} position`, '');
    //   }
    //   console.log("FORMGRP ", obj)
    // }

    formArr.value.forEach((obj, index) => {
      console.log("index", index); // 0, 1, 2
      console.log("value", obj); // 9, 2, 5
      if (selectedTemplate.id == obj.template.id && index !== currentIndex) {
        tempIsSelectedAgain = true;
        this.unitArrayControls().at(currentIndex).patchValue({
          template: "",
        });
        this.toastr.error(`Template ${obj.template.name.toLowerCase()} is already selected at ${index + 1} position`, '');
      }
    });

    return tempIsSelectedAgain;

  }

  onOptionsSelected(value: any, index: any, event: any) {

    console.log("Event", event.target.selectedOptions[0]["data-sanilJaspal"]);
    // let indexOfTemp = value.split(":", 2);
    // indexOfTemp = parseInt(indexOfTemp[0], 10);
    // let selectedTemplate = this.templateList[indexOfTemp];
    let selectedTemplate = event.target.selectedOptions[0]["data-sanilJaspal"];
    console.log("the selected value is " + value, "Index is ", index, "Obj ", selectedTemplate);

    // check if the value is already selected in any other form control if yes then
    // give an error and patch empty value to projectid of the current formcontrol
    let tempIsSelectedAgain = false;
    tempIsSelectedAgain = this.checkIfTempAlreadySelected(selectedTemplate, index);
    console.log("tempIsSelectedAgain ", tempIsSelectedAgain);
    if (tempIsSelectedAgain) {
      return;
    }

    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")

    let valueFromTempList;
    let obj;
    let indexForDelete: number;

    if (this.selectedTemplates.length > 0) {

      // Check if modified on same index
      for (let temp of this.selectedTemplates) {
        if (temp.formIndex == index) {
          valueFromTempList = temp;
          indexForDelete = this.selectedTemplates.indexOf(temp);
          break;
        };
      };
    };

    // If mofified on same index then delete the prev obj and push new obj to arr
    if (valueFromTempList) {
      let oldTempId = valueFromTempList.newTempId;
      let olTempName = valueFromTempList.newTempName;

      obj = {
        oldTempId: oldTempId,
        olTempName: olTempName,
        newTempId: selectedTemplate.id,
        newTempName: selectedTemplate.name,
        formIndex: index
      };

      // const index2: number = this.selectedTemplates.indexOf(obj2 => obj2.formIndex == index);

      if (indexForDelete !== -1) {
        this.selectedTemplates.splice(indexForDelete, 1);
        console.log("ARR after del ", this.selectedTemplates)
      }
    }
    else {

      obj = {
        oldTempId: '',
        olTempName: '',
        newTempId: selectedTemplate.id,
        newTempName: selectedTemplate.name,
        formIndex: index
      };

    }
    console.log("obj before push ", obj)
    this.selectedTemplates.push(obj);
    console.log("ARR after push ", this.selectedTemplates)
  }

  checkIfTemplateSelected(template: any, index: any) {
    if (this.selectedTemplates.length > 0) {
      for (let temp of this.selectedTemplates) {
        if (temp.newTempName == template && temp.formIndex !== index) {
          return true;
        };
        return false;
      }
    }
    return false;
  }


  updateSelectedArrayAfterDelete() {
    // forarr ka loop ghumao
    // har element ko get kro selected arr me se and oska formIndex upfate kro

    let formArr = this.unitArrayControls();
    console.log("formArr", formArr)

    console.log("BEFORE SelectedTemplates", this.selectedTemplates)

    formArr.value.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete", index);
      console.log("value updateSelectedArrayAfterDelete", obj);
      // obj units template -> id name
      this.updateIndexesInSelectedTemplates(obj.template, index);
    });

    console.log("AFTER SelectedTemplates", this.selectedTemplates)

  }

  updateIndexesInSelectedTemplates(temp: any, currentIndex: any) {
    this.selectedTemplates.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete selectedTemplates", index);
      console.log("value updateSelectedArrayAfterDelete selectedTemplates", obj);
      // obj units template -> id name

      if (obj.newTempId == temp.id) {
        obj.formIndex = currentIndex;
        // return
      }

    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


}
