import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  public getProjectList(payload?: any): Observable<any> {
    const url: string = ApiRoutes.getProjectList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addProject(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProject;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public getClientDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getClientDetails;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public deleteProject(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteProject;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public getTemplatesList(): Observable<any> {
    const url: string = ApiRoutes.getTemplatesList;
    return this.http.get(url, { observe: 'response' });
  }

  public updateProduct(payload : any): Observable<any> {
    const url: string = ApiRoutes.updateProject;
    return this.http.post(url,payload, { observe: 'response' });
  }

  public deleteTemplate(payload : any): Observable<any> {
    const url: string = ApiRoutes.deleteTemplateFromProject;
    return this.http.post(url,payload, { observe: 'response' });
  }

  public getProjectDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProjectDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public uploadImage(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadImage;
    return this.http.post(url, payload, { observe: 'response' });
  }
  
}
