import { TimelineService } from './timeline.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @ViewChild('addDoc') addDocModal: any;
  private unsubscribe$ = new Subject();
  currentUser: any;
  senderId: any;
  recieverId: any;
  docUrl: any;
  docName: any;
  docList: any[] =[];
  msgHistory: any;
  projects: any;
  clientInfo: any;
  imgBaseUrl = environment.imgBaseUrl;
  showHide:any = false;
  pdfFormControl:any= false;
  toggleDropdownMenu = false;
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Document?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  isUploaded :boolean = false
  form = this.fb.group({
    sender_id: ['', [Validators.required]],
    client_id: ['', [Validators.required]],
    receiver_id: ['', [Validators.required]],
    message: ['', [Validators.maxLength(300)]],
    document: [''],
    type: ['', [Validators.required, Validators.maxLength(30)]],
    sender_type: ['', [Validators.required, Validators.maxLength(30)]],
    clientdocumentid: [0],
  });

  catDocForm= this.fb.group({
   pdf: ['',Validators.required],
   temp_pdf:[''],
      doc_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          this.noWhitespaceValidator,
          Validators.pattern(/^[a-zA-Z0-9_ÄäÖöÜüÆæß,\s]*$/),
        ],
      ],
      cat_id: ['', Validators.required],
      // project_id:['', Validators.required],
      // template_id:['', Validators.required]
  });

  docFile: any = '';
  docId: any = 0;
  docCategoryList: any[] = [];
  submitted: boolean =false;
  newProjectList: any;
  updatedProjectList: any[];
  activeProject: any;
  selectedData: any;
  selectedData2: string;
  activeTemplate: any;
  ProjectDocList: any;
  allFilteredDocs: any[];
  isContentLoaded: boolean;
  isDocumentExist: boolean;
  docDeleteObj: any;
  DocId: any;
  docrecords: any;
  isEdit:boolean = false;
  constructor(
    private localStorage: LocalStorageService,
    private service: TimelineService,
    public fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private cdr:ChangeDetectorRef,
    private modal:NgbModal,
    private baseService: BaseService,
  ) {}

  ngOnInit(): void {
    this.initSenderAndRecieverId();
    this.getMessageHistory();
    // this.getUploadedDocList();
    this.showllistDocCategory();
    // this.updateReadMessageCount();
    this.getClientProjects();
    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Delete';
        this.popoverMessage = 'Delete document?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Löschen';
        this.popoverMessage = 'Dokument löschen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Stornieren';
      }
    });
  }
  getClientProjects(){

    let payload = {
      id : this.recieverId
    }

    this.service.getClientDetails(payload).subscribe((data: any) => {
      if (data.status === 200) {
      
      this.updatedProjectList = data.body.clientRec?.ClientProjects;
      setTimeout(() => {
        let activeProject: any[] = this.updatedProjectList[0];
        this.activeProject = activeProject;
        this.selectedData = this.activeProject?.Project?.name;

        this.selectedData2 = ` | ${this.activeProject?.Template?.name} | ${this.activeProject?.apartment_name}`;

        this.activeTemplate = this.activeProject?.template_id;
        this.getDoclistByCAT();
      }, 200);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  // getAllClientProjectList(isUpdated?: boolean) {
  //   this.service.getAllClientProjects().subscribe(
  //     (data: any) => {
  //       if (data.status === 200) {
  //         this.newProjectList = [];
  //         this.newProjectList = data?.body?.projectTemplateRec;

  //         this.updatedProjectList = [];
  //         this.newProjectList.filter((list: any) => {
  //           return list?.projectdetail.map((details: any) => {
  //             this.updatedProjectList.push({
  //               progressPercentage:
  //                 (+details.roomAddedWhist / +details.totalRoom) * 100,
  //               details,
  //               project: list,
  //             });
  //           });
  //         });
  //         this.updatedProjectList.forEach(
  //           (n) => delete n?.project?.projectdetail
  //         );
  //         setTimeout(() => {
  //           let activeProject: any[] = this.updatedProjectList[0];
  //           this.activeProject = activeProject;
  //           this.selectedData = this.activeProject?.project?.name;

  //           this.selectedData2 = ` | ${this.activeProject?.details?.Template?.name} | ${this.activeProject?.details?.apartment_name}`;

  //           this.activeTemplate = this.activeProject?.details?.template_id;
  //           this.getDoclistByCAT();
  //         }, 200);
  //       }
  //     },
  //     (err) => {
  //       this.toastr.error(err.error.message, '');
  //     }
  //   );
  // }

  getDoclistByCAT() {
    console.log(this.activeProject, 'activ ptojj');
    
    let payload = {
      userid: this.recieverId,
      project_id: this.activeProject?.Project?.id,
      template_id: this.activeProject?.template_id,
    };
    this.service.templateWiseDocs(payload).subscribe((data) => {
      this.ProjectDocList = data.body.docmunets;
      this.allFilteredDocs = [];
      let flrec: any = [];
      // this.ProjectDocList.reverse();
      this.isContentLoaded = true;
      this.ProjectDocList.forEach((list) => {
        list?.ClientDocuments?.sort(function (a, b) {
          return (
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
          );
        });
        list['createdAtlatest'] = list?.ClientDocuments[0]?.createdAt;
        list?.ClientDocuments?.forEach((x) => {
          this.isDocumentExist = true
          let obj = {
            cat: list?.title,
            ...x,
          };
          flrec.push(obj);
        });
      });
      this.ProjectDocList.sort(function (a, b) {
        return (
          // new Dateb?.updatedAt ? b?.updatedAt : b?.createdAt).getTime() -
          // new Date(a?.updatedAt ? a?.updatedAt : a?.createdAt).getTime()
          new Date(b?.createdAtlatest).getTime() -
          new Date(a?.createdAtlatest).getTime()
        );
      });

      flrec.sort(function (a, b) {
        return (
          new Date(b?.createdAtlatest).getTime() -
          new Date(a?.createdAtlatest).getTime()
        );
      });
      this.allFilteredDocs = flrec;
    });
  }

  OnClickupdatedata(p: any, pi: number) {
    this.toggleDropdownMenu = false;
    
    this.isContentLoaded = false;
    this.isDocumentExist = false;
    this.selectedData = p?.Project?.name;
    this.selectedData2 = ` | ${p?.Template?.name} | ${p?.apartment_name}`;
    this.activeProject = p;
    // this.getDoclist();
    // if(p?.template_id === this.activeProject?.template_id){
    //   return 
    // }
    this.getDoclistByCAT();
  }

  pdfClose() {
    this.pdfFormControl = false;
  }

  closeModal(){
    this.isEdit = false;
    this.showHide =false
    this.pdfFormControl = true
    this.modal.dismissAll();
  }

  DeleteDoc(str:boolean, data:any) {
    if (str) {
    let obj = data;
    let payload = {
      id: obj.id,
      userid: this.recieverId,
      project_id: this.activeProject?.Project?.id,
      template_id: this.activeProject?.template_id
    };

    this.service.DeleteDoc(payload).subscribe((res) => {
      this.getDoclistByCAT();
    });
  }else{
    return
  }
  }

  getDoclistById(obj: any) {
    this.isEdit = true;
    this.DocId = obj.id;
    this.showHide = false;
    let payload = {
      userid: this.recieverId,
      id: obj.id,
      project_id: this.activeProject?.Project?.id,
      template_id: this.activeProject?.template_id,
    };

    this.service.getTempDocByID(payload).subscribe((res) => {
      this.docrecords = res?.body?.docmunets;
      this.createForm(this.docrecords);
     
    });
  }
  createForm(data: any) {
    this.showHide =true
    this.openModal();
    this.catDocForm.patchValue({
      pdf: this.getStr(data.document),
      temp_pdf:data.document,
      doc_name: data.name,
      cat_id: data.catid,
    });
    this.catDocForm.updateValueAndValidity();
  }

  getStr(value):string{
if(value?.lastIndexOf("_") > 0){
  const lastIndex = value?.lastIndexOf("_");
  const startingStr = value?.substring(0, lastIndex);
  const lastValue = value?.substring(lastIndex + 1)?.split('.')[1];
  return `${startingStr}.${lastValue}`
}else{
  return value
}
  }
  uploadProjectDoc(){
   if(this.isEdit){
    this.updatedoc()
   }else{
    this.uploadProjectDocs()
   }
  }
  uploadProjectDocs() {
    this.submitted = true;
    let data = this.catDocForm.value;

    if (data?.pdf) {
      if (this.catDocForm.valid) {
        let payload = {
          docfile: data.pdf,
          doc_name: data.doc_name,
          cat_id: data.cat_id,
          // project_id:data?.project_id,
          // template_id:data?.template_id,
          project_id: this.activeProject?.Project?.id,
          template_id:
            this.activeProject?.template_id,
          userid: this.recieverId,
        };

        this.service.addProjectDoc(payload).subscribe((res) => {
          if (res) {
            // document.getElementById('closeModal').click();
            this.modal.dismissAll();
            this.toastr.success(
              this.translationService.instant('timeline.addedsuccessfully'),
              '',
              { timeOut: 5000 }
            );
            // this.getUploadedDocList();
            this.getDoclistByCAT();
            this.catDocForm.reset();
            this.catDocForm.controls['cat_id'].patchValue('');
            this.showHide = false;
            this.submitted = false;
            this.updateInput();
          } else {
            this.toastr.error(
              this.translationService.instant('timeline.pleaseadddocuments'),
              ''
            );
          }
          // this.form.patchValue({
          //   pdf: res.body.filename,
          // });
        });
      } else {
        this.form.markAllAsTouched();
      }
    } else {
      this.toastr.error(
        this.translationService.instant('timeline.pleaseadddocuments'),
        '',
        { timeOut: 5000 }
      );
    }
  }

  updatedoc() {
    let data = this.catDocForm.value;
    if (data.pdf) {
      if (this.catDocForm.valid) {

        let payload = {
          id: this.DocId,
          docfile: data.temp_pdf,
          doc_name: data.doc_name,
          cat_id: data.cat_id,
          userid: this.recieverId,
          project_id: this.activeProject?.Project?.id,
          template_id:
            this.activeProject?.template_id,
        };
        this.service.updateDocByID(payload).subscribe((res) => {
          // document.getElementById('closeModaledit').click();
          this.modal.dismissAll();
          this.getDoclistByCAT();
          this.catDocForm.reset();
          this.catDocForm.controls['cat_id'].patchValue('');
          this.updateInput();
          this.showHide = false;
          this.isEdit = false
        });

      } else {
        this.form.markAllAsTouched();
      }
    } else {
      this.toastr.error('Please select document');
    }
  }


  updateInput() {
    let inputids = document.getElementsByTagName('input').length;
    for (let index = 0; index < inputids; index++) {
      const element = document.getElementsByTagName('input').item(index);
      if (element.id === 'file-upload') {
        element.value = '';
      }
    }
  }

  showllistDocCategory() {
    let payload = {
      search: '',
      page_start: 1,
      page_limit: 100,
    };
    this.service.listDocCategory(payload).subscribe((data) => {
      if (data?.body?.status) {
        this.docCategoryList = [];
        this.docCategoryList = data?.body?.catalogue;
      }
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  initSenderAndRecieverId() {
    this.currentUser = this.localStorage.getItem('currentUser');
    this.senderId = this.currentUser.id;

    this.route.queryParams.subscribe((params) => {
      this.recieverId = +params['rId'];
    });

    this.prepForm();
    // console.log("currentUser",this.currentUser);
  }

  prepForm() {
    this.form.patchValue({
      sender_type: 'admin',
      sender_id: this.senderId,
      client_id: this.recieverId,
      receiver_id: this.recieverId,
    });
  }

  sendMessageOrDoc(type: any, input: any) {
    if (type === 'msg') {
      if (input.value.trim().length == 0) {
        // this.toastr.error('Whitespace not accepted', '');
        this.toastr.error(
          this.translationService.instant('timeline.WhitespaceMsg')
        );
        return;
      }

      this.form.patchValue({
        message: input.value,
        type: 'message',
      });
    } else if (type === 'doc') {
      this.form.patchValue({
        message: input,
        type: 'document',
      });
    }

    this.service.sendMessage(this.form.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          if (type === 'msg') {
            input.value = '';
            // this.toastr.success('Message Sent', '');
            this.toastr.success(
              this.translationService.instant('timeline.MessageSent')
            );
          } else if (type === 'doc') {
            // this.toastr.success('Document uploaded successfully', '');
            this.toastr.success(
              this.translationService.instant('timeline.DocumentUploaded')
            );
          }
          this.clearForm();
          this.getMessageHistory();
          this.getUploadedDocList();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  clearForm() {
    this.form.patchValue({
      message: '',
      document: '',
      type: '',
    });
    this.docUrl = '';
    this.docName = '';
  }

  getMessageHistory() {
    let payload = {
      type: 'message',
      client_id: this.form.value.client_id,
    };

    this.service.getMessageHistory(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          this.msgHistory = data?.body?.messageRec;
          this.projects = data.body.clientRec?.ClientProjects;
          this.clientInfo = data?.body?.clientRec;
          setTimeout(() => {
            this.getSmoothScroll('islast');
          }, 300);
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  getSmoothScroll(id: string) {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }

  getUploadedDocList() {
    let payload = {
      type: 'document',
      client_id: this.form.value.client_id,
    };

    this.service.getMessageHistory(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          this.docList = data.body.messageRec;
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  shareDoc(doc:any){
    console.log(doc);
    this.sendForDocOnly(doc);
  }

  updateReadMessageCount() {
    let payload = {
      id: this.form.value.client_id,
    };

    this.service.updateReadMessageCount(payload).subscribe(
      (data: any) => {
        console.log(data, 'doctlist');
        
        if (data.status === 200) {
          console.log('RESPONSE', data);
          this.docList = data.body.messageRec;
          // this.cdr.detectChanges()
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  uploadDoc(event: any): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        this.isUploaded=true
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('timeline.pleaseselectfilelessthan5mb'));
          
        }
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        const reader = new FileReader();
        reader.onload = (): void => {
          // this.imgURL = reader.result;
          let payload = {
            file,
            contentType,
          };
          resolve(payload);
        };
        reader.readAsDataURL(file);
        this.isUploaded=false
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then((resp) => {
        this.uploadDocApi(resp);
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
  }

  uploadDocApi(result: any): void {
    let input = new FormData();
    input.append('id', result.file);

    this.service
      .uploadDoc(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.docUrl = resp1.body.imageUrl;
          this.docName = resp1.body.filename;

          this.form.patchValue({
            document: resp1.body.filename,
          });
          this.catDocForm.patchValue({
            pdf: resp1.body.filename,
          });
          this.showHide = true

          this.isUploaded= false
          // this.sendMessageOrDoc('doc', this.docName);
          // this.getUploadedDocList();
        },
        (err) => {
          this.toastr.error(
            this.translationService.instant(
              'timeline.somethingwentwrongwithimageupload'
            ),
            ''
          );
        }
      );

     
  }

  sendForDocOnly(doc?:any) {
    let payload;
    if('id' in doc){
      payload = {
        sender_id: this.senderId,
        receiver_id: this.recieverId,
        message: '',
        document: doc?.document,
        type: 'document',
        sender_type: 'admin',
        clientdocumentid: (doc?.ClientDocumentId || doc?.client_document_id) || 0,
        client_id: this.recieverId,
        lang_type: this.translationService.currentLang
      };
    }else{
      payload = {
        sender_id: this.senderId,
        receiver_id: this.recieverId,
        message: '',
        document: this.docName,
        type: 'document',
        sender_type: 'admin',
        clientdocumentid: this.docId || 0,
        client_id: this.recieverId,
        lang_type: this.translationService.currentLang
      };
    }
   
    this.service.sendMessage(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          // this.toastr.success('Document uploaded successfully', '');
          this.toastr.success(
            this.translationService.instant('timeline.DocumentUploaded')
          );
          this.getMessageHistory();
          this.getUploadedDocList();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  downloadDoc(data: any) {
    console.log(data);

    let docName = data?.document;
    let blob = environment.imgBaseUrl + docName;
    let wert = this.getFileFromUrl(blob, docName);
    wert.then((res) => {
      var reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = function () {
        console.log(reader.result);
        let fileData: any = reader.result;
        const link = document.createElement('a');
        link.setAttribute('download', docName);
        link.setAttribute('target', '_blank');
        link.setAttribute('href', fileData);
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    });
  }

  async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  downloadMyFile(docName: any) {
    console.log(
      'environment.imgBaseUrl + docName',
      environment.imgBaseUrl + docName
    );
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.imgBaseUrl + docName);
    link.setAttribute('download', docName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onDown(type: string, fromRemote: boolean) {
    this.service.onDown(type, fromRemote);
  }

  openModal() {
    this.modal
    .open(this.addDocModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
        );
      }

      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
}
