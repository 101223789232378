<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
  <!-- Content Header (Page header) -->
  <section class="content-header p-0 mb-3">
    <div class="row mb-2 no-gutters">
      <div class="col-lg-6 col-12">
        <h1>
          {{ "DocCategory.documentcategories" | translate }}
        </h1>
      </div>
      <div class="col-lg-6 col-12 mt-2 mt-lg-0">
        <div class="d-lg-flex justify-content-end">
          <div class="mr-3">
            <div class="input-group">
              <input
                #searchVA
                (keyup.enter)="search($event)"
                type="text "
                class="form-control"
                placeholder=" {{ 'DocCategory.searchdocument' | translate }}"
              />
              <div class="input-group-append">
                <span class="input-group-text f-700 onhover"
                  ><i
                    class="fa fa-search"
                    (click)="search(searchVA.value, true)"
                  ></i
                ></span>
              </div>
              <div class="input-group-append">
                <span class="input-group-text f-700 bg-primary-2 onhover"
                  ><i
                    class="fas fa-redo-alt"
                    (click)="clearSearch(searchVA)"
                  ></i
                ></span>
              </div>
            </div>
          </div>
          <div *ngIf="permissions.document==1">
            <a
              (click)="clearForm()"
              class="btn btn-primary mt-2 mt-lg-0 onhover"
              data-toggle="modal"
              data-target="#addCtg"
              href="javascript:; "
              ><i class="fa fa-plus"></i>
              {{ "DocCategory.addcategory" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Content Header (Page header)  -->
  </section>

  <div class="card card-primary">
    <div class="card-header">
      <div class="w-100 d-flex justify-content-between">
        <span> {{ "DocCategory.documentcategorylisting" | translate }}</span>
        <span *ngIf="DocCategorylist">
          {{ "DocCategory.totalcategories" | translate }} 
          {{ totalItems }}</span
        >
      </div>
    </div>

    <div class="card-body table">
      <table class="table table-bordered table-striped table-hover">
        <thead class="table-dark">
          <tr>
            <th style="width: 70px">
              <!-- Sr No. -->
              {{ "listClient.srNo" | translate }}.
            </th>
            <th>{{ "DocCategory.categoryname" | translate }}</th>
            <th style="width: 250px" class="text-center">
              <!-- Action -->
              {{ "addFeature.actions" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let docList of DocCategorylist; index as i">
            <!-- <td *ngIf="page === 1">{{ i + 1 }}</td>
            <td class="align-middle" *ngIf="page !== 1">
              {{ page * 10 - 10 + (i + 1) }}
            </td> -->
            <td>{{ i + 1 + (page - 1) * 10 }}</td>
            <td>{{ docList.title }}</td>
            <td style="width: 250px">
              <div *ngIf="permissions.document==1"
                class="w-100 d-inline-flex justify-content-center align-items-center"
              >
                <a
                  href="javascript:; "
                  class="mr-4"
                  data-toggle="modal"
                  data-target="#editCtg"
                  (click)="getDocListId(docList)"
                >
                  <i class="fa fa-pencil-alt"></i
                ></a>
                <a 
                  href="javascript:; "
                  mwlConfirmationPopover
                  [popoverTitle]="popoverTitle"
                  [popoverMessage]="popoverMessage"
                  [confirmText]="confirmText"
                  [cancelText]="cancelText"
                  placement="top"
                  (confirm)="deleteDoclist(true, docList.id)"
                  (cancel)="deleteDoclist(false, docList.id)"
                >
                  <i class="fa fa-trash text-danger"></i
                ></a>
              </div>
            </td>
          </tr>

          <!-- <tr>
            <td>2</td>
            <td>Purchase documents</td>
            <td style="width: 250px">
              <div
                class="w-100 d-inline-flex justify-content-center align-items-center"
              >
                <a
                  href="javascript:; "
                  class="mr-4"
                  data-toggle="modal"
                  data-target="#editCtg"
                >
                  <i class="fa fa-pencil-alt"></i
                ></a>
                <a href="javascript:; ">
                  <i class="fa fa-trash text-danger"></i
                ></a>
              </div>
            </td>
          </tr>

          <tr>
            <td>3</td>
            <td>Delivery documents</td>
            <td style="width: 250px">
              <div
                class="w-100 d-inline-flex justify-content-center align-items-center"
              >
                <a
                  href="javascript:; "
                  class="mr-4"
                  data-toggle="modal"
                  data-target="#editCtg"
                >
                  <i class="fa fa-pencil-alt"></i
                ></a>
                <a href="javascript:; ">
                  <i class="fa fa-trash text-danger"></i
                ></a>
              </div>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Other documents</td>
            <td style="width: 250px">
              <div
                class="w-100 d-inline-flex justify-content-center align-items-center"
              >
                <a
                  href="javascript:; "
                  class="mr-4"
                  data-toggle="modal"
                  data-target="#editCtg"
                >
                  <i class="fa fa-pencil-alt"></i
                ></a>
                <a href="javascript:; ">
                  <i class="fa fa-trash text-danger"></i
                ></a>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
  <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
    <div class="col-md-12 text-right">
      <div class="pagination justify-content-end">
        <div class="page-item">
          <pagination
            [boundaryLinks]="showBoundaryLinks"
            [firstText]="firstText"
            [lastText]="lastText"
            [previousText]="prevText"
            [nextText]="nextText"
            [totalItems]="totalItems"
            [(ngModel)]="startPage"
            (pageChanged)="pageChanged($event)"
            [maxSize]="maxSize"
          >
          </pagination>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Modal -->
  <div
    class="modal fade"
    id="addCtg"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addCtgModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "DocCategory.addcategory" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="docForm">
            <input
              #title
              type="text"
              style="height: 50px; font-weight: 600; color: #000"
              placeholder=" {{ 'DocCategory.entercategoryname' | translate }}"
              class="form-control"
              id="title"
              formControlName="title"
              required
            />
            <ng-container
              *ngIf="
                docForm.controls['title'].errors &&
                (docForm.get('title').dirty || docForm.get('title').touched)
              "
            >
              <small
                class="text-danger error-msg"
                *ngIf="docForm.controls['title'].hasError('required')"
                >*
                <!-- Label name is
                                    required. -->
                Name is required</small
              >
              <small
                class="text-danger error-msg"
                *ngIf="docForm.controls['title'].hasError('pattern')"
                >*
                <!-- Label name is
                                    required. -->
                Only allowed alphabets</small
              >
              <small
                class="text-danger error-msg"
                *ngIf="
                  !docForm.controls['title'].hasError('required') &&
                  docForm.controls['title'].hasError('whitespace')
                "
                >* Only whitespace is not allowed.</small
              >
            </ng-container>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                aria-label="Close"
                (click)="addDocCategory(title.value)"
                [disabled]="docForm.invalid"
              >
                {{ "DocCategory.savecategory" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Modal -->
  <div
    class="modal fade"
    id="editCtg"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editCtgModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ "DocCategory.addcategory" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="docForm">
            <input
              type="text"
              placeholder="Category Name"
              style="height: 50px; font-weight: 600; color: #000"
              class="form-control"
              formControlName="title"
            />
            <ng-container
              *ngIf="
                docForm.controls['title'].errors &&
                (docForm.get('title').dirty || docForm.get('title').touched)
              "
            >
              <small
                class="text-danger error-msg"
                *ngIf="docForm.controls['title'].hasError('required')"
                >*
                <!-- Label name is
                                  required. -->
                Name is required</small
              >
              <small
                class="text-danger error-msg"
                *ngIf="docForm.controls['title'].hasError('pattern')"
                >*
                <!-- Label name is
                                  required. -->
                Only allowed alphabets</small
              >
              <small
                class="text-danger error-msg"
                *ngIf="
                  !docForm.controls['title'].hasError('required') &&
                  docForm.controls['title'].hasError('whitespace')
                "
                >* Only whitespace is not allowed.</small
              >
            </ng-container>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ "DocCategory.close" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            [disabled]="docForm.invalid"
            (click)="updateDocCat()"
          >
            {{ "DocCategory.updatecategory" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
