<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->
    <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <div class="col-md-auto">
                    <ol class=" breadcrumb ">
                        <li class="breadcrumb-item "><a href="javascript:; ">Dashboard</a></li>
                        <li class="breadcrumb-item active " *ngIf="operation !== 'view'">Edit Client</li>
                        <li class="breadcrumb-item active " *ngIf="operation === 'view'">View Client</li>
                    </ol>
                </div>



            </div>
        </div>
    </section> -->


    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span *ngIf="operation !== 'view'"> 
                    <!-- Edit Client -->
                    {{'editClient.editClient' | translate}}
                </span>
                <span *ngIf="operation === 'view'"> 
                    <!-- View Client -->
                        {{'editClient.viewClient' | translate}}
                </span>

            </div>

        </div>

        <div class="card-body ">
            <form novalidate [formGroup]="form">
                <div class="row">
                    
                    <div class="col-md-3 text-center">
                        <div class="img-container mt-lg-2">

                            <div class="img-priview" (click)="myInput.click()">
                                <img *ngIf="form.value.image" [src]="imgBaseUrl + form.value.image" />
                            </div>
                            <input class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event)" style="display: none;" id="selectedFile" />
                            <div class="support-format">
                                ( {{'addProject.supportFormat'|translate}} png, jpeg, jpg )
                            </div>
                            <div class="col-md-auto ">
                                <a class="btn btn-danger mt-3 " href="javascript:; " (click)="myInput.click()">
                                    <!-- Upload Image -->
                                    {{'addProduct.uploadImage' | translate}}</a>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-md-9">
                        <div class="row ml-lg-4">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- First Name  -->
                                        {{'addClient.firstName' | translate}}<span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.enterFirstName' | translate}}" formControlName="first_name" class="form-control">
        
                                    <ng-container *ngIf="form.controls['first_name'].errors &&
                        (form.get('first_name').dirty || form.get('first_name').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['first_name'].hasError('required')">
                                                <!-- First Name is
                                                required. -->
                                                {{'addClient.firstNameRequired' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['first_name'].hasError('maxlength')">*
                                            <!-- Maximum of 30
                                                characters. -->
                                                {{'addClient.charLimit30' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['first_name'].hasError('pattern')">*
                                            <!-- No
                                                special character allowed. -->
                                                {{'addClient.specialCharMsg' | translate}}
                                            </small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['first_name'].hasError('whitespace')">*
                                            <!-- Whitespace not allowed. -->
                                            {{'addClient.whitespaceNotAllowed' | translate}}
                                        </small>
                                    </ng-container>
        
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- Last Name  -->
                                            {{'addClient.lastName' | translate}}<span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.lastNamePlaceholder' | translate}}" formControlName="last_name" class="form-control">
        
                                    <ng-container *ngIf="form.controls['last_name'].errors &&
                        (form.get('last_name').dirty || form.get('last_name').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['last_name'].hasError('required')">
                                                <!-- Last Name is
                                                required. -->
                                                {{'addClient.lastNameRequired' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['last_name'].hasError('maxlength')">*
                                            <!-- Maximum of 30
                                                characters. -->
                                                {{'addClient.charLimit30' | translate}}
                                            </small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['last_name'].hasError('pattern')">*
                                            <!-- No
                                                special character allowed. -->
                                                {{'addClient.specialCharMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['last_name'].hasError('whitespace')">*
                                            <!-- Whitespace not allowed. -->
                                            {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                    </ng-container>
        
        
                                </div>
                            </div>
        
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- Email Address  -->
                                        {{'addClient.emailAddress' | translate}} <span class="text-danger">*</span></label>
                                    <input [readonly]="true" type="email" placeholder="{{'addClient.enterEmail' | translate}}" formControlName="email" class="form-control">
        
                                    <ng-container *ngIf="form.controls['email'].errors &&
                        (form.get('email').dirty || form.get('email').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['email'].hasError('required')">
                                                <!-- Email is
                                                required. -->
                                                {{'addClient.emailRequiredMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['email'].hasError('maxlength')">*
                                            <!-- Maximum of 60
                                                characters. -->
                                                {{'addClient.charLimit60' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['email'].hasError('email')">*
                                            <!-- Enter valid email. -->
                                            {{'addClient.validEmailMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['email'].hasError('whitespace')">*Whitespace not allowed.</small>
                                    </ng-container>
        
        
                                </div>
                            </div>
        
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- Contact Number -->
                                        {{'addClient.ContactNumber' | translate}} <span class="text-danger"></span></label>
                                    <input [readonly]="operation === 'view'" type="tel" placeholder="Enter Contact Number" formControlName="contact_number" class="form-control">
        
                                    <!-- <ng-container *ngIf="form.controls['contact_number'].errors &&
                        (form.get('contact_number').dirty || form.get('contact_number').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['contact_number'].hasError('required')"> -->
                                                <!-- Contact Number is
                                                required. -->
                                                <!-- {{'addClient.contactNumberRequiredMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['contact_number'].hasError('maxlength')">* -->
                                            <!-- Maximum of 30
                                                characters. -->
                                                <!-- {{'addClient.charLimit11' | translate}}</small> -->
                                        <!-- <small class="text-danger error-msg" *ngIf="form.controls['contact_number'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['contact_number'].hasError('whitespace')">*
                                            Whitespace not allowed. -->
                                            <!-- {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                    </ng-container> -->
        
        
                                </div>
                            </div>
        
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{'addClient.mobileNumber'|translate}} <span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="tel" placeholder="Enter Mobile Number" formControlName="mobile_number" class="form-control">
        
                                    <ng-container *ngIf="form.controls['mobile_number'].errors &&
                    (form.get('mobile_number').dirty || form.get('mobile_number').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['mobile_number'].hasError('required')">
                                            Mobile Number is required.</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['mobile_number'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['mobile_number'].hasError('whitespace')">*
                                            <!-- Whitespace not allowed -->
                                            {{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- Postal code -->
                                        {{'addClient.postalCode' | translate}} <span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.enterPostalCode' | translate}}" formControlName="postal_code" class="form-control">
        
                                    <ng-container *ngIf="form.controls['postal_code'].errors &&
                        (form.get('postal_code').dirty || form.get('postal_code').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['postal_code'].hasError('required')">*
                                                <!-- Postal Code is
                                                required. -->
                                                {{'addClient.postalCodeRequired' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['postal_code'].hasError('maxlength')">*
                                            <!-- Maximum of 30
                                                characters. -->
                                                {{'addClient.charLimit30' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['postal_code'].hasError('pattern')">*
                                            <!-- No
                                                special character allowed. -->
                                                {{'addClient.specialCharMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['postal_code'].hasError('whitespace')">*
                                            <!-- Whitespace not allowed. -->
                                            {{'addClient.whitespaceNotAllowed' | translate}} </small>
                                    </ng-container>
        
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{'addClient.city' | translate}} <span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.enterCity' | translate}}" formControlName="city" class="form-control">
        
                                    <ng-container *ngIf="form.controls['city'].errors &&
                        (form.get('city').dirty || form.get('city').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['city'].hasError('required')">*
                                                {{'addClient.cityRequired' | translate}}</small>
                                    </ng-container>
        
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        <!-- Address -->
                                        {{'addClient.address' | translate}} <span class="text-danger">*</span></label>
                                    <!-- <textarea [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.enterAddress' | translate}}" formControlName="address"    class="form-control resize-none"></textarea> -->
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addClient.enterAddress' | translate}}" formControlName="address"    class="form-control resize-none">
        
                                    <ng-container *ngIf="form.controls['address'].errors &&
                        (form.get('address').dirty || form.get('address').touched)">
                                        <small class="text-danger error-msg" *ngIf="form.controls['address'].hasError('required')">
                                                <!-- Address is
                                                required. -->
                                                {{'addClient.addressRequiredMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['address'].hasError('maxlength')">*
                                            <!-- Maximum of 300
                                                characters. -->
                                                {{'addClient.charLimit300' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['address'].hasError('pattern')">*
                                            <!-- No
                                                special character allowed. -->
                                                {{'addClient.specialCharMsg' | translate}}</small>
                                        <small class="text-danger error-msg" *ngIf="form.controls['address'].hasError('whitespace')">*
                                            <!-- Whitespace not allowed. -->
                                            {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                    </ng-container>
        
        
                                </div>
                            </div>
        
                            <div
                            class="col-6 offset-6"
                          >
                          <div class="form-group">
                            <label>
                                {{'addClient.lang' | translate}}<span class="text-danger">*</span>
                            </label>
                          <select formControlName="lang_type" class="form-control">
                            <option [value]="1">{{'addClient.english'|translate}}</option>
                            <option [value]="2">{{'addClient.german'|translate}}</option>
                          </select>
                          <ng-container *ngIf="form.controls['lang_type'].errors &&
                          (form.get('lang_type').dirty || form.get('lang_type').touched)">
                                            <small class="text-danger error-msg"
                                                *ngIf="form.controls['lang_type'].hasError('required')">
                                                <!-- Address is
                                                required. -->
                                                {{'addClient.langReq' | translate}}
                                            </small>
                                            </ng-container>
                        </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-2 mb-md-3 mt-3 mt-md-5 text-center">
                        <h5 class="f-700 line-heading d-inline-block p-relative">
                            <!-- Assign/Allocate Project -->
                            {{'addClient.assignAllocateProject' | translate}}</h5>
                    </div>
                </div>

                <!-- {{selectedProjects | json}} -->

                <div class="w-100" formArrayName="clientProjects">
                    <div class="row box-shadow-section mb-3" *ngFor="let obj of projectsArrayControls().controls; let i=index" [formGroupName]="i">
                        <!-- <div class="col-md-12 mb-2 mb-md-3 mt-3 mt-md-5 text-center">
                            <h5 class="f-700 line-heading d-inline-block p-relative">Assign/allocate project</h5>
                        </div> -->



                        <div class="col-md-6">
<div class="form-group">
                            <label>
                                <!-- Project Name -->
                                    {{'addClient.projectName' | translate}} <span class="text-danger">*</span></label>
                            <select formControlName="project_id" class="input-group-text f-700 form-control" #mySelect (change)='onOptionsSelected(mySelect.value ,i, $event)' [attr.disabled]=" operation==='view' ? true : null ">
                                    <option hidden value=" " disabled selected>Select Project
                                    </option>
                                    <ng-container *ngFor="let project of projectList ">
                                        <option [data-sanilJaspal]="project "  [ngValue]="project.id ">
                                            {{project.name}}
                                        </option>
                                    </ng-container>
                                </select>

                            <ng-container *ngIf="obj.get( 'project_id').errors && (obj.get( 'project_id').dirty || obj.get( 'project_id').touched) ">
                                <small class="text-danger error-msg " *ngIf="obj.get( 'project_id').hasError( 'pattern') ">*No special
                                            character
                                            allowed.</small>
                                <small class="text-danger error-msg " *ngIf="obj.get( 'project_id').hasError( 'required') ">*Project is
                                            required.</small>
                                <small class="text-danger error-msg " *ngIf="obj.get( 'project_id').hasError( 'maxlength') ">*
                                    <!-- Maximum of 30
                                            characters -->
                                            {{'addFeature.charLimit30' | translate}}.</small>
                            </ng-container>



                            <!-- <div class="form-group ">
                                    <label>Project Name <span class="text-danger ">*</span></label>
                                    <input type="text " placeholder="Enter project_id " formControlName="project_id "
                                        class="form-control ">
    
                                    <ng-container *ngIf="obj.get( 'project_id').errors && (obj.get( 'project_id').dirty || obj.get( 'project_id').touched) ">
                                        <small class="text-danger error-msg "
                                            *ngIf="obj.get( 'project_id').hasError( 'pattern') ">*No special
                                            character
                                            allowed.</small>
                                        <small class="text-danger error-msg "
                                            *ngIf="obj.get( 'project_id').hasError( 'required') ">*project_id is
                                            required.</small>
                                        <small class="text-danger error-msg "
                                            *ngIf="obj.get( 'project_id').hasError( 'maxlength') ">*Maximum of 30
                                            characters.</small>
                                    </ng-container>
    
    
                                </div> -->

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label>
                                    {{'addClient.selectTemplateName' | translate}}<span class="text-danger">*</span>
                            </label>
                            <select formControlName="template_id" class="input-group-text f-700 form-control" #myTemplateSelect (change)='onOptionsTemplateSelected(myTemplateSelect.value ,i, $event)'>
                                <option hidden value="" disabled selected>
                                    <!-- Select Project -->
                                    {{'addClient.selectTemplateName' | translate}}
                                </option>
                                <option [value]="template.id" *ngFor="let template of obj.value.template_list"> {{template.name}}</option>
                                <!-- <ng-container *ngFor="let project of projectList">
                                    <option value="template" >Template</option>
                                </ng-container> -->
                            </select>

                            <ng-container *ngIf="obj.get('template_id').errors &&
                                (obj.get('template_id').dirty || obj.get('template_id').touched)">
                                    <small class="text-danger error-msg" *ngIf="obj.get('template_id').hasError('pattern')">*
                                        <!-- No special
                                            character
                                            allowed -->
                                            {{'addFeature.specialCharMsg' | translate}}.</small>
                                    <small class="text-danger error-msg" *ngIf="obj.get('project_id').hasError('required')">*Project Template is
                                            required.</small>
                                    <small class="text-danger error-msg" *ngIf="obj.get('project_id').hasError('maxlength')">*Maximum of 30
                                            characters.</small>
                                </ng-container>
                        </div>
                        
                        <div class="col-md-6 ">
                            <div class="form-group ">
                                <label>
                                    <!-- Apartment Name  -->
                                        {{'addClient.apartmentName' | translate}}<span class="text-danger ">*</span></label>
                                <input [readonly]="operation==='view' " type="text " placeholder="{{'addClient.enterApartmentName' | translate}}" formControlName="apartment_name" class="form-control ">

                                <ng-container *ngIf="obj.get( 'apartment_name').errors && (obj.get( 'apartment_name').dirty || obj.get( 'apartment_name').touched) ">
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_name').hasError( 'pattern') ">*
                                        <!-- No special
                                            character
                                            allowed. -->
                                            {{'addClient.specialCharMsg' | translate}}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_name').hasError( 'required') ">*
                                        <!-- Apartment Name is
                                            required. -->
                                            {{'addClient.apartmentNameRequired' | translate}}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_name').hasError( 'maxlength') ">*
                                        Maximum of 30
                                            characters.
                                            {{'addClient.charLimit30' | translate}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_name').hasError( 'whitespace') ">*
                                        <!-- Whitespace not allowed. -->
                                        {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                </ng-container>


                            </div>
                        </div>

                        <!-- <div class="col-md-6 ">
                            <div class="form-group ">
                                <label>
                                        {{'addClient.flatId' | translate}}<span class="text-danger ">*</span></label>
                                <input [readonly]="operation==='view' " type="text " placeholder="{{'addClient.enterFlatId' | translate}}" formControlName="flat_id" class="form-control ">

                                <ng-container *ngIf="obj.get( 'flat_id').errors && (obj.get( 'flat_id').dirty || obj.get( 'flat_id').touched) ">
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'flat_id').hasError( 'pattern') ">*
                                        {{'addProject.digitsAllowed' | translate}}.</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'flat_id').hasError( 'required') ">*
                                            {{'addClient.flatIdRequired' | translate}}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'flat_id').hasError( 'maxlength') ">*
                                            {{'addClient.charLimit30' | translate}}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'flat_id').hasError( 'whitespace') ">*
                                        {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                </ng-container>


                            </div>
                        </div> -->

                        <!-- <div class="col-md-6 ">
                            <div class="form-group ">
                                <label>
                                        {{'addClient.apartmentId' | translate}}<span class="text-danger ">*</span></label>
                                <input [readonly]="operation==='view' " type="text " placeholder="{{'addClient.enterApartmentId' | translate}}" formControlName="apartment_id" class="form-control ">

                                <ng-container *ngIf="obj.get( 'apartment_id').errors && (obj.get( 'apartment_id').dirty || obj.get( 'apartment_id').touched) ">
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_id').hasError( 'pattern') ">*
                                        {{'addProject.digitsAllowed' | translate}}.</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_id').hasError( 'required') ">*Apartment ID is
                                            required.</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_id').hasError( 'maxlength') ">*
                                            {{'addClient.charLimit30' | translate}}</small>
                                    <small class="text-danger error-msg " *ngIf="obj.get( 'apartment_id').hasError( 'whitespace') ">*
                                        {{'addClient.whitespaceNotAllowed' | translate}}</small>
                                </ng-container>


                            </div>
                        </div> -->

                        <div class="col-md-6">
                            <div class="form-group">
                                                        <label>
                                                            <!-- Project Status -->
                                                                {{'addClient.status' | translate}} <span class="text-danger">*</span></label>
                                                        <select formControlName="is_completed" class="input-group-text f-700 form-control" #mySelectStatus (change)='onOptionsSelectedStatus(mySelectStatus.value ,i, $event, obj)' [attr.disabled]=" operation==='view' ? true : null ">
                                                                <option hidden [value]="null" disabled selected>Select Status
                                                                </option>
                                                                <ng-container *ngFor="let status of statusList">
                                                                    <option [data-sanilJaspal]="status"  [ngValue]="status.id">
                                                                        {{status.name}}
                                                                    </option>
                                                                </ng-container>
                                                            </select>
                            
                                                        <ng-container *ngIf="obj.get( 'is_completed').errors && (obj.get( 'is_completed').dirty || obj.get( 'is_completed').touched) ">
                                                            <small class="text-danger error-msg " *ngIf="obj.get( 'is_completed').hasError( 'pattern') ">*No special
                                                                        character
                                                                        allowed.</small>
                                                            <small class="text-danger error-msg " *ngIf="obj.get( 'is_completed').hasError( 'required') ">*Project is
                                                                        required.</small>
                                                            <small class="text-danger error-msg " *ngIf="obj.get( 'is_completed').hasError( 'maxlength') ">*
                                                                <!-- Maximum of 30
                                                                        characters -->
                                                                        {{'addFeature.charLimit30' | translate}}.</small>
                                                        </ng-container>
                            
                                                        </div>
                                                    </div>

                        <div class="col-12 d-flex justify-content-end " *ngIf="operation !=='view' ">
                            <div class="form-group ">
                                <label class="w-100 ">&nbsp;</label>
                                <button *ngIf="i!==0 " type="button " class="btn btn-danger mr-2 " mwlConfirmationPopover [popoverTitle]="popoverTitle " [popoverMessage]="popoverMessage "
                                [confirmText]="confirmText"
                                    [cancelText]="cancelText"
                                placement="left " (confirm)="removeClientProject(i,true,obj)
                                " (cancel)="removeClientProject(i,false,obj) "><i class="fa fa-trash "></i></button>
                                <button *ngIf="getLastIndexOfArray(i) " type="button " (click)="addClientProject() " class="btn btn-success "><i class="fa fa-plus "></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer text-right ">
            <button *ngIf="operation !=='view' " class="btn btn-primary " [disabled]="form.invalid " (click)="onUpdate() ">
                <!-- Update Client -->
                    {{'editClient.updateClient' | translate}}
            </button>
            <button *ngIf="operation==='view' " class="btn btn-primary " (click)="goToClientList() ">
                <!-- Go To Client List -->
                {{'editClient.goToClientList' | translate}}</button>
            <button *ngIf="operation==='view'&& permissions.client == 1" class="btn btn-primary ml-2 " (click)="editClient()">
                <!-- Edit Client -->
                {{'editClient.editClient' | translate}}</button>
        </div>
    </div>

    <!-- {{form.value | json}} -->
    <br>
    <!-- Errors => {{getAllErrors(form) | json}} -->

</div>