import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class DocCategoryService {
  constructor(private http: HttpClient) {}

  public getDocs(payload: any): Observable<any> {
    const url: string = ApiRoutes.getDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public deleteDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public addDocCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.addDocCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateDocCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateDocCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
