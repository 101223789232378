import { ListProductComponent } from './components/post-auth-pages/admin/product/list-product/list-product.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeModule } from '@circlon/angular-tree-component';
import { ModalModule } from 'ngb-modal';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';

//Shared
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { DashboardComponent } from './components/post-auth-pages/admin/dashboard/dashboard.component';
import { MenubarComponent } from './shared/components/menubar/menubar.component';
import { TopNavbarComponent } from './shared/components/top-navbar/top-navbar.component';
import { AddCategoryComponent } from './components/post-auth-pages/admin/add-category/add-category.component';
import { AddFeatureComponent } from './components/post-auth-pages/admin/add-category/add-feature/add-feature.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditFeatureComponent } from './components/post-auth-pages/admin/add-category/add-feature/edit-feature/edit-feature.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { DeleteConfirmationComponent } from './shared/components/delete-confirmation/delete-confirmation.component';
import { AddFaqComponent } from './components/post-auth-pages/admin/faq/add-faq/add-faq.component';
import { ListFaqComponent } from './components/post-auth-pages/admin/faq/list-faq/list-faq.component';
import { EditFaqComponent } from './components/post-auth-pages/admin/faq/edit-faq/edit-faq.component';
import { AddProductComponent } from './components/post-auth-pages/admin/product/add-product/add-product.component';
import { EditProductComponent } from './components/post-auth-pages/admin/product/edit-product/edit-product.component';
import { CustomizeFeatureComponent } from './components/post-auth-pages/admin/product/customize-feature/customize-feature.component';
import { UpdateProductComponent } from './components/post-auth-pages/admin/product/edit-product/update-product/update-product.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AddTemplateComponent } from './components/post-auth-pages/admin/template/add-template/add-template.component';
import { ViewTemplateComponent } from './components/post-auth-pages/admin/template/view-template/view-template.component';
import { ListTemplateComponent } from './components/post-auth-pages/admin/template/list-template/list-template.component';
import { AddTemplateTypesComponent } from './components/post-auth-pages/admin/template/add-template/add-template-types/add-template-types.component';
import { AddProjectComponent } from './components/post-auth-pages/admin/project/add-project/add-project.component';
import { ListProjectComponent } from './components/post-auth-pages/admin/project/list-project/list-project.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListClientsComponent } from './components/post-auth-pages/admin/clients/list-clients/list-clients.component';
import { AddClientComponent } from './components/post-auth-pages/admin/clients/add-client/add-client.component';
import { EditProjectComponent } from './components/post-auth-pages/admin/project/edit-project/edit-project.component';
import { TimelineComponent } from './components/post-auth-pages/admin/timeline/timeline.component';
import { EditClientComponent } from './components/post-auth-pages/admin/clients/edit-client/edit-client.component';
import { FileSaverModule } from 'ngx-filesaver';
import { ListStaffComponent } from './components/post-auth-pages/admin/staff/list-staff/list-staff.component';
import { AddStaffComponent } from './components/post-auth-pages/admin/staff/add-staff/add-staff.component';
import { ProjectOverviewComponent } from './components/post-auth-pages/admin/project-overview/project-overview.component';
import { SelectedMostComponent } from './components/post-auth-pages/admin/reports/selected-most/selected-most.component';
import { CustomerProcessTimeComponent } from './components/post-auth-pages/admin/reports/customer-process-time/customer-process-time.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditStaffComponent } from './components/post-auth-pages/admin/staff/edit-staff/edit-staff.component';
import { NgInputNumberI18nModule } from '@danielzotti/ng-input-number-i18n';
import { CustomFormatPipe } from './shared/pipe/custom.pipe';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './shared/services/base.service';
import { CustomFormsModule } from 'ngx-custom-validators';
import { DocCategoryComponent } from './components/post-auth-pages/admin/doc-category/doc-category.component';
import { CartPageComponent } from './components/post-auth-pages/admin/clients/cart-page/cart-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MultipleCategoriesComponent } from './components/post-auth-pages/admin/product/multiple-categories/multiple-categories.component'; 
import { TimeLinePipe } from './shared/pipe/timeline.pipe';
import { DocumentMaskDirective } from './shared/directives/document-mask.directive';
import { PrivacyComponent } from './components/post-auth-pages/admin/privacy/privacy.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ManageAdminProfileComponent } from './components/post-auth-pages/admin/manage-admin-profile/manage-admin-profile.component';
import { ImprintComponent } from './components/post-auth-pages/admin/privacy/imprint/imprint.component';
import { DataPrivacyComponent } from './components/post-auth-pages/admin/privacy/data-privacy/data-privacy.component'
// import { AngularEditorModule } from '@kolkov/angular-editor';
export const CUSTOM_LOCALE = 'it-IT';
export const DEFAULT_LOCALE = 'de-DE';

export function initializeLocale(): string {
  const locale = DEFAULT_LOCALE;
  registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  console.log('initializeLocale() called');
  return locale;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    DashboardComponent,
    MenubarComponent,
    TopNavbarComponent,
    AddCategoryComponent,
    AddFeatureComponent,
    EditFeatureComponent,
    DeleteConfirmationComponent,
    AddFaqComponent,
    ListFaqComponent,
    EditFaqComponent,
    AddProductComponent,
    EditProductComponent,
    ListProductComponent,
    CustomizeFeatureComponent,
    UpdateProductComponent,
    AddTemplateComponent,
    ViewTemplateComponent,
    ListTemplateComponent,
    AddTemplateTypesComponent,
    AddProjectComponent,
    ListProjectComponent,
    ListClientsComponent,
    AddClientComponent,
    EditProjectComponent,
    TimelineComponent,
    EditClientComponent,
    ListStaffComponent,
    AddStaffComponent,
    ProjectOverviewComponent,
    SelectedMostComponent,
    CustomerProcessTimeComponent,
    EditStaffComponent,
    DocCategoryComponent,
    CartPageComponent,
    MultipleCategoriesComponent,
    TimeLinePipe,
    DocumentMaskDirective,
    PrivacyComponent,
    ManageAdminProfileComponent,
    ImprintComponent,
    DataPrivacyComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    TreeModule,
    ModalModule,
    NgxSpinnerModule,
    NgSelectModule,
    CustomFormsModule,
    DragDropModule,
    AngularEditorModule,
ImageCropperModule,
    NgCircleProgressModule.forRoot({
      radius: 20,
      space: 2,
      units: '%',
      unitsFontSize: '10',
      titleFontSize: '10',
      outerStrokeGradient: true,
      outerStrokeWidth: 3,
      outerStrokeColor: '#4f5962',
      outerStrokeGradientStopColor: '#4f5962',
      innerStrokeColor: '#d43532',
      innerStrokeWidth: 2,
      showSubtitle: false,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: true,
      showBackground: false,
      clockwise: false,
      startFromZero: false,
      lazy: false,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    // NgCircleProgressModule.forRoot({
    //   // set defaults here
    //   radius: 20,
    //   outerStrokeWidth: 4,
    //   innerStrokeWidth: 2,
    //   outerStrokeColor: "#4f5962",
    //   innerStrokeColor: "#d43532",
    //   animationDuration: 300,
    //   unitsFontSize: '10',
    //   titleFontSize: '10',
    //   showSubtitle: false
    // }),

    // Shared
    PaginationModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
    }),
    FileSaverModule,
    NgInputNumberI18nModule.forRoot({
      production: false,
      undoOnEsc: false,
      pipe: CustomFormatPipe,
    }),

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    //   TranslateModule.forRoot({
    //     defaultLanguage: 'en'
    // }),
    MatCheckboxModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CustomFormatPipe,
    {
      provide: LOCALE_ID,
      useFactory: initializeLocale,
    },
    CookieService,
    BaseService,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule,MultipleCategoriesComponent,TimeLinePipe, DocumentMaskDirective],
})
export class AppModule {}
