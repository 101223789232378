<div class="content-wrapper card-body" style="min-height: 1416.81px;">
    <!-- <section class="content-header">
    <div class="container-fluid">
      <div class="row justify-content-between">
        <div class="col-md-auto">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:;">
                Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active">List Staff</li>
          </ol>
        </div>
        <div class="col-md-auto">
          <div class="input-group input-group-md p-0">
            <a class="btn btn-primary" href="#/add-staff">
              <i class="nav-icon fas fa-plus"></i> Add Staff
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> -->

    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-6 col-12">
                <h1>{{'listStaff.staff' | translate}}</h1>
            </div>

            <div class="col-lg-6 col-12 mt-2 mt-lg-0">
                <div class="d-lg-flex justify-content-end">

                    <div class="mr-3">
                        <div class="input-group ">
                            <input #searchVA type="text " class="form-control " placeholder="{{'listStaff.searchStaff' | translate}}" (keyup.enter)=search($event)>
                            <div class="input-group-append ">
                                <span class="input-group-text f-700 "><i class="fa fa-search" (click)=search(searchVA.value,true) ></i></span>
                            </div>
                            <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div *ngIf="permissions.staff == 1" class="input-group input-group-md p-0">
                            <a class="btn btn-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.AddStaff]">
                                <i class="nav-icon fas fa-plus"></i> {{'addStaff.addStaff' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Content Header (Page header)  -->
    </section>

    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span>{{'listStaff.staffListing' | translate}}</span>
                <span>{{'listStaff.totalStaff' | translate}} {{totalItems}}</span>
            </div>
        </div>
        <div class="card-body table ">
            <table class="table table-bordered table-striped table-hover table-responsive">
                <thead class="table-dark ">
                    <tr>
                        <th style="width:100px; min-width: 70px; ">{{'listClient.srNo' | translate}}</th>
                        <th style="width:300px ">{{'listStaff.name' | translate}}</th>
                        <th style="width:300px ">{{'listStaff.emailID' | translate}}</th>
                        <th style="width:300px ">{{'addStaff.contactNumber' | translate}} </th>
                        <th style="width:300px ">{{'listStaff.address' | translate}}</th>
                        <th style="width:300px ">{{'listStaff.dateofJoining' | translate}}</th>
                        <th style="width:300px ">{{'addStaff.permission' | translate}}</th>
                        <th style="width:300px ">{{'listStaff.userType' | translate}}</th>
                        <th class="white-space-noswrap">{{'listStaff.action' | translate}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let obj of staffList;index as i">
                        <!-- <td class="align-middle text-center" >{{(i+1)}}</td> -->
                        <!-- <td class="align-middle" *ngIf="page !== 1">{{(page*10)-10 + (i+1)}}</td> -->
                        <td class="align-middle">{{(page*10)-10 + (i+1)}}</td>
                        <td style="width:300px ">{{obj?.firstname | titlecase}} {{obj?.lastname | titlecase}}</td>
                        <td style="width:300px ">{{obj?.email}}</td>
                        <td style="width:300px ">{{obj?.phone_number}}</td>
                        <td style="width:300px ">{{obj?.address}}</td>
                        <td style="width:300px ">{{obj?.joining_date?.split("-").reverse().join("-").replace('-','/').replace('-','/')}}</td>
                        <td style="width:300px ">{{obj?.UserPermissions}}</td>

                        <td style="width:300px " *ngIf="obj?.is_admin">Super Admin</td>
                        <td style="width:300px " *ngIf="!obj?.is_admin">{{'listStaff.staff' | translate}}</td>

                        <td class="text-center white-space-noswrap">
                            <div  *ngIf="!obj?.is_admin"  class="w-100 d-inline-flex justify-content-between align-items-center white-space-noswrap ">
                                <a  *ngIf="!obj?.is_admin" href="javascript:;" class="m-1" (click)="viewStaff(obj)">
                                    <i class="fa fa-eye "></i>
                                </a>
                                <a *ngIf="permissions.staff == 1" href="javascript:;" class="m-1" (click)="editStaff(obj)">
                                    <i class="fa fa-pencil-alt "></i>
                                </a>
                                <span *ngIf="permissions.staff == 1">
            <a  class="m-1" href="javascript:; " mwlConfirmationPopover [popoverTitle]="popoverTitle" 
            [popoverMessage]="popoverMessage"
            [confirmText]="confirmText"
                                    [cancelText]="cancelText"
            placement="left" 
            (confirm)="deleteStaff(true,obj)" (cancel)="deleteStaff(false,obj)">
              <i class="fa fa-trash text-danger "></i>
            </a>
            </span>

                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="row pagination-row mt-5 mb-0">
    <div class="col-md-12 text-right">
      <div class="pagination justify-content-end">
        <div class="page-item">
          <pagination [boundaryLinks]="showBoundaryLinks" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" 
                [(ngModel)]="page" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"></pagination>
          
        </div>
      </div>
    </div>
  </div> -->

    <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
        <div class="col-md-12 text-right">
            <div class="pagination justify-content-end">
                <div class="page-item">
                    <pagination [boundaryLinks]="showBoundaryLinks" [firstText]="firstText" [lastText]="lastText" [previousText]="prevText" [nextText]="nextText" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
                        [maxSize]="maxSize"></pagination>
                </div>
            </div>
        </div>
    </div>

</div>