<div class="content-wrapper card-body">
  <!-- <section class="content-header pt-0 pl-0">
        <div class="container-fluid pl-0">
            <div class="row justify-content-between">
                <div class="col-md-auto">
                    <ol class=" breadcrumb ">
                         <li class="breadcrumb-item "><a href="javascript:; ">Dashboard</a></li>
                        <li class="breadcrumb-item "><a href="javascript:; ">Timeline</a></li>

                    </ol>
                </div>
            </div>
        </div>
    </section> -->

  <div class="card card-primary">
    <div class="card-header">
      <div class="w-100 d-flex justify-content-between">
        <span>
          <!-- Timeline -->
          {{ "timeline.timeline" | translate }}</span>
        <!-- <span>Total templates <strong>224</strong></span> -->

        <div class="dropdown">
          <button (click)="toggleDropdownMenu = !toggleDropdownMenu" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <span class="droptext">
              <span>{{ selectedData }}</span> {{ selectedData2 }}
            </span>
            <svg class="select-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="7.54" viewBox="0 0 14 7.54">
              <path id="chevron-down-dropdown"
                d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                transform="translate(15.499 -4.499) rotate(90)" fill="#4c89d3" fill-rule="evenodd" />
            </svg>
          </button>
          <ul class="dropdown-menu" [ngClass]="toggleDropdownMenu ? 'show':''">
            <li *ngFor="let p of updatedProjectList; let pi = index" style="color: #000;">
              <a style="color: #000;" class="dropdown-item onhover" (click)="OnClickupdatedata(p, pi)">
                <span>{{ p?.Project?.name }}</span> |
                {{ p?.Template?.name }} |
                {{ p?.apartment_name }}
              </a>
            </li>

          </ul>
        </div>
      </div>

    </div>

    <div class="card-body row">
      <div class="col-12 col-xl-4">
        <div class="auto-scroll-files mb-4 background-color-light">
          <div class="col-12 pt-2 pb-2 pl-3 pr-3">
            <h6 class="fm mb-0">
              <!-- Projects -->
              {{ "menu.Projects" | translate }}
            </h6>
          </div>
          <div class="overflow-hidden border-radius mb-2" *ngFor="let proj of projects">
            <div class="row m-0 projects-list-chat" *ngIf="proj?.Project">
              <div class="col-12 pl-2 pr-2 bg-white ml-2 mr-2">
                <div class="media align-items-center pt-2 pb-2">
                  <div class="media-left col-4 col-xl-3 pr-3 pl-0">
                    <img *ngIf="!proj?.Project?.image" src="/assets/img/img-placeholder.jpg" class="img-thumb-timeline" />
                    <img *ngIf="proj?.Project?.image" class="img-thumb-timeline"
                      [src]="imgBaseUrl + proj?.Project?.image" />
                  </div>
                  <div class="media-body pr-1">
                    <h6 class="mb-0">
                      <!-- Project Name -->
                      {{ "dashboard.projectName" | translate }}
                      : {{ proj?.Project?.name | titlecase }}
                    </h6>
                    <p class="mb-0">
                      <!-- Customer Name -->
                      {{ "timeline.CustomerName" | translate }} :
                      {{ clientInfo?.first_name | titlecase }}
                      {{ clientInfo?.last_name | titlecase }}
                    </p>
                    <p class="mb-0">
                      <!-- Postal Code  -->
                      {{ "timeline.PostalCode" | translate }}
                      : {{ clientInfo?.postal_code }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12 background-color-light pt-lg-2 pb-lg-2 pl-lg-3 pr-lg-3 p-3">
                                <div class="row justify-content-between mb-1">
                                    <div class="col-6 fm">Tiles </div>
                                    <div class="col-6 fb text-right">672 <i class="fas fa-euro-sign"></i></div>
                                </div>
                                <div class="row justify-content-between">
                                    <div class="col-6 fm">Bathtu</div>
                                    <div class="col-6 fb text-right"> 672 <i class="fas fa-euro-sign"></i></div>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="border-1 border overflow-hidden border-radius-1">
          <div class="row m-0">
            <div class="col-12 pt-2 pb-3 pl-3 pr-3 background-color-light overflow-hidden">
               <!-- Documents -->
              <!-- <p class="fm mb-2">
               
                {{ "timeline.Documents" | translate }}
              </p> -->

              <!-- <input
                class="btn btn-danger mt-3"
                type="file"
                #myInput
                class="h-auto form-control"
                (change)="uploadDoc($event)"
                style="display: none"
                id="selectedFile"
              />

              <a
                href="javascript:;"
                class="btn btn-danger w-100 d-block fs-1_2 border-radius-25 text-center pt-2 pb-2"
                onclick="document.getElementById('selectedFile').click();"
                ><i class="nav-icon fas fa-plus mr-2"></i>
                {{ "timeline.addNewDocument" | translate }}</a
              > -->

              <a href="javascript:;" class="btn btn-danger w-100 mt-2 d-block fs-1_2 border-radius-25 text-center pt-2 pb-2"
                (click)="catDocForm.reset();
                catDocForm?.controls['cat_id'].patchValue('');
                openModal()"><i class="nav-icon fas fa-plus mr-2"></i>
                {{ "timeline.addNewDocument" | translate }}</a>
            </div>

            <div class="col-12 mt-3">
              <h6 class="fm display-6 mb-4">
                <!-- Uploaded Documents -->
                {{ "timeline.uploadedDocuments" | translate }}
              </h6>
              <div class="auto-scroll-files">
                <!-- <div
                  class="row justify-content-between m-1 pt-0 pb-2 row-repeat flex-nowrap"
                  *ngFor="let doc of docList; index as i"
                >
                  <div class="div d-flex col-9">
                    <i class="fas fa-file text-danger fs-2 mr-2 mt-1"></i>
                    <div class="d-block w-100 pr-4">
                      <h6 class="mb-0 fs-1 break-word">{{ doc?.document | chatMask  }}</h6>
                      <p class="mb-0 break-word">
                        {{
                          doc?.createdAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")
                        }}
                        {{ doc?.createdAt.split("T")[1].split(".")[0] }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="div d-flex align-items-center col-3 text-right justify-content-around"
                  >
                  <a href="javascript:;" [title]=""
                      ><i
                        class="fas fa-share-alt text-danger"
                        (click)="shareDoc(doc)"
                      ></i
                    >
                  </a>
                    <a href="javascript:;"
                      ><i
                        class="fas fa-download text-danger"
                        (click)="onDown(doc.document, true)"
                      ></i
                    ></a>
                  </div>
                </div> -->

                <ng-container *ngIf="isDocumentExist">
                  <div class="doc-items mb-4" *ngFor="let p of ProjectDocList">
                    <h3 class="ctg-title d-flex justify-content-between align-items-center">
                       <span class="titl-text"> {{ p?.title }}</span>  
                      
                    </h3>

                    <div class="doc-item-wrapper row mx-0 mt-5" *ngFor="let data of p?.ClientDocuments">
                      
                      <div class="col-12 col-md-12 col-lg-12 px-0">
                        <span class="date-time">{{ data?.createdAt | date : "dd-MM-yyyy" }}
                          {{ data?.createdAt | date : "H:mm:ss" }}</span>
                        <div class="pdf">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 20 70 50" width="50" height="50">
                            <path
                              d="M 25 11 C 20.029 11 16 15.029 16 20 L 16 52 C 16 56.971 20.029 61 25 61 L 47 61 C 51.971 61 56 56.971 56 52 L 56 31 L 42 31 C 38.686 31 36 28.314 36 25 L 36 11 L 25 11 z M 40 11.34375 L 40 25 C 40 26.105 40.896 27 42 27 L 55.65625 27 L 40 11.34375 z M 29 38 L 43 38 C 44.104 38 45 38.895 45 40 C 45 41.105 44.104 42 43 42 L 29 42 C 27.896 42 27 41.105 27 40 C 27 38.895 27.896 38 29 38 z M 29 47 L 43 47 C 44.104 47 45 47.895 45 49 C 45 50.105 44.104 51 43 51 L 29 51 C 27.896 51 27 50.105 27 49 C 27 47.895 27.896 47 29 47 z" />
                          </svg>

                          <div class="doc-name" *ngIf="data">
                            {{ data?.name }}
                            <span title="{{ data?.document | chatMask }}">
                              {{
                              data?.document | chatMask
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-lg-6 px-0">
                        <div class="doc-actions-wrapper">

                          
                          <button type="button" class="btn btn-main" (click)="shareDoc(data)"
                            [title]="'timeline.actiontooltip1' | translate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.911" height="16.909"
                              viewBox="0 0 16.911 16.909">
                              <path id="send"
                                d="M17.049.157a.538.538,0,0,1,.118.581L10.9,16.4a.808.808,0,0,1-1.431.134L6.048,11.158.67,7.736A.808.808,0,0,1,.8,6.3L16.467.04a.538.538,0,0,1,.581.118ZM7.123,10.843,10.1,15.514l5.1-12.741Zm7.308-8.831L1.691,7.109l4.672,2.972,8.069-8.068Z"
                                transform="translate(-0.295 -0.001)" fill="#fff" />
                            </svg>
                          </button>
                          <button type="button" class="btn btn-main" (click)="onDown(data?.document, true)"
                            [title]="'timeline.actiontooltip2' | translate">
                            <svg id="download" xmlns="http://www.w3.org/2000/svg" width="20.384" height="17.708"
                              viewBox="0 0 20.384 17.708">
                              <path id="Path_39" data-name="Path 39"
                                d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                                transform="translate(0 1.438)" fill="#fff" />
                              <path id="Path_40" data-name="Path 40"
                                d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                                transform="translate(1.233 -1)" fill="#fff" />
                            </svg>
                          </button>
                          <button type="button" class="btn btn-main btn-white" data-bs-toggle="modal"
                            data-bs-target="#editDoc" (click)="getDoclistById(data)"
                            [title]="'timeline.actiontooltip3' | translate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.719" height="16.55"
                              viewBox="0 0 16.719 16.55">
                              <g id="pencil-square" transform="translate(-1 -0.5)">
                                <path (click)="getDoclistById(data)" id="Path_44" data-name="Path 44"
                                  d="M17.128,2.144a.571.571,0,0,1,0,.806l-1.19,1.192L13.655,1.858,14.845.667a.571.571,0,0,1,.807,0l1.476,1.476Zm-2,2.8L12.848,2.664,5.072,10.441a.571.571,0,0,0-.138.224L4.015,13.42a.285.285,0,0,0,.361.361l2.755-.919a.571.571,0,0,0,.224-.137l7.776-7.777Z"
                                  transform="translate(0.424 0)" fill="#070d15" />
                                <path id="Path_45" data-name="Path 45"
                                  d="M1,15.267a1.712,1.712,0,0,0,1.712,1.712H15.267a1.712,1.712,0,0,0,1.712-1.712V8.419a.571.571,0,0,0-1.141,0v6.848a.571.571,0,0,1-.571.571H2.712a.571.571,0,0,1-.571-.571V2.712a.571.571,0,0,1,.571-.571h7.419a.571.571,0,0,0,0-1.141H2.712A1.712,1.712,0,0,0,1,2.712Z"
                                  transform="translate(0 0.071)" fill="#070d15" fill-rule="evenodd" />
                              </g>
                            </svg>
                          </button>
                          <button type="button" mwlConfirmationPopover [popoverTitle]="popoverTitle"
                            [popoverMessage]="popoverMessage" [confirmText]="confirmText" [cancelText]="cancelText"
                            placement="top" (confirm)="DeleteDoc(true, data)" (cancel)="DeleteDoc(false, data)"
                            class="btn btn-main btn-red" [title]="'timeline.actiontooltip4' | translate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.208" height="18.523"
                              viewBox="0 0 16.208 18.523">
                              <path id="trash3"
                                d="M7.367,1.158h3.473a.579.579,0,0,1,.579.579V2.894H6.789V1.737A.579.579,0,0,1,7.367,1.158Zm5.21,1.737V1.737A1.737,1.737,0,0,0,10.841,0H7.367A1.737,1.737,0,0,0,5.631,1.737V2.894H1.579a.579.579,0,0,0,0,1.158H2.2l.988,12.341A2.315,2.315,0,0,0,5.5,18.523H12.71a2.316,2.316,0,0,0,2.309-2.131l.987-12.341h.623a.579.579,0,0,0,0-1.158H12.577Zm2.267,1.158L13.865,16.3a1.158,1.158,0,0,1-1.154,1.065H5.5A1.158,1.158,0,0,1,4.343,16.3L3.364,4.052ZM6.176,5.21a.579.579,0,0,1,.611.544l.579,9.841a.579.579,0,1,1-1.156.069l-.58-9.841a.579.579,0,0,1,.544-.614Zm5.856,0a.579.579,0,0,1,.544.614L12,15.664a.579.579,0,1,1-1.156-.069l.579-9.841a.579.579,0,0,1,.611-.544ZM9.1,5.21a.579.579,0,0,1,.579.579v9.841a.579.579,0,0,1-1.158,0V5.789A.579.579,0,0,1,9.1,5.21Z"
                                transform="translate(-1)" fill="#fff" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>
                </ng-container>
                <ng-container *ngIf="!isDocumentExist && isContentLoaded">
                  <div class="documents-not-found">
                    <h1>{{'timeline.notfoundtitle' | translate}}</h1>
                    <small>{{'timeline.docnotfounddesc' | translate}}</small>
                  </div>
                </ng-container>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-8">
        <section class="msger">
          <header class="msger-header">
            <div class="msger-header-title">
              <i class="fas fa-comment-alt"></i>
              <!-- Messages -->
              {{ "timeline.messages" | translate }}
            </div>
            <!-- <div class="msger-header-options">
                            <span><i class="fas fa-cog"></i></span>
                        </div> -->
          </header>

          <main class="msger-chat">
            <ng-container *ngFor="let msg of msgHistory; index as i; let last = last">
              <div [id]="last ? 'islast' : ''" class="msg left-msg" *ngIf="msg.sender_type === 'client'">
                <div *ngIf="!clientInfo.image" class="msg-img" style="
                    background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg);
                  "></div>
                <div *ngIf="clientInfo?.image" class="msg-img" style="background-image: url({{
                    imgBaseUrl + clientInfo?.image
                  }})"></div>

                <div class="msg-bubble">
                  <div class="msg-info">
                    <div class="msg-info-name">
                      {{ msg?.senderInfo?.first_name | titlecase }}
                      {{ msg?.senderInfo?.last_name | titlecase }}
                    </div>
                    <div class="msg-info-time">
                      {{
                      msg?.createdAt
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")
                      }}
                     {{ msg?.createdAt.split("T")[1].split(".")[0].split("+")[0] }}
                    </div>
                  </div>

                  <div class="msg-text wd-wrap" *ngIf="!msg?.document">
                    {{ msg?.message }}
                  </div>
                  <div class="msg-text wd-wrap" *ngIf="msg?.document">
                    <span>
                      <svg id="file-earmark-pdf_red" class="file-pos" data-name="file-earmark-pdf red"
                        xmlns="http://www.w3.org/2000/svg" width="16.722" height="25.629" viewBox="0 0 26.722 35.629">
                        <path id="Path_37" data-name="Path 37"
                          d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                          transform="translate(-2)" fill="#c23d31" />
                        <path id="Path_38" data-name="Path 38"
                          d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                          transform="translate(0.78 7.685)" fill="#c23d31" />
                      </svg>
                      <span class="ml-1">{{ msg?.document | chatMask }} </span></span>
                    <span>
                      <button type="button" class="btn btn-main" (click)="downloadDoc(msg)">
                        <svg id="download" xmlns="http://www.w3.org/2000/svg" width="20.384" height="17.708"
                          viewBox="0 0 20.384 17.708">
                          <path id="Path_39" data-name="Path 39"
                            d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                            transform="translate(0 1.438)" fill="#fff" />
                          <path id="Path_40" data-name="Path 40"
                            d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                            transform="translate(1.233 -1)" fill="#fff" />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>

              <div [id]="last ? 'islast' : ''" class="msg right-msg"
                *ngIf="msg?.sender_type === 'admin' ? +msg?.receiver_id === +recieverId : false">

                <div class="msg-img" style="
                    background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg);
                  "></div>

                <div class="msg-bubble">
                  <div class="msg-info">
                    <div class="msg-info-name">
                      {{ msg?.senderInfo?.firstname | titlecase }}
                      {{ msg?.senderInfo?.lastname | titlecase }}
                    </div>
                    <div class="msg-info-time">
                      {{
                      msg?.createdAt
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")
                      }}
                      {{ msg?.createdAt.split("T")[1].split(".")[0].split("+")[0] }}
                    </div>
                  </div>

                  <div class="msg-text wd-wrap" *ngIf="!msg?.document">
                    {{ msg?.message }}
                  </div>
                  <div class="msg-text wd-wrap" *ngIf="msg?.document">
                    <span>
                      <svg id="file-earmark-pdf_red" class="file-pos" data-name="file-earmark-pdf red"
                        xmlns="http://www.w3.org/2000/svg" width="16.722" height="25.629" viewBox="0 0 26.722 35.629">
                        <path id="Path_37" data-name="Path 37"
                          d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                          transform="translate(-2)" fill="#c23d31" />
                        <path id="Path_38" data-name="Path 38"
                          d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                          transform="translate(0.78 7.685)" fill="#c23d31" />
                      </svg>
                      <span class="ml-1">{{ msg?.document | chatMask }} </span></span>
                  </div>
                </div>
              </div>
            </ng-container>
          </main>

          <form class="msger-inputarea">
            <input type="text" class="msger-input" #msgEl placeholder="{{'timeline.enterMessagePlaceholder'|translate}}" />
            <button type="submit" class="msger-send-btn pl-4 pr-4" (click)="sendMessageOrDoc('msg', msgEl)">
              <!-- Send -->
              {{ "timeline.send" | translate }}
            </button>
          </form>
        </section>
      </div>


    </div>
  </div>
</div>

<ng-template #addDoc let-modal>
  <div class="bookappointment-modal ">

    <div class="modal-content">

      <div class="modal-body">
        <h3 class="text-center">
          {{ (isEdit ? "timeline.editdocument":"timeline.adddocument") | translate }}
          <span class="titlebar mx-auto d-block"></span>
        </h3>
        <button type="button" class="btn btn-main mt-3 w-100 position-relative pointer">
          <svg *ngIf="!isUploaded" class="mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="13.901" viewBox="0 0 16 13.901">
            <g id="upload" transform="translate(0 -0.999)">
              <path id="Path_42" data-name="Path 42"
                d="M.5,9.9a.5.5,0,0,1,.5.5v2.5a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V10.4a.5.5,0,0,1,1,0v2.5a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V10.4a.5.5,0,0,1,.5-.5Z"
                fill="#fff" />
              <path id="Path_43" data-name="Path 43"
                d="M7.646,1.146a.5.5,0,0,1,.708,0l3,3a.5.5,0,1,1-.708.708L8.5,2.707V11.5a.5.5,0,0,1-1,0V2.707L5.354,4.854a.5.5,0,0,1-.708-.708l3-3Z"
                fill="#fff" />
            </g>
          </svg>
          <img *ngIf="isUploaded" style="width: 20px; height: 2opx;" src="assets/img/loading-gif.gif" />
          {{ "timeline.uploaddocument" | translate }}
          <input type="file" class="upload-input pointer" (change)="uploadDoc($event)" id="file-upload" />
        </button>
        <span class="fs-12 w-100 text-left">(  {{ "timeline.supportDoc" | translate:{value:'pdf, png, jpeg, jpg'} }} )</span>
        <form [formGroup]="catDocForm">
          <div class="added-doc-wrapper">
            <div class="doc-form my-3">
              <div *ngIf="isEdit" class="input-group have-icon">
                <input [hidden]="!showHide" type="text" appDocMask class="disabled" disabled formControlName="pdf" />
            


              </div>

              <div *ngIf="!isEdit && showHide" class="input-group have-icon">
                <input [hidden]="!showHide" type="text" appDocMask class="disabled" disabled formControlName="pdf" />
            

              </div>

              <div class="input-group">
                <input type="text" placeholder="{{ 'timeline.name' | translate }}" formControlName="doc_name" />
                <ng-container *ngIf="
                      catDocForm?.controls['doc_name']?.errors &&
                      (catDocForm.get('doc_name').dirty ||
                        catDocForm.get('doc_name').touched)
                    ">
                  <small class="text-danger error-msg w-100 d-inline-block" *ngIf="
                        catDocForm?.controls['doc_name'].hasError('required') &&
                        submitted
                      ">
                    *
                    {{
                    "timeline.documentnameisrequired" | translate
                    }}.</small>
                  <small class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="catDocForm.controls['doc_name'].hasError('maxlength')">*{{
                    "timeline.Maximumof30characters" | translate
                    }}.</small>
                  <small class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="catDocForm.controls['doc_name'].hasError('pattern')">*
                    {{
                    "timeline.Nospecialcharacterallowed" | translate
                    }}.</small>
                  <small class="text-danger error-msg w-100 d-inline-block" *ngIf="
                        !catDocForm.controls['doc_name'].hasError('required') &&
                        catDocForm.controls['doc_name'].hasError('whitespace')
                      ">*
                    {{
                    "timeline.Whitespacenotallowed" | translate
                    }}.</small>
                </ng-container>
              </div>

              <div class="input-group mb-0">
                <select class="mb-0 form-select" formControlName="cat_id" style="padding-right: 28px">
                  <option value="" [hidden]="catDocForm?.controls?.cat_id?.value">
                    {{ "timeline.selectdocumentcategory" | translate }}
                  </option>
                  <option *ngFor="let dc of docCategoryList; let dci = index" [value]="dc?.id">
                    {{ dc?.title }}
                  </option>
                </select>
       
                <ng-container *ngIf="
                    catDocForm?.controls['cat_id'].errors &&
                      (catDocForm.get('cat_id').dirty || catDocForm.get('cat_id').touched)
                    ">
                  <small class="text-danger error-msg w-100 d-inline-block" *ngIf="
                      catDocForm.controls['cat_id'].hasError('required') &&
                        submitted
                      ">
                    *
                    {{
                    "timeline.documentcategoryisrequired" | translate
                    }}.</small>
                </ng-container>
              </div>
            </div>

          </div>

          <div class="button-row d-flex align-items-center justify-content-between">
            <button type="button" class="btn btn-secondary w-100 mr-2" (click)="closeModal()" >
              {{ "timeline.cancel" | translate }}
            </button>
            <button [disabled]="catDocForm.invalid"  type="button" class="btn btn-primary w-100 "
              (click)="uploadProjectDoc()" style="background: #213e62;">
              {{ "timeline.save" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template>