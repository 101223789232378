<div class="card card-dark mb-0">
  <div class="card-header">
    <h3 class="card-title">
      <!-- Edit Feature -->
      {{ "customizeFeature.editFeature" | translate }}
    </h3>

    <div class="card-tools">
      <button
        type="button"
        class="btn btn-tool"
        data-card-widget="remove"
        data-toggle="tooltip"
        title="Remove"
        (click)="closeModal()"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="mainForm">
      <div class="row editFeatureUi">
        <div class="col-12 d-flex flex-wrap">
          <input formControlName="featureName" class="inputLabel" disabled />
          <input value='{{"customizeFeature.surcharge" | translate}}' class="inputLabel mr-0" disabled />
        </div>

        <div class="col-12" formArrayName="controlValue" *ngIf="!showInput">
          <div
            *ngFor="let obj of controlValue.controls; index as i"
            class="productEdit-list w-100"
          >
            <div [formGroupName]="i">
              <input *ngIf="!showInput" formControlName="value" disabled />

              <input formControlName="price" />
              <p class="text-right mb-0">
                <ng-container
                  *ngIf="
                    obj.get('price').errors &&
                    (obj.get('price').dirty || obj.get('price').touched)
                  "
                >
                  <small
                    class="text-danger error-msg"
                    *ngIf="obj.get('price').hasError('pattern')"
                    >*
                    <!-- No special character
              allowed. -->
                    {{ "addFeature.specialCharMsg" | translate }}</small
                  >
                  <small
                    class="text-danger error-msg"
                    *ngIf="obj.get('price').hasError('required')"
                    >*
                    <!-- Control Value is
              required -->
                    {{ "addFeature.controlValueRequired" | translate }}.</small
                  >
                  <small
                    class="text-danger error-msg"
                    *ngIf="obj.get('price').hasError('maxlength')"
                    >*
                    <!-- Maximum of 10
              characters -->
                    {{ "addProduct.charLimit10" | translate }}.</small
                  >
                </ng-container>
              </p>
            </div>
          </div>
        </div>

        <div class="col-12">
          <input *ngIf="showInput" formControlName="controlValue" disabled />
          <input *ngIf="showInput" formControlName="price" />
        </div>

        <ng-container *ngIf="showInput">
          <ng-container
            *ngIf="
              mainForm.controls['price'].errors &&
              (mainForm.get('price').dirty || mainForm.get('price').touched)
            "
          >
            <small
              class="text-danger error-msg"
              *ngIf="mainForm.controls['price'].hasError('required')"
              >*
              <!-- controlValue is
        required -->
              {{ "addFeature.controlValueRequired" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="mainForm.controls['price'].hasError('maxlength')"
              >*
              <!-- Maximum of 30
        characters -->
              {{ "addFeature.charLimit30" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="mainForm.controls['price'].hasError('pattern')"
              >*{{ "addFeature.specialCharMsg" | translate }}.</small
            >
          </ng-container>
        </ng-container>
      </div>
    </form>
  </div>
  <!-- /.card-body -->
  <div class="card-footer text-right">
    <button
      class="btn btn-success"
      [disabled]="!mainForm.valid"
      (click)="passBack()"
    >
      <!-- Update -->
      {{ "customizeFeature.update" | translate }}
    </button>
  </div>
  <!-- /.card-footer-->
</div>

<!-- <div *ngIf="showInput">
  <input #myInput/>
  <button class="btn btn-primary" (click)="passBack(myInput.value)"></button>
</div> -->

<!-- {{getAllErrors(mainForm) | json}} -->
