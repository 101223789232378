import { APP_ROUTES } from './../../routes/appRoutes';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../storage/local-storage.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  constructor(private ls: LocalStorageService, private router: Router) { }

  showMenu = false;
  public APP_ROUTES = APP_ROUTES;
  activeScreens: any;
  userName:any;
  firstName:any;
  lastName:any;
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.ls.getItem('token') && this.ls.getItem('currentUser')) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }
      }

      if (this.ls.getItem('token') && this.ls.getItem('currentUser')) {
        this.checkActiveScreens();
      }
    });
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    // this.firstName = JSON.parse(localStorage.getItem('currentUser'));
    // this.lastName = JSON.parse(localStorage.getItem('currentUser'));
    console.log(this.userName,'current user');
    
  }

  matchUrl(segment: string): boolean {
    return this.router.url.includes(segment);
  }

  checkActiveScreens() {
    this.activeScreens = this.ls.getItem('currentUser').UserPermissions[0];
    // console.log("activeScreens ",this.activeScreens)
  }
}
