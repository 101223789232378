import { Component, OnInit } from '@angular/core';
import { FeatureService } from '../feature.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CategoryService } from '../../category.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-feature',
  templateUrl: './edit-feature.component.html',
  styleUrls: ['./edit-feature.component.scss'],
})
export class EditFeatureComponent implements OnInit {
  constructor(
    private featureService: FeatureService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private service: CategoryService,
    public activeModal: NgbActiveModal,
    public translationService: TranslateService
  ) {}

  nodeObj: any;
  imageName: any;
  imageUrl: any;
  private unsubscribe$ = new Subject();
  surcharge: Boolean;
  disableField:Boolean;
  imgBaseUrl = environment.imgBaseUrl;
  controlTypeForm = this.fb.group({
    label_name: ['', [Validators.required, Validators.maxLength(30)]],
    control_type: ['', [Validators.required]],
    control_value: ['', [Validators.required, Validators.maxLength(30)]],
    surcharge: ['', [Validators.required]],
    image: ['', []],
    imageUrl: ['', []],
    id: ['', []],
    catalogue_id: ['', []],
    dropdownarray: this.fb.array([]),
  });
  prevImgPresent: Boolean;

  get getDropdownList(): any {
    return this.controlTypeForm.get('dropdownarray') as any;
  }

  newFields(): FormGroup {
    return this.fb.group({
      controlTypeVal: ['', [Validators.required, Validators.maxLength(30)]],
      controlTypeImg: [''],
      controlTypeImgUpload: [''],
    });
  }

  resetdropArrays() {
    this.controlTypeForm.reset();
    this.controlTypeForm.controls['controlType'].patchValue('');
    this.getDropdownList().clear();
  }


  addNewFields() {
    console.log(this.getDropdownList);
    console.log(this.controlTypeForm.value);

    this.getDropdownList?.push(this.newFields());
  }

  removeField(i: number) {
    this.getDropdownList?.removeAt(i);
  }

  showVal(event?: any): void {
    let controlType = this.controlTypeForm.controls.control_type.value;
    if (controlType === 'dropDown') {
      this.controlTypeForm.controls['control_value'].clearValidators();
      this.controlTypeForm.controls[
        'control_value'
      ].updateValueAndValidity();
      this.getDropdownList.controls?.forEach((element) => {
        // element?.controls['controlTypeImgUpload'].setValidators([
        //   Validators.required,
        // ]);
        // element?.controls['controlTypeImgUpload'].updateValueAndValidity();
        element?.controls['controlTypeVal'].setValidators([
          Validators.required,
          Validators.maxLength(30),
        ]);
        element?.controls['controlTypeVal'].updateValueAndValidity();
      });
    } else {
      // console.log(this.getDropdownList);
      this.controlTypeForm.controls['control_value'].setValidators([
        Validators.required,
        Validators.maxLength(30),
      ]);
      this.controlTypeForm.controls[
        'control_value'
      ].updateValueAndValidity();
      this.getDropdownList.controls?.forEach((element) => {
        // element?.controls['controlTypeImgUpload'].clearValidators();
        // element?.controls['controlTypeImgUpload'].updateValueAndValidity();
        element?.controls['controlTypeVal'].clearValidators();
        element?.controls['controlTypeVal'].updateValueAndValidity();
      });
    }
  }

  getPureStringArr(obj: any) {
    let arr2 = [];
    if (obj.control_value) {
      // console.log("Obj",obj)
      let arr = obj?.control_value?.split(',');
      let arr1 =
        obj?.control_valueimg !== null ? obj?.control_valueimg?.split(',') : [];
      if (obj.control_type === 'dropDown') {
        arr.forEach((element, i) => {
          arr2.push({
            val: element ? element : '',
            img: arr1?.length ? (arr1[i] ? this.imgBaseUrl + arr1[i] : '') : '',
          });
        });
      }
      if (obj.control_type === 'input') {
        arr2.push({
          val: obj.control_value ? obj.control_value : '',
          img: obj.image ? obj.image : '',
        });
      }
    }
    return arr2;
  }
  ngOnInit(): void {

    console.log('disabled------------',this.disableField);
    
    console.log('NodeObj', this.nodeObj);

    let imageFileName = this.nodeObj.image;
    if (imageFileName) {
      this.prevImgPresent = true;
    } else {
      this.prevImgPresent = false;
    }
  
    // console.log('disableField 1', this.disableField);
    // if (this.nodeObj.ProductFeatures.length > 0) {
    //   this.disableField = true;
    //   console.log('YESSSSS');
    // }
    // this.disableField = true;
    // console.log('disableField 2', this.disableField);

    // if(this.nodeObj.surcharge === 1)
    // {

    //   console.log("true");
    //   this.nodeObj.surcharge = true;
    //   this.surcharge = true;
    // }
    // if(this.nodeObj.surcharge === 0)
    // {
    //   console.log("false");
    //   this.nodeObj.surcharge = false;
    //   this.surcharge = false;
    // }

    let image = this.nodeObj.image.split('/');
    image = image[image.length - 1];

    this.controlTypeForm.patchValue({
      label_name: this.nodeObj.label_name,
      control_type: this.nodeObj.control_type,
      control_value: this.nodeObj.control_value,
      surcharge: this.nodeObj.surcharge,
      imageUrl: this.nodeObj.image,
      image: image,
      id: this.nodeObj.id,
      catalogue_id: this.nodeObj.catalogue_id,
    });
    
    let ctrl_val:any[] = this.nodeObj.control_value?.split(',');
    let ctrl_img:any[] = this.nodeObj.control_valueimg?.split(',');
    if (ctrl_val?.length && ctrl_img?.length) {
      for (let index = 0; index < +ctrl_val.length; index++) {
        this.getDropdownList.push(
          this.fb.group({
            controlTypeVal: new FormControl(ctrl_val[index],[Validators.required, Validators.maxLength(30)]),
            controlTypeImg: new FormControl(''),
            controlTypeImgUpload: new FormControl(ctrl_img[index]),
          })
        );
      }
    }
   if(this.disableField){
      this.controlTypeForm.disable();
   }
    this.showVal();
    console.log('controlTypeForm', this.controlTypeForm.value);
  }

  editFeature(): void {
    console.log(this.controlTypeForm);
    
    // let imageFileName = this.nodeObj.image.split("/");
    // imageFileName = imageFileName[imageFileName.length - 1];

    // let newImg = this.controlTypeForm.controls.image.value;
    // let aa;
    // if(newImg){
    //   aa = {
    //     label_name,
    //     control_type : this.nodeObj.control_type,
    //     control_value,
    //     surcharge : this.nodeObj.surcharge,
    //     id : this.nodeObj.id,
    //     catalogue_id: this.nodeObj.catalogue_id,
    //     image : newImg
    //   };
    // }
    // else{
    //   aa = {
    //     label_name,
    //     control_type : this.nodeObj.control_type,
    //     control_value,
    //     surcharge : this.nodeObj.surcharge,
    //     id : this.nodeObj.id,
    //     catalogue_id: this.nodeObj.catalogue_id,
    //     image : imageFileName
    //   };
    // }
    
    let imgArray: any[] = [];
    let controlArray: any[] = [];
    this.getDropdownList.controls?.forEach((ctrl) => {
      imgArray.push(ctrl?.controls['controlTypeImgUpload'].value);
      controlArray.push(ctrl?.controls['controlTypeVal'].value);
    });
   let payload:any = this.controlTypeForm.value

   let payload2 
    if (this.controlTypeForm.controls.control_type?.value !== 'dropDown') {
      payload2 = {
        label_name: payload.label_name,
        control_type: payload.control_type,
        control_value:
          payload.control_value == null ? '' : payload.control_value,
        surcharge: payload.surcharge,
        catalogue_id: payload.catalogue_id,
        image: payload.image == null ? '' : payload.image,
        id:payload.id,
      };
    } else {
      if(this.getDropdownList.controls?.length <= 0){
        this.toastr.error(
          this.translationService.instant(
            'editFeature.addFeatureType',
            ''
          )
        );
        return
      }
      payload2 = {
        label_name: payload.label_name,
        control_type: payload.control_type,
        control_value: controlArray?.toString() || '',
        control_valueimg: imgArray?.toString() || '',
        surcharge: payload.surcharge,
        catalogue_id: payload.catalogue_id,
        id:payload.id,
        image: payload.image == null ? '' : payload.image,
        imageUrl:payload.imageUrl,
      };
    }
    console.log('Submitting', payload2);
    this.featureService.editFeature(payload2).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          setTimeout(() => {
            // this.toastr.success('Feature edited successfully.', '');
            this.toastr.success(
              this.translationService.instant(
                'editFeature.featureEditedMsgTsFile',
                ''
              )
            );
          }, 1000);
          this.activeModal.close();
        } else {
          this.toastr.error(data.body.message, '');
          this.activeModal.close();
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
          this.activeModal.close();
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
          this.activeModal.close();
        }
      }
    );
   
  }

  closeModall(): void {
    this.activeModal.close();
  }

  uploadImage(event: any, i:any): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }
        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType,
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then((resp) => {
        this.handleImageAPI(resp, i);
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
  }

  handleImageAPI(result: any, i:any): void {
    let input = new FormData();
    input.append('id', result.file);

    this.service
      .uploadImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.imageUrl = resp1.body.imageUrl;
          this.imageName = resp1.body.filename;
         
          if (i == null) {
            this.controlTypeForm.patchValue({
              image: resp1.body.filename,
              imageUrl: resp1.body.imageUrl,
            });
            this.prevImgPresent = false;
          } else {
            this.getDropdownList.controls[i].controls[
              'controlTypeImgUpload'
            ].patchValue(resp1.body.filename);
            this.prevImgPresent = true;
          }
          
        },
        (err) => {
          this.toastr.error('Something went wrong with image upload', '');
        }
      );
  }

  changeControlType(type: any) {
    this.controlTypeForm.patchValue({
      control_type: type,
    });
    this.nodeObj.control_type = type;
    this.showVal();
  }

  changeSurgeValue(surge: any) {
    this.controlTypeForm.patchValue({
      surcharge: surge,
    });
    this.nodeObj.surcharge = surge;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}
