import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) { }

  public getTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTemplate;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public addTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.addTemplate;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public updateTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateTemplate;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public getTemplateList(payload?:any): Observable<any> {
    const url: string = ApiRoutes.getTemplateList;
    if(payload){
      return this.http.post(url, payload, { observe: 'response' });
    }
    else{
      return this.http.post(url, { observe: 'response' });
    }
  }

  public deleteTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteTemplate;
    return this.http.post(url,payload, { observe: 'response' });
  }
  
  public copyTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.copyTemplate;
    return this.http.post(url,payload, { observe: 'response' });
  }

  public deleteTemplateType(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteTemplateType;
    return this.http.post(url,payload, { observe: 'response' });
  }

  public publishTemplate(payload: any): Observable<any> {
    const url: string = ApiRoutes.publishTemplate;
    return this.http.post(url,payload, { observe: 'response' });
  }

  public getTemplateTypeList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTemplateTypeList;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public addTemplateArea(payload: any): Observable<any> {
    const url: string = ApiRoutes.addTemplateArea;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public templateSearch(payload: any): Observable<any> {
    const url: string = ApiRoutes.templateSearch;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public uploadImageApi(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadImage;
    return this.http.post(url, payload, { observe: 'response' });
  }


}
