import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { ClientService } from '../client.service';

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace =
    ((control && control.value && control.value.toString()) || '').trim()
      .length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}
@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent implements OnInit {
  renderer: any;
  disabled = true;
  isEditable = false;
  ButtonText = '';
  activeProject: any;
  isContentLoaded: boolean = false;
  isProductExist: boolean;
  selectedData: any;
  selectedData2: string;
  reqIds: any;
  constants: any;
  client_id: number = 0;
  newProjectList: any[];
  updatedProjectList: any[];
  activeTemplate: any;
  wishList: any[] = [];
  imgBaseUrl = environment.imgBaseUrl;
  clientName: string = '';
  totalCost: any;
  form: FormGroup;
  selectedCurrentProduct: any;
  areAllProductsExists:boolean = true;
  rowId: any;
  constructor(
    private clientService: ClientService,
    private toastr: ToastrService,
    private router: Router,
    private translationService: TranslateService,
    private acRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      notes: [
        '',
        [Validators.required, Validators.maxLength(500), noWhitespaceValidator],
      ],
    });
    this.clientName =
      (localStorage.selectedClient && localStorage.getItem('selectedClient')) ||
      '';
    this.acRoute.params.subscribe((params: Params) => {
      this.client_id = +params['id'];
    });
  }

  getTextFieldFocused() {
    setTimeout(() => {
      document.querySelectorAll('textarea').forEach((item) => item.focus());
    }, 1000);
  }

  addNoteModal(product:any, category:any) {
    this.rowId = product?.id;
    this.selectedCurrentProduct = product;
    
    // form modification
    this.resetForm();
    this.enableControl();
  }

  viewNoteModal(product: any, category:any) {
    this.rowId = product?.id;
    this.selectedCurrentProduct = product;
    this.ButtonText = this.translationService.instant('productCart.editnote');
    this.isEditable = false;

    // form modification
    this.resetForm();
    this.disableControl();
    this.form.patchValue({ notes: product.product_note });

  }

  resetForm() {
    // form modification
    this.form.patchValue({ notes: '' });
    this.form.controls['notes'].markAsUntouched();
    this.form.updateValueAndValidity();
  }

  get getNotes(): FormControl {
    return this.form.get('notes') as FormControl;
  }

  cancelModal() {
    this.selectedCurrentProduct = null;
    this.rowId = null;
    this.isEditable = false;

    // form modification
    this.enableControl();
    this.resetForm();
  }

  EditNotes() {
    this.ButtonText = this.translationService.instant('productCart.savenote');
    this.isEditable = true;

    // form modification
    this.enableControl();
  }

  submitUpdateNotes() {
    // perform api action
    let payload = {
      note:this.form.controls['notes'].value.trim(),
      id:this.rowId,
      // id:this.selectedCurrentProduct.product_id,
      // project_id:this.selectedCurrentProduct.project_id,
      // template_id:this.selectedCurrentProduct.template_id
    }
    // perform api action 
    this.clientService.updateNote(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastr.success(this.translationService.instant('productCart.noteupdatesuccess'));
          this.ButtonText = this.translationService.instant('productCart.editnote');
          let cancel = document.getElementById('cancelEdit');
          cancel.click();
          this.cancelModal();
          this.getwishlistitembytempId();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      })

  }

  submitAddNote() {
    let payload = {
      note:this.form.controls['notes'].value.trim(),
      id:this.rowId,
      // id:this.selectedCurrentProduct.product_id,
      // project_id:this.selectedCurrentProduct.project_id,
      // template_id:this.selectedCurrentProduct.template_id
    }
    // perform api action 
    this.clientService.addNote(payload).subscribe(
      (data: any) => {
        console.log(data);
        if (data.status === 200) {
          this.toastr.success(this.translationService.instant('productCart.noteaddedsuccess'));
          let cancel = document.getElementById('cancelAdd');
          cancel.click();
          this.cancelModal();
          this.getwishlistitembytempId();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      })
  }

  deleteNote(isDelete: boolean, prod: any) {
    if (!isDelete) {
      return;
    } else {
      // perform delete action
      let payload = {
        note:'',
        id:this.rowId,
        // product_id:this.selectedCurrentProduct.product_id,
        // project_id:this.selectedCurrentProduct.project_id,
        // template_id:this.selectedCurrentProduct.template_id
      }
      // perform api action 
      this.clientService.deleteNote(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastr.success(this.translationService.instant('productCart.notedeletesuccess'));
            let cancel = document.getElementById('cancelEdit');
            cancel.click();
            this.cancelModal();
            this.getwishlistitembytempId();
          }
        },
        (err) => {
          this.toastr.error(err.error.message, '');
        })
    }
  }

  enableControl(){
    this.form.controls['notes'].enable();
    this.form.updateValueAndValidity();
  }

  disableControl(){
    this.form.controls['notes'].disable();
    this.form.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.getAllClientProjectList();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ButtonText = this.translationService.instant('productCart.editnote');
    }, 1500);
  }

  OnClickupdatedata(p: any, pi: number) {
    if (p?.details?.template_id === this.activeProject?.details?.template_id) {
      return;
    }
    this.isContentLoaded = false;
    this.isProductExist = false;
    this.areAllProductsExists = true;
    this.selectedData = p?.project?.name;
    this.selectedData2 = ` | ${p?.details?.Template?.name} | ${p?.details?.apartment_name}`;
    this.activeProject = p;
    this.getAllClientProjectList();
  }

  getAllClientProjectList() {
    let payload = {
      client_id: +this.client_id || 0,
    };

    this.clientService.getClientAllProjectsList(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.newProjectList = [];
          this.updatedProjectList = [];
          this.newProjectList = data?.body?.projectTemplateRec;

          this.newProjectList.filter((list: any) => {
            return list?.projectdetail.map((details: any) => {
              this.updatedProjectList.push({
                progressPercentage:
                  (+details.roomAddedWhist / +details.totalRoom) * 100,
                details,
                project: list,
              });
            });
          });

          if (!this.activeProject?.project?.id) {
            this.activeProject = this.updatedProjectList[0];
            this.selectedData = this.activeProject?.project?.name;
            this.selectedData2 = ` | ${this.activeProject?.details?.Template?.name} | ${this.activeProject?.details?.apartment_name}`;
            this.activeTemplate = this.activeProject?.details?.template_id;
          }
          setTimeout(() => {
            this.getwishlistitembytempId();
          }, 300);
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  getwishlistitembytempId() {
    const wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      client_id: this.client_id || 0,
    };
    this.clientService.getCartItems(wishlistData).subscribe((data: any) => {
      if (data.status === 200) {
        this.wishList = data?.body?.productWishlistRec;
        this.totalCost = data?.body?.costofAllRooms;
        this.isContentLoaded = true;
        this.wishList.forEach((data) => {
          data?.products?.length &&
            data?.products?.forEach((elementp, i) => {
              this.areAllProductsExists = false;
              elementp['isNotedAdded'] = false;
              if (elementp['product_note']) {
                elementp['isNotedAdded'] = true;
              }
            });
        });
      }
    });
  }
}
