<!-- <div class="container mt-100">
	<div class="row align-items-center flex-column">
		<h2 class="heading-img-center text-center">Login</h2>
		<div class="card col-10 mt-4 mb-5">
			<div class="card-body">
				<form novalidate [formGroup]="LoginForm">
					<div class="row pt-4">
						<div class="col-md-6">
							<div class="form-group">
								<label>*Email Address</label>
								<input type="text" class="form-control" formControlName="email" autocomplete="off" placeholder="Your Email Address" />
							</div>
							<ng-container *ngIf="LoginForm.controls['email'].errors && (LoginForm.get('email').dirty || LoginForm.get('email').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!LoginForm.controls['email'].errors.required">*Email is required.</small>
								<small class="text-danger error-msg" [hidden]="!LoginForm.controls['email'].errors.pattern">*Email is invalid.</small>
							</ng-container>
						</div>

						<div class="col-md-6">
							<div class="form-group relative icon-field">
								<label>*Password</label>
								<input type="{{passwordVisibility ? 'text' : 'password'}}" class="form-control" formControlName="password" autocomplete="off" placeholder="Password" />
								<a href="javascript:;" class="icon"><img src="assets/img/eye.png" alt="view" (click)="passwordVisibility = !passwordVisibility" /></a>
							</div>
							<ng-container *ngIf="LoginForm.controls['password'].errors && (LoginForm.get('password').dirty || LoginForm.get('password').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!LoginForm.controls['password'].errors.required">*Password is required.</small>
								<small class="text-danger error-msg" [hidden]="!LoginForm.controls['password'].errors.minlength">
									*Password must be minimum 6 character long.
								</small>
							</ng-container>
						</div>

						<div class="col d-flex justify-content-center align-items-center mt-4 mb-4 flex-column">
							<button (click)="doLogin($event)">
								Login
							</button>
							<br>
							<a href="javascript:;" [routerLink]="['../' + APP_ROUTES.forgotPassword]">Forgot Password?</a>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div> -->
<div class="login-page">
	<div class="login-box">
		<div class="login-logo mb-4">
			<a href="javascript:;"><img src="/assets/img/logo.png" alt="logo"/></a>
		</div>
		<!-- /.login-logo -->
		<div class="card">
			<div class="card-header text-center f-700">
				<!-- Login -->
				 {{'login.heading1' | translate}}
			  </div>
			<div class="card-body login-card-body">
				<form novalidate [formGroup]="LoginForm">
					<div class="form-group">
						<label>
							<!-- Email -->
							{{'login.email' | translate}}
						</label>
					<div class="input-group">
						<input type="email" class="form-control" formControlName="email"
							autocomplete="off" placeholder="{{'login.emailPlaceholder' | translate}}">
					
						<div class="input-group-append">
							<div class="input-group-text">
								<span class="fas fa-envelope"></span>
							</div>
						</div>
						
					</div>
					<ng-container *ngIf="LoginForm.controls['email'].errors &&
								(LoginForm.get('email').dirty || LoginForm.get('email').touched ||
								isSubmitting)">
								<small class="text-danger error-msg"
									[hidden]="!LoginForm.controls['email'].errors.required">
									<!-- *Email is required. -->
									{{'login.emailRequired' | translate}}</small>
								<small class="text-danger error-msg"
									[hidden]="!LoginForm.controls['email'].errors.pattern">
									<!-- *Email is
									invalid. -->
									*{{'login.emailInvalid' | translate}}
								</small> 
							</ng-container>

							</div>
							<div class="form-group">
					<label >
						<!-- Password -->
							{{'login.passwordPlaceholder' | translate}}
					</label>
					<div class="input-group">
						
						<input type="{{passwordVisibility ? 'text' : 'password'}}"
							class="form-control" formControlName="password" autocomplete="off"
							placeholder="{{'login.passwordPlaceholder' | translate}}">
					
						<div class="input-group-append">
							<div class="input-group-text">
								<span class="fas fa-lock"></span>
							</div>
						</div>
					</div>
					<ng-container *ngIf="LoginForm.controls['password'].errors &&
					(LoginForm.get('password').dirty || LoginForm.get('password').touched ||
					isSubmitting)">
					<small class="text-danger error-msg"
						[hidden]="!LoginForm.controls['password'].errors.required">
						<!-- *Password is
						required. -->
						*{{'login.passwordRequired' | translate}}
					</small>
					<small class="text-danger error-msg"
						[hidden]="!LoginForm.controls['password'].errors.minlength">
						<!-- *Password must be minimum 6 character long. -->
						*{{'login.passwordCharLimit' | translate}}
					</small>
				</ng-container>

							</div>
					<div class="row">
						<!-- <div class="col-8">
			  <div class="icheck-primary">
				<input type="checkbox" id="remember">
				<label for="remember">
				  Remember Me
				</label>
			  </div>
			</div> -->
						<!-- /.col -->
						
						<div class="col-12 text-right">
				<p class="mb-1">
					<a href="javascript:;" [routerLink]="['../' + APP_ROUTES.forgotPassword]">
						<!-- forgot my password -->
						{{'login.forgotPassword' | translate}}
					</a>
				</p>
						</div>


						<div class="col-12 text-center mt-3">
							<button type="submit" class="btn btn-primary"
								(click)="doLogin($event)">
								<!-- Sign In -->
								{{'login.signIn' | translate}}
							</button>
						</div>
						<!-- /.col -->
					</div>
				</form>

			</div>
			<!-- /.login-card-body -->
		</div>
	</div>
</div>
<!-- /.login-box -->