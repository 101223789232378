import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, private ls: LocalStorageService, private router: Router) { }

  public getCategories(): Observable<any> {
    const url: string = ApiRoutes.getCategories;
    return this.http.get(url, { observe: 'response' });
  }

  public addCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.addCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public deleteCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public uploadImage(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadImage;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getFeatureList(payload: any): Observable<any> {
    const url: string = ApiRoutes.listFeatures;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
