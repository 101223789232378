import { StaffService } from './../staff.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/shared/services/dateService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss'],
  providers: [
   
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class AddStaffComponent implements OnInit {

  form: FormGroup;
  todayDate: any;
  minDate : any;

  constructor(public fb: FormBuilder,
    private toastr: ToastrService,
    private service: StaffService,
    private router: Router,
    private dateAdapter: NgbDateAdapter<string>,
    private ngbCalendar: NgbCalendar,
    private translationService : TranslateService) {

    this.form = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
      last_name: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(60), this.noWhitespaceValidator]],
      phone_number: ['', [Validators.required, , Validators.pattern(/^[0-9\s]*$/), this.noWhitespaceValidator]],
      address: ['', [Validators.required, Validators.maxLength(300), this.noWhitespaceValidator]],
      postal_code: ['', [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]],
      city : ['',Validators.required],
      joining_date: [this.getTodayDate(), [Validators.required]],
      is_admin: [0],
      permission: this.fb.group({
        dashboard_screen: [0],
        dashboard: [0],
        product_screen: [0],
        product: [0],
        catalogue_screen: [0],
        catalogue: [0],
        client_screen: [0],
        client: [0],
        staff_screen: [0],
        staff: [0],
        template_screen: [0],
        template: [0],
        project_screen: [0],
        project: [0],
        timeline_screen: [0],
        timeline: [0],
        faq_screen: [0],
        faq: [0],
        document: [0],
        document_screen:[0]
      })

    });
  }

  ngOnInit(): void {
    this.todayDate = this.getTodayDate();
    this.minDate = this.getMinDate();
  }

  getTodayDate(): any {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;

    const date = moment().format('YYYY-MM-DD');
    const [year, month, day] = date.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
    // return this.mapDate(obj);
  }

  toDate(dob: string): any {
    const [year, month, day] = dob.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
  }

  mapDate(date?: any): any {
    if (!date) {
      return '';
    }
    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  addStaff() {

      let date  =  this.form.value.joining_date.day + '-' +
      this.form.value.joining_date.month + '-' +
      this.form.value.joining_date.year;
      date = date.split("-").reverse().join("-");
      date = this.formatDate(date);
     
   // // let date = this.mapDate(this.form.value.joining_date);
    // let date = this.form.value.joining_date.split("-").reverse().join("-");
    // date = this.formatDate(date);

    this.form.patchValue({ joining_date: date });
    console.log("PAYLOAD ", this.form.value);
    this.service.addStaff(this.form.value).subscribe((data: any) => {
      console.log("response ", data);
      if (data.status === 200) {
        // this.toastr.success('Staff added successfully', '');
        this.toastr.success(this.translationService.instant('addStaff.staffAddMsgTsFile'));

        this.router.navigate([APP_ROUTES.listStaff]).then(() => { });
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }

  updateForm(control: any, event: any) {
    console.log(control,'dasdsdasd');
    
    let value = this.form.get('permission').get(control).value;
    if (value) {
      // this.form.patchValue({
      //   control : 1
      // });
      this.form.get('permission').get(control).setValue(1);
    }
    else {
      
      this.form.get('permission').get(control).setValue(0);
    }

    if(!event.target.checked){
      this.form.patchValue({
        is_admin : false
      });
    }
  }

  isAdmin(control: any, event: any){
    console.log("event.target.checked ",event.target.checked);
    if(event.target.checked){
      this.form.patchValue({
        permission : {
            dashboard_screen: 1,
            dashboard: 1,
            product_screen: 1,
            product: 1,
            catalogue_screen: 1,
            catalogue: 1,
            client_screen: 1,
            client: 1,
            staff_screen: 1,
            staff: 0,
            template_screen: 1,
            template: 1,
            project_screen: 1,
            project: 1,
            timeline_screen: 1,
            timeline: 1,
            faq_screen: 1,
            faq: 1,
            document: 1,
            document_screen:1
            
        }
      });
    }
    else{
      this.form.patchValue({
        permission : {
            dashboard_screen: 1,
            dashboard: 0,
            product_screen: 1,
            product: 0,
            catalogue_screen: 1,
            catalogue: 0,
            client_screen: 1,
            client: 0,
            staff_screen: 1,
            staff: 0,
            template_screen: 1,
            template: 0,
            project_screen: 1,
            project: 0,
            timeline_screen: 1,
            timeline: 0,
            faq_screen: 1,
            faq: 0,
            document: 0,
            document_screen:1
        }
      });
    }
  }

  getMinDate(): any {

    const date = moment().format('YYYY-MM-DD');
    const [year, month, day] = date.split('-');
    const obj = {
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day.split(' ')[0].trim(), 10)
    };
    return obj;
  }

  formatDate(str : any) : any{
    let [year,month,day] = str.split("-");
    if(day.length == 1){
      day = '0' + day;
    }
    if(month.length == 1){
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }


}
