<div class="content-wrapper card-body" style="min-height: 1416.81px;">
    <div class="card card-primary ">
      <div class="card-header">
        <div class="w-100  d-flex justify-content-between">
          <span *ngIf="!operation">{{'editStaff.editStaff' | translate}}</span>
          <span *ngIf="operation">{{'editStaff.viewStaff' | translate}}</span>
        </div>
      </div>
      <div class="card-body">
        <form novalidate [formGroup]="form">
            <!-- {{form.value | json}} -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.addStaff' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="text" placeholder="{{'addStaff.enterFirstName'  | translate}}" formControlName="first_name" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.lastName' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="text" placeholder="{{'addClient.lastNamePlaceholder' | translate}}" formControlName="last_name" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.emailAddress' | translate}}<span class="text-danger">*</span></label>
                <input [readonly]="operation" type="email" placeholder="{{'addStaff.enterEmail' | translate}}" formControlName="email" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.contactNumber' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="tel" placeholder="{{'addStaff.enterContactNumber' | translate}}" formControlName="phone_number" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.address' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="text" placeholder="{{'addClient.enterAddress' | translate}}" formControlName="address"  class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.postalCode' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="text" placeholder="{{'addClient.postalCode' | translate}}" formControlName="postal_code" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'addStaff.city' | translate}} <span class="text-danger">*</span></label>
                <input [readonly]="operation" type="text" placeholder="{{'addStaff.city' | translate}}" formControlName="city" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">

                <!-- <label>{{'addStaff.dateofJoining' | translate}} <span class="text-danger">*</span></label>
                  <div class="input-group">

                      <input [readonly]="true" class="form-control" placeholder="dd/mm/yyyy"
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" 
                 (click)="operation ? doNothing() : d2.toggle()" formControlName="joining_date">

                      <div class="input-group-append">
                          <div class="input-group-text">
                              <i class="fa fa-calendar" (click)="operation ? doNothing() : d2.toggle()" style="cursor: pointer;" aria-hidden="true"></i>
                          </div>
                      </div>
                  </div> -->

              </div>
            </div>

          </div>
          <div class="row justify-content-center mt-5 mb-3">
            <div lass="col-md-12 mb-2 mb-md-3 mt-3 mt-md-5 text-center">
              <h5 class="f-700 line-heading d-inline-block p-relative">{{'addStaff.permission' | translate}}</h5>
            </div>
          </div>
          <div class="row p-2 ">
            <div class=" col-12 shadow-box-lg p-lg-5 p-4 rounded border" >
              <div class="w-100">
                <div class="row">
                  <div class="col-lg-4 mb-4 mb-lg-0">
                    <div class="form-check fs-5 d-flex align-items-center pl-0">
                      <input [attr.disabled]="operation"  
                      (change)="isAdmin('is_admin',$event)"
                      type="checkbox" formControlName="is_admin" value=""  class="checkbox-size mr-2">
                      <label class="form-check-label bold" for="flexCheckDefault">
                      <h5 class="mb-0">Select All</h5>
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-8" formGroupName="permission">
                    <div class="box w-100 p-lg-4 p-2 border rounded">
                      <ul class="list-group list-group-flush">

                        <li class="list-group-item bg-transprent pl-0 pr-0">
                          <div class="row align-items-center">
                            <div class="col-5 d-flex align-items-center">
                              <input class="form-check-input" type="checkbox" formControlName="catalogue_screen"
                              [attr.disabled]="operation"
                              (change)="updateForm('catalogue_screen',$event)" value=""  class="checkbox-size mr-2">
                              <label class="form-check-label bold" for="flexCheckDefault">
                                <strong>{{'addStaff.catalogue' | translate}}</strong>
                              </label>
                            </div>
                            <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.catalogue_screen">
                              <h6 class="m-0">{{'addStaff.view' | translate}}</h6>
                              <div  class="custom-control custom-switch ml-2">
                                <input  type="checkbox" formControlName="catalogue"
                                [attr.disabled]="operation"
                                (change)="updateForm('catalogue',$event)" class="custom-control-input" id="customSwitchcatalogue" checked>
                                <label class="custom-control-label" for="customSwitchcatalogue"></label>
                              </div>
                              <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                            </div>
                          </div>
                        </li>

                        <li class="list-group-item bg-transprent pl-0 pr-0">
                            <div class="row align-items-center">
                              <div class="col-5 d-flex align-items-center">
                                <input class="form-check-input" type="checkbox"
                                [attr.disabled]="operation"
                                 formControlName="product_screen"
                                 (change)="updateForm('product_screen',$event)"  value=""  class="checkbox-size mr-2">
                                <label class="form-check-label bold" for="flexCheckDefault">
                                  <strong>{{'addStaff.products' | translate}}</strong>
                                </label>
                              </div>
                              <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.product_screen">
                                <h6 class="m-0">{{'addStaff.view' | translate}}</h6>
                                <div  class="custom-control custom-switch ml-2">
                                  <input  type="checkbox"
                                  [attr.disabled]="operation"
                                   formControlName="product"
                                  (change)="updateForm('product',$event)"
                                  class="custom-control-input" id="customSwitchproduct" checked>
                                  <label class="custom-control-label" for="customSwitchproduct"></label>
                                </div>
                                <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                              </div>
                            </div>
                          </li>


                          <li class="list-group-item bg-transprent pl-0 pr-0">
                            <div class="row align-items-center">
                              <div class="col-5 d-flex align-items-center">
                                <input class="form-check-input" type="checkbox" 
                                [attr.disabled]="operation"
                                formControlName="template_screen" 
                                (change)="updateForm('template_screen',$event)" value=""  class="checkbox-size mr-2">
                                <label class="form-check-label bold" for="flexCheckDefault">
                                  <strong>{{'addStaff.templates' | translate}}</strong>
                                </label>
                              </div>
                              <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.template_screen">
                                <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                                <div  class="custom-control custom-switch ml-2">
                                  <input  type="checkbox" 
                                  [attr.disabled]="operation"
                                  formControlName="template" 
                                  (change)="updateForm('template',$event)" class="custom-control-input" id="customSwitch0template" checked>
                                  <label class="custom-control-label" for="customSwitch0template"></label>
                                </div>
                                <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                              </div>
                            </div>
                          </li>
                          

                            <li class="list-group-item bg-transprent pl-0 pr-0">
                                <div class="row align-items-center">
                                  <div class="col-5 d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" [attr.disabled]="operation"
                                    formControlName="client_screen" 
                                    (change)="updateForm('client_screen',$event)" value=""  class="checkbox-size mr-2">
                                    <label class="form-check-label bold" for="flexCheckDefault">
                                      <strong>{{'addStaff.client' | translate}}</strong>
                                    </label>
                                  </div>
                                  <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.client_screen">
                                    <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                                    <div  class="custom-control custom-switch ml-2">
                                      <input  type="checkbox" 
                                      [attr.disabled]="operation"
                                      formControlName="client"
                                      (change)="updateForm('client',$event)" class="custom-control-input" id="customSwitchclient" checked>
                                      <label class="custom-control-label" for="customSwitchclient"></label>
                                    </div>
                                    <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item bg-transprent pl-0 pr-0">
                                <div class="row align-items-center">
                                  <div class="col-5 d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox"
                                    [attr.disabled]="operation"
                                     formControlName="project_screen" 
                                    (change)="updateForm('project_screen',$event)" value=""  class="checkbox-size mr-2">
                                    <label class="form-check-label bold" for="flexCheckDefault">
                                      <strong>{{'addStaff.projects' | translate}}</strong>
                                    </label>
                                  </div>
                                  <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.project_screen">
                                    <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                                    <div  class="custom-control custom-switch ml-2">
                                      <input  type="checkbox" 
                                      [attr.disabled]="operation"
                                      formControlName="project" 
                                      (change)="updateForm('project',$event)" class="custom-control-input" id="customSwitch0project" checked>
                                      <label class="custom-control-label" for="customSwitch0project"></label>
                                    </div>
                                    <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item bg-transprent pl-0 pr-0">
                                  <div class="row align-items-center">
                                    <div class="col-5 d-flex align-items-center">
                                      <input class="form-check-input" type="checkbox"
                                       [attr.disabled]="operation"
                                       formControlName="staff_screen" 
                                      (change)="updateForm('staff_screen',$event)" value=""  class="checkbox-size mr-2">
                                      <label class="form-check-label bold" for="flexCheckDefault">
                                        <strong>{{'addStaff.staff' | translate}}</strong>
                                      </label>
                                    </div>
                                    <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.staff_screen">
                                      <h6 class="m-0">{{'addStaff.view' | translate}}</h6>
                                      <!-- <div  class="custom-control custom-switch ml-2">
                                        <input  type="checkbox" 
                                        [attr.disabled]="operation"
                                        formControlName="staff"
                                        (change)="updateForm('staff',$event)" class="custom-control-input" id="customSwitch0staff" checked>
                                        <label class="custom-control-label" for="customSwitch0staff"></label>
                                      </div>
                                      <h6 class="m-0">{{'addStaff.write' | translate}}</h6> -->
                                    </div>
                                  </div>
                                </li>


                                  

                                  <!-- <li class="list-group-item bg-transprent pl-0 pr-0">
                                    <div class="row align-items-center">
                                      <div class="col-5 d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" formControlName="timeline_screen" 
                                        (change)="updateForm('timeline_screen',$event)" value=""  class="checkbox-size">
                                        <label class="form-check-label bold" for="flexCheckDefault">
                                          <strong>Timeline</strong>
                                        </label>
                                      </div>
                                      <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.timeline_screen">
                                        <h6 class="m-0">View </h6>
                                        <div  class="custom-control custom-switch ml-2">
                                          <input  type="checkbox" formControlName="timeline" 
                                          (change)="updateForm('timeline',$event)" class="custom-control-input" id="customSwitch0timeline" checked>
                                          <label class="custom-control-label" for="customSwitch0timeline"></label>
                                        </div>
                                        <h6 class="m-0">Write</h6>
                                      </div>
                                    </div>
                                  </li> -->

                                  <li class="list-group-item bg-transprent pl-0 pr-0">
                                    <div class="row align-items-center">
                                      <div class="col-5 d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" 
                                        [attr.disabled]="operation"
                                        formControlName="faq_screen" 
                                        (change)="updateForm('faq_screen',$event)" value=""  class="checkbox-size mr-2">
                                        <label class="form-check-label bold" for="flexCheckDefault">
                                          <strong>FAQ</strong>
                                        </label>
                                      </div>
                                      <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.faq_screen">
                                        <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                                        <div  class="custom-control custom-switch ml-2">
                                          <input  type="checkbox" 
                                          [attr.disabled]="operation"
                                           formControlName="faq" 
                                          (change)="updateForm('faq',$event)" class="custom-control-input" id="customSwitch0faq" checked>
                                          <label class="custom-control-label" for="customSwitch0faq"></label>
                                        </div>
                                        <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                                      </div>
                                    </div>
                                  </li>

                                  <!-- Added Documents permission -->
                                  <li class="list-group-item bg-transprent pl-0 pr-0">
                                    <div class="row align-items-center">
                                      <div class="col-5 d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" 
                                        [attr.disabled]="operation"
                                        formControlName="document_screen"
                                         (change)="updateForm('document_screen',$event)" value="" class="checkbox-size mr-2">
                                        <label class="form-check-label bold" for="flexCheckDefault">
                                          <strong> {{ "DocCategory.documentcategories" | translate }}</strong>
                                        </label>
                                      </div>
                                      <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.document_screen">
                                        <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                                        <div class="custom-control custom-switch ml-2">
                                          <input type="checkbox"
                                          [attr.disabled]="operation"
                                           formControlName="document"
                                           (change)="updateForm('document',$event)"
                                            class="custom-control-input" id="customSwitchdocument" checked>
                                          <label class="custom-control-label" for="customSwitchdocument"></label>
                                        </div>
                                        <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                                      </div>
                                    </div>
                                  </li>


                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="border-top text-right p-3" *ngIf="!operation">
        <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="updateStaff()">
          <!-- Update -->
          {{'addCategory.update' | translate}} </button>
      </div>
    </div>
  </div>
