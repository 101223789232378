import { TranslateService } from '@ngx-translate/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent implements OnInit {

  templateList: any;
  APP_ROUTES = APP_ROUTES;
  popoverTitle = 'Delete';
  popoverMessage = 'Do you want to delete this Template?';
  confirmText="Confirm";
  cancelText="Cancel";
  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  totalItems: any =0;
  page: number = 1;
  permissions : any;
  firstText="<<";
  lastText=">>";
  prevText="<";
  nextText=">";

  constructor(private tempService: TemplateService,
    private toastr: ToastrService,
    private router: Router,
    private sharedService : SharedService,
    private baseService : BaseService,
    private translationService : TranslateService ) { }

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.getTemplateList();

    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete";
        this.popoverMessage="Do you want to delete this Template?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";

      }
      else{
        this.popoverTitle="Löschen";
        this.popoverMessage="Möchten Sie diese Vorlage löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Abbrechen";
      }
    });
  }

  pageChanged(event: any): void {
    // this.spinner.show();
    this.page = event.page;
    this.loadPage();
  }

  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: ""
    }
    this.getTemplateList(payload);
  }

  clearSearch(el : any){
    el.value = "";
    this.getTemplateList();
  }

  getTemplateList(payload?:any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search: ""
      };
    };

    this.tempService.getTemplateList(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.templateList = data.body.templatesRec;
        this.totalItems = data.body.totalCount;
        if(this.totalItems == 0){
          // this.toastr.error("No Client Found");
          this.toastr.error(this.translationService.instant('listTemplate.noTempFound'));
        } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });

  }

  deleteTemplate(str: Boolean, id: any) {
    if (str) {
      let payload = {
        id: id,
        status: 'delete'
      };

      this.tempService.deleteTemplate(payload).subscribe((data: any) => {
        
        if (data.status === 200) {
          console.log("response ", data);
          setTimeout(() => {
            // this.toastr.success('Template deleted successfully', '');
            this.toastr.success(this.translationService.instant('editClient.templateDeletedMsgTsFile'));

          }, 1000);
          this.loadPage();
        }
        else {
          this.toastr.error(data.body.message, '');
        }
      }, err => {
        console.log('error', err)
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
        else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      });

    } else { }
  }

  editTemplate(id: any) {
    localStorage.getItem('curTempStep');
    this.router.navigate([APP_ROUTES.addTemplate], { queryParams: { tId: id, op: 'edit' } }).then(() => { });
  }
  
  copyTemplate(id: any) {
      let payload = {
        id: +id,
      };
      this.tempService.copyTemplate(payload).subscribe((data: any) => {
        
        if (data.status === 200) {
          console.log("response ", data);
          setTimeout(() => {
            // this.toastr.success('Template deleted successfully', '');
            this.toastr.success(this.translationService.instant('editClient.cloneTemplate'));

          }, 1000);
          this.loadPage();
        }
        else {
          this.toastr.error(data.body.message, '');
        }
      }, err => {
        console.log('error', err)
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
        else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      });
  }
  search(event: any, clickFromBtn?:Boolean) {
    
    let searchText;

    if(clickFromBtn){
      searchText = event;
    }
    else{
      searchText = event.target.value;
    }

    if (searchText) {
      let payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search: searchText
      }
      this.tempService.getTemplateList(payload).subscribe((data: any) => {
        

        if (data.status === 200) {
          console.log("response ", data);
          this.templateList = data.body.templatesRec;
          console.log("templateList", this.templateList)
        }
        else {
          this.toastr.error(data.body.message, '');
        }
      }, err => {
        console.log('error', err)
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
        else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      });
    }
  }

}
