import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss'],
})
export class ListProductComponent implements OnInit {
  productList: any;
  APP_ROUTES = APP_ROUTES;
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Product?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  totalItems: any = 0;
  page: number = 1;
  permissions: any;
  searchElement = '';
  firstText = '<<';
  lastText = '>>';
  prevText = '<';
  nextText = '>';

  constructor(
    private service: ProductService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private baseService: BaseService,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.spinner.show();
    this.getProductList();

    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Delete';
        this.popoverMessage = 'Delete Product?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Löschen';
        this.popoverMessage = 'Produkt löschen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Stornieren';
      }
    });
  }

  pageChanged(event: any): void {
    this.spinner.show();
    this.page = event.page;
    this.loadPage();
  }
  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: this.searchElement,
    };
    this.getProductList(payload);
  }

  getProductList(payload?: any) {
    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search: this.searchElement,
      };
    }

    this.service.getProductList(payload).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status === 200) {
          this.spinner.hide();
          console.log('LIST OF PRODUCTS', data);
          this.productList = data.body.productRec;
          this.totalItems = data.body.totalRecord;
          if (this.totalItems == 0) {
            // this.toastr.error("No Client Found");
            this.toastr.error(
              this.translationService.instant(
                'listProduct.noProductFoundMsgTsFile'
              )
            );
          }
        } else {
          this.spinner.hide();
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
        this.spinner.hide();
      }
    );
  }

  editProduct(obj: any) {
    console.log('obj.id', obj);
    localStorage.setItem('singleProd',JSON.stringify(obj));
    this.router
      .navigate([APP_ROUTES.editProduct], { queryParams: { pId: obj.id } })
      .then(() => {});
  }

  deleteProduct(str: Boolean, obj: any) {
    console.log('obj', obj);
    if (str) {
      let payload = {
        id: obj,
      };
      this.service.deleteProduct(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.loadPage();
            setTimeout(() => {
              // this.toastr.success('Product deleted successfully.', '');
              this.toastr.success(
                this.translationService.instant(
                  'listProduct.productDeletedMsgTsFile'
                )
              );
            }, 1000);
          } else {
            this.toastr.error(data.body.message, '');
          }
        },
        (err) => {
          console.log('error', err);
          if (err.error.message) {
            this.toastr.error(err.error.message, '');
          } else {
            // this.toastr.error('Something went wrong. Please try again later', '');
            this.toastr.error(
              this.translationService.instant('login.genricErrorTsFile')
            );
          }
        }
      );
    } else {
    }
  }
  copyProduct(id: any) {
    let payload = {
      id: +id,
    };
    this.service.copyProduct(payload).subscribe((data: any) => {
      
      if (data.status === 200) {
        console.log("response ", data);
        setTimeout(() => {
          // this.toastr.success('Template deleted successfully', '');
          this.toastr.success(this.translationService.instant('listProduct.cloneTemplate'));

        }, 1000);
        this.loadPage();
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
}
  clearSearch(el: any) {
    el.value = '';
    this.searchElement = '';
    this.loadPage();
  }

  search(event: any, cond?: Boolean) {
    let search;
    if (cond) {
      search = event;
    } else {
      search = event.target.value;
    }
    this.searchElement = search;
    this.loadPage();
  }

  getType(type): string {
    if (!type) {
      return '-';
    }
    if (type === 'flat') {
      return this.translationService.instant('listProduct.pieces');
    } else if (type === 'variant') {
      return this.translationService.instant('listProduct.surface');
    }
  }
}
