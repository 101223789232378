<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body check">
  <!-- Content Header (Page header) -->
  <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                        <li class="breadcrumb-item active" *ngIf="!operation">Add Template Type</li>
                        <li class="breadcrumb-item active" *ngIf="operation">Edit Template Type</li>
                    </ol>
                </div>
            </div>
        </div>
    </section> -->

  <div class="card card-primary">
    <!-- remove status progressbar  -->

    <!-- <div
      class="card-header header-primary align-items-center d-inline-flex"
      *ngIf="operation === 'save'"
    >
      <span class="white-space-noswrap">Status</span>
      <div class="progress">
        <div
          class="progress-bar bg-success"
          role="progressbar"
          style="width: {{ progressBarValue }}"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <span class="white-space-noswrap"
        >{{ progressBarValue }} {{ "addTemplateTypes.completed" | translate }}
      </span>
    </div> -->
    <div class="card-body">
      <h4 class="mb-3 f-600" *ngIf="operation === 'save'">
        <!-- Create Template Type -->
        {{ "addTemplateTypes.createTemplateType" | translate }}
      </h4>
      <h4 class="mb-3 f-600" *ngIf="operation === 'edit'">
        <!-- Edit Template Type -->
        {{ "addTemplateTypes.editTemplateType" | translate }}
      </h4>
      <form>
        <div class="card card-primary">
          <div class="card-header header-primary text-center f-700">
            {{ "addTemplate.step" | translate }} {{ pageNo + 1 }}

            <!-- {{form.value | json}} -->
          </div>
          <!-- {{getAllErrors(form) | json}} -->
          <form [formGroup]="form">
            <div
              class="alert alert-info pt-1 pb-1 ml-3 mr-3 mb-0 mt-3 pl-2 pr-2"
            >
              {{ "addTemplateTypes.provide0asthedefaultvalue" | translate }}

              <strong>{{
                "addTemplateTypes.runningmeter/sqm" | translate
              }}</strong>
              {{
                "addTemplateTypes.ifyoudontishtoprovidespecificvalue"
                  | translate
              }}
            </div>
            <div class="card-body" formArrayName="templateTypeForm">
              <div
                class="card w-100 card-default"
                *ngFor="let obj of templateType().controls; index as i"
              >
                <div class="card-body">
                  <ng-container [formGroup]="obj">
                    <div class="row">
                      <div class="col-md-12 form-group mb-3">
                        <div class="col-12 p-0">
                          <label
                            >{{ obj?.value?.name
                            }}<span class="text-danger">*</span></label
                          >
                        </div>

                        <div class="w-100 d-flex justify-content-between">
                          <ng-container formGroupName="TemplateTypeAreas">
                            <div class="col-4 align-items-center">
                              <div class="input-group-append">
                                <!-- <select formControlName="template_area_type" class="input-group-text f-700">
                                                                    <option hidden value="" disabled selected>Choose Area
                                                                        Type<span class="text-danger">*</span>
                                                                    </option>
                                                                    <option *ngFor="let suit of oppoSuits" [value]="suit">
                                                                        {{suit}}
                                                                    </option>
                                                                </select> -->
                                <label class="inputLabel mr-2 mb-2">
                                  {{ "addTemplateTypes.areaFloor" | translate }}
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <input
                                type="text"
                                ngInputNumberI18n=".2-2"
                                class="form-control rounded"
                                placeholder="{{
                                  'addTemplateTypes.enterRoomArea' | translate
                                }}"
                                formControlName="template_area"
                              />

                              <div class="invalid-feedback d-block text-right">
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area')
                                        .hasError('required')
                                    "
                                    >*
                                    <!-- Template area is required -->
                                    {{
                                      "addTemplateTypes.tempAreaReq"
                                        | translate
                                    }}.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area')
                                        .hasError('pattern')
                                    "
                                    >*
                                    <!-- Only digits allowed -->
                                    {{
                                      "addProject.digitsAllowed" | translate
                                    }}.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area')
                                        .hasError('whitespace')
                                    "
                                    >*
                                    <!-- Whitespace not allowed -->
                                    {{
                                      "addFeature.whitespaceNotAllowed"
                                        | translate
                                    }}.</small
                                  >
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_type').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_type').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_type').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_type')
                                        .hasError('required')
                                    "
                                    >*
                                    <!-- Template area type required -->
                                    {{
                                      "addTemplateTypes.tempAreaTypeReq"
                                        | translate
                                    }}.</small
                                  >
                                </ng-container>
                              </div>
                            </div>
                            <div class="col-4 align-items-center">
                              <div class="input-group-append">
                                <!-- <select formControlName="template_area_type" class="input-group-text f-700">
                                                                    <option hidden value="" disabled selected>Choose Area
                                                                        Type<span class="text-danger">*</span>
                                                                    </option>
                                                                    <option *ngFor="let suit of oppoSuits" [value]="suit">
                                                                        {{suit}}
                                                                    </option>
                                                                </select> -->
                                <label class="inputLabel mr-2 mb-2">
                                  {{ "addTemplateTypes.areaWall" | translate }}
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <input
                                type="text"
                                ngInputNumberI18n=".2-2"
                                class="form-control rounded"
                                placeholder="{{
                                  'addTemplateTypes.enterRoomArea' | translate
                                }}"
                                formControlName="template_area_wall"
                              />

                              <div class="invalid-feedback d-block text-right">
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_wall').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_wall').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_wall').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_wall')
                                        .hasError('required')
                                    "
                                    >*
                                    <!-- Template area is required -->
                                    {{
                                      "addTemplateTypes.tempAreaWallReq"
                                        | translate
                                    }}.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_wall')
                                        .hasError('pattern')
                                    "
                                    >*
                                    <!-- Only digits allowed -->
                                    {{
                                      "addProject.digitsAllowed" | translate
                                    }}.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_wall')
                                        .hasError('whitespace')
                                    "
                                    >*
                                    <!-- Whitespace not allowed -->
                                    {{
                                      "addFeature.whitespaceNotAllowed"
                                        | translate
                                    }}.</small
                                  >
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_type').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('template_area_type').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_type').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('template_area_type')
                                        .hasError('required')
                                    "
                                    >*
                                    <!-- Template area type required -->
                                    {{
                                      "addTemplateTypes.tempAreaTypeReq"
                                        | translate
                                    }}.</small
                                  >
                                </ng-container>
                              </div>
                            </div>

                            <div class="col-4 align-items-center">
                              <div class="input-group-append">
                                <label class="inputLabel mr-2 mb-2">
                                  {{
                                    "addTemplateTypes.runningMeter" | translate
                                  }}
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <input
                                type="text"
                                ngInputNumberI18n=".2-2"
                                class="form-control rounded"
                                placeholder="Enter Running Meter"
                                formControlName="running_meter_area"
                              />

                              <div class="invalid-feedback d-block text-right">
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('running_meter_area').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('running_meter_area').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area')
                                        .hasError('required')
                                    "
                                    >*Template area is required.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area')
                                        .hasError('pattern')
                                    "
                                    >*
                                    <!-- Only digits allowed -->
                                    {{
                                      "addProject.digitsAllowed" | translate
                                    }}.</small
                                  >
                                  <small
                                    class="text-danger error-msg mr-2"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area')
                                        .hasError('whitespace')
                                    "
                                    >*
                                    <!-- Whitespace not allowed -->
                                    {{
                                      "addFeature.whitespaceNotAllowed"
                                        | translate
                                    }}.</small
                                  >
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                    obj
                                      .get('TemplateTypeAreas')
                                      .get('running_meter_area').errors &&
                                    (obj
                                      .get('TemplateTypeAreas')
                                      .get('running_meter_area').dirty ||
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area').touched)
                                  "
                                >
                                  <small
                                    class="text-danger error-msg"
                                    *ngIf="
                                      obj
                                        .get('TemplateTypeAreas')
                                        .get('running_meter_area')
                                        .hasError('required')
                                    "
                                    >*Running meter area type required.</small
                                  >
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                        </div>

                        <!-- <label class="inputLabel"><input type="text" formControlName="name"
                                                        disabled></label> -->
                      </div>

                      <div class="col-md-6 form-group room-category">
                        <label class="inputLabel">
                          {{ "addTemplateTypes.productcategory" | translate
                          }}<span class="text-danger">*</span></label
                        >
                        <div class="input-group mb-3">
                          <!-- <ng-container formGroupName="TemplateTypeAreas"> -->
                          <input
                            type="text"
                            #searchVA
                            class="form-control site"
                            placeholder=" {{
                              'addTemplateTypes.selectcategory' | translate
                            }}"
                            (click)="closeDropdownAndOpenSearch(ref123, ref1)"
                            (keyup.enter)="
                              onKeyUpEvent($event, obj.value.id, ref123)
                            "
                          />

                          <!-- <ng-container
                                                            *ngIf="obj.get('TemplateTypeAreas').get('category_ids').errors &&
              (obj.get('TemplateTypeAreas').get('category_ids').dirty || obj.get('TemplateTypeAreas').get('category_ids').touched)">
                                                            <small class="text-danger error-msg"
                                                                *ngIf="obj.get('TemplateTypeAreas').get('category_ids').hasError('required')">*Template area type
                                                                required.</small>
                                                        </ng-container> -->

                          <!-- </ng-container>     -->
                          <ul
                            class="site categoryList d-none section{{ i }}"
                            #ref123
                            id="ref123"
                          >
                            <li
                              class="site"
                              *ngFor="let cat of searchResult; index as i"
                              [class.cat_items]="cat?.allproducts?.length <= 0"
                              (click)="
                                callAll(
                                  's',
                                  cat,
                                  obj.value.id,
                                  ref123,
                                  ref1,
                                  searchVA
                                )
                              "
                            >
                              <label class="site">
                                <span
                                  class="ctgname site"
                                  style="color: #000; font-size: 12px"
                                  >{{ cat.cat_name }}</span
                                >
                                <span
                                  class="mr-5 ms-2 sd site"
                                  *ngIf="cat?.allproducts?.length <= 0"
                                >
                                  {{
                                    "addTemplateTypes.noproducts" | translate
                                  }}
                                </span>
                              </label>
                            </li>
                          </ul>
                          <ul class="categoryList d-none site" #ref1 id="ref1">
                            <!-- <li *ngFor="let cat of nodes2; index as i">
                                                <label>
                                                    <input type="checkbox" />{{cat.title}} {{i}} {{cat.children.length}}  </label>
                                                    <ng-container *ngIf="cat.children.length>0">
                                                            <a href="javascript:;"><i class="fa fa-angle-right"></i></a>
                                                            <ul class="sub-menu-items" >
                                                                    <li *ngFor="let cat2 of cat.children; index as j">
                                                                        <label><input type="checkbox" /> {{cat2.title}} {{j}}</label>
                                                                        <ng-container *ngIf="cat2.children.length>0">
                                                                                <a href="javascript:;"><i class="fa fa-angle-right"></i></a>
                                                                                <ul class="sub-menu-items" >
                                                                                        <li *ngFor="let cat3 of cat2.children; index as j">
                                                                                            <label><input type="checkbox" /> {{cat3.title}} {{j}}</label>
                                                                                            <ng-container *ngIf="cat3.children.length>0">
                                                                                                    <a href="javascript:;"><i class="fa fa-angle-right"></i></a>
                                                                                                    <ul class="sub-menu-items" >
                                                                                                            <li *ngFor="let cat4 of cat3.children; index as j">
                                                                                                                <label><input type="checkbox" /> {{cat4.title}} {{j}}</label>
                                                                                                            </li>
                                                                                                    </ul>
                                                                                            </ng-container>
                                                                                        </li>
                                                                                </ul>
                                                                        </ng-container>
                                                                    </li>
                                                            </ul>
                                                    </ng-container>
                                                    
                                        </li> -->

                            <ng-template
                              #myTemplateRef
                              *ngTemplateOutlet="
                                viewTemplate;
                                context: { list: nodes2, id: obj.value.id }
                              "
                            >
                            </ng-template>

                            <!-- <li><label><input type="checkbox" />Main category</label> <a href="javascript:;"><i class="fa fa-angle-right"></i></a>
                                                <ul class="sub-menu-items">
                                                    <li><label><input type="checkbox" /> Sub category</label></li>
                                                    <li><label><input type="checkbox" /> Sub category</label> 
                                                        <a href="javascript:;"><i class="fa fa-angle-right"></i></a>
                                                        <ul class="sub-menu-items">
                                                            <li><label><input type="checkbox" /> Sub category</label></li>
                                                            <li><label><input type="checkbox" /> Sub category</label> <a href="javascript:;"><i class="fa fa-angle-right"></i></a></li>
                                                            <li><label><input type="checkbox" /> Sub category</label></li>
                                                            <li><label><input type="checkbox" /> Sub category</label></li>
                                                            <li><label><input type="checkbox" /> Sub category</label></li>
                                                            <li><label><input type="checkbox" /> Sub category</label></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> -->
                          </ul>
                          <div
                            class="input-group-append site"
                            (click)="
                              searchOnButtonClickAndCloseSearchandOpenDropdown(
                                searchVA.value,
                                obj.value.id,
                                ref123,
                                true,
                                ref123,
                                ref1
                              )
                            "
                          >
                            <span class="input-group-text f-700 site"
                              ><i
                                class="fa fa-search site"
                                aria-hidden="true"
                              ></i
                            ></span>
                          </div>
                          <div class="input-group-append site">
                            <span
                              class="input-group-text f-700 site"
                              (click)="
                                closeSearchandOpenDropdown(
                                  ref1,
                                  ref123,
                                  searchVA,
                                  index
                                )
                              "
                              ><i class="fa fa-angle-down site"></i
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-6 form-group"
                        *ngIf="
                          obj?.controls?.TemplateTypeAreas?.value?.category_ids
                            ?.length > 0
                        "
                      >
                        <!-- Category Selected -->
                        <label class="w-100 mb-0 ml-5 ctg-label">
                          <!-- {{'addTemplateTypes.categorySelected' | translate}} -->
                          <p class="max-quantity-title">
                            {{ "addTemplateTypes.maxquantity" | translate }}
                          </p>
                        </label>
                        <div
                          class="w-100 ml-5 selected-ctg-wrapper"
                          *ngIf="
                            obj?.controls?.TemplateTypeAreas?.value
                              ?.category_ids?.length
                          "
                          cdkDropList
                          [cdkDropListData]="
                            obj?.controls?.TemplateTypeAreas?.value
                              ?.category_ids
                          "
                          (cdkDropListDropped)="
                            onDrop(
                              $event,
                              obj?.controls?.TemplateTypeAreas?.value
                                ?.category_ids
                            )
                          "
                        >
                          <div
                            class="d-flex align-items-center category-tage-wrapper"
                            *ngFor="
                              let category of obj?.controls?.TemplateTypeAreas
                                ?.value?.category_ids
                            "
                            cdkDragPreviewContainer="parent"
                            [cdkDragData]="category"
                            cdkDrag
                            style="padding: 5px 4px; cursor: move"
                          >
                            <span class="category-tag mb-0">
                              {{
                                category.title
                                  ? category.title
                                  : category.cat_name
                              }} 
                              <a
                                href="javascript:;"
                                (click)="
                                  deleteCategoryFromList(category, obj.value.id)
                                "
                                ><i class="fa fa-times"></i
                              ></a>
                            </span>
                            <div class="cartquantity" *ngIf="category && category.isParent==true">
                              <div class="d-flex" >
                                <button
                                  type="button"
                                  (click)="addQunatity(category)"
                                  class="plus"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9.259"
                                    height="9.834"
                                    viewBox="0 0 9.259 9.834"
                                  >
                                    <path
                                      id="icon_plus_white"
                                      data-name="icon plus white"
                                      d="M1.438-7.663H4.6v2.681H1.438v3.627H-1.484V-4.982H-4.657V-7.663h3.173v-3.525H1.438Z"
                                      transform="translate(4.657 11.188)"
                                      fill="#243977"
                                    />
                                  </svg>
                                </button>
                                <input
                                  type="text"
                                  class="cartquantityvalue"
                                  [(ngModel)]="category.qty"
                                  disabled
                                  [ngModelOptions]="{ standalone: true }"
                                />

                                <button
                                  type="button"
                                  (click)="minusQunatity(category)"
                                  class="minus"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10.058"
                                    height="2.449"
                                    viewBox="0 0 10.058 2.449"
                                  >
                                    <path
                                      id="icon_minus_white"
                                      data-name="icon minus white"
                                      d="M5.007-5.362H-5.052V-7.812H5.007Z"
                                      transform="translate(5.052 7.812)"
                                      fill="#243977"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <span style="font-size: 26px; margin-left: 10px">
                              <i class="fa fa-bars" style="color: #676b6e"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>

          <div class="card-footer text-center">
            <button
              *ngIf="pageNo > 1"
              class="btn btn-primary mr-3"
              (click)="loadPage('prev')"
            >
              <!-- Previous -->
              {{ "addTemplateTypes.previous" | translate }}
            </button>
            <button
              *ngIf="pageNo == 1"
              class="btn btn-primary mr-3"
              (click)="goToStep1()"
            >
              <!-- Previous
                            Step -->
              {{ "addTemplateTypes.previousStep" | translate }}
            </button>

            <button
              *ngIf="showNext && showNextStepButton2()"
              class="btn btn-primary mr-3"
              (click)="loadPage('next')"
            >
              <!-- NEXT -->
              {{ "addTemplateTypes.next" | translate }}
            </button>
            <!-- <button
              *ngIf="showNextStepButton() && form.valid"
              class="btn btn-primary mr-3"
              (click)="loadPage('next')"
            >
             
              {{ "addTemplateTypes.nextStep" | translate }}
            </button> -->
            <button
              *ngIf="!showNext && showNextStepButton2()"
              class="btn btn-primary mr-3"
              (click)="loadPage('next')"
            >
              <!-- Next Step -->
              {{ "addTemplateTypes.nextStep" | translate }}
            </button>
            <button
              *ngIf="operation === 'save'"
              class="btn btn-dark mr-3"
              [disabled]="form.invalid"
              (click)="save(true)"
            >
              <!-- Save As Draft -->
              {{ "addTemplateTypes.saveAsDraft" | translate }}
            </button>
            <button
              *ngIf="operation === 'edit'"
              class="btn btn-success mr-3"
              [disabled]="form.invalid"
              (click)="save(true)"
            >
              <!-- Save -->
              {{ "addTemplateTypes.save" | translate }}
            </button>
            <button
              *ngIf="savedTemplateArea"
              class="btn btn-primary"
              mwlConfirmationPopover
              [popoverTitle]="popoverTitle"
              [popoverMessage]="popoverMessage"
              [confirmText]="confirmText"
              [cancelText]="cancelText"
              placement="top"
              (confirm)="publish(true)"
              (cancel)="publish(false)"
            >
              <!-- Publish -->
              {{ "addTemplateTypes.publish" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #viewTemplate let-list="list" let-id="id" class="site">
  <li
    id="cat{{ i }}"
    class="site"
    *ngFor="let cat1 of list; index as i"
    [class.cat_items]="cat1?.allproducts?.length <= 0"
    [class.d-block]="cat1?.isExpand"
  >
    <span
      class="list-item-inner d-flex align-items-center justify-content-between"
    >
      <label class="site check" (click)="FieldsChange($event, cat1, id)">
        <!-- <input type="checkbox" /> -->
        {{ cat1.title }}
        <span class="site mr-5" *ngIf="cat1?.allproducts?.length <= 0 &&cat1?.children?.length <= 0">
          {{ "addTemplateTypes.noproducts" | translate }}
        </span>
      </label>
      <a
        href="javascript:;"
        class="site"
        (click)="toggleExpandList($event, cat1, id, i)"
        *ngIf="cat1?.children?.length > 0"
        ><i
          class="fa site check-icon"
          [ngClass]="!cat1?.isExpand ? 'fa-angle-right' : 'fa-angle-down'"
        ></i>
      </a>
    </span>
    <ng-container *ngIf="cat1?.children?.length > 0">
      <ul class="sub-menu-items site" [class.d-block]="cat1?.isExpand">
        <ng-template
          class="site"
          #myTemplateRef
          *ngTemplateOutlet="
            viewTemplate;
            context: { list: cat1.children, id: id }
          "
        >
        </ng-template>
      </ul>
    </ng-container>
  </li>
</ng-template>
