import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient,) { }

  

  public listFeatures(payload: any): Observable<any> {
    const url: string = ApiRoutes.listFeatures;
    return this.http.post(url, payload, { observe: 'response' });
  }
  
  public getFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.getFeature;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.addFeature;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public editFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.editFeature;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public deleteFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteFeatures;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
