<div class="content-wrapper card-body" style="min-height: 1416.81px">
  <ngx-spinner></ngx-spinner>
  <!-- <div *ngFor="let obj of breadcrumbs; index as i">
      <div *ngIf="i !== 0"> > </div>
      {{obj.name}}
  </div> -->
  <!-- 
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-">
        <ol class="breadcrumb float-sm-right" *ngFor="let obj of breadcrumbs; index as i">
          <li class="breadcrumb-item active">{{obj.name}}</li>
        </ol>
      </div> -->

  <!-- Content Header (Page header) -->

  <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-">

                    <ol class="breadcrumb ">
                        <div *ngIf="i !== 0"> > </div>
                        <li class="breadcrumb-item active" *ngFor="let obj of breadcrumbs; index as i">{{obj.name}}</li>
                    </ol>
                </div>

            </div>
        </div>
    </section> -->

  <!-- Main content -->
  <section class="content check">
    <!-- Default box -->
    <div class="card card-solid">
      <div class="card-body">
        <form novalidate [formGroup]="productForm" [hidden]="!step1" id="productFormtop">
          <div class="row">
            <div class="col mb-3">
              <h3>
                <!-- Edit product -->
                {{ "editProduct.editProd" | translate }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group w-100">
                <label for="exampleInputEmail1">
                  <!-- Product Name -->
                  {{ "addProduct.producName" | translate }}</label>

                <input type="text" class="form-control" placeholder="{{ 'addProduct.enterProductName' | translate }}"
                  formControlName="product_name" />

                <ng-container *ngIf="
                    productForm.controls['product_name'].errors &&
                    (productForm.get('product_name').dirty ||
                      productForm.get('product_name').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      productForm.controls['product_name'].hasError('required')
                    ">* {{ "addFeature.labelNameRequired" | translate }}.</small>
                  <small class="text-danger error-msg" *ngIf="
                      productForm.controls['product_name'].hasError('maxlength')
                    ">*{{ "menu.charLimit80" | translate }}.</small>
                </ng-container>
                <small class="text-danger error-msg" *ngIf="
                    productForm.controls['product_name'].hasError('pattern')
                  ">*{{ "addFeature.specialCharMsg" | translate }}.</small>
              </div>
            </div>

            <div class="col-md-4" *ngIf="productForm.controls['type'].value != 'variant'">
              <div class="form-group w-100">
                <label for="exampleInputEmail1">
                  {{ "addProduct.price" | translate }}</label>

                <input ngInputNumberI18n=".2-2" type="text" class="form-control" placeholder="Field Name"
                  formControlName="price" />

                <ng-container *ngIf="
                    productForm.controls['price'].errors &&
                    (productForm.get('price').dirty ||
                      productForm.get('price').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('required')">* {{
                    "addFeature.labelNameRequired" | translate }}..</small>
                  <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('maxlength')">* {{
                    "addProduct.charLimit10" | translate }}.</small>
                </ng-container>
                <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('pattern')">* {{
                  "addFeature.specialCharMsg" | translate }}.</small>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group w-100">
                <label for="exampleInputEmail1">
                  <!-- Description -->
                  {{ "addProduct.description" | translate }}
                  <span class="text-danger">*</span>
                </label>

                <textarea type="text" rows="3" class="form-control" placeholder="{{ 'editProduct.enterDesc' | translate }}"
                  formControlName="description" ></textarea>

                <ng-container *ngIf="
                    productForm.controls['description'].errors &&
                    (productForm.get('description').dirty ||
                      productForm.get('description').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      productForm.controls['description'].hasError('required')
                    ">*
                    <!-- Description is
                          required -->
                    {{ "addProject.descriptionRequired" | translate }}.</small>
                  <small class="text-danger error-msg" *ngIf="
                      productForm.controls['description'].hasError('maxlength')
                    ">*{{ "addClient.charLimit300" | translate }}.</small>
                </ng-container>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group w-100">
                <label for="exampleInputEmail1">
                  <!-- Type -->
                  {{ "editProduct.type" | translate }}
                  <span class="text-danger">*</span>
                </label>

                <select (change)="hideShowQuantity()" formControlName="type" class="form-control">
                  <option hidden value="" disabled selected>  {{'addProduct.choosetype' | translate}}</option>
                  <option value="flat"> {{'listProduct.pieces' | translate}}</option>
                  <option value="variant">{{'listProduct.surface' | translate}}</option>
                </select>

                <ng-container *ngIf="
                    productForm.controls['type'].errors &&
                    (productForm.get('type').dirty ||
                      productForm.get('type').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="productForm.controls['type'].hasError('required')">*Type
                    is required.</small>
                  <small class="text-danger error-msg" *ngIf="productForm.controls['type'].hasError('maxlength')">*{{
                    "addClient.charLimit300" | translate }}.</small>
                </ng-container>
              </div>
            </div>

            <div class="col-md-6" *ngIf="productForm.controls['type'].value === 'flat'">
              <div class="form-group w-100">
                <label for="exampleInputEmail1"> {{'addProduct.quantity' | translate}}
                  <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" placeholder=" {{'addProduct.enterquantity' | translate}}" formControlName="quantity" />
                <ng-container *ngIf="
                    productForm.controls['quantity'].errors &&
                    (productForm.get('quantity').dirty ||
                      productForm.get('quantity').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      productForm.controls['quantity'].hasError('required')
                    ">*Quanitity is required.</small>
                  <small class="text-danger error-msg"
                    *ngIf="productForm.controls['quantity'].hasError('digits')">*Quanitity should be contain
                    digits.</small>
                  <small class="text-danger error-msg" *ngIf="
                      !productForm.controls['quantity'].hasError('digits') &&
                      productForm.controls['quantity'].hasError('gt')
                    ">*Quanitity should greater than 0.</small>
                </ng-container>
              </div>
            </div>

            <!-- Surface Types -->

            <div class="check col-md-12 row no-gutter" *ngIf="productForm.controls['type'].value === 'variant'">
              <div class="select-surface-type col-md-12">
                <div class="row my-2">
                  <div class="col-md-4">
                    <div class="radio-input">
                      <input type="radio" id="vl1" value="price" formControlName="priceType" ng-model="priceType"
                        name="priceType" (input)="checkPriceValue($event.target.value)" />
                      <span class="checkbox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                          <path id="check-lg_white" data-name="check-lg white"
                            d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                            transform="translate(-3.001 -3.75)" fill="#fff" />
                        </svg>
                      </span>
                      <h5>{{ "addProduct.priceFloor" | translate }}</h5>
                    </div>
                  </div>
                  <div class="col-md-4" [hidden]="
                      productForm.controls['priceType'].value !== 'price'
                    ">
                    <div class="show-input">
                      <input type="text" ngInputNumberI18n=".2-2" class="form-control" placeholder="Enter Price"
                        formControlName="price" />
                      <ng-container *ngIf="
                          productForm.controls['price'].errors &&
                          (productForm.get('price').dirty ||
                            productForm.get('price').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price'].hasError('required')
                          ">*
                          <!-- Label name is
                  required -->
                          {{ "addProduct.pricereq" | translate }}.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price'].hasError('maxlength')
                          ">*
                          <!-- Maximum of 10 characters -->
                          {{ "addProduct.charLimit10" | translate }}.</small>
                      </ng-container>
                      <span class="currency">€</span>
                    </div>
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-md-4">
                    <div class="radio-input">
                      <input type="radio" id="vl2" value="pricewall" formControlName="priceType" ng-model="priceType"
                        name="priceType" (input)="checkPriceValue($event.target.value)" />
                      <span class="checkbox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                          <path id="check-lg_white" data-name="check-lg white"
                            d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                            transform="translate(-3.001 -3.75)" fill="#fff" />
                        </svg>
                      </span>
                      <h5>{{ "addProduct.priceWall" | translate }}</h5>
                    </div>
                  </div>
                  <div class="col-md-4" [hidden]="
                      productForm.controls['priceType'].value !== 'pricewall'
                    ">
                    <div class="show-input">
                      <span class="currency">€</span>
                      <input type="text" ngInputNumberI18n=".2-2" class="form-control" placeholder="Enter Price"
                        formControlName="price_wall" />

                      <ng-container *ngIf="
                          productForm.controls['price_wall'].errors &&
                          (productForm.get('price_wall').dirty ||
                            productForm.get('price_wall').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price_wall'].hasError(
                              'required'
                            )
                          ">*
                          <!-- Label name is
                 required -->
                          {{ "addProduct.pricereq" | translate }}.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price_wall'].hasError(
                              'maxlength'
                            )
                          ">*
                          <!-- Maximum of 10 characters -->
                          {{ "addProduct.charLimit10" | translate }}.</small>
                      </ng-container>
                      <small class="text-danger error-msg" *ngIf="
                          productForm.controls['price_wall'].hasError('pattern')
                        ">*
                        <!-- No special character allowed. -->
                        {{ "addFeature.specialCharMsg" | translate }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-md-4">
                    <div class="radio-input">
                      <input type="radio" ng-model="priceType" name="priceType" id="vl3" value="priceruning"
                        formControlName="priceType" (input)="checkPriceValue($event.target.value)" />
                      <span class="checkbox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                          <path id="check-lg_white" data-name="check-lg white"
                            d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                            transform="translate(-3.001 -3.75)" fill="#fff" />
                        </svg>
                      </span>
                      <h5>{{ "addProduct.runningMeter" | translate }}</h5>
                    </div>
                  </div>
                  <div class="col-md-4" [hidden]="
                      productForm.controls['priceType'].value !== 'priceruning'
                    ">
                    <div class="show-input" ng-show="content == 'value3'">
                      <span class="currency">€</span>
                      <input type="text" class="form-control" placeholder="Enter Price"
                        formControlName="price_running_meter" />

                      <ng-container *ngIf="
                          productForm.controls['price_running_meter'].errors &&
                          (productForm.get('price_running_meter').dirty ||
                            productForm.get('price_running_meter').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls[
                              'price_running_meter'
                            ].hasError('required')
                          ">*
                          <!-- Label name is
                 required -->
                          {{ "addProduct.pricereq" | translate }}.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls[
                              'price_running_meter'
                            ].hasError('maxlength')
                          ">*
                          <!-- Maximum of 10 characters -->
                          {{ "addProduct.charLimit10" | translate }}.</small>
                      </ng-container>
                      <small class="text-danger error-msg" *ngIf="
                          productForm.controls['price_running_meter'].hasError(
                            'pattern'
                          )
                        ">*
                        <!-- No special character allowed. -->
                        {{ "addFeature.specialCharMsg" | translate }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-4 "> 
                            <div class="form-group w-100">
                                <label for="exampleInputEmail1">
                                        {{'addProduct.priceFloor' | translate}}
                                        <span class="text-danger">*</span>
                                </label>
                               <input type="text"  ngInputNumberI18n=".2-2" class="form-control" placeholder="Enter Price" formControlName="price" />
                                <ng-container *ngIf="productForm.controls['price'].errors &&
                              (productForm.get('price').dirty || productForm.get('price').touched)">
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('required')">*
                                        
                                    {{'addFeature.labelNameRequired' | translate}}.</small>
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('maxlength')">*
                                      
                                        {{'addProduct.charLimit10' | translate}}.</small>
                                </ng-container>
                                <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('pattern')">*
                                    
                                  {{'addFeature.specialCharMsg' | translate}}
                                 </small>
                            </div>
                        </div> -->
              <!-- <div class="col-md-4">
                            <div class="form-group w-100">
                                <label for="exampleInputEmail1">
                                   
                                        {{'addProduct.priceWall' | translate}}
                                <span class="text-danger">*</span>
                                </label>
                            
                                <input type="text" 
                                 ngInputNumberI18n=".2-2" 
                                 class="form-control" 
                                 placeholder="Enter Price" 
                                 formControlName="price_wall" />

                                <ng-container *ngIf="productForm.controls['price_wall'].errors &&
                              (productForm.get('price_wall').dirty || productForm.get('price_wall').touched)">
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('required')">*
                                      
                                    {{'addFeature.labelNameRequired' | translate}}.</small>
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('maxlength')">*
                                        
                                        {{'addProduct.charLimit10' | translate}}.</small>
                                </ng-container>
                                <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('pattern')">*
                                    
                                  {{'addFeature.specialCharMsg' | translate}}
                                 </small>
                            </div>
                        </div> -->
              <!-- <div class="col-md-4">
                            <div class="form-group w-100">
                                <label for="exampleInputEmail1">
                                   
                                        {{'addProduct.runningMeter' | translate}}
                                <span class="text-danger">*</span>
                                </label>
                            
                                <input type="text" 
                                 ngInputNumberI18n=".2-2" 
                                 class="form-control" 
                                 placeholder="Enter Price" 
                                 formControlName="price_running_meter" />

                                <ng-container *ngIf="productForm.controls['price_running_meter'].errors &&
                              (productForm.get('price_running_meter').dirty || productForm.get('price_running_meter').touched)">
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('required')">*
                                       
                                    {{'addFeature.labelNameRequired' | translate}}.</small>
                                    <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('maxlength')">*
                                        
                                        {{'addProduct.charLimit10' | translate}}.</small>
                                </ng-container>
                                <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('pattern')">*
                                    
                                  {{'addFeature.specialCharMsg' | translate}}
                                 </small>
                            </div>
                        </div> -->
            </div>
            <!-- end Surface Types -->
          
            <div class="col-md-4">
              <div class="form-group w-100">
                <label>{{ "editProduct.updateImage" | translate }} <small>( {{'addProject.supportFormat'|translate}} png, jpeg, jpg )</small></label>
                <input class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event,'')"  id="selectedFile" />
                <!-- <input type="file" id="fileupload" multiple #myInput class="h-auto form-control"
                  (change)="uploadImage($event)" style="color: #656a6d" onchange="this.style.color='transparent'" /> -->
                <!-- <input class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event)" style="display: none;" id="selectedFile" />
                                <div class="col-md-auto ">
                                        <a class="btn btn-danger mt-3 " href="javascript:; " (click)="myInput.click()">Upload Image</a>
                                    </div> -->
              </div>

              <div class="col-md-12 justify-content-center align-self-center" *ngIf="featureList?.length>0">
                <div class="d-flex  w-100">
                  <input type="checkbox" formControlName="is_extra" id="checkbox_extra" class="h-auto  form-control mr-2" style="width:20px"
                  title="" />
                  <label for="checkbox_extra" class="mt-2">
                    {{ "addProduct.selectExtra" | translate }}
                  </label>
                </div>
              </div>
              <div class="col-md-12 mt-4 justify-content-center align-self-center">
                <div class="d-flex w-100">
                  <button type="button" [title]="'addProduct.browseCat' | translate" class="btn btn-default mr-2" (click)="openCatModal()">
                    {{ "addProduct.chooseCategory" | translate }}
                    <span class="ml-2">
                    <i class='fa fa-folder-open'></i>
                    </span>
                  </button>
                  
                 
                </div>
                <!-- <small class="text-danger error-msg" *ngIf="category_ids_arr.length === 0 && isModalOpened">
                  *Category is required.
                </small> -->
                <small class="text-danger error-msg" *ngIf="
                              productForm.controls[
                                'catalogue_id'
                              ].hasError('required')
                            && isModalOpened">
                     
                   *{{ "addProduct.catReq" | translate }}.</small>
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group">
                <label>{{ "editProduct.currentImage" | translate }}</label>
                <!-- <ng-container *ngIf="prevImgPresent; then prevImg; else newImg">
                </ng-container>

                <ng-template #prevImg>
                  <div class="thumb-image mx-2">
                    <img [src]="imgBaseUrl + imgUrl" />
                  </div>
                </ng-template>
                <ng-template #newImg>
                  <div *ngIf="!noImage" class="thumb-image mx-2">
                    <img [src]="imgUrl" />
                  </div>
                  <div *ngIf="noImage" class="thumb-image mx-2">
                    {{ "addFeature.noImage" | translate }}
                  </div>
                </ng-template> -->
                <div class="d-flex flex-wrap justify-content-start" *ngIf="productImageList?.length > 0">
                  <div *ngFor="let preview of productImageList; let i = index"  class="position-relative img-wrapper" (click)="openImageModal(preview)">
                   <span  class="input-icon input-icon-right close-icoo">
                    <svg (click)="removeProductImage(i)" xmlns="http://www.w3.org/2000/svg"
                      class="input-icon input-icon-right close-icoo" width="16" height="16" viewBox="0 0 16 16">
                      <path id="x-circle-fill"
                        d="M16,8A8,8,0,1,1,8,0a8,8,0,0,1,8,8ZM5.354,4.646a.5.5,0,0,0-.708.708L7.293,8,4.646,10.646a.5.5,0,0,0,.708.708L8,8.707l2.646,2.647a.5.5,0,0,0,.708-.708L8.707,8l2.647-2.646a.5.5,0,1,0-.708-.708L8,7.293Z" />
                    </svg>
                  </span>
                    <img [src]="imgBaseUrl + preview" class="preview"  />
                    <small >{{preview}}</small>
                  </div>

                </div>
                <div class="d-flex justify-content-start" *ngIf="productImageList?.length <= 0">
                  <div *ngIf="!noImage" class="thumb-image mx-2">
                    <img [src]="imgUrl" />
                  </div>
                  <div *ngIf="noImage" class="thumb-image mx-2">
                    {{ "addFeature.noImage" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-end">
            <button type="button" class="btn btn-success mr-2" [disabled]="!productForm.valid"
              (click)="updateProduct()">
              <!-- Update Product -->
              {{ "editProduct.updateProduct" | translate }}
            </button>
            <!-- <button
              *ngIf="containsFeatures"
              type="button"
              class="btn btn-default"
              (click)="goToStep2()"
            >
              {{ "editProduct.goToStep2" | translate }}
            </button> -->
            <button *ngIf="featureList?.length > 0" type="button" class="btn btn-default" (click)="goToStep2()">
              {{ "editProduct.goToStep2" | translate }}
            </button>
          </div>
        </form>

        <!-- <div *ngIf="step2">
          <div class="table-responsive">
            <h3>
             
              {{ "editProduct.featureList" | translate }}
            </h3>
            <table class="table table-striped table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th>{{ "addProduct.name" | translate }}</th>
                  <th>{{ "addProduct.featuretype" | translate }}</th>
                  <th>{{ "addProduct.price" | translate }}</th>
                  <th>{{ "addProduct.surcharge" | translate }}</th>
                  <th>{{ "addProduct.action" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let obj of productFeature">
                  <td class="align-middle">
                    <div class="form-check pl-0">
                    
                      <label class="form-check-label" for="exampleCheck1">{{ obj?.CatalogueFeature?.label_name }}
                      </label>
                    </div>
                  </td>
                  <td class="align-middle">
                  
                    <div class="ft-wrapper">
                      <div class="drop-feature">
                        <img *ngIf="
                            obj?.control_type === 'dropDown' && obj?.control_img
                          " [src]="
                            obj?.control_img
                              ? imgBaseUrl + obj?.control_img
                              : 'assets/img/placeholder.png'
                          " class="ft-img" />
                        <img *ngIf="
                            obj?.control_type === 'input' &&
                            obj?.CatalogueFeature?.image
                          " [src]="
                            obj?.CatalogueFeature?.image
                              ? imgBaseUrl + obj?.CatalogueFeature?.image
                              : 'assets/img/placeholder.png'
                          " class="ft-img" />
                        <span class="form-check-label">{{ obj?.control_value }}
                        </span>
                      </div>
                    </div>

                  
                  </td>
                  <td>
                    <div>
                      <label class="form-check-label">{{ obj?.price }} </label>
                    </div>
                  </td>
                  <td *ngIf="obj?.CatalogueFeature?.surcharge === 1">
                    {{ "addProduct.yes" | translate }}
                  </td>
                  <td *ngIf="obj?.CatalogueFeature?.surcharge === 0">
                    {{ "addProduct.no" | translate }}
                  </td>
                  <td class="align-middle mw-150 mw-150">
                    <button *ngIf="obj?.CatalogueFeature?.surcharge === 1" (click)="customizeFeature(obj)"
                      class="btn-sm-round btn-edit bg-grey text-dark">
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <label *ngIf="obj?.CatalogueFeature?.surcharge === 0" class="form-check-label">
                      {{ "addProduct.noActionAvailable" | translate }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p class="w-100 text-right m-0">
            <button type="button" class="btn btn-default my-2 mr-2" (click)="backToStep1()">
            
              {{ "editProduct.backToStep1" | translate }}
            </button>
          </p>
        </div> -->

        <div *ngIf="step2">
          <div class="card-body table-responsive" id="inst">
           
      
            <h3>
              <!-- Feature List -->
              {{ "editProduct.featureList" | translate }}
            </h3>
            <table class="table table-striped table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 160px">
                    <span class="d-flex"><input type="checkbox" class="mr-2" [(ngModel)]="selectall"
                        [ngModelOptions]="{ standalone: true }" id="selectall" [checked]="selectall"
                        (input)="selectAllFeatureToggle()" />
                      {{ "addProduct.selectAll" | translate }}</span>
                  </th>
                  <th>{{ "addProduct.name" | translate }}</th>
                  <th>{{ "addProduct.catName" | translate }}</th>
                  <th>{{ "addProduct.controlValue" | translate }}</th>
                  <th>{{ "addProduct.surcharge" | translate }}</th>
                  <th>{{ "addProduct.action" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let obj of featureList;let i =index">
                  <td>
                    <input type="checkbox" [checked]="obj?.isSelected" (input)="toggleSelectedFeature(obj)" />
                  </td>
                  <td class="align-middle">
                    <div class="form-check pl-0">
                      <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" /> -->
                      <label class="form-check-label" for="exampleCheck1">{{ obj.label_name }}
                      </label>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div class="form-check pl-0">
                      <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" /> -->
                      <label class="form-check-label" for="exampleCheck1">{{ obj.cat_name }}
                      </label>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div *ngIf="obj.control_type === 'dropDown'">
                      <select class="form-control">
                        <option *ngFor="let opt of getPureString(obj.control_value)">
                          <ng-container>{{ opt }}</ng-container>
                        </option>
                      </select>
                    </div>
                    <div *ngIf="obj.control_type === 'input'">
                      <label class="form-check-label">{{ obj.control_value }} </label>
                    </div>
                  </td>
      
                  <td *ngIf="obj.surcharge === 1">
                    <!-- Yes -->
                    {{ "addProduct.yes" | translate }}
                  </td>
                  <td *ngIf="obj.surcharge === 0">
                    <!-- No -->
                    {{ "addProduct.no" | translate }}
                  </td>
                  <td class="align-middle mw-150 mw-150">
                    <button *ngIf="obj.surcharge === 1" (click)="customizeFeature(obj, i)"
                      class="btn-sm-round btn-edit bg-grey text-dark">
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <label *ngIf="obj.surcharge === 0" class="form-check-label">
                      <!-- No Action Available  -->
                      {{ "addProduct.noActionAvailable" | translate }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer bg-grey justify-content-end d-flex">
        <button type="button" *ngIf="step1" class="btn btn-primary" (click)="showProductListing()">
          <!-- Show Product Listing -->
          {{ "editProduct.showProductListing" | translate }}
        </button>

       <p class="text-right">
        <button *ngIf="step2" type="button" class="btn btn-default mr-1" (click)="backToStep1()">
          {{ "editFeature.step1" | translate }}
        </button>
      </p>
        <p class="text-right">
          <button *ngIf="step2" type="button" class="btn btn-primary" (click)="saveProductFeatures()">
            <!-- Save Product Features -->
            {{ "editFeature.updateProdFeat" | translate }}
          </button>
        </p>
      </div>
    </div>
  </section>
</div>

<ng-template #imagePreview let-modal>
  <div class="card" style="margin-bottom: 0;">
    <div class="card-header-top">
     
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()" style="position: absolute; right: -7px; background: white; opacity: 1; color: rgb(255, 255, 255); top: -6px; height: 20px; width: 20px; border-radius: 40px; display: flex; justify-content: center; align-items: center; font-size: 15px; font-weight: 400; line-height: 1;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path id="x-circle-fill" d="M16,8A8,8,0,1,1,8,0a8,8,0,0,1,8,8ZM5.354,4.646a.5.5,0,0,0-.708.708L7.293,8,4.646,10.646a.5.5,0,0,0,.708.708L8,8.707l2.646,2.647a.5.5,0,0,0,.708-.708L8.707,8l2.647-2.646a.5.5,0,1,0-.708-.708L8,7.293Z"/>
        </svg>
        
      </button>
    </div>
    <div class="card-body">
      <img [src]="previewImage" alt="loading.." class="img-fluid" style="width: 100%;">
    </div>
  </div>
</ng-template>

<ng-template #cropImageModal let-modal>
  <div
    class="modal-body bookappointment-modal "
  >
    <image-cropper
    [imageChangedEvent]="null"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="true"
      [aspectRatio]="1.6 / 1"
      format="png"
      [imageQuality]="100"
      [imageFile]="imageOrigin"
      [resizeToWidth]="imageWidth"
      [resizeToHeight]="imageHeight"
      [onlyScaleDown]="isImageScaleDown"
      [backgroundColor]="'#ffffff'"
(imageLoaded)="imageLoaded($event)"
(imageCropped)="imageCropped($event)"
(startCropImage)="imageCroppedStarted($event)"
(loadImageFailed)="imageLoadFailed($event)"
></image-cropper>
    </div>
  <div class="modal-footer justify-content-between mb-3 px-md-5 px-3">
    <button
      type="button"
      class="btn btn-transparent text-black mw-180 mx-0"
      aria-label="Close"
      (click)="
        modal.dismiss('Cross click');
        closeModal();
      "
    >
      Cancel
    </button>
    <button
  
      type="button"
      class="btn btn-dark mw-180 mx-0"
      (click)="saveImage(cropImageModal)"
    >
      Update
    </button>
  </div>
</ng-template>