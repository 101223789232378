import { CustomerProcessTimeComponent } from './components/post-auth-pages/admin/reports/customer-process-time/customer-process-time.component';
import { SelectedMostComponent } from './components/post-auth-pages/admin/reports/selected-most/selected-most.component';
import { EditProjectComponent } from './components/post-auth-pages/admin/project/edit-project/edit-project.component';
import { ListProjectComponent } from './components/post-auth-pages/admin/project/list-project/list-project.component';
import { AddProjectComponent } from './components/post-auth-pages/admin/project/add-project/add-project.component';
import { ListTemplateComponent } from './components/post-auth-pages/admin/template/list-template/list-template.component';
import { ViewTemplateComponent } from './components/post-auth-pages/admin/template/view-template/view-template.component';
import { AddTemplateComponent } from './components/post-auth-pages/admin/template/add-template/add-template.component';
import { ListProductComponent } from './components/post-auth-pages/admin/product/list-product/list-product.component';
import { AddProductComponent } from './components/post-auth-pages/admin/product/add-product/add-product.component';
import { ListFaqComponent } from './components/post-auth-pages/admin/faq/list-faq/list-faq.component';
import { AddFaqComponent } from './components/post-auth-pages/admin/faq/add-faq/add-faq.component';
import { AddCategoryComponent } from './components/post-auth-pages/admin/add-category/add-category.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { DashboardComponent } from './components/post-auth-pages/admin/dashboard/dashboard.component';
import { LoggedInUserGuard } from './auth/loggedInUser.guard';
import { EditProductComponent } from './components/post-auth-pages/admin/product/edit-product/edit-product.component';
import { AddTemplateTypesComponent } from './components/post-auth-pages/admin/template/add-template/add-template-types/add-template-types.component';
import { ListClientsComponent } from './components/post-auth-pages/admin/clients/list-clients/list-clients.component';
import { AddClientComponent } from './components/post-auth-pages/admin/clients/add-client/add-client.component';
import { TimelineComponent } from './components/post-auth-pages/admin/timeline/timeline.component';
import { EditClientComponent } from './components/post-auth-pages/admin/clients/edit-client/edit-client.component';
import { ListStaffComponent } from './components/post-auth-pages/admin/staff/list-staff/list-staff.component';
import { AddStaffComponent } from './components/post-auth-pages/admin/staff/add-staff/add-staff.component';
import { ProjectOverviewComponent } from './components/post-auth-pages/admin/project-overview/project-overview.component';
import { EditStaffComponent } from './components/post-auth-pages/admin/staff/edit-staff/edit-staff.component';
import { PermissionGuard } from './auth/permission.guard';
import { DocCategoryComponent } from './components/post-auth-pages/admin/doc-category/doc-category.component';
import { AuthGuard } from './auth/auth.guard';
import { CartPageComponent } from './components/post-auth-pages/admin/clients/cart-page/cart-page.component';
import { PrivacyComponent } from './components/post-auth-pages/admin/privacy/privacy.component';
import { ManageAdminProfileComponent } from './components/post-auth-pages/admin/manage-admin-profile/manage-admin-profile.component';
import { ImprintComponent } from './components/post-auth-pages/admin/privacy/imprint/imprint.component';
import { DataPrivacyComponent } from './components/post-auth-pages/admin/privacy/data-privacy/data-privacy.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInUserGuard],
  },
  {
    path: 'timeline',
    component: TimelineComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgot_password',
    component: ChangePasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
    // canActivate: [PermissionGuard],
  },
  {
    path: 'category',
    component: AddCategoryComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-faq',
    component: AddFaqComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
    // canActivate: [PermissionGuard]
  },
  {
    path: 'list-faq',
    component: ListFaqComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'list-product',
    component: ListProductComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-product',
    component: AddProductComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-product',
    component: EditProductComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-template',
    component: AddTemplateComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-template-type',
    component: AddTemplateTypesComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'list-template',
    component: ListTemplateComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'list-project',
    component: ListProjectComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-project',
    component: AddProjectComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-project',
    component: EditProjectComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'client/cart-page/:id',
    component: CartPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'list-client',
    component: ListClientsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-client',
    component: AddClientComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-client',
    component: EditClientComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'add-staff',
    component: AddStaffComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
    // canActivate: [PermissionGuard]
  },
  {
    path: 'edit-staff',
    component: EditStaffComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
    // canActivate: [PermissionGuard]
  },
  {
    path: 'overview',
    component: ProjectOverviewComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'list-staff',
    component: ListStaffComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'most-selected',
    component: SelectedMostComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-process',
    component: CustomerProcessTimeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },

  {
    path: 'document-category',
    component: DocCategoryComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'data-privacy',
    component: DataPrivacyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-profile',
    component: ManageAdminProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation:'ignore'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
