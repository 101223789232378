<!-- <div class="container mt-100">
	<div class="row align-items-center flex-column">
		<h2 class="heading-img-center text-center">Change password</h2>
		<div class="card col-10 mt-4 mb-5">
			<div class="card-body">
				<form novalidate [formGroup]="ChangePasswordForm">
					<div class="row pt-4">
						<div class="col-md-12">
							<div class="form-group password-icon">
								<label>New password</label>
								<input type="password" class="form-control" formControlName="newPassword" autocomplete="off"
									placeholder="Enter Password" />
							</div>
							<ng-container *ngIf="formControls['newPassword'].errors && (ChangePasswordForm.get('newPassword').dirty || ChangePasswordForm.get('newPassword').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!formControls['newPassword'].errors.required">
									*Password is required.
								</small>
								<small class="text-danger error-msg" [hidden]="!formControls['newPassword'].errors.minlength">
									*Password must be minimum 6 character long.
								</small>
							</ng-container>
						</div>


						<div class="col-md-12">
							<div class="form-group password-icon">
								<label>Confirm password</label>
								<input type="password" class="form-control" formControlName="confirmNewPassword" autocomplete="off"
									placeholder="Confirm Password" />
							</div>
							<ng-container *ngIf="formControls['confirmNewPassword'].errors && (ChangePasswordForm.get('confirmNewPassword').dirty || ChangePasswordForm.get('confirmNewPassword').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!formControls['confirmNewPassword'].errors.required">
									*Password is required.
								</small>
								<small class="text-danger error-msg" [hidden]="!formControls['confirmNewPassword'].errors.minlength">
									*Password must be minimum 6 character long.
								</small>
								<small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
									Passwords do not match
								</small>
							</ng-container>
						</div>
						<div *ngIf="formSubmitSuccess" class="col-12 d-flex justify-content-center align-items-center">
							<p class="m-0 text-success">
								Password changed, please <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
							</p>
						</div>
						<div class="col-12 d-flex justify-content-center align-items-center mt-4 mb-4 flex-column">
							<button (click)="change($event)">
								Save Password
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div> -->
<div class="login-page">

	<div class="login-box">
		<div class="login-logo mb-4">
			<a  href="javascript:;"><img src="/assets/img/logo.svg" alt="logo" /></a>
		</div>
		<!-- /.login-logo -->
		<div class="card">
			<div _ngcontent-lqd-c127="" class="card-header text-center f-700">
				<!-- Change password  -->
				{{'change-password.heading1' | translate}}
			</div>
			<div class="card-body login-card-body">
				<p class="login-box-msg">
					<!-- You are only one step a way from your new password,recover your password now. -->
					{{'change-password.heading2' | translate}}
				</p>

				<form novalidate [formGroup]="ChangePasswordForm">
					<div class="form-group">
						<label>
							<!-- New Password -->
							{{'change-password.newPassword' | translate}}
						</label>
						<div class="input-group">

							<input type="password" class="form-control" formControlName="newPassword"
								autocomplete="off" placeholder="{{'change-password.passwordPlaceholder' | translate}}">
							<div class="input-group-append">
								<div class="input-group-text">
									<span class="fas fa-lock"></span>
								</div>
							</div>
						</div>
						<ng-container *ngIf="formControls['newPassword'].errors &&
							(ChangePasswordForm.get('newPassword').dirty ||
							ChangePasswordForm.get('newPassword').touched || isSubmitting)">
							<small class="text-danger error-msg"
								[hidden]="!formControls['newPassword'].errors.required">
								<!-- *Password is required. -->
								{{'change-password.passwordRequired' | translate}}
							</small>
							<small class="text-danger error-msg"
								[hidden]="!formControls['newPassword'].errors.minlength">
								<!-- *Password must be minimum 6 character long. -->
								{{'change-password.passwordCharLimit' | translate}}
							</small>
							<!-- <small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
								Passwords do not match
							</small> -->
						</ng-container>
					</div>

					<div class="form-group">
						<label >
							<!-- Retype Password -->
							{{'change-password.retypePassword' | translate}}
						</label>
						<div class="input-group">

							<input type="password" class="form-control"
								formControlName="confirmNewPassword" autocomplete="off" placeholder="{{'change-password.confirmPasswordPlaceholder' | translate}}">
							<div class="input-group-append">
								<div class="input-group-text">
									<span class="fas fa-lock"></span>
								</div>
							</div>
						</div>
						<ng-container
							*ngIf="formControls['confirmNewPassword'].errors &&
							(ChangePasswordForm.get('confirmNewPassword').dirty ||
							ChangePasswordForm.get('confirmNewPassword').touched || isSubmitting)">
							<small class="text-danger error-msg"
								[hidden]="!formControls['confirmNewPassword'].errors.required">
								{{'change-password.passwordRequired' | translate}}
							</small>
							<small class="text-danger error-msg"
								[hidden]="!formControls['confirmNewPassword'].errors.minlength">
								<!-- *Password must be minimum 6 character long. -->
								{{'change-password.passwordCharLimit' | translate}}
							</small>
							<small class="text-danger error-msg"
								[hidden]="ChangePasswordForm.errors?.passwordNotMatch">
								<!-- Passwords do not match -->
								{{'change-password.passwordNotMatch' | translate}}
							</small>
						</ng-container>
					</div>

					<div class="row">
						<div class="col-12">
							<button type="submit" class="btn btn-primary btn-block"
								(click)="change($event)">
								<!-- Change password -->
								{{'change-password.heading1' | translate}}
							</button>
						</div>
						<!-- /.col -->
					</div>
				</form>

				<!-- <p class="mt-3 mb-1">
			<a class="btn btn-block btn-outline-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
		  </p> -->
			</div>
			<!-- /.login-card-body -->
		</div>
	</div>

</div>
<!-- /.login-box -->
