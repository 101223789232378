import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  public addStaff(payload?: any): Observable<any> {
    const url: string = ApiRoutes.addStaff;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateStaff(payload?: any): Observable<any> {
    const url: string = ApiRoutes.updateStaff;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public viewStaff(payload?: any): Observable<any> {
    const url: string = ApiRoutes.viewStaff;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public listStaff(payload?: any): Observable<any> {
    const url: string = ApiRoutes.listStaff;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public deleteStaff(payload?: any): Observable<any> {
    const url: string = ApiRoutes.deleteStaff;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
