import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { DashboardService } from './dashboard.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private localStorage : LocalStorageService,
    private router: Router,
    private dashService:DashboardService,
    private toastr: ToastrService,) { }

    clientStats : any;
    projectStats : any;
    APP_ROUTES = APP_ROUTES;

  ngOnInit(): void {
    this.getStats();
  }

  getStats() {

    this.dashService.getStats().subscribe((data: any) => {
      if (data.status === 200) {
        console.log("RESPONSE", data);
        this.clientStats = data?.body.clientRec;
        this.projectStats = data?.body.projectRec;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });


  }

  goTo(page : any){
    if(page === 'clients'){
      this.router.navigate([APP_ROUTES.listClient]).then(() => { });
    }
    else if (page === 'projects'){
      this.router.navigate([APP_ROUTES.listProject]).then(() => { });
    }
  }

}
