<!-- <app-menubar></app-menubar>
<app-top-navbar></app-top-navbar> -->
<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper card-body">
  <section class="content-header p-0 mb-3">
    <div class="row mb-2 no-gutters">
                <div class="col-lg-6 col-12">
                    <h1>
                      <!-- Catalogue -->
                      {{'addCategory.heading1' | translate}}
                    </h1>
                </div>
                <!-- <div class="col-lg-6 col-12 mt-2 mt-lg-0">
                    <div class="d-lg-flex justify-content-end">
                        <div>
                            <a class="btn btn-primary " href="javascript:; " [routerLink]="['../' + APP_ROUTES.addProject]"><i
                                class="fa fa-plus "></i> Add Project</a>
                        </div>
                    </div>
                </div> -->
    </div>
<!-- Content Header (Page header)  -->
</section>
    <section class="content">
        <div class="card card-primary   box border-0 ">
             <div class="card-header">
                <div class="w-100  d-flex justify-content-between">
                    <span>
                      <!-- Catalogue Listing  -->
                      {{'addCategory.heading2' | translate}}
                    </span>
                    <!-- <span>Total templates <strong>224</strong></span> -->
                </div>

            </div>
                <!-- Default box -->
                <div class="card-body">
                      
                  <!--<input #mainEl type="text"> <button (click)=show()>Show</button>
<button (click)=addMainCategory(mainEl.value)>addMainCategory</button>-->

                  <div class="row">
                      <div class="col">
                          <div class="input-group col-7 input-group-md mb-3 p-0 mb-4" *ngIf="permissions.catalogue == 1">
                              <input type="text" #mainEl class="form-control" [placeholder]="'addCategory.mainCategory' | translate">
                              <span class="input-group-append">
          <button type="button" (click)=addMainCategory(mainEl.value) class="btn btn-primary btn-md"><i
              class="fa fa-plus"></i> 
              <!-- Add Main Category -->
              {{'addCategory.mainCategory' | translate}}</button>
        </span>
                          </div>
                          <tree-root id="tree2" [focused]="true" [nodes]="nodes2">
                              <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
                                  <div class="tree-node">
                                      <tree-node-expander [node]="node"></tree-node-expander>
                                      <!-- <input type="checkbox" [checked]="node.isActive" (change)="node.toggleActivated(true)" /> -->

                                      <div class="node-content-wrapper d-inline-flex align-items-center" [class.node-content-wrapper-active]="node.isActive" [class.node-content-wrapper-focused]="node.isFocused" (click)="node.toggleActivated(true)">
                                          <!-- <span [class]="node.data.className + 'Index'">{{ index }}</span>-->
                                          <span [class]="node.data.className" [class.title]="true" class="white-space-noswrap mr-2">{{ node.data.title }}</span>
                                          <!--<span [class]="node.data.className" [class.title]="true">----{{ node.data.id }}</span>-->

                                          <div class="input-group input-group-sm p-0 no-wrap d-none" #ref2>
                                              <input #inputEl2 type="text" id="itemName{{ node.data.id }}" maxlength="30" [placeholder]="'addCategory.editCategory' | translate">
                                              <span class="input-group-append">
                  <button type="button" (click)="callAll(node.data,inputEl2.value,'edit',ref2,inputEl2)"
                    class="btn btn-primary btn-lg"> 
                    <!-- Update -->
                    {{'addCategory.update' | translate}}
                  </button>
                </span>
                                          </div>


                                          <div class="input-group input-group-sm p-0 no-wrap d-none" #ref1>
                                              <input #inputEl1 type="text" id="itemName{{ node.data.id }}" maxlength="30" [placeholder]="'addCategory.addSubCategory' | translate">
                                              <!-- <small *ngIf="showMsg">Length cannot be greater than 30</small> -->
                                              <span class="input-group-append">
                  <button type="button" class="btn btn-primary btn-lg"
                    (click)="callAll(node.data,inputEl1.value,'add',ref1,inputEl1)"> 
                    <!-- Add -->
                    {{'addCategory.add' | translate}}
                  </button>
                </span>
                                          </div>

                                          <div class="action-buttons" >
                                              <button *ngIf="permissions.catalogue == 1"
                                              (click)="showNodeFn(ref2,ref1)" class="btn-edit bg-grey text-dark"><i
                    class="fas fa-pencil-alt"></i></button>
                                              <button *ngIf="permissions.catalogue == 1 && node.data.level != 6" (click)="showNodeFn(ref1,ref2)" class="btn-add bg-success"><i class="fa fa-plus"></i></button>
                                              <!-- <button (click)="modifyChildNode(node.data,inputEl1.value,'delete')" class="btn-delete bg-red"><i
                    class="fa fa-minus"></i></button> -->
                                              <button *ngIf="permissions.catalogue == 1"
                                              (click)="deletConfirmation(node.data,inputEl1.value,'delete')" class="btn-delete bg-red"><i class="fa fa-minus"></i></button>

                                              <button (click)="editFn(node.data)" class="btn-edit bg-grey text-dark"><i
                    class="fas fa-ellipsis-h"></i></button>
                                          </div>

                                      </div>


                                      <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                                  </div>
                              </ng-template>
                          </tree-root>



                          <!--
<tree-root id="tree2" [focused]="true" [nodes]="nodes2">
<ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
<div class="tree-node">
<tree-node-expander [node]="node"></tree-node-expander>
<input type="checkbox" [checked]="node.isActive" (change)="node.toggleActivated(true)" />

<div
  class="node-content-wrapper"
  [class.node-content-wrapper-active]="node.isActive"
  [class.node-content-wrapper-focused]="node.isFocused"
  (click)="node.toggleActivated(true)">
  <span [class]="node.data.className + 'Index'">{{ index }}</span>
  <span [class]="node.data.className" [class.title]="true">{{ node.data.title }}</span>
  <span [class]="node.data.className" [class.title]="true">----{{ node.data.id }}</span>
  <input #inputEl type="text" id="itemName{{ node.data.id }}">
  <button (click)="modifyChildNode(node.data,inputEl.value,'add')">Add child to id --- {{node.data.id}}</button>
  <button (click)="modifyChildNode(node.data,inputEl.value,'edit')">Edit id --- {{node.data.id}}</button>
  <button (click)="modifyChildNode(node.data,inputEl.value,'delete')">Delete id --- {{node.data.id}}</button>
</div>
<tree-node-children [node]="node" [templates]="templates"></tree-node-children>
</div>
</ng-template>
</tree-root>
-->
                      </div>

                      <!-- <div class="col">
    <div class="card shadow-box">
      
      <form role="form">
        
        <div class="card-body">
          <p><strong>Category:</strong>  Sanitacy > Rail > End Rails</p>
          <hr/>
          <div class="form-group w-100">
            <label for="exampleInputEmail1">Add  custom filed</label>
           
          <div class="input-group input-group-md mb-3 p-0 ">
            <input type="text" #mainEl class="form-control">
            <span class="input-group-append">
              <button type="button" class="btn btn-default">Add</button>
            </span>
          </div>
          </div>

          <ul class="prod-list">
            <li>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">Aluminum </label>
              </div>
              <select class="form-control mx-2">
                <option>Full high</option>
                <option>Half High</option>
              </select>

              <div class="thumb-image mx-2"><img src="/assets/img/img22.jpg"/></div>

            </li>

            <li>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck2">
                <label class="form-check-label" for="exampleCheck2">Stainless                    </label>
              </div>
              <select class="form-control mx-2">
                <option>Half High</option>
                <option>Full high</option>
              </select>

              <div class="thumb-image mx-2"><img src="/assets/img/img22.jpg"/></div>

            </li>

          </ul>

        
        </div>

        <div class="card-footer bg-white text-center">
         <button type="submit" class="btn btn-default mr-2">Cancel</button> <button type="submit" class="btn btn-primary">Submit</button>
        </div>
        <p class="text-danger ml-2">* Surcharge would be applicable </p>
      </form>
    </div>
    
  
  </div> -->

                  </div>

              </div>
                <!-- /.card -->

        </div>
    </section>
    <!-- /.content -->
</div>
<!-- /.content-wrapper -->