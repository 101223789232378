import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './../../../../../shared/services/shared.service';
import { EditFaqComponent } from './../edit-faq/edit-faq.component';
import { FaqService } from './../faq.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-list-faq',
  templateUrl: './list-faq.component.html',
  styleUrls: ['./list-faq.component.scss']
})
export class ListFaqComponent implements OnInit {

  constructor(private router: Router,
    private service: FaqService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sharedService : SharedService,
    private baseService : BaseService,
    private translationService : TranslateService) { }

  public APP_ROUTES = APP_ROUTES;
  popoverTitle = 'Delete';
  popoverMessage = 'Do you want to delete FAQ?';
  confirmText="Confirm";
  cancelText="Cancel";
  faqList: any;
  permissions : any;

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.getFaqList();

    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete";
        this.popoverMessage="Do you want to delete FAQ?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";
      }
      else{
        this.popoverTitle="Löschen";
        this.popoverMessage="Möchten Sie FAQ löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Stornieren";
      }
    });
  }

  getFaqList() {
    this.service.getFaqList()


    this.service.getFaqList().subscribe((data: any) => {
      if (data.status === 200) {
        console.log("RESPONSE", data);
        this.faqList = data.body.faqRec;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });


  }

  showDeleteLocationPopup(str: Boolean, obj: any) {
    console.log("obj", obj);
    if (str) {
      this.deleteNode(obj);
    }
    else {

    }
  }

  deleteNode(obj: any) {
    let payload = {
      id: obj
    };

    this.service.deleteFaq(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("RESPONSE", data);
        // this.toastr.success('FAQ deleted successfully.', '');
        this.toastr.success(this.translationService.instant('listFaq.faqDeletedMsgTsFile'));

        this.getFaqList();
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });

  }

  editNode(obj: any) {
    const modalRef = this.modalService.open(EditFaqComponent, { size: 'xl', windowClass: '' });
    modalRef.componentInstance.faqObj = obj;
    modalRef.result.then(() => {
      this.getFaqList();
    }, (reason) => {
      console.log(`Dismissed `);
    });;
  }

  redirectToAddFaq() {
    this.router.navigate([APP_ROUTES.addFaq]).then(() => {
    });
  }

  enableDisableFaq(id: any, event: any) {
    console.log("Id ", id, " event.target.checked ", event.currentTarget.checked)
    console.log(" !event.target.checked ", !event.currentTarget.checked)

    let payload = {
      id: id,
      status: event.currentTarget.checked == true ? 1 : 0
    };

    this.service.enableDisableFaq(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("RESPONSE", data);
        if (payload.status == 1) {
          // this.toastr.success('FAQ enabled successfully.', '');
          this.toastr.success(this.translationService.instant('listFaq.faqEnable'));

        }
        else {
          // this.toastr.success('FAQ disabled successfully.', '');
          this.toastr.success(this.translationService.instant('listFaq.faqDisable'));

        }

      }
      else {
        event.target.checked = !event.target.checked;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });

  }

  onKeydownEvent(event: KeyboardEvent, value: any, obj: any): void {
    value = value.replace('+', '');
    console.log("VALUE = ",value, " KEY ",event.key)
    if (event.key !== 'Backspace' &&  event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      if (value.trim().length == 0) {
        // this.toastr.error("Space not accepted", '');
        this.toastr.error(this.translationService.instant('listFaq.spaceNo'));

        return;
      }
      if (isNaN(value)) {
        // this.toastr.error("Only Digits accepted", '');
        this.toastr.error(this.translationService.instant('listFaq.digitYes'));

        return;
      }
      if (value.trim() < 0 || value.trim() > 1000) {
        // this.toastr.error("Please enter a value between 1 and 1000", '');
        this.toastr.error(this.translationService.instant('listFaq.range'));

        return;
      }
      // console.log("value ",value , " obj ",obj)

      let payload = {
        id: obj.id,
        order_by: value
      }
      this.service.orderFaq(payload).subscribe((data: any) => {
        if (data.status === 200) {
          console.log("RESPONSE", data);
          // this.toastr.success('FAQ order saved successfully.', '');
          this.toastr.success(this.translationService.instant('listFaq.faqOrderMsgTsFile'));
          this.getFaqList();

        }
      }, err => {
        this.toastr.error(err.error.message, '');
      });
    }
  }

}
