// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl: 'http://112.196.1.221:9065/',
  // imgBaseUrl: 'http://112.196.1.221:9065/uploads/',
  apiBaseUrl: 'https://backend.vilgertshofer.com/',
  imgBaseUrl: 'https://backend.vilgertshofer.com/uploads/',
  itemsPerPage: 10,
  // clientSideUrl: 'http://112.196.1.221:8054/#/',
  clientSideUrl: 'https://immoplaner.vilgertshofer.com/#/',
  // clientSideUrl : 'http://immoplaner.vilgertshofer.com/#/',

  // clientSideUrl : "http://localhost:4200/#/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
