import { environment } from '../../../environments/environment';

export class ApiRoutes {
  // api base url
  static apiV1: string = 'api/v1/';
  public static apiBaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV1;

  public static get login(): string {
    return this.apiBaseUrl + 'login';
  }
  public static get register(): string {
    return this.apiBaseUrl + 'user/register';
  }
  public static get profile(): string {
    return this.apiBaseUrl + 'profile';
  }
  public static get updateProfile(): string {
    return this.apiBaseUrl + 'update_profile';
  }
  public static get emailVerification(): string {
    return this.apiBaseUrl + 'player/activate/?token=';
  }
  public static get forgotPassword(): string {
    return this.apiBaseUrl + 'forgot_password';
  }
  public static get checkResetToken(): string {
    return this.apiBaseUrl + 'token_verify';
  }
  public static get changePassword(): string {
    return this.apiBaseUrl + 'reset_password';
  }
  public static get resetPassword(): string {
    return this.apiBaseUrl + 'update_password';
  }
  public static get setPassword(): string {
    return this.apiBaseUrl + 'set_password';
  }

  // Category
  public static get getCategories(): string {
    return this.apiBaseUrl + 'list_category';
  }
  public static get addCategory(): string {
    return this.apiBaseUrl + 'add_category';
  }
  public static get updateCategory(): string {
    return this.apiBaseUrl + 'update_category';
  }
  public static get deleteCategory(): string {
    return this.apiBaseUrl + 'delete_category';
  }
  public static get uploadImage(): string {
    return this.apiBaseUrl + 'uploadImage';
  }
  public static get uploadmutipleImage(): string {
    return this.apiBaseUrl + 'uploadMultiImage';
  }

  // Feature
  public static get listFeatures(): string {
    return this.apiBaseUrl + 'listcat_feature';
  }
  public static get getFeature(): string {
    return this.apiBaseUrl + 'editcat_feature';
  }
  public static get addFeature(): string {
    return this.apiBaseUrl + 'addcat_feature';
  }
  public static get editFeature(): string {
    return this.apiBaseUrl + 'updatecat_feature';
  }
  public static get deleteFeatures(): string {
    return this.apiBaseUrl + 'deletecat_feature';
  }

  // FAQ
  public static get getFaqList(): string {
    return this.apiBaseUrl + 'faqlist';
  }
  public static get addFaq(): string {
    return this.apiBaseUrl + 'add_faq';
  }
  public static get deleteFaq(): string {
    return this.apiBaseUrl + 'delete_faq';
  }
  public static get enableDisableFaq(): string {
    return this.apiBaseUrl + 'faq_status';
  }
  public static get updateFaq(): string {
    return this.apiBaseUrl + 'update_faq';
  }
  public static get orderFaq(): string {
    return this.apiBaseUrl + 'orderby_faq';
  }

  // Product
  public static get getMainCategoryList(): string {
    return this.apiBaseUrl + 'main_category';
  }
  public static get addProduct(): string {
    return this.apiBaseUrl + 'add_product';
  }
  public static get addFeaturesToProduct(): string {
    return this.apiBaseUrl + 'add_product_feature';
  }
  public static get updateFeaturesToProduct(): string {
    return this.apiBaseUrl + 'update_product_feature';
  }
  public static get getProductList(): string {
    return this.apiBaseUrl + 'list_product';
  }
  public static get getProductDetails(): string {
    return this.apiBaseUrl + 'edit_product';
  }
  public static get deleteProduct(): string {
    return this.apiBaseUrl + 'delete_product';
  }
  public static get copyProduct(): string {
    return this.apiBaseUrl + 'copy_product';
  }
  public static get updateProductFeature(): string {
    return this.apiBaseUrl + 'update_product_feature';
  }
  public static get allListcat_feature(): string {
    return this.apiBaseUrl + 'allListcat_feature';
  }
  public static get updateProduct(): string {
    return this.apiBaseUrl + 'update_product';
  }

  //doc-category
  public static get getDoc(): string {
    return this.apiBaseUrl + 'list_docCategory';
  }
  public static get deleteDoc(): string {
    return this.apiBaseUrl + 'delete_docCategory';
  }

  public static get addDocCategory(): string {
    return this.apiBaseUrl + 'add_docCategory';
  }

  public static get updateDocCategory(): string {
    return this.apiBaseUrl + 'update_docCategory';
  }

  // Template
  public static get getTemplate(): string {
    return this.apiBaseUrl + 'edit_template';
  }
  public static get addTemplate(): string {
    return this.apiBaseUrl + 'add_template';
  }
  public static get updateTemplate(): string {
    return this.apiBaseUrl + 'update_template';
  }
  public static get getTemplateList(): string {
    return this.apiBaseUrl + 'template_list';
  }
  public static get deleteTemplate(): string {
    return this.apiBaseUrl + 'template_status';
  }
  public static get copyTemplate(): string {
    return this.apiBaseUrl + 'copy_template';
  }
  public static get deleteTemplateType(): string {
    return this.apiBaseUrl + 'delete_template_feature';
  }
  public static get publishTemplate(): string {
    return this.apiBaseUrl + 'template_status';
  }
  public static get getTemplateTypeList(): string {
    return this.apiBaseUrl + 'template_type_list';
  }
  public static get addTemplateArea(): string {
    return this.apiBaseUrl + 'add_template_area';
  }
  public static get templateSearch(): string {
    return this.apiBaseUrl + 'template_cat_search';
  }

  // Project
  public static get getProjectList(): string {
    return this.apiBaseUrl + 'project_list';
  }
  public static get addProject(): string {
    return this.apiBaseUrl + 'project_add';
  }
  public static get getTemplatesList(): string {
    return this.apiBaseUrl + 'project_templatelist';
  }
  public static get getProjectDetails(): string {
    return this.apiBaseUrl + 'project_edit';
  }
  public static get updateProject(): string {
    return this.apiBaseUrl + 'project_update';
  }
  public static get deleteProject(): string {
    return this.apiBaseUrl + 'project_delete';
  }
  public static get deleteTemplateFromProject(): string {
    return this.apiBaseUrl + 'project_templatedelete';
  }
  public static get getProjectOverviewList(): string {
    return this.apiBaseUrl + 'project_overview';
  }

  // Client
  public static get addClient(): string {
    return this.apiBaseUrl + 'client_add';
  }
  public static get getClientList(): string {
    return this.apiBaseUrl + 'client_list';
  }
  public static get deleteClient(): string {
    return this.apiBaseUrl + 'client_delete';
  }
  public static get getClientDetails(): string {
    return this.apiBaseUrl + 'client_edit';
  }
  public static get getTempDocByID(): string {
    return this.apiBaseUrl + 'get_TemplateDocsbyid';
  }

  public static get DeleteDoc(): string {
    return this.apiBaseUrl + 'delete_TemplateDocsbyid';
  }
  public static get updateClient(): string {
    return this.apiBaseUrl + 'client_update';
  }
  public static get deleteClientProject(): string {
    return this.apiBaseUrl + 'client_project_delete';
  }
  public static get blockUnblockClient(): string {
    return this.apiBaseUrl + 'client_status';
  }
  public static get openClientSideDashboard(): string {
    return this.apiBaseUrl + 'admin_client_wishlist';
  }
  public static get getClientProjects(): string {
    return this.apiBaseUrl + 'adminClient_projects';
  }
  public static get getClientCartItems(): string {
    return this.apiBaseUrl + 'show_wishlistFromTemplateId';
  }
  public static get addNote(): string {
    return this.apiBaseUrl + 'updateProductNote';
  }
  public static get updateNote(): string {
    return this.apiBaseUrl + 'updateProductNote';
  }
  public static get deleteNote(): string {
    return this.apiBaseUrl + 'updateProductNote';
  }

  // Timeline
  public static get sendMessage(): string {
    return this.apiBaseUrl + 'add_message';
  }
  public static get getMessageHistory(): string {
    return this.apiBaseUrl + 'message_thread';
  }
  public static get uploadDoc(): string {
    return this.apiBaseUrl + 'uploadImage';
  }
  public static get getUploadedDocList(): string {
    return this.apiBaseUrl + 'message_thread';
  }
  public static get listDocCategory(): string {
    return this.apiBaseUrl + 'list_docCategory';
  }
  public static get addProjectDoc(): string {
    return this.apiBaseUrl + 'add_ProjectDoc';
  }
  public static get updateDocByID(): string {
    return this.apiBaseUrl + 'update_TemplateDocsbyid';
  }
  public static get updateReadMessageCount(): string {
    return this.apiBaseUrl + 'client_message_review';
  }
  public static get getAllClientProjects(): string {
    return this.apiBaseUrl + 'client_projects';
  }
  public static get templateWiseDocs(): string {
    return this.apiBaseUrl + 'get_TemplateWiseDocs';
  }
  // Dashboard
  public static get dashboardApi(): string {
    return this.apiBaseUrl + 'admin_dashboard';
  }

  // Report
  public static get mostSelected(): string {
    return this.apiBaseUrl + 'client_report';
  }
  public static get mostSelected2(): string {
    return this.apiBaseUrl + 'product_report';
  }

  // Notifications
  public static get getNotifications(): string {
    return this.apiBaseUrl + 'dashboard_notification';
  }

  // Staff
  public static get addStaff(): string {
    return this.apiBaseUrl + 'add_staff';
  }
  public static get listStaff(): string {
    return this.apiBaseUrl + 'staff_list';
  }
  public static get deleteStaff(): string {
    return this.apiBaseUrl + 'delete_staff';
  }
  public static get updateStaff(): string {
    return this.apiBaseUrl + 'update_staff';
  }
  public static get viewStaff(): string {
    return this.apiBaseUrl + 'edit_staff';
  }
  public static get getPrivacy(): string {
    return this.apiBaseUrl + 'get_page_content'
  }
  public static get addPrivacy(): string {
    return this.apiBaseUrl + 'add_page_content'
  }

  // Get admin 
  public static get getAdminDetail(): string {
    return this.apiBaseUrl + 'get_profile'
  }
  public static get updateAdminDetail(): string {
    return this.apiBaseUrl + 'update_admin_profile'
  }
  
}
