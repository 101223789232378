import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private ls : LocalStorageService) { }
  
  getPermissions() : any{
    return this.ls.getItem('currentUser').UserPermissions[0];
  }
}
