import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, formatNumber } from '@angular/common';
import { NgInputNumberI18nPipe } from '@danielzotti/ng-input-number-i18n';
import localeIt from '@angular/common/locales/it';
@Pipe({
  name: 'customFormat'
})
export class CustomFormatPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: any) {
    super(locale);
  }

  transform(value: string | number, format?: string): string {
    if (value === null || value === undefined) {
      return '';
    }

    if (typeof value === 'number') {
      value = value.toString();
     }

    let superValue = super.transform(value, format);
    superValue = superValue == null ? '0' : superValue;
    return value === '0' ? '0' : !format ? value : superValue;
  }
}
