import { TranslateService } from '@ngx-translate/core';
import { EditFeatureComponent } from './edit-feature/edit-feature.component';
import { FeatureService } from './feature.service';
import {
  Component,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CategoryService } from '../category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-feature',
  templateUrl: './add-feature.component.html',
  styleUrls: ['./add-feature.component.scss'],
})
export class AddFeatureComponent implements OnInit {
  controlTypeForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private service: CategoryService,
    private toastr: ToastrService,
    private featureService: FeatureService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private translationService: TranslateService,
    private baseService: BaseService
  ) {
    this.controlTypeForm = this.fb.group({
      labelName: [
        '',
        [
          Validators.required,
          Validators.maxLength(60),
          this.noWhitespaceValidator,
        ],
      ],
      controlType: ['', [Validators.required]],
      controlTypeValue: ['', [Validators.required, Validators.maxLength(30)]],
      dropdownarray: this.fb.array([this.newFields()]),
      surgeCharge: [false],
      image: [''],
    });
  }
  isUploadCLick: boolean = false;
  displayLocationDeletePopup = false;
  show = false;
  isUploaded: boolean = false;
  get getDropdownList(): any {
    return this.controlTypeForm.get('dropdownarray') as any;
  }

  newFields(): FormGroup {
    return this.fb.group({
      controlTypeVal: ['', [Validators.required, Validators.maxLength(30)]],
      controlTypeImg: [''],
      controlTypeImgUpload: [''],
    });
  }

  addNewFields() {
    console.log(this.getDropdownList);
    console.log(this.controlTypeForm.value);

    this.getDropdownList?.push(this.newFields());
  }

  removeField(i: number) {
    this.getDropdownList?.removeAt(i);
  }
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Feature?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  confirmClicked = false;
  cancelClicked = false;
  controlTypes: any = ['Dropdown', 'Input'];
  imageName: any;
  imageUrl: any;
  featurelist: any;
  parentNode: any;
  imgBaseUrl = environment.imgBaseUrl;
  private unsubscribe$ = new Subject();
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  booleanCondition = false;
  permissions: any;

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    console.log('Parent', this.parentNode);
    this.listFeatures();

    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Delete';
        this.popoverMessage = 'Delete Feature?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Löschen';
        this.popoverMessage = 'Extra löschen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Abbrechen';
      }
    });
  }

  passBack() {
    // this.activeModal.close(this.user);
  }

  listFeatures() {
    let payload = {
      catalogue_id: this.parentNode.id,
    };
    this.featureService.listFeatures(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response list', data);
          this.featurelist = data.body.catFeatureList;
          console.log(this.featurelist,'f list');
          
          // setTimeout(() => {
          //   this.toastr.success('Feature added successfully.', '');
          // }, 1000);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant(
              'addFeature.featureDeletedMsgTsFile'
            )
          );
        }
      }
    );
  }

  resetdropArrays() {
    this.controlTypeForm.reset();
    this.controlTypeForm.controls['controlType'].patchValue('');
    this.show = false;
    this.getDropdownList().clear();
  }

  addFeature(): void {
    this.booleanCondition = true;
    let payload = this.controlTypeForm.value;
    console.log(payload,"---payload-----");
    
    // payload.nodeId=this.parentNode.id;
    // payload.imageUrl = this.imageUrl;
    // payload.imageName = this.imageName;
    // this.activeModal.close(payload);
    if (
      this.controlTypeForm.valid &&
      (this.isUploadCLick ? this.isUploaded : true)
    ) {
      let imgArray: any[] = [];
      let controlArray: any[] = [];
      this.getDropdownList.controls?.forEach((ctrl) => {
        imgArray.push(ctrl?.controls['controlTypeImgUpload'].value);
        controlArray.push(ctrl?.controls['controlTypeVal'].value);
      });

      let payload2;
      if (this.controlTypeForm.controls.controlType?.value !== 'dropDown') {
        payload2 = {
          label_name: payload.labelName,
          control_type: payload.controlType,
          control_value:
            payload.controlTypeValue == null ? '' : payload.controlTypeValue,
          surcharge: payload.surgeCharge,
          catalogue_id: this.parentNode.id,
          image: payload.image == null ? '' : payload.image,
        };
      } else {
        payload2 = {
          label_name: payload.labelName,
          control_type: payload.controlType,
          control_value: controlArray?.toString() || '',
          control_valueimg: imgArray?.toString() || '',
          surcharge: payload.surgeCharge,
          catalogue_id: this.parentNode.id,
          image: payload.image == null ? '' : payload.image,
        };
      }

      if (payload2.surcharge) {
        payload2.surcharge = 1;
      } else {
        payload2.surcharge = 0;
      }

      // console.log('payload2', payload2);

      // add feature
      this.featureService.addFeature(payload2).subscribe(
        (data: any) => {
          this.booleanCondition = false;
          if (data.status === 200) {
            this.listFeatures();
            // console.log('response ', data);
            this.resetdropArrays();
            // console.log(this.myInputVariable.nativeElement.files);
            this.myInputVariable.nativeElement.value = '';
            // console.log(this.myInputVariable.nativeElement.files);
            // this.imageName=null;
            // this.imageUrl=null;
            setTimeout(() => {
              // this.toastr.success('Feature added successfully.', '');
              this.toastr.success(
                this.translationService.instant(
                  'addFeature.featureAddedMsgTsFile',
                  ''
                )
              );
            }, 1000);
          } else {
            this.toastr.error(data.body.message, '');
          }
        },
        (err) => {
          this.booleanCondition = false;
          console.log('error', err);
          if (err.error.message) {
            this.toastr.error(err.error.message, '');
          } else {
            // this.toastr.error('Something went wrong. Please try again later', '');
            this.toastr.error(
              this.translationService.instant('login.genricErrorTsFile')
            );
          }
        }
      );
    } else {
      this.controlTypeForm.markAllAsTouched();
    }
  }

  uploadImage(event: any, i?: any, drop?: any): void {
    console.log(drop);
    this.isUploadCLick = true;
    this.isUploaded = false;
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }
        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType,
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then((resp) => {
        this.handleImageAPI(resp, i);
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
  }

  handleImageAPI(result: any, i?: any): void {
    let input = new FormData();
    input.append('id', result.file);
    this.service
      .uploadImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          // this.imageUrl = resp1.body.imageUrl;
          // this.imageName = resp1.body.filename;
          if (i == null) {
            this.controlTypeForm.patchValue({
              image: resp1.body.filename,
            });
            this.isUploaded = true;
          } else {
            this.getDropdownList.controls[i].controls[
              'controlTypeImgUpload'
            ].patchValue(resp1.body.filename);
            this.isUploaded = true;
          }
        },
        (err) => {
          this.toastr.error('Something went wrong with image upload', '');
        }
      );
  }

  deleteNode(id: any): void {
    let payload = {
      id,
    };
    this.featureService.deleteFeature(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          // console.log('response ', data);
          this.listFeatures();
          setTimeout(() => {
            // this.toastr.success('Feature deleted successfully.', '');
            this.toastr.success(
              this.translationService.instant(
                'addFeature.featureDeletedMsgTsFile',
                ''
              )
            );
          }, 1000);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.error_code == 1) {
          this.toastr.error(
            this.translationService.instant('addFeature.featNotExistMsgTsFile')
          );
        } else if (err.error.error_code == 2) {
          this.toastr.error(
            this.translationService.instant('addFeature.delProdFirstMsgTsFile')
          );
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.success(
            this.translationService.instant('login.genricErrorTsFile', '')
          );
        }
      }
    );
  }

  editNode(nodeObj: any): void {

    const modalRef = this.modalService.open(EditFeatureComponent, {
      size: 'xl',
      windowClass: '',
    });
    modalRef.componentInstance.nodeObj = nodeObj;
    // modalRef.componentInstance.editPart = true;
    modalRef.componentInstance.disableField = false;
    modalRef.result.then(
      () => {
        this.listFeatures();
      },
      (reason) => {
        console.log(`Dismissed `);
      }
    );
  }


  viewFeature(nodeObj:any){
    
    const modalRef = this.modalService.open(EditFeatureComponent, {
      size: 'xl',
      windowClass: '',
    });
    modalRef.componentInstance.nodeObj = nodeObj;
    modalRef.componentInstance.disableField = true;
    modalRef.result.then(
      () => {
        this.listFeatures();
      },
      (reason) => {
        console.log(`Dismissed `);
      }
    );
  }

  closeModal(): void {
    this.activeModal.close();
  }
  showVal(event: any): void {
    let controlType = this.controlTypeForm.controls.controlType.value;
    if (controlType === 'dropDown') {
      this.show = true;
      this.controlTypeForm.controls['controlTypeValue'].clearValidators();
      this.controlTypeForm.controls[
        'controlTypeValue'
      ].updateValueAndValidity();
      this.getDropdownList.controls?.forEach((element) => {
        // element?.controls['controlTypeImgUpload'].setValidators([
        //   Validators.required,
        // ]);
        // element?.controls['controlTypeImgUpload'].updateValueAndValidity();
        element?.controls['controlTypeVal'].setValidators([
          Validators.required,
          Validators.maxLength(30),
        ]);
        element?.controls['controlTypeVal'].updateValueAndValidity();
      });
    } else {
      this.show = false;
      // console.log(this.getDropdownList);
      this.controlTypeForm.controls['controlTypeValue'].setValidators([
        Validators.required,
        Validators.maxLength(30),
      ]);
      this.controlTypeForm.controls[
        'controlTypeValue'
      ].updateValueAndValidity();
      this.getDropdownList.controls?.forEach((element) => {
        // element?.controls['controlTypeImgUpload'].clearValidators();
        // element?.controls['controlTypeImgUpload'].updateValueAndValidity();
        element?.controls['controlTypeVal'].clearValidators();
        element?.controls['controlTypeVal'].updateValueAndValidity();
      });
    }
  }

  showDeleteLocationPopup(str: Boolean, obj: any) {
    // console.log('obj', obj);
    if (str) {
      this.deleteNode(obj);
    } else {
    }
  }

  getPureString(obj: any) {
    if (obj) {
      // console.log("Obj",obj)
      let arr = obj.split(',');
      let arr2 = [];
      for (var val of arr) {
        if (val === '') {
          // console.log("Var is empty")
        } else {
          arr2.push(val);
        }
      }
      // console.log("arr2",arr2)
      return arr2;
    }
  }

  getPureStringArr(obj: any) {
    let arr2 = [];
    if (obj.control_value) {
      // console.log("------------------Obj-------------",obj)
      let arr = obj?.control_value?.split(',');
      let arr1 =
        obj?.control_valueimg !== null ? obj?.control_valueimg?.split(',') : [];
      if (obj.control_type === 'dropDown') {
        arr.forEach((element, i) => {
          arr2.push({
            val: element ? element : '',
            img: arr1?.length ? (arr1[i] ? this.imgBaseUrl + arr1[i] : '') : '',
          });
        });
      
      }
// console.log(arr2,"arrr11111------------");

      if (obj.control_type === 'input') {
        // console.log(obj,"after input object");
        
        arr2.push({
          val: obj.control_value ? obj.control_value : '',
          img: obj.image ? (this.imgBaseUrl+obj.image ): '',
        });
        // console.log(obj.image,"arr2-----------------");
        
      }
    }
    return arr2;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  add() {
    let row = document.createElement('div');
    row.className = 'row mt-3';
    row.innerHTML = `<div class="col-md-4">
          <label>Control Value</label>
          <input type="text" placeholder="Control Value" class="form-control">
      </div>
      <div class="col-md-4">
          <label>Upload Image</label>
              <input type="file" #myInput class="form-control" (change)="uploadImage($event)" />
      </div> <div class="col-md-4">
      <label class="d-block">&nbsp;</label>
      <button type="button" class="removeitem" (click)="removeCRV()"><img src="assets/img/cros.png"></button>
  </div>`;
    document.querySelector('.addon-feature-lsiting').appendChild(row);
  }
}
