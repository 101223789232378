import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from './../../../add-category/category.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Inject,
} from '@angular/core';
import { TemplateService } from '../../template.service';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormArray,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/shared/services/base.service';
import { DOCUMENT } from '@angular/common';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-add-template-types',
  templateUrl: './add-template-types.component.html',
  styleUrls: ['./add-template-types.component.scss'],
})
export class AddTemplateTypesComponent implements OnInit {
  pageNo = 1;
  pageLimit = 2;
  totalRecord: any;
  templateId: any;
  operation: any;
  showNext = false;
  showPublish = false;
  savedTemplateArea = false;
  list: any;
  min_limit: number = 0;
  progressBarValue = '0%';
  form = this.fb.group({
    templateTypeForm: new FormArray([]),
  });
  oppoSuits: any = ['SQM', 'SQF'];
  nodes2 = [];
  searchResult: any;
  @ViewChild('ref123')
  myInputVariable: ElementRef;
  TemplateTypeAreasArr: any;
  popoverTitle = 'Publish';
  popoverMessage = 'Do you want to Publish this Template?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  allNodeCategories:any[] =[];
  catMapList:any[] =[];
  templateTypeFormArray = this.form.get('templateTypeForm') as FormArray;

  constructor(
    private tempService: TemplateService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private service: CategoryService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private baseService: BaseService,
    private translationService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  @HostListener('click', ['$event']) onClick($event) {
    if (!$event?.target?.className?.includes('site')) {
      this.document?.querySelectorAll('#ref1')?.forEach((c) => {
        c?.classList?.add('d-none');
      });
      this.document?.querySelectorAll('#ref123')?.forEach((c) => {
        c?.classList?.add('d-none');
      });
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((params) => {
      this.templateId = params['tId'];
      this.operation = params['op'];
    });
    let page = localStorage.curTempStep && JSON.parse(localStorage.getItem('curTempStep'))
    if(page){
      this.pageNo = page;
      setTimeout(()=>{
        localStorage.removeItem('curTempStep')
      },1000)
    }
    this.getTypeDetails(this.pageNo);

    this.getCategories();

    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Publish';
        this.popoverMessage = 'Do you want to Publish this Template?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Veröffentlichen';
        this.popoverMessage = 'Möchten Sie diese Vorlage veröffentlichen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Abbrechen';
      }
    });
    
  }

  loadPage(str: any) {
    this.spinner.show();
    this.TemplateTypeAreasArr = [];
    this.showNext = false;
    this.savedTemplateArea = false;
    if (str === 'next') {
      this.nextPage();
    }
    if (str === 'prev') {
      this.prevPage();
      this.getTypeDetails(this.pageNo);
    }
   
  }

  processNestedArrays(data: any[]) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        if(item?.children?.length > 0){
          item['isExpand'] = false;
        }
        this.allNodeCategories.push(item);
        this.processNestedArrays(item?.children);
      }
    }
    return this.allNodeCategories
  }
  getCategories(): void {
    this.service.getCategories().subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.nodes2 = data.body.mainCat;
          this.nodes2 = [...this.nodes2];
          this.nodes2.forEach(v => {v.isParent = true;});
          let catsList:any = this.processNestedArrays(this.nodes2);
          this.catMapList = catsList;
          console.log('Final allNodeCategories -->', this.allNodeCategories);
          console.log('Final catMapList -->', this.catMapList);
          console.log('Final data -->', this.nodes2);
          // setTimeout(() => {
          //   this.toastr.success('Category updated successfully.', '');
          // }, 1000);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  getTypeDetails(pageNo: any) {
    let payload = {
      template_id: parseInt(this.templateId, 10),
      page_start: pageNo,
      page_length: this.pageLimit,
    };
    this.tempService.getTemplateTypeList(payload).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status === 200) {
          console.log('responseWWWWWWWWWW ', data);
          this.TemplateTypeAreasArr =
            data.body.templatesTypeRec[0].TemplateTypeAreas;
          this.totalRecord = data.body.totalRecord;
          this.list = data.body.templatesTypeRec;

          let val = data.body.totalInsertTemplateArea;
          val = (val / this.totalRecord) * 100;
          this.progressBarValue = val.toFixed(2) + '%';
          console.log('this.progressBarValue', this.progressBarValue);
          this.prepareForm();
          // setTimeout(() => {
          //   this.toastr.success('Templates fetched successfully', '');
          // }, 1000);
          let result = this.totalRecord - (this.pageNo - 1) * 2;
    let isLastPage = false;
    if (result <= 2) {
      isLastPage = true;
    } else {
      isLastPage = false;
    }

    if (isLastPage && this.form.valid) {
      this.savedTemplateArea = true;
      this.showPublish = true;
    }else{
      this.savedTemplateArea = false;
      this.showPublish = false;
    }
        
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        this.spinner.hide();
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  prepareForm() {
    this.resetWholeForm();

    console.log('LLLLLLLLL', this.form.value);
    for (var obj of this.list) {
      let mainForm = this.fb.group({
        id: [obj.id ? obj.id : ''],
        template_id: [obj.template_id ? obj.template_id : ''],
        name: [obj.name, [Validators.required]],
        description: [
          obj.description,
          [Validators.required, Validators.maxLength(300)],
        ],
        TemplateTypeAreas: this.fb.group({
          id: [''],
          template_type_id: [''],
          template_id: [''],
          template_area: [
            '0',
            [
              Validators.required,
              Validators.maxLength(30),
              Validators.pattern(/^[0-9,.*\s]*$/),
              this.noWhitespaceValidator,
            ],
          ],
          template_area_wall: [
            '0',
            [
              Validators.required,
              Validators.maxLength(30),
              Validators.pattern(/^[0-9,.*\s]*$/),
              this.noWhitespaceValidator,
            ],
          ],
          template_area_type: ['SQM', [Validators.required]],
          running_meter_area: [
            '0',
            [
              Validators.required,
              Validators.maxLength(30),
              Validators.pattern(/^[0-9,.*\s]*$/),
              this.noWhitespaceValidator,
            ],
          ],
          category_ids: [[], [Validators.required]],
        }),
      });

      // let TemplateTypeAreas = mainForm.get("TemplateTypeAreas") as FormArray;

      let info = obj.TemplateTypeAreas[0];
      console.log(info,'info cat');
    
      if (info) {
        if(info.catRec.length>0){
          info.catRec.forEach(item=>{
            if(item.qty==0){
              item.isParent=false
            }else{
              item.isParent=true

            }
          })
        }
        
        // let innerForm = this.fb.group({
        //   id : [info.id ? info.id : ''],
        //   template_type_id : [info.template_type_id ? info.template_type_id : ''],
        //   template_id : [info.template_id ? info.template_id : ''],
        //   template_area : [info.template_area , [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,*\s]*$/)]],
        //   template_area_type : [info.template_area_type , [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z0-9_,*\s]*$/)]],
        // });

        // mainForm.patchValue({
        //   TemplateTypeAreas : innerForm
        // });
        info?.catRec?.length > 0 &&
          info?.catRec?.forEach((cat) => {
            cat.qty = +cat?.qty ? +cat?.qty : this.min_limit;
            
          });
        // console.log(info?.catRec, 'sgsdfgf');

        mainForm.patchValue({
          TemplateTypeAreas: {
            id: info.id ? info.id : '',
            template_type_id: info.template_type_id
              ? info.template_type_id
              : '',
            template_id: info.template_id ? info.template_id : '',
            template_area: info.template_area,
            template_area_type: info.template_area_type,
            running_meter_area: info.running_meter_area,
            category_ids: info.catRec,
            template_area_wall: info.template_area_wall,
          },
        });
        // TemplateTypeAreas.push(innerForm);
      }

      this.templateTypeFormArray.push(mainForm);
    }

    // console.log('this.form.value', JSON.stringify(this.form.value));
  }

  nextPage() {
    if(this.form.valid){
      this.save(false)
    }else{
    this.pageNo = this.pageNo + 1;
    this.getTypeDetails(this.pageNo);
    }
  }

  prevPage() {
    this.pageNo = this.pageNo - 1;
  }

  templateType(): FormArray {
    return this.form.get('templateTypeForm') as FormArray;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
  allcats:any = [];
  allCatsForDelete:any=[];
  allCatsForUpdate:any=[];
  
  processNestedArrays11(data: any, cat?:any) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        // show subcategory
        this.allcats?.push(item)
        this.processNestedArrays11(item?.children,item);
      }
    }
  }

  selectedData:any;

  FieldsChange(values: any, obj: any, id: any) {
    this.allcats = [];
    const allCat=this.nodes2
    
    function findObjectByIdWithFamily(allCat, id) {
      for (const item of allCat) {
        if (item.id === id) {
          return item; // Found the object with the specified id
        } else if (item.children && item.children.length > 0) {
          const childResult = findObjectByIdWithFamily(item.children, id);
          if (childResult) {
            return {
              id: item.id,
              title: item.title,
              allproducts: item.allproducts,
              children: [childResult],
            };
          }
        }
      }
      return null; // The id was not found in the data
    }
    const result = findObjectByIdWithFamily(allCat, obj?.id);
  
    this.allcats.push(result);
    console.log(this.allcats,'object');
    
    if(this.allcats.length>0){
     
      for(let i=0;i<this.allcats.length ;i++){
        if(i==0){
          this.allcats[i].isParent=true
        }else{
          this.allcats[i].isParent=false
        }
      }
    }
    this.processNestedArrays11(result?.children)
    // console.log(this.allcats, 'obkjiii');
    this.selectedData=result
    console.log(this.selectedData,'select============');
    
    // if (!obj?.allproducts) {
    //   this.toastr.error(`No products found in this category`, '');
    //   return;
    // }
    // if (obj?.allproducts?.length <= 0) {
    //   this.toastr.error(`No products found in this category`, '');
    //   return;
    // }
    // console.log("values.currentTarget.checked", values.currentTarget.checked, "obj", obj, "id ", id);
    // console.log('obj', obj, 'id ', id);
    // console.log("FORM ARRAY", this.templateTypeFormArray);
    let ids = this.allcats?.map(x=>x?.id)
    let val: any;
    let grp: any;
    for (val of this.templateTypeFormArray.controls) {
      if (val.value.id == id) {
        grp = val;
      }
      }
    

    let catArray = grp.get('TemplateTypeAreas').get('category_ids').value;
   
    // console.log(catArray, 'arrau');

    // if (values.currentTarget.checked == true) {
    // var index = catArray.findIndex((el) => el.id == obj.id);

    let catExists = false;
    // for (var value of catArray) {
    //   if (ids?.includes(value.id)) {
    //     catExists = true;
    //   }
    // }
    // console.log('catExists', catExists);


    // if(!('allproducts' in obj) || obj?.allproducts?.length <= 0){
    // this.min_limit = 0;
    // }

    let existId = this.allcats.map(x=> x?.id);
    let IfExistCatArray = catArray.filter(item => existId.includes(item?.id));

    if (IfExistCatArray.length) {
       this.toastr.error(`Category is already selected`, '');
    }

    if (catArray.length >= 0) {
      this.allcats.forEach((item)=>{
          let sampleObj = {
            id: item.id,
            title: item.title ? item.title : item.cat_name,
            qty: this.min_limit,
            isParent: item.isParent ? item.isParent : false ,
            parent_id: item.parent_id
          };
          let existId = catArray.map(x=> x?.id);
          if(!existId.includes(item?.id)){
            catArray.push(sampleObj);
          }
      })
      catArray = [...new Set(catArray)]
    }
  

    // if (!catExists) {
    //    this.toastr.error(`Category is already selected`, '');
    // }

    // if (!catExists) {
    //   if (catArray.length >= 0) {
    //     this.allcats.forEach((item)=>{
    //       let sampleObj = {
    //         id: item.id,
    //         title: item.title ? item.title : item.cat_name,
    //         qty: this.min_limit,
    //       };
    //       catArray.push(sampleObj);
    //     })
        
    //   }
    // } else {
    //   this.toastr.error(`Category is already selected`, '');
    // }

    // }
    // else if (values.currentTarget.checked == false) {
    // var index = catArray.findIndex((el) => el.id == obj.id);
    // catArray.splice(index, 1); // first element removed
    // console.log("INSIDE UNCHECK CATARRAY", catArray);
    // }
    console.log('catArray', catArray);
    catArray.forEach(ele=>{
      if(ele.isParent==true && ele.qty==0){
        ele.qty=1
      }else{
        if(ele.isParent==false){
          ele.qty=0
        }
      }
    })
    
    grp.patchValue({ TemplateTypeAreas: { category_ids: catArray } });

    console.log(grp.value, 'VALLLLLL');

    // const featuresArray = grp.get('TemplateTypeAreas').get('category_ids') as FormArray;
    // featuresArray.push(obj.id);
    // console.log("featuresArray",featuresArray);

    // const abc =this.templateTypeFormArray.value.filter((val)=>val.id == id);
    // console.log(abc,">>>>>>>>>>>>>>>>>>>>>>>");
    // let categoryIdArray=[];
    // categoryIdArray.push(obj.id);
    // console.log(categoryIdArray);

    // get the obj with this id from form
    // then push into array of cats
  }

  processNestedArrays11Del(data: any, id?:any) {
    for (const item of data) {
      // if(item?.id === id){
      //   this.allCatsForDelete?.push(item)
      // }
      // this.processNestedArrays11Del(item?.children,item?.id);
      if (Array.isArray(item?.children)) {
        if(item?.id === id){
          this.allCatsForDelete?.push(item)
        }
        this.processNestedArrays11Del(item?.children,id);
      }
    }
  }


  processNestedArrays13(data: any) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        this.allCatsForUpdate?.push(item)
        this.processNestedArrays13(item?.children);
      }
    }
  }

  deleteCategoryFromList(obj: any, id: any) {
    // console.clear()
    // console.log('obj', obj, 'id', id ,'line 515');
    // console.log(this.nodes2, 'nodes 3');
    
    // this.allCatsForDelete = [];
    // this.processNestedArrays11Del([...this.nodes2], obj?.id);
    // this.allCatsForUpdate = [];
    // this.processNestedArrays13(this.allCatsForDelete);
    // console.log(this.allCatsForDelete, 'fordeleter');

    // console.log(this.allCatsForUpdate, 'for update ');
    // console.log(this.allCatsForUpdate.map(x=>x?.id), 'for update idsssl');
    
    let val: any;
    let grp: any;
    for (val of this.templateTypeFormArray.controls) {
      if (val.value.id == id) {
        grp = val;
      }
    }

    let catArray = grp.get('TemplateTypeAreas').get('category_ids').value;
    console.log('data======================================',catArray);
    

    var index = catArray.findIndex((el) => el.id == obj.id);
    console.log(catArray,'568');
    console.log(index,'index 570---------------');
    
    catArray.splice(index, 1); // first element removed
    // for (let index = catArray.length - 1; index >= 0; index--) {
    //   const del = catArray[index];
    //   if (+del.parent_id === +obj.id) {
    //     console.log(del, 'child data', index);
    //     catArray.splice(index, 1);
    //     for(let j=0;j<catArray.length;j++){
    //       if(del.id==catArray[j].parent_id){
    //         const del2= catArray[j];
    //         console.log(del2,'delete 2 object');
    //         catArray.splice(j,1);
    //         for(let k=0;k<catArray.length;k++){
    //           if(del2.id==catArray[k].parent_id){
    //             const del3=catArray[k];
    //             catArray.splice(k,1);
    //             for(let m=0;m<catArray.length;m++){
    //               if(del3.id==catArray[m].parent_id){
    //                 const del4=catArray[m];
    //                 catArray.splice(m,1)
    //                 for(let n=0;n<catArray.length;n++){
    //                   if(del4.id==catArray[n].parent_id){
    //                     catArray.splice(n,1);
    //                   }
    //                 }
    //               }
    //             }

    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    function removeChildren(parentId) {
      for (let index = catArray.length - 1; index >= 0; index--) {
        const del = catArray[index];
        if (+del.parent_id === parentId) {
          console.log(del, 'child data', index);
          catArray.splice(index, 1);
          removeChildren(del.id); // Recursively remove children of del
        }
      }
    }
    for (let index = catArray.length - 1; index >= 0; index--) {
      const del = catArray[index];
      if (+del.parent_id === +obj.id) {
        console.log(del, 'child data', index);
        catArray.splice(index, 1);
        removeChildren(del.id);
      }
    }
    console.log(catArray,'582 line');
    // catArray.forEach((ele)=>{
    //   if(obj.id==ele.parent_id){
    //     catArray.splice(index,1)
    //   }
    // })
    // let childIndex=[]
    // for(let i=0; i<catArray.length;i++){
    //   if(obj.isParent==true){
    //     if(obj.id==catArray[i].parent_id){
    //       childIndex.push(i)
    //     }
    //   }
    // }
    // console.log(childIndex,'childIndex values');
    // for(let i=0;i<childIndex.length;i++){
    //   catArray.splice(childIndex[i])
    // }

    // catArray.forEach(ele => {
    //   var index2 = catArray.findIndex((el) => el.parent_id == obj.id);
    //   console.log(index2,'index 2 ---------');
    //   catArray.splice(index2, 1);
      
    // })
    // let delIds = this.allCatsForUpdate.map(x=>x?.id)
    // catArray = catArray.filter(item => !delIds.includes(item?.id));
    console.log('INSIDE DELETE CATARRAY', catArray);
    grp.patchValue({ TemplateTypeAreas: { category_ids: catArray } });
    console.log(grp.value, 'VALLLLLL');
  }
  

  getRefresh() {
    let url = this.router.url;
    console.log(url);
    // return
    url = url.split('?')[0]
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url],{
        queryParams:{
          tId:this.templateId,
          op:this.operation
        }
      }).then(()=>{
      }));
  }

  save(fromNextStep:boolean) {
    console.log('PAYLOAD ', JSON.stringify(this.form.value));
    this.tempService.addTemplateArea(this.form.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
         
          console.log('responseWWWWWWWWWW ', data);

          if (this.operation !== 'edit') {
            this.totalRecord = data.body.totalRecord;
            let val = data.body.totalInsertTemplateArea;
            val = (val / this.totalRecord) * 100;
            this.progressBarValue = val + '%';
            console.log('this.progressBarValue', this.progressBarValue);
          }
          
          this.savedTemplateArea = true;
          let publishBool = this.showPublishButton();
          console.log('publishBool', publishBool);
          if (this.savedTemplateArea === true && publishBool) {
            this.showPublish = true;
            console.log('showPublish', this.showPublish);
          }

              if(fromNextStep){
      localStorage.setItem('curTempStep',JSON.stringify(this.pageNo))
      this.getRefresh();
    }else{
     
      this.toastr.success(
        this.translationService
          .instant('addTemplate.stepSavedSuccessMessage')
          .replace('{}', this.pageNo + 1),
        ''
      );
      let bool = this.showNextButton();
      if (bool) {
      this.showNext = true;
      }
      this.pageNo = this.pageNo + 1;
      this.getTypeDetails(this.pageNo);
    }
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
    
  }

  showPublishButton(): Boolean {
    let result = this.totalRecord - (this.pageNo - 1) * 2;
    if (result <= 2) {
      return true;
    }
    return false;
  }

  showNextButton(): Boolean {
    let result = this.totalRecord - (this.pageNo - 1) * 2;
    console.log('page no', this.pageNo, 'result', result);
    // if (this.pageNo == this.totalRecord/2) {
    //   return false;
    // }

    if (result > 2) {
      return true;
    }
    return false;
  }

  random(obj: any) {
    console.log('RANDOM', obj);
  }

  showNodeFn(element: any) {
    const previousElement = element.classList;
    console.log('previousElement', previousElement);
    if (previousElement.contains('d-none')) {
      previousElement.remove('d-none');
    } else {
      previousElement.add('d-none');
    }
  }

  resetWholeForm() {
    this.form.reset();
    while (this.templateTypeFormArray.length !== 0) {
      this.templateTypeFormArray.removeAt(0);
    }
  }

  publish(str: Boolean) {
    if (str) {
      let payload = {
        id: this.templateId,
        status: 'publish',
      };

      this.tempService.deleteTemplate(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            console.log('response ', data);
            setTimeout(() => {
              // this.toastr.success('Template published successfully', '');
              this.toastr.success(
                this.translationService.instant(
                  'addTemplateTypes.templatePublishedMsgTsFile'
                )
              );
            }, 1000);
            this.router.navigate([APP_ROUTES.listTemplate]).then(() => {});
          } else {
            this.toastr.error(data.body.message, '');
          }
        },
        (err) => {
          console.log('error', err);
          if (err.error.message) {
            this.toastr.error(err.error.message, '');
          } else {
            // this.toastr.error('Something went wrong. Please try again later', '');
            this.toastr.error(
              this.translationService.instant('login.genricErrorTsFile')
            );
          }
        }
      );
    } else {
    }
  }

  clearSearch(el: any) {
    console.log('Inside clear SEARCH');
    if (el) {
      el.value = '';
    }
    this.myInputVariable.nativeElement.value = '';
    this.searchResult = null;

    const previousElement = el.classList;
    console.log('previousElement', previousElement);
    if (previousElement.contains('d-none')) {
      // previousElement.remove('d-none');
    } else {
      previousElement.add('d-none');
    }
  }

  onKeyUpEvent(event: any, id: any, el: any, fromButtonClick?: Boolean) {
    // console.log("event.target.value", event.target.value, "id", id)
    this.showNodeFn(el);

    let payload;
    if (fromButtonClick) {
      payload = {
        search: event,
      };
    } else {
      payload = {
        search: event.target.value,
      };
    }

    this.tempService.templateSearch(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RE ', data);
          if (data.body.catalogueList.length > 0) {
            this.searchResult = data.body.catalogueList;
          } else {
            // this.toastr.error("No category found", '');
            this.toastr.error(
              this.translationService.instant(
                'addTemplateTypes.noCategoryFound'
              )
            );
          }
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  callAll(val: any, catObj: any, id: any, el: any, el2: any, el3: any) {
    console.log(catObj);

    this.FieldsChange(val, catObj, id);
    this.clearSearch(el);
    this.clearSearch(el2);
    el3.value = '';
  }

  closeDropdownAndOpenSearch(el1: any, el2: any) {
    this.clearSearch(el1);
    this.clearSearch(el2);
  }

  closeSearchandOpenDropdown(el1: any, el2: any, el3: any,index?:any) {
    this.document?.querySelectorAll('#ref1')?.forEach((c,i) => {
      if(index !== i ){
        c?.classList?.add('d-none');
        this.processNestedArrays(this.nodes2)
      }
    });
  
    this.clearSearch(el2);
    this.showNodeFn(el1);
    el3.value = '';
  }

  showNextStepButton(): Boolean {
    if (!this.showNext) {
      if (this.TemplateTypeAreasArr && this.TemplateTypeAreasArr.length > 0) {
        // If control comes here it means the result is already saved
        // Now we can check if its the last page then we show publish button
        if (this.showPublishButton()) {
          this.showPublish = true;
          return false;
        }
        return true;
      }
    }
    return false;
  }

  showNextStepButton2():boolean{
    let result = this.totalRecord - (this.pageNo - 1) * 2;
    let isLastPage = false;
    if (result <= 2) {
      isLastPage = true;
    } else {
      isLastPage = false;
    }
    return !isLastPage && this.templateType()?.controls?.length > 1
  }

  goToStep1() {
    if (this.operation == 'edit') {
      this.router
        .navigate([APP_ROUTES.addTemplate], {
          queryParams: { tId: this.templateId, op: 'edit' },
        })
        .then(() => {});
    } else if (this.operation == 'save') {
      this.router
        .navigate([APP_ROUTES.addTemplate], {
          queryParams: { tId: this.templateId, op: 'save' },
        })
        .then(() => {});
    }
  }

  checkForPublishButton(): Boolean {
    // !() &&  && (totalRecord - ((pageNo - 1) * 2))
    // check if last page check if saved
    let result = this.totalRecord - (this.pageNo - 1) * 2;
    let isLastPage = false;
    if (result <= 2) {
      isLastPage = true;
    } else {
      isLastPage = false;
    }

    if (isLastPage && this.showPublish && this.form.valid) {
      return true;
    }

    // if (isLastPage && this.form.valid) {
    //   return true;
    // }
    return false;
  }

  searchOnButtonClickAndCloseSearchandOpenDropdown(
    event: any,
    id: any,
    el: any,
    fromButtonClick: Boolean,
    el1: any,
    el2: any
  ) {
    if (event) {
      this.onKeyUpEvent(event, id, el, fromButtonClick);
    }
    // this.closeDropdownAndOpenSearch(el1,el2);
  }

  hello(str: any) {
    console.log('HERE', str);
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      (
        (control &&
          control.value !== null &&
          control.value !== undefined &&
          (control.value || Number(control.value) === 0) &&
          control.value.toString()) ||
        ''
      ).trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  addQunatity(category: any, object:any) {
    console.log(category,'category add quantity');
    console.log(object,'object addQuantity');
    console.log(this.list);
    // let item = this.catMapList.find(x=> +x?.id === +category?.id)
    // if(!('allproducts' in item) || item?.allproducts?.length <= 0){
    //   alert('cant add qty...product not found in this category ')
    //   return 
    // }
    category.qty = category.qty + 1;
  }

  minusQunatity(category: any) {
    if (category.qty >1) category.qty = category.qty - 1;
  }

  onDrop(event: CdkDragDrop<string[]>, itemArray) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  toggleExpandList(event:any, cat1:any, id:any, index:any){
    console.log(event, 'event');
    console.log(cat1, 'cat1');
    console.log(id, 'id');
    console.log(index, 'index');
    console.log('togle expand list');
    cat1.isExpand = !cat1?.isExpand
    
  }
}
