<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
  <!-- Content Header (Page header) -->

  <section class="content-header p-0 mb-3">
    <div class="row mb-2 no-gutters">
      <div class="col-lg-6 col-12">
        <h1>
          <!-- Product -->
          {{ "listProduct.prod" | translate }}
        </h1>
      </div>

      <div
        class="col-lg-6 col-12 mt-2 mt-lg-0"
        *ngIf="permissions.product == 1"
      >
        <div class="d-lg-flex justify-content-end">
          <div class="mr-3">
            <div class="input-group">
              <input
                #searchVA
                type="text "
                class="form-control"
                placeholder="{{ 'listProduct.searchProduct' | translate }}"
                (keyup.enter)="search($event)"
              />
              <div class="input-group-append">
                <span class="input-group-text f-700"
                  ><i
                    class="fa fa-search"
                    (click)="search(searchVA.value, true)"
                  ></i
                ></span>
              </div>
              <div class="input-group-append" (click)="clearSearch(searchVA)">
                <span class="input-group-text f-700 bg-primary-2"
                  ><i class="fas fa-redo-alt"></i
                ></span>
              </div>
            </div>
          </div>

          <div>
            <a
              class="btn btn-primary"
              href="javascript:;"
              [routerLink]="['../' + APP_ROUTES.addProduct]"
            >
              <i class="nav-icon fas fa-plus"></i>
              <!-- Add Products -->
              {{ "listProduct.addProducts" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Header (Page header)  -->
  </section>

  <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2 align-items-center">
                <div class="col-md-8">
                    <ol class="breadcrumb">
                       <li class="breadcrumb-item"><a href="javascript:;" [routerLink]="['../' + APP_ROUTES.dashboard]">Dashboard</a></li>
                        <li class="breadcrumb-item active">List Products</li>
                    </ol>
                </div>



                <div class="col d-inline-flex justify-content-end">
                    <a class="btn btn-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.addProduct]">
                        <i class="nav-icon fas fa-plus"></i> Add Products
                    </a>
                </div>
            </div>
        </div>
         /.container-fluid
    </section> -->

  <!-- Main content -->
  <section class="content">
    <div class="card card-primary">
      <div class="card-header">
        <div class="w-100 d-flex justify-content-between">
          <span>
            <!-- Product Listing -->
            {{ "listProduct.productListing" | translate }}
          </span>
          <span>
            <!-- Total Products -->
            {{ "listProduct.totProduct" | translate }}
            <strong>{{ totalItems }}</strong></span
          >
        </div>
      </div>
      <div class="card-body table-responsive">
        <table class="table table-striped table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>{{ "listProduct.sNo" | translate }}</th>
              <th>{{ "listProduct.productName" | translate }}</th>
              <th style="width: 150px">
                {{ "listProduct.productPrice" | translate }}
              </th>
              <th>{{ "listProduct.categoryName" | translate }}</th>

<!-- remove this  -->

              <!-- <th> -->
                <!-- Has Feature -->
                <!-- {{ "listProduct.hasFeat" | translate }}
              </th> -->





              <th>{{ "editProduct.type" | translate }}</th>
              <th
                style="width: 105px !important"
                *ngIf="permissions.product == 1"
              >
                {{ "listProduct.actions" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of productList; index as i">
              <td class="align-middle text-center" *ngIf="page === 1">
                {{ i + 1 }}
              </td>
              <td class="align-middle" *ngIf="page !== 1">
                {{ page * 10 - 10 + (i + 1) }}
              </td>
              <td class="align-middle">
                <div class="form-check pl-0">
                  <label class="form-check-label" for="exampleCheck1"
                    >{{ obj.product_name }}
                  </label>
                </div>
              </td>
              <td class="align-middle text-right" style="white-space: nowrap">
                <div class="form-check pl-0">
                  <label class="form-check-label" >
                    {{ (obj.price>0 ? obj.price : '') | number : "1.2-2" : "de-DE" }}
                    <i *ngIf="obj.price>0" class="fas fa-euro-sign f-11"></i>

                    {{
                      obj.type === "variant" && obj.price_wall>0
                        ? " " +
                          ((obj.price_wall>0 ? obj.price_wall : '')
                            | number : "1.2-2" : "de-DE")
                        : ''
                    }}
                    <i
                      *ngIf="obj.type === 'variant' && obj.price_wall>0"
                      class="fas fa-euro-sign f-11"
                    ></i>
                    {{
                      obj.type === "variant" && obj.price_running_meter>0
                        ? " " +
                          ((obj.price_running_meter
                            ? obj.price_running_meter
                            : ''
                          ) | number : "1.2-2" : "de-DE")
                        : ""
                    }}
                    <i
                      *ngIf="obj.type === 'variant' && obj.price_running_meter>0"
                      class="fas fa-euro-sign f-11"
                    ></i>
                  </label>
                </div>
              </td>
              <td>
                <ng-container>
                  <span *ngFor="let cat of obj?.ProductCategories;let isLast=last">
                  <span *ngIf="cat?.Catalogue?.is_delete==0">{{ cat?.Catalogue?.cat_name }}</span><span *ngIf="!isLast">,</span>
                  </span>
                </ng-container>
                
              </td>
              <!-- <td
                *ngIf="
                  obj.Catalogue.ProductFeatures &&
                  obj.Catalogue.ProductFeatures.length > 0
                "
              >
                true
              </td>
              <td
                *ngIf="
                  !obj.Catalogue.ProductFeatures ||
                  obj.Catalogue.ProductFeatures.length == 0
                "
              >
                true
              </td> -->

              <td>
                {{ getType(obj?.type) }}
              </td>

              <td
                class="align-middle mw-150 mw-150"
                *ngIf="permissions.product == 1"
              >
              <button (click)="copyProduct(obj.id)" class="no-border bg-transparent text-dark"> 
                <i class="fa fa-copy"></i>
                </button>
                <button
                  (click)="editProduct(obj)"
                  class="no-border bg-transparent text-dark"
                >
                  <i class="fas fa-pencil-alt"></i>
                </button>

                <button
                  class="text-danger no-border bg-transparent"
                  mwlConfirmationPopover
                  [popoverTitle]="popoverTitle"
                  [popoverMessage]="popoverMessage"
                  [confirmText]="confirmText"
                  [cancelText]="cancelText"
                  placement="top"
                  (confirm)="deleteProduct(true, obj.id)"
                  (cancel)="deleteProduct(false, obj.id)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

  <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
    <div class="col-md-12 text-right">
      <div class="pagination justify-content-end">
        <div class="page-item">
          <pagination
            [boundaryLinks]="showBoundaryLinks"
            [firstText]="firstText"
            [lastText]="lastText"
            [previousText]="prevText"
            [nextText]="nextText"
            [itemsPerPage]="itemsPerPage"
            [totalItems]="totalItems"
            [(ngModel)]="page"
            (pageChanged)="pageChanged($event)"
            [maxSize]="maxSize"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
