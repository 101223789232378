import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { FaqService } from '../faq.service';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss'],
})
export class EditFaqComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private service: FaqService,
    private toastr: ToastrService,
    private router: Router,
    private activeModal: NgbActiveModal,
    private translationService: TranslateService
  ) {}

  faqObj: any;
  faqForm = this.fb.group({
    language_type:['DE',[Validators.required]],
    question: [
      '',
      [
        Validators.required,
        Validators.maxLength(300),
        this.noWhitespaceValidator,
      ],
    ],
    answer: [
      '',
      [
        Validators.required,
        Validators.maxLength(1000),
        this.noWhitespaceValidator,
      ],
    ],
    id: [''],
  });

  ngOnInit(): void {
    this.faqForm.patchValue({
      language_type:this.faqObj.language_type,
      question: this.faqObj.question,
      answer: this.faqObj.answer,
      id: this.faqObj.id,
    });
  }

  updateFaq() {
    this.service.updateFaq(this.faqForm.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          setTimeout(() => {
            // this.toastr.success('FAQ updated successfully.', '');
            this.toastr.success(
              this.translationService.instant('editFaq.faqUpdatedMsgTsFile')
            );
          }, 1000);
          this.activeModal.close();
          this.router.navigate([APP_ROUTES.listFaq]).then(() => {});
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  closeModall() {
    this.activeModal.close();
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
