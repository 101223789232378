import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { FaqService } from '../faq.service';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss'],
})
export class AddFaqComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private service: FaqService,
    private toastr: ToastrService,
    private translationService: TranslateService
  ) {}

  public APP_ROUTES = APP_ROUTES;
  faqForm = this.fb.group({
    language_type:['DE',[Validators.required]],
    question: [
      '',
      [
        Validators.required,
        Validators.maxLength(300),
        this.noWhitespaceValidator,
      ],
    ],
    answer: [
      '',
      [
        Validators.required,
        Validators.maxLength(1000),
        this.noWhitespaceValidator,
      ],
    ],
    id: [''],
  });

  ngOnInit(): void {
  }

  addFaq() {
    console.log(this.faqForm.value,'form');
    
    this.service.addFaq(this.faqForm.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('RESPONSE', data);
          setTimeout(() => {
            // this.toastr.success('FAQ added successfully.', '');
            this.toastr.success(
              this.translationService.instant('addFaq.faqAddedMsgTsFile')
            );
          }, 1000);
          this.router.navigate([APP_ROUTES.listFaq]).then(() => {});
        }
      },
      (err) => {
        console.log('Error', err);
        this.toastr.error(err.error.message, '');
      }
    );
  }
  // languageChange(lang): void {

  //   if(lang=='DE'){
  //     const payload={ lang:'DE',
  //     type:'privacy_policy'}
  //     // this.getPrivacy(payload);
  //   }
  //   if(lang=='EN'){
  //     const payload={ lang:'EN',
  //     type:'privacy_policy'}
  //     // this.getPrivacy(payload);
  //   }
  // }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
