import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customize-feature',
  templateUrl: './customize-feature.component.html',
  styleUrls: ['./customize-feature.component.scss'],
})
export class CustomizeFeatureComponent implements OnInit {
  ctrlval;
  mainForm: FormGroup;
  controlValue: any;
  showInput: Boolean;
  updatedForm:any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.mainForm) {
      this.updatedForm = JSON.parse(JSON.stringify(this.mainForm.value));
      console.log('mainForm CustomizeFeatureComponent', this.mainForm.value);
      this.controlValue = this.mainForm.get('controlValue') as FormArray;
      console.log(this.controlValue,'control value 23')
      // console.log(this.ctrlval);

      if (this.ctrlval === 'input') {
        this.mainForm.get('controlValue').clearValidators();
        this.mainForm.get('controlValue').updateValueAndValidity();
      }
    }
  }

  passBack(inputValue?: any): void {
    console.log(this.mainForm.value);

    if (this.showInput) {
      console.log('returning val 1', this.mainForm.value);
      console.log('returning val 2', this.mainForm.value.controlValue);
      // this.activeModal.close(this.mainForm.value.controlValue);
      // this.activeModal.close(this.mainForm.value.price);
      this.activeModal.close(this.mainForm.value);
    } else {
      console.log('returning mainForm', this.mainForm.value);
      this.activeModal.close(this.mainForm);
    }
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  closeModal() {
    this.mainForm.patchValue({...this.updatedForm})
    this.activeModal.close(this.updatedForm);
  }
}
