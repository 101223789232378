<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper pl-3 pr-3">
    <!-- Content Header (Page header) -->
    <section class="content-header pb-2">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>
            {{'Admin.profile' | translate}}</h1>
        </div>
        <div class="col-sm-6">
        </div>
      </div>
    </section>
    <!-- Main content -->
    <section class="content">
      <!-- Default box -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- <div class="card-header">
              <h3 class="card-title"> {{'privacyPolicy.title' | translate }}</h3>
  
            </div> -->
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="false">
                <div class="row">
                  
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label> {{'Admin.first_name' | translate}}</label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="firstname" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'Title'">
                       
                    </div>
                    <ng-container *ngIf="
                    form.controls['firstname'].errors &&
                    (form.get('firstname').dirty ||
                      form.get('firstname').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['firstname'].hasError(
                        'required'
                      )
                    ">*
                    <!-- Label name is
                              required. -->
                  First name is required</small>
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['firstname'].hasError(
                        'maxlength'
                      )
                    ">*
                    <!-- Maximum of 30
                              characters. -->
                    </small>
                </ng-container>
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>{{'Admin.last_name' | translate}} </label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="lastname" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'Title'">
                       
                    </div>
                    <ng-container *ngIf="
                    form.controls['lastname'].errors &&
                    (form.get('lastname').dirty ||
                      form.get('lastname').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['lastname'].hasError(
                        'required'
                      )
                    ">*
                    <!-- Label name is
                              required. -->
                  Last name is required</small>
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['lastname'].hasError(
                        'maxlength'
                      )
                    ">*
                    <!-- Maximum of 30
                              characters. -->
                    </small>
                </ng-container>
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>{{'Admin.email' | translate}} </label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="email" type="text"
                        class="pl-0 form-control border-0 shadow-none fw-regular fs-6"
                        [placeholder]="'E-mail'" disabled>
                       
                    </div>
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>
  
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>{{'Admin.phone' | translate}}</label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="phone_number" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'Phone number'">
                       
                    </div>
                    <ng-container *ngIf="
                    form.controls['phone_number'].errors &&
                    (form.get('phone_number').dirty ||
                      form.get('phone_number').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['phone_number'].hasError(
                        'required'
                      )
                    ">*
                    <!-- Label name is
                              required. -->
                  phone number is required</small>
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['email'].hasError(
                        'maxlength'
                      )
                    ">*
                    <!-- Maximum of 30
                              characters. -->
                    </small>
                </ng-container>
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>
                 
                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>{{'Admin.address' | translate}} </label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="address" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'Phone number'">
                       
                    </div>
                    <ng-container *ngIf="
                    form.controls['address'].errors &&
                    (form.get('address').dirty ||
                      form.get('address').touched)
                  ">
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['address'].hasError(
                        'required'
                      )
                    ">*
                    <!-- Label name is
                              required. -->
                  address is required</small>
                  <small class="text-danger error-msg" *ngIf="
                      form.controls['address'].hasError(
                        'maxlength'
                      )
                    ">*
                    <!-- Maximum of 30
                              characters. -->
                    </small>
                </ng-container>
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>

                  <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>{{'Admin.city' | translate}} </label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="city" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'city'">
                       
                    </div>
                   
                    <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                    </app-validation-errors> -->
                  </div>

                  <!-- <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                    <label>Postal Code </label>
                    <div class="input-group input-group-style border-bottom bg-transparent">
                      <input formControlName="postal_code" type="text"
                        class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                        [placeholder]="'posatal code'">
                       
                    </div>
                  </div> -->
                  <!-- <app-validation-errors [control]="description" [controlName]="'privacyPolicy.description' | translate">
                  </app-validation-errors> -->
                </div>
  
                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                  <button type="submit" class="btn btn-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block mr-2">
                    {{'Admin.update' | translate}} </button>
                  <!-- <a [routerLink]="['/',APP_ROUTES.dashboard]"
                    class="btn btn-outline-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block">{{'common.Cancel'|
                    translate}}</a> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>