import { PreAuthPagesService } from './../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public APP_ROUTES = APP_ROUTES;
  public LoginForm: FormGroup;
  public isSubmitting: boolean = false;
  public isDisabled: boolean = false;
  public authorized: boolean = false;
  public passwordVisibility: boolean = false;
  returnUrl: any = '';

  constructor(
    private router: Router,
    private auth: PreAuthPagesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translationService: TranslateService
  ) {
    localStorage.clear();
    sessionStorage.clear();
    this.route.queryParams.subscribe((params) => {
      if (params['redirect']) {
        this.returnUrl = params['redirect'];
      } else {
        this.returnUrl = '';
      }
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  handleRedirect() {
    if (this.returnUrl.includes('timeline')) {
      let url = this.returnUrl.split('?')[1];
      let key = url.split('=')[0];
      let id = url.split('=')[1];
      this.router.navigate([this.returnUrl?.split('?')[0]], {
        queryParams: {
          [key]: id,
        },
      });
      return;
    }
    this.router.navigate([this.returnUrl]);
  }

  get formControls() {
    return this.LoginForm.controls;
  }

  private initForm(): void {
    this.LoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      // 'type': new FormControl(2)
    });
  }

  public doLogin(e: number): void {
    if (this.LoginForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;

    this.auth.login(this.LoginForm.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          setTimeout(() => {
            // this.toastr.success('Logged in successfully.', '');
            this.toastr.success(
              this.translationService.instant('login.loginMsgTsFile')
            );
          }, 1000);
          if (this.returnUrl) {
            this.handleRedirect();
          } else {
            this.router.navigate([APP_ROUTES.dashboard]).then(() => {});
          }
        } else if (data.status === 403) {
          // const title: string = 'Account Blocked';
          // const html: string = 'Your account has been blocked by admin';
          this.toastr.error(
            this.translationService.instant('login.blockedMsgTsFile')
          );
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }
}
