import { TranslateService } from '@ngx-translate/core';
import { CustomizeFeatureComponent } from './../customize-feature/customize-feature.component';
import { ProductService } from './../product.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CategoryService } from '../../add-category/category.service';
import { IActionMapping, ITreeOptions, ITreeState, KEYS, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from 'ngx-custom-validators';
import { environment } from 'src/environments/environment';
import { BooleanLiteral } from 'typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MultipleCategoriesComponent } from '../multiple-categories/multiple-categories.component';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  dropdownList:any[] = [];
  selectedItems:any[] = [];
  placeholder:string = 'Choose another category.';
  dropdownSettings:IDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
  };
  prepareCatPayload:any[] = []
  imageuploadlimit = 6;
  imagesizelimit = 5;
  selectedFeatureList:[] =[];
  constructor(
    public fb: FormBuilder,
    private toastr: ToastrService,
    private service: ProductService,
    private categoryService: CategoryService,
    private router: Router,
    private modalService: NgbModal,
    private cd:ChangeDetectorRef,
    private spinner:NgxSpinnerService,
    private translationService: TranslateService,
  ) {
    this.imageuploadlimit = this.service.imageuploadlimit;
    this.imagesizelimit = this.service.imagesizelimit;
  }
  selectall: any = false;
  showInstructions = true;
  productImageList: any[] = [];
  APP_ROUTES = APP_ROUTES;
  productForm = this.fb.group({
    product_name: [
      '',
      [
        Validators.required,
        Validators.maxLength(80),
        this.noWhitespaceValidator,
      ],
    ],
    type: ['', [Validators.required]],
    description: [
      '',
      [
        Validators.required,
        Validators.maxLength(300),
        this.noWhitespaceValidator,
      ],
    ],
    price: [
      null,
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[0-9,.]*$/),
      ],
    ],
    floor: [0,[Validators.required]],
    wall : [0,[Validators.required]],
    running_meter : [0,[Validators.required]],
    price_wall: [
      null,
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[0-9,.]*$/),
      ],
    ],
    price_running_meter: [
      null,
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^[0-9,.]*$/),
      ],
    ],
    catalogue_id: ['', Validators.required],
    image: [''],
    product_id: [''],
    products_feature: [''],
    quantity: [0],
    priceType: [''],
    tempPrice: [0],
    is_extra:[false]
  });

  featureForm = new FormArray([]);

  step1 = false;
  step2 = false;
  step3 = false;
  private unsubscribe$ = new Subject();
  mainCategories: any;
  nodes2 = [];
  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  // @ViewChild('viewNode') treeNative!:TreeComponent
  featureList: any[] =[];
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Product?';
  imageUrl: any;
  selectedFiles?: FileList;

  imgarray: string[] = [];
  imgBaseUrl = environment.imgBaseUrl;
  catListForProduct:any[] = [];
  catListTemp:any[] = [];
  category_ids_arr:any[]= []


  // cropped image
  @ViewChild('cropImageModal') cropModal: any;
  imageChangedEvent: any = '';
  croppedImage: any;
  fileName: string;
  imageFormat: string;
  imageIndex:any;
  imageWidth:any = 0;
  imageHeight:any = 0;
  isImageScaleDown:any = false;
  imageOrigin:any;


  actionMapping: IActionMapping = {
    mouse: {
      contextMenu: (tree, node, $event) => {
        $event.preventDefault();
      },
      checkboxClick: (tree, node: TreeNode, $event) => {
        $event.stopPropagation();
       
        if(node.isSelected === undefined){
          node.setIsSelected(true)
          return;
        }

        node.setIsSelected(!node.isSelected)
        
        // node.toggleSelected();
      },
      dblClick: (tree, node, $event) => {
        if (node.hasChildren) {
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      },
      click: (tree: TreeModel, node: TreeNode, $event) => {},
      expanderClick: (tree, node: TreeNode, $event) => {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    },
    keys: {
      [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
    }
  };
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: true,
    actionMapping: this.actionMapping,
    idField: 'id',
    displayField: 'title',
    childrenField: 'children',
  };
  isModalOpened:any = false;
  openCatModal(){
    this.isModalOpened = true;
    const modalRef = this.modalService.open(MultipleCategoriesComponent, {
      size: 'lg',
      windowClass: '',
      keyboard:true,
      centered:true,
      backdrop:'static'
    });
    
    modalRef.componentInstance.nodes = this.nodes2;
    modalRef.componentInstance.patchValue = null;
    modalRef.result.then(
      (childNodes) => {
        if(childNodes){
          console.log('childNodes',childNodes);
          const result= [];
          childNodes.forEach(ele=>{
            console.log('ele ==>',ele);
            if(ele.children.length === 0) {
              result.push(ele);
            }
          })
          console.log('result',result);
          localStorage.setItem('cat_ids',JSON.stringify(result));
          this.category_ids_arr = result;
          let ids = this.category_ids_arr.map((item)=> String(item?.id));
          this.productForm.patchValue({ catalogue_id: ids || [] });
          this.getAllFeatureList(ids);
          // this.featureList = [];
          // this.category_ids_arr?.forEach((item=>{
          //   item?.ProductFeatures?.forEach((feat=>{
          //   this.featureList.push(feat);
          //   }))
          // }))   
        }
        this.category_ids_arr.forEach(x=>{
          console.log(x, 'itemm');
          
        })
        console.log(this.category_ids_arr.length, 'aaaa');
        
        // console.log(result, 'modal resu;t');
        
      },
      (reason) => {
        console.log(`Dismissed in if el`);
      }
    );
  } 
  
  ngOnInit(): void {
    this.step1 = false;
    this.step2 = true;
    localStorage.removeItem('cat_ids')
    this.getCategories(); 
  }
  ngAfterViewInit(){
  
    setTimeout(() => {
      document.getElementById('productFormtop')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 700);
   }

  onItemSelect(item: any) {
    let isExists = this.prepareCatPayload.some(cat=> cat?.id === item.id);
    !isExists && this.prepareCatPayload.push(item)
    console.log(this.prepareCatPayload);
  }
  onSelectAll(items: any) {
    this.prepareCatPayload = items;
    console.log(this.prepareCatPayload);
  }
  onDeSelectAll(items: any) {
    this.prepareCatPayload = items;
    console.log(this.prepareCatPayload);
  }
  onItemDeSelect(item: any) {
    let index = this.prepareCatPayload.findIndex(cat=> cat?.id === item.id);
    this.prepareCatPayload.splice(index,1);
    console.log(this.prepareCatPayload);
    
  }
  onFilterChange(item: any) {
    console.log(item);
  }

  updateProductValidation(type?: any): void {
    if (this.productForm.controls?.type.value === 'variant') {
      if (type === 'price') {
        this.productForm.get('price').setValue(null);
        this.productForm
          .get('price')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price').updateValueAndValidity();
        this.productForm.get('price_wall').setValidators([]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price_running_meter').setValidators([]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
      }
      if (type === 'pricewall') {
        this.productForm.get('price_wall').setValue(null);
        this.productForm
          .get('price_wall')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price').setValidators([]);
        this.productForm.get('price').updateValueAndValidity();
        this.productForm.get('price_running_meter').setValidators([]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
      }
      if (type === 'priceruning') {
        this.productForm.get('price_running_meter').setValue(null);
        this.productForm
          .get('price_running_meter')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ]);
        this.productForm.get('price_running_meter').updateValueAndValidity();
        this.productForm.get('price_wall').setValidators([]);
        this.productForm.get('price_wall').updateValueAndValidity();
        this.productForm.get('price').setValidators([]);
        this.productForm.get('price').updateValueAndValidity();
      }
    } else {
      this.productForm.get('price').setValue(0);
      this.productForm
        .get('price')
        .setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[0-9,.]*$/),
        ]);
      this.productForm.get('price').updateValueAndValidity();
      this.productForm.get('price_wall').setValue(0);
      this.productForm.get('price_wall').setValidators([]);
      this.productForm.get('price_wall').updateValueAndValidity();
      this.productForm.get('price_running_meter').setValue(0);
      this.productForm.get('price_running_meter').setValidators([]);
      this.productForm.get('price_running_meter').updateValueAndValidity();
      if (this.productForm.get('tempPrice').value) {
        this.productForm
          .get('price')
          .setValue(this.productForm.get('tempPrice').value);
      }
    }
  }

 processNestedArrays(data: any[], cat?:any) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        item['isChecked'] = false;
        if(item?.children?.length > 0){
          item['isIntermediate']=false;
          item.hasChildren = true;
        }
        this.catListTemp.push(item);
        this.processNestedArrays(item?.children,item);
      }else{
      }
     
    }
    return this.catListTemp
  }

  processNestedCheckboc(node:any, data: any[], isChecked:boolean) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        item['isChecked'] = isChecked;
        this.processNestedCheckboc(node, item?.children,isChecked);
      }else{
        item['isChecked'] = isChecked;
      }
    }
  }
  processNestedCheckboxIntermediate(node:any, data: any[]) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        item['isIntermediate'] = false;
        this.processNestedCheckboxIntermediate(node, item?.children);
      }else{
        item['isIntermediate'] = false;
      }
    }
  }

  toggleCatCheckbox(node:any, isChecked:any){
    node.data.isChecked = isChecked;
    if(node.data.children){
      this.processNestedCheckboc(node,node.data.children,node.data.isChecked)
    }
    if ('isIntermediate' in node?.data){
      if(!isChecked){
        this.processNestedCheckboxIntermediate(node,node.data.children)
        return 
      }
    }
    if(node?.parent == null || node?.parent == "null") return
    if(node.parent){
      this.NestedOnParentForIntermidiate(node)
    }
  }

  NestedOnParentForIntermidiate(node:any){
    if(node?.parent == null || node?.parent == "null") return
    let isSome = (node.data.children || []).some(child => !child?.isChecked)
    let isEvery = (node.data.children || []).every(child => !child?.isChecked)
    let isSomeInter = (node.data.children || []).some(child => child?.isIntermediate)
    let isEveryInter = (node.data.children || []).every(child => child?.isIntermediate)
    console.log(isSome, 'isSome');
    console.log(isEvery, 'isEvery',node.data.title);
    
    if(isSome || isEvery || isSomeInter || isEveryInter){
      if(node.data.children?.length > 0){
        node.data['isIntermediate'] = true;
      }
      // if(isEvery){
      //   this.processNestedCheckboxIntermediate(node,)
      // }
    }else{
        node.data['isIntermediate'] = false;
        node.data['isChecked'] = true;
    }
    if(node.parent){
      this.NestedOnParentForIntermidiate(node.parent)
    }
  }

  getCategories(): void {
    this.categoryService.getCategories().subscribe(
      (data: any) => {
        if (data.status === 200) {
          // console.log('response ', data);
          this.nodes2 = data.body.mainCat;
          this.catListForProduct = [];
          this.catListForProduct = this.processNestedArrays(this.nodes2);
          
          // this.catListForProduct = this.processNestedArrays(JSON.parse(JSON.stringify(data.body.mainCat)));
          this.dropdownList = this.catListForProduct;
          this.nodes2 = [...this.nodes2];
          //  setTimeout(()=>{
          //   this.treeNative.treeModel.doForAll((node)=>{
              
          //    if([14,26,25,5,33].includes(node?.data?.id)){
          //    node.setActiveAndVisible(true)
          //    node.setIsSelected(true)
          //   }
          //   })
          //   },500)
          // console.log('Final data -->', this.nodes2);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  checkPriceValue(priceControl: any) {
    console.log(priceControl);
    if( priceControl=='price'){
      this.productForm.patchValue({ floor: 1,running_meter:0,wall:0 });
    }
    if( priceControl=='pricewall'){
      this.productForm.patchValue({ wall: 1 ,floor: 0 ,running_meter:0 });
    }
    if( priceControl=='priceruning'){
      this.productForm.patchValue({ running_meter: 1 ,wall: 0,floor:0});
    }
    console.log(this.productForm.value,'value form');
    
    this.updateProductValidation(priceControl);
  }
  addProductNoFeatures(){
    // let imglist = this.productImageList.map((img: any) => (img.filename)).toString();
    // this.productForm.patchValue({ image: imglist });
    // if (this.productForm.valid) {
    //   let payload = {
    //     products: [],
    //   };
    //   if (this.productForm.get('price').value === null) {
    //     this.productForm.get('price').setValue(0);
    //   }
    //   if (this.productForm.get('price_running_meter').value === null) {
    //     this.productForm.get('price_running_meter').setValue(0);
    //   }
    //   if (this.productForm.get('price_wall').value === null) {
    //     this.productForm.get('price_wall').setValue(0);
    //   }
    //   if (this.productForm.get('type').value === 'variant') {
    //     if (this.productForm.get('priceType').value !== 'price') {
    //       this.productForm.get('price').setValue(0);
    //     }
    //   }
    
    //   payload.products.push(this.productForm.value);
    //   this.service.addProduct(payload).subscribe(
    //     (data: any) => {
    //       if (data.status === 200) {
    //         this.step2 = false;
    //         this.step3 = true;

    //         console.log('REPSO ', data);
    //         this.productForm.patchValue({
    //           product_id: data.body.addProduct[0].id,
    //         });
    //         console.log('this.productForm.value', this.productForm.value);
    //         let noFeature = false;
    //         if (this.featureList?.length === 0) {
    //           noFeature = true;
    //           this.router.navigate([APP_ROUTES.listProduct]).then(() => { });
    //           setTimeout(() => {
    //             // this.toastr.success('Product being added successfully without features', '');
    //             this.toastr.success(
    //               this.translationService.instant(
    //                 'addProduct.productAddedWithoutFeaturesMsgTsFile'
    //               )
    //             );
    //           }, 1000);
    //         }
    //         if (!noFeature) {
    //           setTimeout(() => {
    //             // this.toastr.success('Product added successfully.', '');
    //             this.toastr.success(
    //               this.translationService.instant(
    //                 'addProduct.productAddedMsgTsFile'
    //               )
    //             );
    //           }, 1000);
    //         }
    //       } else {
    //         this.toastr.error(data.body.message, '');
    //       }
    //     },
    //     (err) => {
    //       console.log('error', err);
    //       if (err.error.error_code == 4) {
    //         this.toastr.error(
    //           this.translationService.instant(
    //             'addProduct.prodAlreadyAddedMsgTsFile'
    //           )
    //         );
    //       } else {
    //         // this.toastr.error('Something went wrong. Please try again later', '');
    //         this.toastr.error(
    //           this.translationService.instant('login.genricErrorTsFile')
    //         );
    //       }
    //     }
    //   );
    // } else {
    //   this.productForm.markAllAsTouched();
    //   // if (!this.productForm.controls['image'].value) {
    //   //   this.toastr.error('please choose image of the product')
    //   // }
    // }

    this.saveProductFeatures()
  }

  addProduct() {
    // let ids:number[] = [];
    // this.treeNative.treeModel.doForAll((node:TreeNode)=>{
    //   if(node.isSelected && !node.isPartiallySelected){
    //     ids.push(node.data.id)
    //   }
    //  });

    //  console.log(ids, 'idsll');

    //  return
    let imglist = this.productImageList.map((img: any) => (img.filename)).toString();
    this.productForm.patchValue({ image: imglist });
    if (this.productForm.valid && this.category_ids_arr.length > 0) {
      let payload = {
        products: [],
      };
      if (this.productForm.get('price').value === null) {
        this.productForm.get('price').setValue(0);
      }
      if (this.productForm.get('price_running_meter').value === null) {
        this.productForm.get('price_running_meter').setValue(0);
      }
      if (this.productForm.get('price_wall').value === null) {
        this.productForm.get('price_wall').setValue(0);
      }
      if (this.productForm.get('type').value === 'variant') {
        if (this.productForm.get('priceType').value !== 'price') {
          this.productForm.get('price').setValue(0);
        }
      }
      if(this.featureList?.length>0){
        this.step2 = false;
        this.step3 = true;
        setTimeout(() => {
          document.getElementById('inst')?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 300);
      }else{
        this.saveProductFeatures();
      }
        
       
      //   let noFeature = false;
      //   if (this.featureList?.length === 0) {
      //     noFeature = true;
      //     this.router.navigate([APP_ROUTES.listProduct]).then(() => { });
      //     setTimeout(() => {
      //       // this.toastr.success('Product being added successfully without features', '');
      //       this.toastr.success(
      //         this.translationService.instant(
      //           'addProduct.productAddedWithoutFeaturesMsgTsFile'
      //         )
      //       );
      //     }, 1000);
        
      //   if (!noFeature) {
      //     setTimeout(() => {
      //       // this.toastr.success('Product added successfully.', '');
      //       this.toastr.success(
      //         this.translationService.instant(
      //           'addProduct.productAddedMsgTsFile'
      //         )
      //       );
      //     }, 1000);
      //   }
      // } 
      // payload.products.push(this.productForm.value);
      // this.service.addProduct(payload).subscribe(
      //   (data: any) => {
      //     if (data.status === 200) {
      //       this.step2 = false;
      //       this.step3 = true;

      //       console.log('REPSO ', data);
      //       this.productForm.patchValue({
      //         product_id: data.body.addProduct[0].id,
      //       });
      //       console.log('this.productForm.value', this.productForm.value);
      //       let noFeature = false;
      //       if (this.featureList?.length === 0) {
      //         noFeature = true;
      //         this.router.navigate([APP_ROUTES.listProduct]).then(() => { });
      //         setTimeout(() => {
      //           // this.toastr.success('Product being added successfully without features', '');
      //           this.toastr.success(
      //             this.translationService.instant(
      //               'addProduct.productAddedWithoutFeaturesMsgTsFile'
      //             )
      //           );
      //         }, 1000);
      //       }
      //       if (!noFeature) {
      //         setTimeout(() => {
      //           // this.toastr.success('Product added successfully.', '');
      //           this.toastr.success(
      //             this.translationService.instant(
      //               'addProduct.productAddedMsgTsFile'
      //             )
      //           );
      //         }, 1000);
      //       }
      //     } else {
      //       this.toastr.error(data.body.message, '');
      //     }
      //   },
      //   (err) => {
      //     console.log('error', err);
      //     if (err.error.error_code == 4) {
      //       this.toastr.error(
      //         this.translationService.instant(
      //           'addProduct.prodAlreadyAddedMsgTsFile'
      //         )
      //       );
      //     } else {
      //       // this.toastr.error('Something went wrong. Please try again later', '');
      //       this.toastr.error(
      //         this.translationService.instant('login.genricErrorTsFile')
      //       );
      //     }
      //   }
      // );
     
    } else {
      this.productForm.markAllAsTouched();
      this.isModalOpened = true;
      return
      // if (!this.productForm.controls['image'].value) {
      //   this.toastr.error('please choose image of the product')
      // }
    }
  }

  checkIfObjectExists(obj: any): FormGroup {
    let condition = false;
    if (this.featureForm.controls.length > 0) {
      this.featureForm.controls.forEach((element, index) => {
        console.log('element', element.value);
        if (obj.id === element?.value?.featureId) {
          console.log('Form element being returned is =>', element);
          return element;
        }
      });
    } else {
      return null;
    }
  }

  customizeFeature(obj: any) {
    // console.log("OBJ",obj);
    let el;
    if (this.featureForm.controls.length > 0) {
      this.featureForm.controls.forEach((element, index) => {
        // console.log("element",element.value);
        if (obj.id === element.value.featureId) {
          // console.log("Form element being returned is =>",element);
          el = element;
          this.featureForm.removeAt(index);
        }
      });
    }
    // console.log("form",el);
    if (el) {
      // console.log("INSIDE FORM",el);
      const modalRef = this.modalService.open(CustomizeFeatureComponent, {
        size: 'lg',
        windowClass: '',
      });
      if (el.value.controlType === 'input') {
        modalRef.componentInstance.showInput = true;
      } else {
        modalRef.componentInstance.showInput = false;
      }

      modalRef.componentInstance.mainForm = el;
      modalRef.componentInstance.ctrlval = obj.control_type;
      modalRef.result.then(
        (result) => {
          // console.log("result ",result);
          // el.patchValue({
          //   controlValue : result
          // });
          this.featureForm.push(el);
          // console.log("featureForm result for INPUT YOOOOOOOOO",JSON.stringify(this.featureForm.value));
        },
        (reason) => {
          this.featureForm.push(el);
          console.log(`Dismissed in if el`);
        }
      );
    } else {
      if (obj.control_type === 'input') {
        console.log('SEEEEEEEEEEEEE ', obj);
        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: [
            obj.control_value,
            [
              Validators.required,
              Validators.maxLength(30),
              Validators.pattern(/^[0-9]*$/),
            ],
          ],
          price: [
            '0',
            [
              Validators.required,
              Validators.maxLength(10),
              Validators.pattern(/^[0-9,.]*$/),
              ,
            ],
          ],

          featureSurcharge: [obj.surcharge],
          catalogue_id:[obj.catalogue_id]
        });
        // console.log("mainForm",JSON.stringify(mainForm.value));

        const modalRef = this.modalService.open(CustomizeFeatureComponent, {
          size: 'xl',
          windowClass: '',
        });
        modalRef.componentInstance.showInput = true;
        modalRef.componentInstance.mainForm = mainForm;
        modalRef.componentInstance.ctrlval = obj.control_type;
        modalRef.result.then(
          (result) => {
            console.log('result ', result);
            mainForm.patchValue({
              // controlValue: result,
              price: result?.price,
            });

            console.log(`mainForm.patchValue `, mainForm);

            this.featureForm.push(mainForm);
            // console.log("featureForm result for INPUT",JSON.stringify(this.featureForm.value));
          },
          (reason) => {
            console.log(`Dismissed in `);
          }
        );
      } else {
        let mainForm = this.fb.group({
          featureId: [obj.id],
          featureName: [obj.label_name],
          controlType: [obj.control_type],
          controlValue: new FormArray([]),
          featureSurcharge: [obj.surcharge],
          catalogue_id:[obj.catalogue_id]
        });
        let combinedarr = [];
        let controlValue = mainForm.get('controlValue') as FormArray;
        let arr = obj?.control_value?.split(',') || [];
        let arr1 =
          obj?.control_valueimg !== null
            ? obj?.control_valueimg?.split(',')
            : [];
        arr.forEach((element, i) => {
          combinedarr.push({ val: element, img: arr1[i] });
        });
        console.log(combinedarr);

        for (var val of combinedarr) {
          if (val) {
            let innerForm = this.fb.group({
              value: [val.val],
              price: [
                '0',
                [
                  Validators.required,
                  Validators.maxLength(10),
                  Validators.pattern(/^[0-9,.]*$/),
                ],
              ],
              value_img: [val.img],
            });
            controlValue.push(innerForm);
          }
        }

        // console.log("mainForm",JSON.stringify(mainForm.value));

        const modalRef = this.modalService.open(CustomizeFeatureComponent, {
          size: 'lg',
          windowClass: '',
        });
        modalRef.componentInstance.mainForm = mainForm;
        modalRef.componentInstance.showInput = false;
        modalRef.componentInstance.ctrlval = obj.control_type;
        // modalRef.result.then((result) => {
        //   if (result) {
        //   console.log("result ",result.value);
        //   }
        //   }).catch(err => {
        //     console.error(err)
        // });

        modalRef.result.then(
          (result) => {
            // console.log("result ",result.value);
            if (result) {
              this.featureForm.push(result);
            }
            console.log('HELLO bb', (this.featureForm.value));
            console.log('HELLO ', JSON.stringify(this.featureForm.value));
          },
          (reason) => {
            console.log(`Dismissed `, JSON.stringify(this.featureForm.value));
          }
        );
      }
    }
  }
  selectAllFeatureToggle() {
    this.featureList.forEach((element) => {
      if (!this.selectall) {
        element.isSelected = true;
      } else {
        element.isSelected = false;
      }
    });
  }
  toggleSelectedFeature(obj: any) {
    obj.isSelected = !obj.isSelected;
    let selectedItems = this.featureList.filter((x) => x?.isSelected).length;
    let unSelectedItems = this.featureList.filter((x) => !x?.isSelected).length;
    if (this.featureList?.length === selectedItems) {
      this.selectall = true;
    }
    if (this.featureList?.length >= unSelectedItems && unSelectedItems > 0) {
      this.selectall = false;
    }
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  saveProductFeatures() {
    // this.clearFormArray(this.featureForm)
   
    let selectedList: any = this.featureList?.filter(
      (feature: any) => feature?.isSelected
    );

    this.selectedFeatureList = selectedList;
    if(this.productForm.controls['is_extra'].value){
      if(selectedList?.length <= 0){
        this.toastr.error(
          this.translationService.instant(
            'addProduct.atleastOneExtra'
          )
        );
        return
      }
    }
    if (selectedList?.length > 0)
      for (var obj of selectedList) {
        // console.log("obj lopp",obj);
        if (obj.surcharge === 0) {
          // console.log("Inside if ",obj);
          // if(obj.control_type === 'dropDown' ){
          //   let mainForm = this.fb.group({
          //     featureId: [obj.id],
          //     featureName: [obj.label_name],
          //     featureControlType : [obj.control_type],
          //     controlValue: new FormArray([]),
          //     featureSurcharge: [obj.surcharge]
          //   });

          //   let controlValue = mainForm.get("controlValue") as FormArray;

          //     for(var val of obj.control_value.split(','))
          //     {
          //       if(val){
          //         let innerForm = this.fb.group({
          //           value: [val],
          //           price: ["0"]
          //         });
          //         controlValue.push(innerForm);
          //       }
          //     }

          //     this.featureForm.push(mainForm);

          // }

          // else {
          //   let mainForm = this.fb.group({
          //     featureId: [obj.id],
          //     featureName: [obj.label_name],
          //     featureControlType : [obj.control_type],
          //     controlValue: [obj.control_value],
          //     featureSurcharge: [obj.surcharge]
          //   });

          //   this.featureForm.push(mainForm);
          // }
          this.prepareInnerForm(obj);
        } else {
          // console.log("this.featureForm.value",this.featureForm.value);
          // debugger;
          let condition = false;
          // console.log("Inside else ",obj);
          if (this.featureForm.controls.length > 0) {
            this.featureForm.controls.forEach((element, index) => {
              // console.log("element",element.value);
              if (obj.id === element?.value?.featureId) {
                condition = true;
              }
            });
            if (!condition) {
              // console.log("YESSSSSSSSSSSSS obj added -->", obj.label_name)
              this.prepareInnerForm(obj);
            }
          } else {
            // console.log("NOOOOOOOOOOO")
            this.prepareInnerForm(obj);
          }
        }
      }

    // console.log("saveProductFeatures result ",JSON.stringify(this.featureForm.value));
    this.productForm.patchValue({
      products_feature: selectedList?.length > 0 ? this.featureForm.value : [],
    });
    // console.log("saveProductFeatures productForm result ",JSON.stringify(this.productForm.value));
    let payload = {
      products: this.productForm.value,
    };

    console.log('final output =>', JSON.stringify(this.productForm.value));
    // this.productForm.reset();
    this.addFeaturesToProduct(this.productForm.value);

    // this.router.navigate([APP_ROUTES.listProduct]).then(() => { });
  }

  gotoStep1(){
    this.step2 = true;
    this.step3 = false;
    setTimeout(() => {
      document.getElementById('productFormtop')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 300);
  }

  addFeaturesToProduct(payload?: any) {
    console.log('addFeaturesToProduct =>', JSON.stringify(payload));
    let imglist = this.productImageList.map((img: any) => (img.filename)).toString();
    let ids = this.category_ids_arr.map((item)=> item?.id);
    this.productForm.patchValue({ image: imglist, catalogue_id: ids || [] });
    let prod = {
        products: [],
      };
      let formObj;
    if (this.productForm.valid) {
      
      if (this.productForm.get('price').value === null) {
        this.productForm.get('price').setValue(0);
      }
      if (this.productForm.get('price_running_meter').value === null) {
        this.productForm.get('price_running_meter').setValue(0);
      }
      if (this.productForm.get('price_wall').value === null) {
        this.productForm.get('price_wall').setValue(0);
      }
      if (this.productForm.get('type').value === 'variant') {
        if (this.productForm.get('priceType').value !== 'price') {
          this.productForm.get('price').setValue(0);
        }
      }


//       var arr = [ {cat_id: 2},{cat_id: 3},{cat_id: 2},{cat_id: 2},{cat_id: 3},{cat_id: 2},{cat_id: 2},{cat_id: 4},{cat_id: 4} ];
// var map = arr.reduce(function(prev, cur) {
//   prev[cur['cat_id']] = (prev[cur['cat_id']] || 0) + 1;
//   return cur;
// }, {});

// // map is an associative array mapping the elements to their frequency:
// console.log(map);

console.log(payload?.products_feature, 'payload prod feature.');
      
      console.log(this.productForm.value, 'valloo');
      
      let catArr = [];
      let featArr = []
      this.productForm.value?.catalogue_id?.forEach(item=>{
        featArr = [];
        this.productForm.value?.products_feature?.forEach(feat=>{
          if(+item === +feat?.catalogue_id){
           featArr.push(feat)
           let catObj = {
            catalogue_id:item,
            products_feature:featArr
           }
           let itemEx = catArr.some((cati)=> +cati?.catalogue_id === +item);
           !itemEx &&  catArr.push(catObj)
          }
        })
        
      })
      let notIncludedArr = []
      
      let catArrIncludesId = catArr?.map(i => +i?.catalogue_id)
      const arr = this.productForm.value?.catalogue_id?.filter(i => !catArrIncludesId.includes(+i));
      arr?.forEach(item=>{
        let catObj = {
          catalogue_id:item,
          products_feature:[]
         }
         let itemEx = notIncludedArr?.some((cati)=> +cati?.catalogue_id === +item);
         !itemEx &&  notIncludedArr.push(catObj)
      })
      let payloadCatArr = JSON.parse(JSON.stringify(catArr))
      payloadCatArr?.forEach(cat=>{
        cat?.products_feature?.forEach(feat=>{
          delete feat?.catalogue_id
        })
      })

      payloadCatArr = [...payloadCatArr,...notIncludedArr]
      let data2 = this.productForm.value;
      formObj = {
        catalogues: payloadCatArr,
        description: data2?.description,
        image: data2?.image,
        is_extra: data2?.is_extra,
        price: data2?.price,
        priceType: data2?.priceType,
        price_running_meter: data2?.price_running_meter,
        price_wall: data2?.price_wall,
        product_id: data2?.product_id,
        product_name: data2?.product_name,
        quantity: data2?.quantity,
        tempPrice: data2?.tempPrice,
        type: data2?.type,
        floor: data2?.floor,
        wall:  data2?.wall,
        running_meter: data2?.running_meter
      };
      console.log(formObj, 'ffgotto');
      prod.products.push(this.productForm.value);
    }else{
      this.productForm.markAllAsTouched();
      return
    }
    // delete prod?.products[0]?.products_feature
    
    let CombinedPayload={
      ...prod,
      // products_feature:payload?.products_feature || [],
      // category_ids:this.prepareCatPayload.filter(item=> String(item?.id)) || []
    }
    this.service.addFeaturesToProduct(formObj).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          setTimeout(() => {
            // this.toastr.success('Product updated successfully.', '');
            this.toastr.success(
              this.translationService.instant(
                'addProduct.productUpdatedMsgTsFile'
              )
            );
          }, 1000);
          localStorage.removeItem('cat_ids')
          this.router.navigate([APP_ROUTES.listProduct]).then(() => { });
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  prepareInnerForm(obj: any) {
    console.log(obj, 'find cat id ...//');
    
    if (obj.control_type === 'dropDown') {
      let mainForm = this.fb.group({
        featureId: [obj.id],
        featureName: [obj.label_name],
        controlType: [obj.control_type],
        controlValue: new FormArray([]),
        featureSurcharge: [obj.surcharge],
        catalogue_id:[obj.catalogue_id]
      });

      let controlValue = mainForm.get('controlValue') as FormArray;

      // for (var val of obj.control_value.split(',')) {
      //   if (val) {
      //     let innerForm = this.fb.group({
      //       value: [val],
      //       price: ['0'],
      //     });
      let combinedarr = [];
      let arr = obj?.control_value?.split(',') || [];
      let arr1 =
        obj?.control_valueimg !== null ? obj?.control_valueimg?.split(',') : [];
      arr.forEach((element, i) => {
        combinedarr.push({ val: element, img: arr1[i] });
      });
      console.log(combinedarr);

      for (var val of combinedarr) {
        if (val) {
          let innerForm = this.fb.group({
            value: [val.val],
            price: ['0'],
            value_img: [val.img],
          });
          controlValue.push(innerForm);
        }
      }

      this.featureForm.push(mainForm);
    } else {
      let mainForm = this.fb.group({
        featureId: [obj.id],
        featureName: [obj.label_name],
        controlType: [obj.control_type],
        controlValue: [obj.control_value],
        price: [obj.price],
        featureSurcharge: [obj.surcharge],
        catalogue_id:[obj.catalogue_id]
      });

      this.featureForm.push(mainForm);
    }
  }

  editProduct() {
    console.log(
      'this.productForm.value.product_id',
      this.productForm.value.product_id
    );
    this.router
      .navigate([
        APP_ROUTES.listProduct,
        {
          queryParams: {
            pId: this.productForm.value.product_id,
          },
        },
      ])
      .then(() => { });
  }

  selectCat(nodeData: any, node:any) {
    console.log('NODE IAALLL ', node);
    console.log('NODE IS ', nodeData);
    this.productForm.patchValue({
      catalogue_id: nodeData.id,
      is_extra:false
    });
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      document.getElementById('productFormtop')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 300);
    this.selectedItems = [{id:nodeData.id,title:nodeData.title}]
    // this.processNestedArrays(this.nodes2,nodeData)
    this.getFeatureList(nodeData.id);
  }

  patchSelectedValue(){

  }

  getAllFeatureList(id: any) {
    let payload = {
      catalogues: id,
    };
  this.spinner.show()
    this.service.getAllListCatFeature(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.spinner.hide();
          this.featureList = [];
          this.selectall = false;
          // this.featureList = data.body.catFeatureList;
          data.body.catFeatureList?.forEach((item=>{
            item?.cat_feature?.forEach(feat=>{
              feat['cat_id'] = item?.cait_id;
              feat['cat_name'] = item?.cat_name || '';
              this.featureList.push(feat)
            })
          }))
          if(this.featureList.length > 0){
            this.productForm.controls['is_extra'].patchValue(true);
          }else{
            this.productForm.controls['is_extra'].patchValue(false);
          }
          this.featureList?.forEach((element) => {
            element.isSelected = false;
          });
          this.featureList.sort((a,b)=> a.label_name.localeCompare(b.label_name))
          
          console.log(this.featureList, 'bind featlist');
          
        } else {
          this.toastr.error(data.body.message, '');
          this.spinner.hide();
        }
      },
      (err) => {
        console.log('error', err);
        this.spinner.hide();
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }


  getFeatureList(id: any) {
    let payload = {
      catalogue_id: id,
    };

    this.categoryService.getFeatureList(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.featureList = data.body.catFeatureList;
          this.featureList?.forEach((element) => {
            element.isSelected = false;
          });
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  getPureString(obj: any) {
    if (obj) {
      let arr = obj.split(',');
      let arr2 = [];
      for (var val of arr) {
        if (val === '') {
          // console.log("Var is empty")
        } else {
          arr2.push(val);
        }
      }
      // console.log("arr2",arr2)
      return arr2;
    }
  }

  processNestedParent(data: any[]) {
    
    for (const item of data) {
      if(item)
      if (Array.isArray(item?.children)) {
        this.catListTemp.push(item);
        this.processNestedArrays(item?.children);
      }
    }
    return this.catListTemp
  }
  
  selectCategory(event: any) {
    console.log('selectCategory', event);
    let id;
    let features;
    for (var val of this.mainCategories) {
      if (event.target.value === val.cat_name) {
        id = val.id;
        features = val.catFeature;
      }
    }
    if (id && features) {
      console.log('id', id, 'features', features);

      this.productForm.patchValue({
        category: id,
      });
    }
  }
  uploadImage(event : any, index : any){

    console.log("-----------------Index------------- ",index)
    this.uploadImage2(event,index,true);
    
  }
  uploadImage2(event: any, index :any ,dimesnionCheck ?: Boolean): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        this.fileName = '';
        this.imageFormat = '';
        this.fileName = event.target.files[0]?.name;
        this.imageFormat = event.target.files[0]?.type;
        this.imageIndex= null;
        this.imageIndex = index;
        
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }

        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then(resp => {
        this.imageOrigin  = event.target.files[0]
        const isSizeExceed = event.target.files[0].size >= 5 * 1024 * 1024;
        this.isImageScaleDown = isSizeExceed ? true : false;
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
            img.onload = () => {
              this.imageWidth = isSizeExceed
                ? +img.width - (+img.width / 100) * 50
                : img.width;
              this.imageHeight = isSizeExceed
                ? +img.height - (+img.height / 100) * 50
                : img.height;
            };
        // this.imageChangedEvent=event;
        this.cd.detectChanges();
        this.open();
      })
      .catch(err => {
        this.toastr.error(err, '');
      });
  }
  open() {
    this.cd.detectChanges();
    this.modalService
    .open(this.cropModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
        );
      }
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }

hideSpinner(){
 
  setTimeout(()=>{this.spinner.hide()}, 500)
  
}
imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
  this.cd.detectChanges();
  setTimeout(()=>{this.spinner.hide()}, 500)
  this.hideSpinner();
  const imageBlob = this.dataURItoBlob(event.base64 || '');
    const imageFile = new File([imageBlob], this.fileName, {
      type: this.imageFormat || 'image/png',
    });
    this.croppedImage = imageFile;
    console.log(imageFile);
    console.log(
      `Cropped image size before compression: ${
        imageFile.size >= 1024 * 1024
          ? (imageFile.size / (1024 * 1024)).toFixed(2) + ' MB.'
          : (imageFile.size / 1024).toFixed(0) + ' KB.'
      }`
    );
    
    
}
dataURItoBlob(dataURI: string) {
  let url = dataURI.toString();
  const byteString = window.atob(
    url.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
  );
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], {
    type: this.imageFormat || 'image/png',
  });
  return blob;
}

      imageLoaded(image: LoadedImage) {
        // show cropper
        setTimeout(()=>{this.spinner.hide()}, 500)
      }
      
      imageCroppedStarted(event:any){
        setTimeout(() => {
          this.spinner.show();
        }, 50);
      }
      
      imageLoadFailed(event: any) {
        this.hideSpinner();
        this.closeModal();
        this.modalService.dismissAll();
        console.log('loading failed...');
        
      }
      closeModal(){
        this.hideSpinner();
        this.modalService.dismissAll();
        this.imageChangedEvent = null;
        this.croppedImage = null;
        var input:any = document.getElementsByTagName('input');
        for (let index = 0; index < input.length; index++) {
          const element = input[index];
          if(element.type === 'file'){
            element.value = '';
          }
         }
        return;
      }
      saveImage(modal?: any) {
        console.log(this.croppedImage,'cropped image');
        this.handleImageAPI(this.croppedImage,this.imageIndex);
      }

  // uploadImage(event: any): void {

  //   this.spinner.show();
  //   this.imgarray = [];
  //   this.selectedFiles = event.target.files;
    
  //   // if (this.selectedFiles.length > 6) {
  //     //   alert('please selct');
  //     //   return;
  //     // }
      
  //     const promise = new Promise((resolve, reject) => {
      
  //       if (this.selectedFiles && this.selectedFiles[0]) {
  //         const numberofselectedfiles = this.selectedFiles.length;
          
  //       // if (numberofselectedfiles < 7) {
  //       for (let i = 0; i < numberofselectedfiles; i++) {
  //         const contentType = this.selectedFiles[i].type;
  //         const size = this.selectedFiles[i].size;
  //         if (Number((size / 1048576).toFixed(1)) > this.imagesizelimit) {
  //           reject(this.translationService.instant('toastmsg.imagesizelimit', { value: this.imagesizelimit }));
  //           event.target.value = null;
  //           this.spinner.hide();
  //         }
  //         const allowedArr = ['png', 'jpeg', 'jpg'];

  //         const file = this.selectedFiles[i];

  //         const extension = file.name.split('.').pop();

  //         if (allowedArr.includes(extension)) {
  //           const reader = new FileReader();

  //           reader.onload = (e: any): void => {
  //             this.imgarray.push(e.target.result);
  //             // this.imgURL = reader.result;
  //             let payload = {
  //               file,
  //               contentType,
  //             };

  //             resolve(payload);
  //           };
  //           reader.readAsDataURL(this.selectedFiles[i]);
  //         } else {
  //           reject(this.translationService.instant('toastmsg.uploadvaliddoc'));
  //           this.spinner.hide();
  //           event.target.value = null;
  //         }
  //       }
  //       // } else {
  //       //   reject('Please select maximum 6 files');
  //       //   event.target.value = null;
  //       // }
  //     } else {
  //       reject(this.translationService.instant('toastmsg.selectfile'));
  //       this.spinner.hide();
  //       event.target.value = null;
  //     }
  //   });
  //   promise
  //     .then((resp) => {
  //       if (this.productImageList.length >= this.imageuploadlimit) {
  //         this.spinner.hide();
  //         event.target.value = null;
  //         return this.toastr.error(this.translationService.instant('toastmsg.imageuploadlimit', { value: this.imageuploadlimit }), '')
  //       }

  //       if (this.productImageList.length < this.imageuploadlimit + 1)
  //         this.handleImageAPI(resp, this.selectedFiles);
  //     })
  //     .catch((err) => {
  //       this.spinner.hide();
  //       this.toastr.error(err, '');
  //     });
  // }

  removeProductImage(num: number) {
    // this.imgarray.splice(num, 1);
    this.productImageList.splice(num, 1);
  }
  handleImageAPI(result: any, files: any): void {
  
    // this.imgarray = []
    let input = new FormData();
    input.append('id', result);
    // for (let file of files) {
    //   input.append('id', file);
    // }

    this.service
      .uploadmutipleImage(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          setTimeout(() => {
            
            this.spinner.hide();
          }, 500);

          this.imgarray = [];
          this.imageUrl = resp1.body.imageUrl;
          this.imgarray = resp1.body.uploadedfiles;
          if (this.productImageList.length >= this.imageuploadlimit)
            return this.toastr.error(this.translationService.instant('toastmsg.imageuploadlimit', { value: this.imageuploadlimit }), '')
          this.imgarray.forEach((item) => this.productImageList.length < this.imageuploadlimit && this.productImageList.push(item))
          // this.imageName = resp1.body.filename;
          this.productForm.patchValue({
            image: resp1.body.filename,
          });
          this.closeModal()
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(this.translationService.instant('toastmsg.somethingwrong'), '');
        }
      );
  }

  //   getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
  //     let hasError = false;
  //     const result = Object.keys(form.controls).reduce((acc, key) => {
  //         const control = form.get(key);
  //         const errors = (control instanceof FormGroup || control instanceof FormArray)
  //             ? this.getAllErrors(control)
  //             : control.errors;
  //         if (errors) {
  //             acc[key] = errors;
  //             hasError = true;
  //         }
  //         return acc;
  //     }, {} as { [key: string]: any; });
  //     return hasError ? result : null;
  // }

  cancel() {
    this.step2 = true;
    this.step1 = false;
    
    this.productForm.patchValue({
      catalogue_id: '',
      product_name: '',
      description: '',
      type: '',
      price: '',
      image: '',
      product_id: '',
      products_feature: '',
    });
    this.router
      .navigate([
        APP_ROUTES.listProduct
      ])
      .then(() => { });
  }

  closeInstructions() {
    this.showInstructions = false;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  hideShowQuantity(): void {
    if (this.productForm.get('type').value === 'flat') {
      this.productForm.get('quantity').setValue('');
      this.productForm
        .get('quantity')
        .setValidators([
          Validators.required,
          CustomValidators.digits,
          CustomValidators.gt(0),
        ]);
      this.productForm.get('quantity').updateValueAndValidity();
      if (this.productForm.get('price').value > 0) {
        this.productForm
          .get('tempPrice')
          .setValue(this.productForm.get('price').value);
      }
    } else if (this.productForm.get('type').value === 'variant') {
      this.productForm.get('quantity').setValue(0);
      this.productForm.get('quantity').setValidators([]);
      this.productForm.get('quantity').updateValueAndValidity();
    }
    this.updateProductValidation();
  }
}
