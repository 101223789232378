<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->
    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-5 col-12">
                <h1>{{'selectedMostOftenReport.mostOften' | translate}}</h1>
            </div>
                <div class="col-lg-7 col-12 d-lg-flex d-block justify-content-end" style="align-self: flex-end;">
                    
                        <form class="d-lg-flex d-block justify-content-end mr-lg-2">
                            <div class="form-group hidden mb-0 mb-lg-0 mb-2">
                              <div class="input-group">
                                <input name="datepicker" hidden
                                       class="form-control"
                                       ngbDatepicker
                                       #datepicker="ngbDatepicker"
                                       [autoClose]="'outside'"
                                       (dateSelect)="onDateSelection($event)"
                                       [displayMonths]="2"
                                       [dayTemplate]="t"
                                       outsideDays="hidden"
                                       [startDate]="fromDate!"
                                       tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                  <span class="custom-day"
                                        [class.focused]="focused"
                                        [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <div class="form-group mb-lg-0 mb-2">
                              <div class="input-group">
                                <input #dpFromDate disabled
                                       class="form-control" placeholder="dd/mm/yyyy"
                                       name="dpFromDate"
                                       [value]="format(fromDate)"
                                       (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar border-2" (click)="datepicker.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group ml-lg-2 mb-lg-0 mb-2">
                              <div class="input-group">
                                <input #dpToDate disabled
                                       class="form-control" placeholder="dd/mm/yyyy"
                                       name="dpToDate"
                                       [value]="format(toDate)"
                                       (input)="toDate = validateInput(toDate, dpToDate.value)">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar border-2" (click)="datepicker.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          

                        
                        
                        <div class="mr-lg-2 mb-lg-0 mb-2">
                          <div class="input-group ">
                              <input #searchVA type="text " class="form-control " placeholder="{{'customerProcessTimeReport.search' | translate}}" (keyup.enter)=search($event)>
                              <div class="input-group-append ">
                                  <span class="input-group-text f-700 "><i class="fa fa-search" (click)=search(searchVA.value,true) ></i></span>
                              </div>
                              <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                  <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                              </div>
                          </div>
                      </div>

                      <a href="javascript:; " class="btn btn-primary pl-3 pr-3" (click)="applyFilter()"> {{'customerProcessTimeReport.apply' | translate}}</a>
                    <button type="btn" class="text-danger no-border bg-transparent pl-3 pr-3" (click)="export()">
                        <i class="fa fa-download"></i>
                    </button>
                </div>
            

        </div>
    </section>

    <!-- <pre>From: {{ fromDate | json }} </pre>
    <pre>To: {{ toDate | json }} </pre> -->
    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span> {{'selectedMostOftenReport.mostOften' | translate}}</span>
            </div>
        </div>
        <div class="card-body table-responsive">
            <table class="table table-bordered table-striped">
                <thead class="table-dark ">
                    <tr>
                        <th style="width:70px ">{{'listFaq.srNo' | translate}}</th>
                        <th>{{'selectedMostOftenReport.product' | translate}}</th>
                        <th>{{'selectedMostOftenReport.standardPrice' | translate}}</th>
                        <!-- <th>Quantity Purchased</th> -->
                        <th>{{'selectedMostOftenReport.numberOfClientsPicked' | translate}}</th>
                        <th>{{'selectedMostOftenReport.lastSearchedOn' | translate}}</th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let obj of reportList; index as i">
                        <td>{{i+1}}</td>
                        <td>{{obj.product_name}}</td>
                        <td>{{obj.product_price | number: '1.2-2' :'de-DE'}}  <i class="fas fa-euro-sign f-11"></i>
                        </td>
                        <!-- <td>{{obj.total_prodcuts}}</td> -->
                        <td>{{obj.total_prodcuts}}</td>
                        <td>{{obj.updated_at.split('T')[0].split("-").reverse().join("-").replace('-','/').replace('-','/')}}</td>
                    </tr>

                </tbody>

            </table>
            <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
                <div class="col-md-12 text-right">
                    <div class="pagination justify-content-end">
                        <div class="page-item">
                            <pagination [boundaryLinks]="showBoundaryLinks"
                            [firstText]="firstText" [lastText]="lastText"
                    [previousText]="prevText" [nextText]="nextText"
                     [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                                [(ngModel)]="page" (pageChanged)="pageChanged($event)" [maxSize]="maxSize"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


