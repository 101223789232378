<!-- <div>
    <label for="">label_name</label>
    <textarea  #label_name>{{nodeObj.label_name}}</textarea>
</div>
<div>
    <label for="" >control_type</label>
    <textarea #control_type>{{nodeObj.control_type}}</textarea>
</div>
<div>
    <label for="">control_value</label>
    <textarea #control_value>{{nodeObj.control_value}}</textarea>
</div>
<div>
    <label for="">surcharge</label>
    <textarea #surcharge>{{nodeObj.surcharge}}</textarea>
</div>
<div>
    <label for="">Image</label>
    <input type="file" #myInput (change)="uploadImage($event)"><br><br>
</div> -->
<div class="card mb-0">
  <div class="card-header">
    <h3 class="card-title" *ngIf="!disableField">
      <!-- Edit custom field -->
      {{ "editFeature.editCustomField" | translate }}
    </h3>
    <h3 class="card-title" *ngIf="disableField">
      <!-- Edit custom field -->
      {{ "editFeature.viewCustomField" | translate }}


    </h3>
    <button (click)="closeModall()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="card-body">
    <form novalidate [formGroup]="controlTypeForm">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="">
              <!-- Field name -->
              {{ "addFeature.fieldName" | translate }}
            </label>


            <textarea type="text" [disabled]="disableField" class="form-control"
              formControlName="label_name"></textarea>

            <ng-container *ngIf="
                controlTypeForm.controls['label_name'].errors &&
                (controlTypeForm.get('label_name').dirty ||
                  controlTypeForm.get('label_name').touched)
              ">
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['label_name'].hasError('required')
                ">*
                <!-- Label name is required. -->
                {{ "addFeature.labelNameRequired" | translate }}</small>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['label_name'].hasError('maxlength')
                ">*
                <!-- Maximum of 30 characters. -->
                {{ "addFeature.charLimit30" | translate }}</small>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['label_name'].hasError('pattern')
                ">*
                <!-- No special character allowed. -->
                {{ "addFeature.specialCharMsg" | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="col-md-4">
          <div class="pl-4">
          <label for="">
            <!-- Control type -->
            {{ "addFeature.controlType" | translate }}</label>
          <br />
          <div class="form-check form-check-inline d-inline">
            <input [disabled]="disableField" type="radio" name="dropDown" id="dropDown" class="with-gap"
              (change)="changeControlType('dropDown')" [checked]="nodeObj.control_type === 'dropDown'" />
            <label class="form-check-label ml-1 cursor-pointer" for="dropDown">
              <!-- Dropdown -->
              {{ "addFeature.dropDown" | translate }}</label>
          </div>

          <div class="form-check form-check-inline d-inline">
            <input [disabled]="disableField" type="radio" name="input" id="input" class="with-gap"
              (change)="changeControlType('input')" [checked]="nodeObj.control_type === 'input'" />
            <label class="form-check-label ml-1 cursor-pointer" for="input">
              <!-- Input -->
              {{ "addFeature.Input" | translate }}</label>
          </div>

          <!--<input type="radio" name="dropDown" id="dropDown" class="with-gap"
                (change)="changeControlType('dropDown')" [checked]="nodeObj.control_type === 'dropDown'">Dropdown

                <input type="radio" name="input" id="input" class="with-gap"
                (change)="changeControlType('input')" [checked]="nodeObj.control_type === 'input'">Input-->
              </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <!-- <label for="">
              {{ "addFeature.featuretype" | translate }}</label
            >
            <div class="ft-wrapper">
              <div
                class="drop-feature"
                *ngFor="let opt of getPureStringArr(nodeObj); let id = index"
              >
                <img [src]="opt?.img" class="ft-img" *ngIf="opt?.img" />
                <span>{{ opt?.val }}</span>
              </div>
            </div> -->

            <div class="add-drop-down-list" [hidden]="
              controlTypeForm?.controls?.control_type?.value !== 'dropDown'
            ">
              <div class="addon-feature-lsiting">
                <div class="row" formArrayName="dropdownarray">
                  <ng-container *ngFor="let drop of getDropdownList.controls; let i = index">
                    <ng-container [formGroupName]="i">
                      <div class="col-md-4">
                        <label>
                          <!-- Control Value -->
                          {{ "addFeature.feature" | translate }}</label>
                        <input type="text" placeholder="{{ 'addFeature.featureenter' | translate }}"
                          class="form-control" formControlName="controlTypeVal" />
                        <ng-container *ngIf="
                          drop.controls['controlTypeVal'].errors &&
                          (drop.get('controlTypeVal').dirty ||
                            drop.get('controlTypeVal').touched)
                        ">
                          <small class="text-danger error-msg" *ngIf="
                            drop.controls['controlTypeVal'].hasError('required')
                          ">*
                            <!-- Control Value is
                                          required. -->
                            {{
                            "addFeature.controlValueRequired"
                            | translate
                            : {
                            value: ("addFeature.feature" | translate)
                            }
                            }}</small>
                          <small class="text-danger error-msg" *ngIf="
                            drop.controls['controlTypeVal'].hasError('maxlength')
                          ">*
                            <!-- Maximum of 30 characters. -->
                            {{ "addFeature.charLimit30" | translate }}</small>
                        </ng-container>
                        <small class="text-danger error-msg" *ngIf="
                          drop.controls['controlTypeVal'].hasError('pattern')
                        ">*
                          <!-- No special character allowed. -->
                          {{ "addFeature.specialCharMsg" | translate }}</small>
                      </div>
                      <div class="col-md-5">
                        <label>{{'addFeature.uploadImage' | translate}} <small style="font-size: 11px;">(support png, jpeg,
                          jpg)</small></label>
                        <input type="file" #myInput class="form-control" formControlName="controlTypeImg"
                          (change)="uploadImage($event, i, drop)" />
                          <span class="d-block text-right"></span>
                      </div>
                      <div class="col-md-2" [hidden]="!drop.controls?.controlTypeImgUpload?.value">
                        <img style="object-fit: contain;border: 1px solid #eaeaea;margin: 0 auto;height: 90px;border-radius: 4px;display: block;" class="iluid my-1" [src]="imgBaseUrl + drop.controls?.controlTypeImgUpload?.value"
                          width="120px" height="120px">
                      </div>
                      <div class="col-md-1 text-right" *ngIf="!this.disableField">
                        <label class="d-block">&nbsp;</label>
                        <button type="button" class="removeitem" (click)="removeField(i)">
                          <img src="assets/img/cros.png" />
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div> 
              <div class="row" *ngIf="!this.disableField">
                <div class="col-md-8">
                  <button style="background: #f2f2f2;" class="btn btn-primary-new mt-3" (click)="addNewFields()">
                    <img class="mr-2" src="assets/img/plus.png" />
                    {{ "addFeature.addfeaturetype" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <textarea
              [attr.disabled]="disableField ? true : null"
              formControlName="control_value"
              class="form-control"
              >{{ nodeObj.control_value }}</textarea
            > -->

            <ng-container *ngIf="
                controlTypeForm.controls['control_value'].errors &&
                (controlTypeForm.get('control_value').dirty ||
                  controlTypeForm.get('control_value').touched)
              ">
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['control_value'].hasError('required')
                ">*
                <!-- Label name is
                                    required. -->
                {{ "addFeature.labelNameRequired" | translate }}</small>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['control_value'].hasError(
                    'maxlength'
                  )
                ">*
                <!-- Maximum of 30 characters. -->
                {{ "addFeature.charLimit30" | translate }}</small>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['control_value'].hasError('pattern')
                ">*
                <!-- No special character allowed. -->
                {{ "addFeature.specialCharMsg" | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group pl-4">
            <label for="">
              <!-- Surcharge -->
              {{ "addFeature.surcharge" | translate }}</label>
            <br />
            <!-- <input type="checkbox" [checked]="nodeObj.surcharge === 1" (change)="nodeObj.surcharge = 1" /> True
                <input type="checkbox" [checked]="nodeObj.surcharge === 0" (change)="nodeObj.surcharge = 0" /> False -->

            <div class="form-check form-check-inline d-inline">
              <input [disabled]="disableField" type="radio" class="with-gap" id="true-radio"
                (change)="changeSurgeValue(1)" [checked]="nodeObj.surcharge === 1" />
              <label class="form-check-label ml-1 cursor-pointer" for="true-radio">
                <!-- True -->
                {{ "editFeature.Withsurcharge" | translate }}</label
              >
            </div>

            <div class="form-check form-check-inline d-inline">
              <input [disabled]="disableField" type="radio" class="with-gap" id="false-radio"
                (change)="changeSurgeValue(0)" [checked]="nodeObj.surcharge === 0" />
              <label class="form-check-label ml-1 cursor-pointer" for="false-radio">
                <!-- False -->
                {{ "editFeature.Withoutsurcharge" | translate }}</label
              >
            </div>

            <!--<input type="radio" class="with-gap"
                (change)="changeSurgeValue(1)" [checked]="nodeObj.surcharge === 1">True

                <input type="radio" class="with-gap"
                (change)="changeSurgeValue(0)" [checked]="nodeObj.surcharge === 0">False-->
          </div>
        </div>
        <div class="col-md-6" [hidden]="
        controlTypeForm?.controls?.control_type?.value === 'dropDown'
      ">
          <div class="form-group">
            <label>
              <!-- Control Value -->
              {{ "addFeature.feature" | translate }}</label>
              <div>
                <input type="text" placeholder="{{ 'addFeature.featureenter' | translate }}"
                class="form-control h-auto mb-2" formControlName="control_value" />
                <ng-container *ngIf="
                controlTypeForm.controls['control_value'].errors &&
                (controlTypeForm.get('control_value').dirty ||
                  controlTypeForm.get('control_value').touched)
              ">
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['control_value'].hasError(
                    'required'
                  )
                ">*
                <!-- Control Value is
                                  required. -->
                {{
                "addFeature.controlValueRequired"
                | translate
                : {
                value: ("addFeature.feature" | translate)
                }
                }}</small>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['control_value'].hasError(
                    'maxlength'
                  )
                ">*
                <!-- Maximum of 30 characters. -->
                {{ "addFeature.charLimit30" | translate }}</small>
            </ng-container>
              </div>
              
            <label for="">
              <!-- Image -->
              {{ "addFeature.Image" | translate }}
            </label>
            <input [disabled]="disableField" type="file" class="form-control h-auto" id="myfile" name="myfile"
              (change)="uploadImage($event, null)" /><br /><br />

            <div *ngIf="controlTypeForm?.controls?.image?.value" class="thumb-image mx-2"><img
                [src]="imgBaseUrl + controlTypeForm?.controls?.image?.value" /></div>
            <div *ngIf="!controlTypeForm?.controls?.image?.value" class="thumb-image mx-2">{{'editFeature.noImage'|translate}}</div>
          </div>
        </div>

        <!-- <div class="col-md-6">
          <div class="form-group">
            <label>
              {{ "editFeature.currentImage" | translate }}</label
            >
            <ng-container *ngIf="prevImgPresent; then prevImg; else newImg">
            </ng-container>

            <ng-template #prevImg>
              <div class="thumb-image mx-2"><img [src]="nodeObj.image" /></div>
            </ng-template>
            <ng-template #newImg>
              <div *ngIf="imageUrl" class="thumb-image mx-2">
                <img [src]="imageUrl" />
              </div>
              <div *ngIf="!imageUrl" class="thumb-image mx-2">
                {{ "addFeature.noImage" | translate }}
              </div>
            </ng-template>
          </div>
        </div> -->
      </div>

      <p class="w-100 text-center">
        <button *ngIf="!disableField" (click)="editFeature()" class="btn btn-primary"
          [disabled]="!controlTypeForm.valid">
          <!-- Save
                Feature -->
          {{ "editFeature.saveFeature" | translate }}
        </button>
      </p>
    </form>
  </div>



  <!-- {{getAllErrors(controlTypeForm) | json}} -->
</div>