
 <!-- <div class="content-wrapper card-body">

    <section class="content">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Title</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
              <i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
              <i class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="card-body">
          Start creating your amazing application!
        </div>
        <div class="card-footer">
          Footer
        </div>
      </div>
    </section>
  </div> -->

  <div class="content-wrapper card-body">

    <section  class="content-header p-0 mb-3">
        <div class="row mb-2 d-flex align-items-center">
            <div class="col-lg-5 col-12">
                <h1>{{'dashboard.dashboard' | translate}}</h1>
            </div>

            <!-- <div class="col-lg-7 text-right pr-3">
                <p class="mb-0 theme-color">
                    <strong>
                        {{'dashboard.disclaimer' | translate}}
                    </strong>
                    {{ 'dashboard.disclaimerMessage' | translate }}        
                </p>
            </div> -->
        </div>
    </section>

    <section class="content">
        <div class="row">
            <div class="col-md-6 pl-md-0">
                <div class="card card-primary">
                    <div class="card-header">
                        <div class="w-100  d-flex justify-content-between">
                            <span>{{'dashboard.newClients' | translate}}</span>
                        <a href="javascript" [routerLink]="['../' + APP_ROUTES.listClient]"> <span >{{'dashboard.viewAll' | translate}}</span></a>
                        </div>
                    </div>
    
                    <div class="card-body table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 100px;">{{'listFaq.srNo' | translate}}</th>
                                    <th>{{'dashboard.name' | translate}}</th>
                                    <th>{{'dashboard.projectName' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of clientStats; index as i">
                                    <td class="align-middle">
                                        {{ i + 1}}
                                    </td>
                                    <td class="align-middle">
                                        <div class="form-check pl-0">
                                            <label class="form-check-label" for="exampleCheck1">{{obj?.first_name}} {{obj?.last_name}} </label>
                                        </div>
                                    </td>
                                    <td class="align-middle text-right">
                                        <div class="form-check pl-0">
                                            <label class="form-check-label">{{obj?.ClientProjects[0]?.Project?.name}} </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="card card-primary">
                    <div class="card-header">
                        <div class="w-100  d-flex justify-content-between">
                            <span> {{'dashboard.existingProject' | translate}}</span>
                            <a href="javascript" [routerLink]="['../' + APP_ROUTES.listProject]"><span >{{'dashboard.viewAll' | translate}}</span></a>
                        </div>
        
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead class="thead-dark">
                                <tr>
                                    <th style="width: 100px;">{{'dashboard.sNo' | translate}}</th>
                                    <th>{{'dashboard.projectName' | translate}}</th>
                                    <th>{{'dashboard.projectLocation' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of projectStats; index as i">
                                    <td class="align-middle">
                                        {{ i + 1}}
                                    </td>
                                    <td class="align-middle">
                                        <div class="form-check pl-0">
                                            <label class="form-check-label" for="exampleCheck1">{{obj.name}}</label>
                                        </div>
                                    </td>
                                    <td class="align-middle text-right">
                                        <div class="form-check pl-0">
                                            <label class="form-check-label">{{obj.location}} </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
            </div>
           
        </div>
    </section>
</div>