import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) { }

  public getFaqList(): Observable<any> {
    const url: string = ApiRoutes.getFaqList;
    return this.http.get(url, { observe: 'response' });
  }

  public addFaq(payload : any): Observable<any> {
    const url: string = ApiRoutes.addFaq;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public deleteFaq(payload : any): Observable<any> {
    const url: string = ApiRoutes.deleteFaq;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public enableDisableFaq(payload : any): Observable<any> {
    const url: string = ApiRoutes.enableDisableFaq;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateFaq(payload : any): Observable<any> {
    const url: string = ApiRoutes.updateFaq;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public orderFaq(payload : any): Observable<any> {
    const url: string = ApiRoutes.orderFaq;
    return this.http.post(url, payload, { observe: 'response' });
  }

}
