import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserGuard implements CanActivate {

  constructor(private router: Router, private location: Location) { }
  //   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //     if (localStorage.getItem('token') ) {
  //         this.goBack();
  //       return true
  //     } else {
  //     //   this.router.navigate(['/login']);
  //       return false;
  //     }
  //   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') && localStorage.getItem('currentUser')) {
      console.log("Inside in gaurd");
      this.router.navigate(["/dashboard"]); // or home
    }
    return true;
  }

  goBack() {
    this.location.back();
  }
}