import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {

  popoverMessage = 'Do you want to delete it?';
  confirmText="Confirm";
  cancelText="Cancel";

  constructor(public activeModal: NgbActiveModal,
    private baseService : BaseService) { }

  ngOnInit(): void {
    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverMessage="Do you want to delete it?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";
      }
      else{
        this.popoverMessage="Möchten Sie es löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Abbrechen";
      }
    });
  }

  passBack(result : Boolean) {
    this.activeModal.close(result);
    }

}
