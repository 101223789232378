import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) { }
  imageuploadlimit = 6;
  imagesizelimit = 5;
  public uploadImage(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadImage;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public uploadmutipleImage(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadmutipleImage;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getMainCategories(): Observable<any> {
    const url: string = ApiRoutes.getMainCategoryList;
    return this.http.get(url, { observe: 'response' });
  }

  public addProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addFeaturesToProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.addFeaturesToProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateFeaturesToProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateFeaturesToProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public deleteProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public copyProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.copyProduct;
    return this.http.post(url,payload, { observe: 'response' });
  }
  public updateProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateProductFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateProductFeature;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getAllListCatFeature(payload: any): Observable<any> {
    const url: string = ApiRoutes.allListcat_feature;
    return this.http.post(url, payload, { observe: 'response' });
  }

   
}
