import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrivacyServiceService } from '../privacy-service.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  form: FormGroup;
  pageContent: any;
  
  constructor(  private fb: FormBuilder,public privacy:PrivacyServiceService,private toastr: ToastrService,private translationService: TranslateService) {
    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.required]],
      lang: ['gm', [Validators.required]],
      type:'imprint'
    });
   }

   editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '10',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ['toggleEditorMode', 'insertVideo']
    ],
   }  ; 

  ngOnInit(): void {
    this.languageChange('gm')
  }
  languageChange(lang): void {

    if(lang=='gm'){
      const payload={ lang:'gm',
      type:'imprint'}
      this.getPrivacy(payload);
    }
    if(lang=='en'){
      const payload={ lang:'en',
      type:'imprint'}
      this.getPrivacy(payload);
    }
  }
  getPrivacy(payload){
    this.privacy.getPrivacy(payload).subscribe((resp:any)=>{
      this.pageContent= resp.body.content;
      this.form.patchValue({
        title: this.pageContent.title,
        description: this.pageContent.description,
      });
    });
  }

  submit(): void {
    const payload=this.form.value
    if(this.form.valid){
      this.privacy.addPrivacy(payload).subscribe((res:any)=>{
        this.languageChange(payload.lang)
        this.toastr.success(
          this.translationService.instant('privacy.addedsucess'),
          '',
          { timeOut: 3000 }
        );
       }) 
    }
  }
}
