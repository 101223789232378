<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body" style="min-height: 1416.81px">
  <!-- Content Header (Page header) -->
  <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-">
                    <ol class="breadcrumb float-sm-right">
                       <li class="breadcrumb-item"><a href="javascript:;" [routerLink]="['../' + APP_ROUTES.dashboard]">Dashboard</a></li>
                        <li class="breadcrumb-item active">Add Product</li>
                    </ol>
                </div>

            </div>
        </div>
        /.container-fluid
    </section> -->

  <!-- <div class="row">
        <div class="col">
            <h3>Add Product</h3>

        </div>
    </div> -->
  <div class="card card-primary">
    <div class="card-header" >
      <div class="w-100 d-flex justify-content-between">
        <span>
          <!-- Add Product -->
          {{ "addProduct.addProduct" | translate }}
        </span>
      </div>
    </div>
    <div *ngIf="!step3">
      <div class="card-body row ">
        <div class="col-md-3" [class.disabled]="step2" *ngIf="!step3" style="opacity: 0;">
         
        </div>

        <div class="col-md-12">
          <form novalidate [formGroup]="productForm" [hidden]="!step2" id="productFormtop">
            <div class="card card-dark shadow-box-lg">
              <div class="card-header header-primary">
                <!-- Add Product Details -->
                {{ "addProduct.addProductDetails" | translate }}
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group w-100">
                      <label for="exampleInputEmail1">
                        <!-- Product Name -->
                        {{ "addProduct.producName" | translate }}
                        <span class="text-danger">*</span>
                      </label>

                      <input type="text" class="form-control" placeholder=" {{
                          'addProduct.enterProductName' | translate
                        }}" formControlName="product_name" />

                      <ng-container *ngIf="
                          productForm.controls['product_name'].errors &&
                          (productForm.get('product_name').dirty ||
                            productForm.get('product_name').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['product_name'].hasError(
                              'required'
                            )
                          ">*
                          <!-- Label name is
                                    required. -->
                          {{
                          "addFeature.labelNameRequired" | translate
                          }}</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['product_name'].hasError(
                              'maxlength'
                            )
                          ">*
                          <!-- Maximum of 30
                                    characters. -->
                          {{ "menu.charLimit80" | translate }}</small>
                      </ng-container>
                      <small class="text-danger error-msg" *ngIf="
                          productForm.controls['product_name'].hasError(
                            'pattern'
                          )
                        ">*
                        <!-- No
                                special character allowed. -->
                        {{ "addFeature.specialCharMsg" | translate }}</small>
                      <!-- <small class="text-danger error-msg"
                                            *ngIf="productForm.controls['product_name'].hasError('whitespace')">*Whitespace not allowed.</small> -->
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="productForm.controls['type'].value != 'variant'">
                    <div class="form-group w-100">
                      <label for="exampleInputEmail1">
                        <!-- Price
                                            priceFloor
                                            -->
                        {{ "addProduct.price" | translate }}
                        <span class="text-danger">*</span>
                      </label>
                      <!-- {{productForm.value.price | json}} -->
                      <input type="text" ngInputNumberI18n=".2-2" class="form-control" placeholder=" {{'addProduct.enterPrice' | translate}}"
                        formControlName="price" />

                      <ng-container *ngIf="
                          productForm.controls['price'].errors &&
                          (productForm.get('price').dirty ||
                            productForm.get('price').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price'].hasError('required')
                          ">*
                          <!-- Label name is
                                            required -->
                          {{
                          "addFeature.labelNameRequired" | translate
                          }}.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['price'].hasError('maxlength')
                          ">*
                          <!-- Maximum of 10 characters -->
                          {{ "addProduct.charLimit10" | translate }}.</small>
                      </ng-container>
                      <small class="text-danger error-msg" *ngIf="
                          productForm.controls['price'].hasError('pattern')
                        ">*
                        <!-- No special character allowed. -->
                        {{ "addFeature.specialCharMsg" | translate }}
                      </small>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group w-100">
                      <label for="exampleInputEmail1">
                        <!-- Description -->
                        {{ "addProject.description" | translate }}
                        <span class="text-danger">*</span>
                      </label>

                      <textarea type="text" rows="3" class="form-control" placeholder=" {{'editProduct.enterDesc' | translate}}"
                        formControlName="description" ></textarea>

                      <ng-container *ngIf="
                          productForm.controls['description'].errors &&
                          (productForm.get('description').dirty ||
                            productForm.get('description').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['description'].hasError(
                              'required'
                            )
                          ">*
                          <!-- Description is
                                  required -->
                          {{
                          "addProject.descriptionRequired" | translate
                          }}.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['description'].hasError(
                              'maxlength'
                            )
                          ">*
                          <!-- Maximum of 300 characters -->
                          {{ "addClient.charLimit300" | translate }}.</small>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group w-100">
                      <label for="exampleInputEmail1">
                        {{ "addProduct.type" | translate }}
                        <span class="text-danger">*</span>
                      </label>
                      <select (change)="hideShowQuantity()" formControlName="type" class="form-control">
                        <option hidden value="" disabled selected>
                        {{'addProduct.choosetype' | translate}}
                        </option>
                        <option value="flat"> {{'listProduct.pieces' | translate}}</option>
                        <option value="variant">{{'listProduct.surface' | translate}}</option>
                      </select>

                      <ng-container *ngIf="
                          productForm.controls['type'].errors &&
                          (productForm.get('type').dirty ||
                            productForm.get('type').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['type'].hasError('required')
                          ">
                          {{ "addProject.typeRequiredError" | translate }}
                        </small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['type'].hasError('maxlength')
                          ">*{{ "addClient.charLimit300" | translate }}.</small>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-md-6" *ngIf="productForm.controls['type'].value === 'flat'">
                    <div class="form-group w-100">
                      <label for="exampleInputEmail1">{{'addProduct.quantity' | translate}}
                        <span class="text-danger">*</span>
                      </label>
                      <input type="text" class="form-control" placeholder="{{'addProduct.enterquantity' | translate}}"
                        formControlName="quantity" />
                      <ng-container *ngIf="
                          productForm.controls['quantity'].errors &&
                          (productForm.get('quantity').dirty ||
                            productForm.get('quantity').touched)
                        ">
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['quantity'].hasError(
                              'required'
                            )
                          ">*Quanitity is required.</small>
                        <small class="text-danger error-msg" *ngIf="
                            productForm.controls['quantity'].hasError('digits')
                          ">*Quanitity should be contain digits.</small>
                        <small class="text-danger error-msg" *ngIf="
                            !productForm.controls['quantity'].hasError(
                              'digits'
                            ) && productForm.controls['quantity'].hasError('gt')
                          ">*Quanitity should greater than 0.</small>
                      </ng-container>
                    </div>
                  </div>
                  <!-- <div
                    class="col-md-12 row no-gutter"
                    *ngIf="productForm.controls['type'].value === 'variant'"
                  >
                    <div class="col-md-4">
                      <div class="form-group w-100">
                        <label for="exampleInputEmail1">
                         
                          {{ "addProduct.priceFloor" | translate }}
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          ngInputNumberI18n=".2-2"
                          class="form-control"
                          placeholder="Enter Price"
                          formControlName="price"
                        />

                        <ng-container
                          *ngIf="
                            productForm.controls['price'].errors &&
                            (productForm.get('price').dirty ||
                              productForm.get('price').touched)
                          "
                        >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls['price'].hasError('required')
                            "
                            >*
                           
                            {{
                              "addFeature.labelNameRequired" | translate
                            }}.</small
                          >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls['price'].hasError(
                                'maxlength'
                              )
                            "
                            >*
                            {{ "addProduct.charLimit10" | translate }}.</small
                          >
                        </ng-container>
                        <small
                          class="text-danger error-msg"
                          *ngIf="
                            productForm.controls['price'].hasError('pattern')
                          "
                          >*
                          {{ "addFeature.specialCharMsg" | translate }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group w-100">
                        <label for="exampleInputEmail1">
                        
                          {{ "addProduct.priceWall" | translate }}
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          ngInputNumberI18n=".2-2"
                          class="form-control"
                          placeholder="Enter Price"
                          formControlName="price_wall"
                        />

                        <ng-container
                          *ngIf="
                            productForm.controls['price_wall'].errors &&
                            (productForm.get('price_wall').dirty ||
                              productForm.get('price_wall').touched)
                          "
                        >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls['price_wall'].hasError(
                                'required'
                              )
                            "
                            >*
                            
                            {{
                              "addFeature.labelNameRequired" | translate
                            }}.</small
                          >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls['price_wall'].hasError(
                                'maxlength'
                              )
                            "
                            >*
                            {{ "addProduct.charLimit10" | translate }}.</small
                          >
                        </ng-container>
                        <small
                          class="text-danger error-msg"
                          *ngIf="
                            productForm.controls['price_wall'].hasError(
                              'pattern'
                            )
                          "
                          >*
                          {{ "addFeature.specialCharMsg" | translate }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group w-100">
                        <label for="exampleInputEmail1">
                         
                          {{ "addProduct.runningMeter" | translate }}
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          ngInputNumberI18n=".2-2"
                          class="form-control"
                          placeholder="Enter Price"
                          formControlName="price_running_meter"
                        />

                        <ng-container
                          *ngIf="
                            productForm.controls['price_running_meter']
                              .errors &&
                            (productForm.get('price_running_meter').dirty ||
                              productForm.get('price_running_meter').touched)
                          "
                        >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls[
                                'price_running_meter'
                              ].hasError('required')
                            "
                            >*
                          
                            {{
                              "addFeature.labelNameRequired" | translate
                            }}.</small
                          >
                          <small
                            class="text-danger error-msg"
                            *ngIf="
                              productForm.controls[
                                'price_running_meter'
                              ].hasError('maxlength')
                            "
                            >*
                            {{ "addProduct.charLimit10" | translate }}.</small
                          >
                        </ng-container>
                        <small
                          class="text-danger error-msg"
                          *ngIf="
                            productForm.controls[
                              'price_running_meter'
                            ].hasError('pattern')
                          "
                          >*
                          {{ "addFeature.specialCharMsg" | translate }}
                        </small>
                      </div>
                    </div>
                  </div> -->

                  <!-- Surface Types -->

                  <div class="check col-md-12 row no-gutter" *ngIf="productForm.controls['type'].value === 'variant'">
                    <div class="select-surface-type col-md-12">
                      <div class="row my-2">
                        <div class="col-md-6">
                          <div class="radio-input">
                            <input type="radio" id="vl1" value="price" formControlName="priceType" ng-model="priceType"
                              name="priceType" (input)="checkPriceValue($event.target.value)" />
                            <span class="checkbox">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                                <path id="check-lg_white" data-name="check-lg white"
                                  d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                                  transform="translate(-3.001 -3.75)" fill="#fff" />
                              </svg>
                            </span>
                            <h5>{{ "addProduct.priceFloor" | translate }}</h5>
                          </div>
                        </div>
                        <div class="col-md-6" [hidden]="
                            productForm.controls['priceType'].value !== 'price'
                          ">
                          <div class="show-input">
                            <span class="currency">€</span>
                            <input type="text" ngInputNumberI18n=".2-2" class="form-control" placeholder="{{'addProduct.enterPrice' | translate}}"
                              formControlName="price" />
                            <ng-container *ngIf="
                                productForm.controls['price'].errors &&
                                (productForm.get('price').dirty ||
                                  productForm.get('price').touched)
                              ">
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls['price'].hasError(
                                    'required'
                                  )
                                ">*
                                <!-- Label name is
                        required -->
                                {{ "addProduct.pricereq" | translate }}.</small>
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls['price'].hasError(
                                    'maxlength'
                                  )
                                ">*
                                <!-- Maximum of 10 characters -->
                                {{
                                "addProduct.charLimit10" | translate
                                }}.</small>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-md-6">
                          <div class="radio-input">
                            <input type="radio" id="vl2" value="pricewall" formControlName="priceType"
                              ng-model="priceType" name="priceType" (input)="checkPriceValue($event.target.value)" />
                            <span class="checkbox">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                                <path id="check-lg_white" data-name="check-lg white"
                                  d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                                  transform="translate(-3.001 -3.75)" fill="#fff" />
                              </svg>
                            </span>
                            <h5>{{ "addProduct.priceWall" | translate }}</h5>
                          </div>
                        </div>
                        <div class="col-md-6" [hidden]="
                            productForm.controls['priceType'].value !==
                            'pricewall'
                          ">
                          <div class="show-input">
                            <span class="currency">€</span>
                            <input type="text" ngInputNumberI18n=".2-2" class="form-control" placeholder="{{'addProduct.enterPrice' | translate}}"
                              formControlName="price_wall" />

                            <ng-container *ngIf="
                                productForm.controls['price_wall'].errors &&
                                (productForm.get('price_wall').dirty ||
                                  productForm.get('price_wall').touched)
                              ">
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls['price_wall'].hasError(
                                    'required'
                                  )
                                ">*
                                <!-- Label name is
                       required -->
                                {{ "addProduct.pricereq" | translate }}.</small>
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls['price_wall'].hasError(
                                    'maxlength'
                                  )
                                ">*
                                <!-- Maximum of 10 characters -->
                                {{
                                "addProduct.charLimit10" | translate
                                }}.</small>
                            </ng-container>
                            <small class="text-danger error-msg" *ngIf="
                                productForm.controls['price_wall'].hasError(
                                  'pattern'
                                )
                              ">*
                              <!-- No special character allowed. -->
                              {{ "addFeature.specialCharMsg" | translate }}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-md-6">
                          <div class="radio-input">
                            <input type="radio" ng-model="priceType" name="priceType" id="vl3" value="priceruning"
                              formControlName="priceType" (input)="checkPriceValue($event.target.value)" />
                            <span class="checkbox">
                              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                                <path id="check-lg_white" data-name="check-lg white"
                                  d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                                  transform="translate(-3.001 -3.75)" fill="#fff" />
                              </svg>
                            </span>
                            <h5>{{ "addProduct.runningMeter" | translate }}</h5>
                          </div>
                        </div>
                        <div class="col-md-6" [hidden]="
                            productForm.controls['priceType'].value !==
                            'priceruning'
                          ">
                          <div class="show-input" ng-show="content == 'value3'">
                            <span class="currency">€</span>
                            <input type="text" class="form-control" placeholder="{{'addProduct.enterPrice' | translate}}"
                              formControlName="price_running_meter" />

                            <ng-container *ngIf="
                                productForm.controls['price_running_meter']
                                  .errors &&
                                (productForm.get('price_running_meter').dirty ||
                                  productForm.get('price_running_meter')
                                    .touched)
                              ">
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls[
                                    'price_running_meter'
                                  ].hasError('required')
                                ">*
                                <!-- Label name is
                       required -->
                                {{ "addProduct.pricereq" | translate }}.</small>
                              <small class="text-danger error-msg" *ngIf="
                                  productForm.controls[
                                    'price_running_meter'
                                  ].hasError('maxlength')
                                ">*
                                <!-- Maximum of 10 characters -->
                                {{
                                "addProduct.charLimit10" | translate
                                }}.</small>
                            </ng-container>
                            <small class="text-danger error-msg" *ngIf="
                                productForm.controls[
                                  'price_running_meter'
                                ].hasError('pattern')
                              ">*
                              <!-- No special character allowed. -->
                              {{ "addFeature.specialCharMsg" | translate }}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-4 "> 
        <div class="form-group w-100">
            <label for="exampleInputEmail1">
                    {{'addProduct.priceFloor' | translate}}
                    <span class="text-danger">*</span>
            </label>
           <input type="text"  ngInputNumberI18n=".2-2" class="form-control" placeholder="Enter Price" formControlName="price" />
            <ng-container *ngIf="productForm.controls['price'].errors &&
          (productForm.get('price').dirty || productForm.get('price').touched)">
                <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('required')">*
                    
                {{'addFeature.labelNameRequired' | translate}}.</small>
                <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('maxlength')">*
                  
                    {{'addProduct.charLimit10' | translate}}.</small>
            </ng-container>
            <small class="text-danger error-msg" *ngIf="productForm.controls['price'].hasError('pattern')">*
                
              {{'addFeature.specialCharMsg' | translate}}
             </small>
        </div>
    </div> -->
                    <!-- <div class="col-md-4">
        <div class="form-group w-100">
            <label for="exampleInputEmail1">
               
                    {{'addProduct.priceWall' | translate}}
            <span class="text-danger">*</span>
            </label>
        
            <input type="text" 
             ngInputNumberI18n=".2-2" 
             class="form-control" 
             placeholder="Enter Price" 
             formControlName="price_wall" />

            <ng-container *ngIf="productForm.controls['price_wall'].errors &&
          (productForm.get('price_wall').dirty || productForm.get('price_wall').touched)">
                <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('required')">*
                  
                {{'addFeature.labelNameRequired' | translate}}.</small>
                <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('maxlength')">*
                    
                    {{'addProduct.charLimit10' | translate}}.</small>
            </ng-container>
            <small class="text-danger error-msg" *ngIf="productForm.controls['price_wall'].hasError('pattern')">*
                
              {{'addFeature.specialCharMsg' | translate}}
             </small>
        </div>
    </div> -->
                    <!-- <div class="col-md-4">
        <div class="form-group w-100">
            <label for="exampleInputEmail1">
               
                    {{'addProduct.runningMeter' | translate}}
            <span class="text-danger">*</span>
            </label>
        
            <input type="text" 
             ngInputNumberI18n=".2-2" 
             class="form-control" 
             placeholder="Enter Price" 
             formControlName="price_running_meter" />

            <ng-container *ngIf="productForm.controls['price_running_meter'].errors &&
          (productForm.get('price_running_meter').dirty || productForm.get('price_running_meter').touched)">
                <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('required')">*
                   
                {{'addFeature.labelNameRequired' | translate}}.</small>
                <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('maxlength')">*
                    
                    {{'addProduct.charLimit10' | translate}}.</small>
            </ng-container>
            <small class="text-danger error-msg" *ngIf="productForm.controls['price_running_meter'].hasError('pattern')">*
                
              {{'addFeature.specialCharMsg' | translate}}
             </small>
        </div>
    </div> -->
                  </div>
                  <!-- end Surface Types -->

               
                <div class="w-100"></div>
                  <div class="col-md-6 justify-content-center align-self-center">

                    <div class="d-flex w-100">
                      <button type="button" [title]="'addProduct.browseCat' | translate " class="btn btn-default mr-2" (click)="openCatModal()">
                        {{ "addProduct.chooseCategory" | translate }}
                        <span class="ml-2">
                        <i class='fa fa-folder-open'></i>
                        </span>
                      </button>
                      
                     
                    </div>
                    <!-- <small class="text-danger error-msg" *ngIf="category_ids_arr.length === 0 && isModalOpened">
                      *Category is required.
                    </small> -->
                    <small class="text-danger error-msg" *ngIf="
                                  productForm.controls[
                                    'catalogue_id'
                                  ].hasError('required')
                                && isModalOpened">
                         
                       *{{ "addProduct.catReq" | translate }}.</small>
                  </div>

                  <div class="col-md-6 justify-content-center align-self-center" *ngIf="featureList?.length>0">
                    <div class="d-flex  w-100">
                      <input type="checkbox" formControlName="is_extra" id="checkbox_extra" class=" h-auto form-control mr-2" style="width:20px"
                      title="" />
                      <label for="checkbox_extra" class="mt-2">
                        {{ "addProduct.selectExtra" | translate }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="form-group w-100 d-inline">
                      <label for="exampleInputEmail1">
                        <!-- Upload Image -->
                        {{ "addProduct.uploadImage" | translate }} <small style="font-size:12px">( ( {{'addProject.supportFormat'|translate}} png, jpeg, jpg )</small>
                      </label>
                      <input class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event,i)"  id="selectedFile" />
                      <!-- <div class="support-format">
                          ( Support Format  : png, jpeg, jpg )
                      </div> -->
                      <!-- <input type="file" id="fileupload" multiple #myInput class="h-auto form-control"
                        (change)="uploadImage($event)" style="color: #656a6d" onchange="this.style.color='transparent'"
                        title="" /> -->

                 
                    </div>
                 

                  </div>   
                  
                  <div class="d-flex flex-wrap justify-content-start mt-4" >

                  <div  class="position-relative img-wrapper" *ngFor="let preview of productImageList; let i = index" >

                    <img [src]="preview?.imageUrl" class="preview "  />

                    <svg class="input-icon input-icon-right close-icoo" (click)="removeProductImage(i)" xmlns="http://www.w3.org/2000/svg"
                      class="input-icon input-icon-right close-icoo" width="16" height="16" viewBox="0 0 16 16">
                      <path id="x-circle-fill"
                        d="M16,8A8,8,0,1,1,8,0a8,8,0,0,1,8,8ZM5.354,4.646a.5.5,0,0,0-.708.708L7.293,8,4.646,10.646a.5.5,0,0,0,.708.708L8,8.707l2.646,2.647a.5.5,0,0,0,.708-.708L8.707,8l2.647-2.646a.5.5,0,1,0-.708-.708L8,7.293Z" />
                    </svg>
                    <small>{{preview?.filename}}</small>
                  </div>
                </div>
                  <!-- <div class="col-md-8">
                    <div class="form-group">
                      <label>{{
                        "editProduct.currentImage" | translate
                      }}</label>
                      <ng-container *ngIf="noImage; else newImg">
                      </ng-container>

                      <ng-template #newImg>
                        <div *ngIf="imageUrl" class="d-flex">
                          <div *ngIf="!noImage" class="thumb-image mx-2">
                            <img [src]="imageUrl" />
                          </div>
                          <div *ngIf="!noImage" class="thumb-image mx-2">
                            <img [src]="imageUrl" />
                          </div>
                          <div *ngIf="!noImage" class="thumb-image mx-2">
                            <img [src]="imageUrl" />
                          </div>
                          <div *ngIf="!noImage" class="thumb-image mx-2">
                            <img [src]="imageUrl" />
                          </div>
                        </div>
                        <div *ngIf="!imageUrl" class="thumb-image mx-2">
                           No Image 
                          {{ "addFeature.noImage" | translate }}
                        </div>
                      </ng-template>
                    </div>
                  </div> -->

                 
                </div>

                <!-- <div class="col-6 mt-4">
<ng-multiselect-dropdown
  [placeholder]="placeholder"
  [settings]="dropdownSettings"
  [data]="dropdownList"
  [(ngModel)]="selectedItems"
  [ngModelOptions]="{standalone: true}"
  (onSelect)="onItemSelect($event)"
  (onDeSelect)="onItemDeSelect($event)"
  (onSelectAll)="onSelectAll($event)"
  (onDeSelectAll)="onDeSelectAll($event)"
  (onFilterChange)="onFilterChange($event)"
>
</ng-multiselect-dropdown>
                </div> -->

                 <tree-root class="d-none" id="tree2a"  [class.node-content-wrapper-active]="false"
                 [class.node-content-wrapper-focused]="false"
                 #viewNode [focused]="true" [nodes]="nodes2" [options]="options"></tree-root>

                
                 
                </div>
             
             
                <div class="w-100 text-right card-footer">
                <button type="button" class="btn btn-default mr-2" (click)="cancel()">
                  <!-- Cancel -->
                  {{ "addProduct.cancel" | translate }}
                </button>
                <button *ngIf="featureList?.length > 0" type="button" class="btn btn-primary" (click)="addProduct()">
                  <!-- Add Product -->
                  {{ "editProduct.goToStep2" | translate }}
                </button>

                <button *ngIf="featureList?.length <= 0" type="button" class="btn btn-primary" (click)="addProduct()">
                  <!-- Add Product -->
                  {{ "addProduct.addProduct" | translate  }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- {{getAllErrors(productForm) | json}} -->

  <div class="card" *ngIf="step3">
    <div class="card-body table-responsive">
      <div class="card text-dark bg-light mb-3 instructions" *ngIf="showInstructions">
        <div class="card-body" id="inst">
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="text-dark">×</span> 
                    </button>-->
          <h5 class="card-title">
            <!-- Instructions -->
            {{ "addProduct.instructions" | translate }}
          </h5>
          <p class="card-text">
            <!-- Next Set Surcharge price for the Features mapped to this Category and Product -->
            {{ "addProduct.instructionsText" | translate }}.
          </p>
        </div>
      </div>

      <h3>
        <!-- Feature List -->
        {{ "addProduct.extras" | translate }}
      </h3>
      <table class="table table-striped table-bordered">
        <thead class="thead-dark">
          <tr>
            <th style="width: 160px">
              <span class="d-flex"><input type="checkbox" class="mr-2" [(ngModel)]="selectall"
                  [ngModelOptions]="{ standalone: true }" id="selectall" [checked]="selectall"
                  (input)="selectAllFeatureToggle()" />
                {{ "addProduct.selectAll" | translate }}</span>
            </th>
            <th>{{ "addProduct.name" | translate }}</th>
            <th>{{ "addProduct.catName" | translate }}</th>
            <th>{{ "addProduct.controlValue" | translate }}</th>
            <th>{{ "addProduct.surcharge" | translate }}</th>
            <th>{{ "addProduct.action" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let obj of featureList">
            <td>
              <input type="checkbox" [checked]="obj?.isSelected" (input)="toggleSelectedFeature(obj)" />
            </td>
            <td class="align-middle">
              <div class="form-check pl-0">
                <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" /> -->
                <label class="form-check-label" for="exampleCheck1">{{ obj.label_name }}
                </label>
              </div>
            </td>
            <td class="align-middle">
              <div class="form-check pl-0">
                <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" /> -->
                <label class="form-check-label" for="exampleCheck1">{{ obj.cat_name }}
                </label>
              </div>
            </td>
            <td class="align-middle">
              <div *ngIf="obj.control_type === 'dropDown'">
                <select class="form-control">
                  <option *ngFor="let opt of getPureString(obj.control_value)">
                    <ng-container>{{ opt }}</ng-container>
                  </option>
                </select>
              </div>
              <div *ngIf="obj.control_type === 'input'">
                <label class="form-check-label">{{ obj.control_value }} </label>
              </div>
            </td>
            0
            <td *ngIf="obj.surcharge === 1">
              <!-- Yes -->
              {{ "addProduct.yes" | translate }}
            </td>
            <td *ngIf="obj.surcharge === 0">
              <!-- No -->
              {{ "addProduct.no" | translate }}
            </td>
            <td class="align-middle mw-150 mw-150">
              <button *ngIf="obj.surcharge === 1" (click)="customizeFeature(obj)"
                class="btn-sm-round btn-edit bg-grey text-dark">
                <i class="fas fa-pencil-alt"></i>
              </button>
              <label *ngIf="obj.surcharge === 0" class="form-check-label">
                <!-- No Action Available  -->
                {{ "addProduct.noActionAvailable" | translate }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
 
  
  <p class="text-right">
    <button *ngIf="step3" type="button" class="btn btn-default" (click)="gotoStep1()">
      <!-- Save Product Features -->
      {{ "editProduct.backToStep1" | translate }}
    </button>
 
    <button *ngIf="step3" type="button" class="btn btn-primary" (click)="saveProductFeatures()">
      <!-- Save Product Features -->
      {{ "addProduct.saveProductFeatures" | translate }}
    </button>
  </p>
</div>

<ng-template #cropImageModal let-modal>
  <div
    class="modal-body bookappointment-modal "
  >
    <image-cropper
    [imageChangedEvent]="null"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="true"
      [aspectRatio]="1.6 / 1"
      format="png"
      [imageQuality]="100"
      [imageFile]="imageOrigin"
      [resizeToWidth]="imageWidth"
      [resizeToHeight]="imageHeight"
      [onlyScaleDown]="isImageScaleDown"
      [backgroundColor]="'#ffffff'"
(imageLoaded)="imageLoaded($event)"
(imageCropped)="imageCropped($event)"
(startCropImage)="imageCroppedStarted($event)"
(loadImageFailed)="imageLoadFailed($event)"
></image-cropper>
    </div>
  <div class="modal-footer justify-content-between mb-3 px-md-5 px-3">
    <button
      type="button"
      class="btn btn-transparent text-black mw-180 mx-0"
      aria-label="Close"
      (click)="
        modal.dismiss('Cross click');
        closeModal();
      "
    >
      Cancel
    </button>
    <button
  
      type="button"
      class="btn btn-dark mw-180 mx-0"
      (click)="saveImage(cropImageModal)"
    >
      Update
    </button>
  </div>
</ng-template>