<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->
    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-4 col-12">
                <h1>{{'projectOverview.projectOverview' | translate}}</h1>
            </div>
            <div class="col-lg-8 col-12 d-flex justify-content-end">

                <form novalidate [formGroup]="form" class="row w-100 d-flex justify-content-end flex-nowrap">

                   


                    <!-- <select class="f-700 form-control col-lg-4" formControlName="client_id">
                        <option hidden value="" disabled selected>Select Clients</option>
                        <option *ngFor="let obj of dropDownClientList;index as i" [ngValue]="obj.id">{{obj.first_name}}
                            {{obj.last_name}}</option>
                    </select> -->

                    <!-- {{this.form.value | json}} -->
                    <ng-select class="f-700  col-lg-5" formControlName="client_id" [items]="dropDownClientList" [multiple]="true" [closeOnSelect]="false" [searchable]="false" bindLabel="first_name" bindValue="id" placeholder="{{'projectOverview.selectClients' | translate}}">
                    </ng-select>
                    <select class="f-700 form-control mr-2 col-lg-5" formControlName="project_id">
                        <option hidden value="" disabled selected>{{'projectOverview.selectProject' | translate}}</option>
                        <option *ngFor="let obj of dropDownProjectList;index as i" [ngValue]="obj.id">{{obj.name}}
                        </option>
                    </select>

                    <div class="input-group-append " (click)="clearSearch() ">
                        <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                    </div>
                    <a href="javascript:; " class="btn btn-primary pl-3 pr-3 ml-1 " (click)="applyFilter()"> {{'projectOverview.apply' | translate}}</a>
                    <button type="btn" class="text-danger no-border bg-transparent pl-3 pr-3" (click)="export()">
                        <i class="fa fa-download"></i>
                    </button>
                </form>

            </div>
        </div>
        <!-- Content Header (Page header)  -->
    </section>


    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span> {{'projectOverview.projectOverview' | translate}}</span>
                <!-- <span>Total Projects {{totalItems}}</span> -->
            </div>
        </div>
        <div class="card-body table ">
            <table class="table table-bordered table-striped">
                <thead class="table-dark ">
                    <tr>
                        <th>{{'listFaq.srNo' | translate}}</th>
                        <th>{{'projectOverview.clientName' | translate}}</th>
                        <th>{{'projectOverview.clientAddress' | translate}}</th>
                        <th>{{'projectOverview.projectDetails' | translate}}</th>
                        <th>{{'projectOverview.countOfProducts' | translate}}</th>
                        <th>{{'projectOverview.productsDetails' | translate}}</th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let obj of projectList; index as i">
                        <td class="text-center">{{i+1}}</td>
                        <td>{{obj.client_name}}</td>
                        <td>{{obj.client_address}} </td>
                        <td>{{obj.projectNames}}</td>
                        <td class="text-center">{{obj.total_product}}</td>
                        <td>{{obj.prod_detail}}</td>
                    </tr>

                </tbody>

            </table>
            <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
                <div class="col-md-12 text-right">
                    <div class="pagination justify-content-end">
                        <div class="page-item">
                            <pagination [boundaryLinks]="showBoundaryLinks" [itemsPerPage]="itemsPerPage" [firstText]="firstText" [lastText]="lastText" [previousText]="prevText" [nextText]="nextText" [totalItems]="totalItems" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
                                [maxSize]="maxSize"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>