<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->

    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-6 col-12">
                <h1>
                    <!-- Clients -->
                    {{'menu.Clients' | translate}}</h1>
            </div>

            <div class="col-lg-6 col-12 mt-2 mt-lg-0">
                <div class="d-lg-flex justify-content-end">
                    <div>
                        <div class="input-group ">
                            <input type="text " #searchVA class="form-control " placeholder="{{'listClient.searchClientPlaceholder' | translate}}" (keyup.enter)=search($event)>
                            <div class="input-group-append ">
                                <span class="input-group-text f-700 "><i class="fa fa-search " (click)=search(searchVA.value,true)></i></span>
                            </div>
                            <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-auto " [routerLink]="['../' + APP_ROUTES.addClient]" *ngIf="permissions.client == 1">
                        <a class="btn btn-primary " href="javascript:; "><i
                                    class="fa fa-plus "></i>
                                     <!-- Add Client -->
                                    {{'listClient.addClient' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Content Header (Page header)  -->
    </section>
    <!-- 
    <section class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <div class="col-md-auto">
                    <ol class=" breadcrumb ">
                        
                    </ol>
                </div>
                <div class="col-md-auto">
                    <div class="row justify-content-end ">
                        <div class="col-md-auto">
                            <div class="w-100">
                                <div class="input-group ">
                                    <input type="text " #searchVA class="form-control " placeholder="Search Client " (keyup.enter)=search($event)>
                                    <div class="input-group-append ">
                                        <span class="input-group-text f-700 "><i class="fa fa-search " (click)=search(searchVA.value,true)></i></span>
                                    </div>
                                    <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                        <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-auto " [routerLink]="['../' + APP_ROUTES.addClient]">
                            <a class="btn btn-primary " href="javascript:; "><i
                                class="fa fa-plus "></i> Add Client</a>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </section> -->


    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span> 
                    <!-- Client Listing -->
                        {{'listClient.clientListing' | translate}}
                </span>
                <span>
                    <!-- Total Clients  -->
                    {{'listClient.totalClients' | translate}} {{totalItems}}</span>
            </div>

        </div>

        <div class="card-body table  table-responsive table-style">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-dark">
                    <tr>
                        <th>{{'listClient.srNo' | translate}}.</th>
                        <th>{{'listClient.firstName' | translate}}</th>
                        <th>{{'listClient.lastName' | translate}}</th>
                        <th>{{'listClient.email'|translate}}</th>
                        <th>{{'listClient.residentialUnit' | translate}}</th>
                        <th *ngIf="permissions.client == 1" style="width:100px ">
                        {{'listClient.blockClient' | translate}}     
                        </th>
                        <th>{{'listClient.clientProgress' | translate}}</th>
                        <th>{{'listClient.action' | translate}}</th>
                    </tr>

                </thead>
                <tbody>


                    <tr *ngFor="let obj of clientList; index as i">
                        <td class="text-center" *ngIf="page === 1">{{(i+1)}}</td>
                        <td *ngIf="page !== 1">{{(page*10)-10 + (i+1)}}</td>
                        <td>{{obj.first_name}}</td>
                        <td>{{obj.last_name}}</td>
                        <td>{{obj.email}}</td>
                        <td>{{obj.address}}</td>

                        <td *ngIf="permissions.client == 1">
                            <div class="custom-control custom-switch  text-center ">
                                <input (change)="blockUnblockClient(obj.id,$event)" type="checkbox" class="custom-control-input" id="customSwitch{{i}}" [checked]="obj.status == 0">
                                <label class="custom-control-label" for="customSwitch{{i}}"></label>
                            </div>
                        </td>
                        <td>
                            <div style="display:flex;justify-content: center;align-items: center;">
                                    <circle-progress style="display:block" [percent]="getRoomPercentage(obj)"></circle-progress>
                            </div>    
                        </td>
                        <td>
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a class="m-1" href="javascript:;" (click)="viewClient(obj)"><i class="fa fa-eye "></i></a>
                                <a *ngIf="permissions.client == 1" class="m-1" href="javascript:;" (click)="editClient(obj)"> <i class="fa fa-pencil-alt "></i></a>
                                <span *ngIf="permissions.client == 1"> <a href="javascript:;" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage"
                                    [confirmText]="confirmText"
                                    [cancelText]="cancelText"
                                    placement="left" (confirm)="deleteClient(true,obj)" (cancel)="deleteClient(false,obj)">
                                    <i class="fa fa-trash text-danger "></i></a></span>
                                <a (click)="openTimeline(obj)" class=" border-0 postion-relative m-1 cursor-pointer"><i class="fas fa-comment-alt"></i>
                                    <span *ngIf="obj.pendingMessage !==0" class="notify-bubble">{{obj.pendingMessage}}</span></a>

                                <a *ngIf="permissions.client == 1" (click)="openClientSideDashboard(obj.id)" class=" border-0 postion-relative m-1 cursor-pointer"><i class="fas fa-heart"></i>
                                        </a>
                                        <a
                  *ngIf="permissions.client == 1"
                  href="javascript:; "
                  (click)="goToCartPage(obj)"
                  class=" border-0 postion-relative m-1 cursor-pointer"
                > 
                  <i class="fa fa-shopping-cart"></i
                >
                <span  class="notify-bubble" *ngIf="obj.totalCartCount>0">{{obj.totalCartCount}}</span>
             </a>
             
                            </div>

                        </td>

                    </tr>

                    <!-- <tr>
                        <td>2</td>
                        <td>client 2</td>
                        <td>R2</td>

                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>


                    <tr>
                        <td>3</td>
                        <td>client 3</td>
                        <td>R3</td>

                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr> -->

                </tbody>


            </table>
        </div>
    </div>

    <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
        <div class="col-md-12 text-right">
            <div class="pagination justify-content-end">
                <div class="page-item">
                    <pagination [boundaryLinks]="showBoundaryLinks" [firstText]="firstText" [lastText]="lastText" [previousText]="prevText" [nextText]="nextText" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
                        [maxSize]="maxSize"></pagination>
                    <!-- <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    Next
                  </ng-container>
                </ng-template>
    
                <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    Previous
                  </ng-container>
                </ng-template>
    
                <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                  Last
                  </ng-container>
                </ng-template>
                 
                <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                  First
                  </ng-container>
                </ng-template> -->
                </div>
            </div>
        </div>
    </div>

</div>