export class APP_ROUTES {
    public static root: string = '';
    public static slash: string = '/';

    //* Pre auth Layout */
    public static login: string = 'login';
    public static forgotPassword: string = 'forgot-password';
    public static changePassword: string = 'reset_password';
    public static setSocialPassword: string = 'set_password';
    public static register: string = 'register';
    public static verification: string = 'verification';
    public static aboutUs: string = 'aboutUs';
    public static termsAndConditions: string ='termsAndConditions';
    public static privacyPolicy: string ='privacyPolicy';

    //* post auth Layoyt */
    public static home: string = 'home';
    public static dashboard: string = 'dashboard';
    public static resetPassword: string = 'reset-password';
    public static categories: string = 'category';
    public static listFaq: string = 'list-faq';
    public static addFaq: string = 'add-faq';
    public static addProduct: string = 'add-product';
    public static listProduct: string = 'list-product';
    public static editProduct: string = 'edit-product';
    public static listTemplate: string = 'list-template';
    public static addTemplate: string = 'add-template';
    public static addTemplateType: string = 'add-template-type';
    public static addProject: string = 'add-project';
    public static listProject: string = 'list-project';
    public static editProject: string = 'edit-project';
    public static listClient: string = 'list-client';
    public static addClient: string = 'add-client';
    public static editClient: string = 'edit-client';
    public static clientCartpage: string = '/client/cart-page/';
    public static openTimeline: string = 'timeline';
    public static listStaff: string = 'list-staff';
    public static AddStaff: string = 'add-staff';
    public static editStaff: string = 'edit-staff';
    public static ProjectOverview: string = 'overview';
    public static MostSelectedReport: string = 'most-selected'
    public static CustomerProcessTime: string = 'customer-process'
    

}