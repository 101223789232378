<div class="content-wrapper card-body" style="min-height: 1416.81px;">
  <!--<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2 justify-content-between">
        <div class="col-md-auto">
           <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:;">
                Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active">Add Staff</li>
          </ol>
        </div>
      </div>
    </div>
  </section> -->
  <div class="card card-primary ">
    <div class="card-header">
      <div class="w-100  d-flex justify-content-between">
        <span>{{'addStaff.addStaff' | translate}}</span>
      </div>
    </div>
    <div class="card-body">
      <form novalidate [formGroup]="form">
        <!-- {{form.value | json}} -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.firstName' | translate }}<span class="text-danger">*</span></label>
              <input type="text" placeholder="{{'addStaff.enterFirstName'  | translate}}" formControlName="first_name"
                class="form-control">

              <ng-container *ngIf="form.controls['first_name'].errors &&
                (form.get('first_name').dirty || form.get('first_name').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['first_name'].hasError('required')">{{'addStaff.numberOfClientsPicked' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['first_name'].hasError('maxlength')">{{'addStaff.charLimitMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['first_name'].hasError('whitespace')">{{'addStaff.spaceMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['first_name'].hasError('pattern')">{{'addStaff.specialCharMsg' |
                  translate}}</small>
              </ng-container>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.lastName' | translate}} <span class="text-danger">*</span></label>
              <input type="text" placeholder="{{'addClient.lastNamePlaceholder' | translate}}"
                formControlName="last_name" class="form-control">

              <ng-container *ngIf="form.controls['last_name'].errors &&
                (form.get('last_name').dirty || form.get('last_name').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['last_name'].hasError('required')">{{'addClient.lastNameRequired' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['last_name'].hasError('maxlength')">{{'addStaff.charLimitMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['last_name'].hasError('whitespace')">{{'addStaff.spaceMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['last_name'].hasError('pattern')">{{'addStaff.specialCharMsg' |
                  translate}}</small>
              </ng-container>


            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.emailAddress' | translate}} <span class="text-danger">*</span></label>
              <input type="email" placeholder="{{'addStaff.enterEmail' | translate}}" formControlName="email"
                class="form-control">

              <ng-container *ngIf="form.controls['email'].errors &&
                (form.get('email').dirty || form.get('email').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['email'].hasError('required')">{{'addClient.emailRequiredMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['email'].hasError('email')">{{'addClient.validEmailMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['email'].hasError('maxlength')">{{'addClient.charLimit60' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['email'].hasError('whitespace')">{{'addStaff.spaceMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['email'].hasError('pattern')">{{'addStaff.specialCharMsg' | translate}}</small>
              </ng-container>



            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.contactNumber' | translate}} <span class="text-danger">*</span></label>
              <input type="tel" placeholder="{{'addStaff.enterContactNumber' | translate}}"
                formControlName="phone_number" class="form-control">

              <ng-container *ngIf="form.controls['phone_number'].errors &&
                (form.get('phone_number').dirty || form.get('phone_number').touched)">
                <small class="text-danger error-msg" *ngIf="form.controls['phone_number'].hasError('required')">*Phone
                  number is
                  required.</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['phone_number'].hasError('maxlength')">{{'addStaff.charLimitMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['phone_number'].hasError('whitespace')">{{'addStaff.spaceMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['phone_number'].hasError('pattern')">{{'addStaff.specialCharMsg' |
                  translate}}</small>
              </ng-container>


            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.address' | translate}} <span class="text-danger">*</span></label>
              <input type="text" placeholder="{{'addClient.enterAddress' | translate}}" formControlName="address"
                class="form-control">

              <ng-container *ngIf="form.controls['address'].errors &&
                (form.get('address').dirty || form.get('address').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['address'].hasError('required')">{{'addClient.addressRequiredMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['address'].hasError('maxlength')"></small>{{'addClient.charLimit300' |
                translate}}.
                <small class="text-danger error-msg"
                  *ngIf="form.controls['address'].hasError('whitespace')">{{'addStaff.spaceMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['address'].hasError('pattern')">{{'addStaff.specialCharMsg' | translate}}</small>
              </ng-container>


            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.postalCode' | translate}}<span class="text-danger">*</span></label>
              <input type="text" placeholder="{{'addClient.postalCode' | translate}}" formControlName="postal_code"
                class="form-control">

              <ng-container *ngIf="form.controls['postal_code'].errors &&
                (form.get('postal_code').dirty || form.get('postal_code').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['postal_code'].hasError('required')">{{'addClient.postalCodeRequired' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['postal_code'].hasError('maxlength')">{{'addStaff.charLimitMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['postal_code'].hasError('whitespace')">{{'addStaff.spaceMsg' |
                  translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['postal_code'].hasError('pattern')">{{'addStaff.specialCharMsg' |
                  translate}}</small>
              </ng-container>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'addStaff.city' | translate}}<span class="text-danger">*</span></label>
              <input type="text" placeholder="{{'addStaff.city' | translate}}" formControlName="city"
                class="form-control">

              <ng-container *ngIf="form.controls['city'].errors &&
                (form.get('city').dirty || form.get('city').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['city'].hasError('required')">{{'addClient.cityRequired' |
                  translate}}</small>
              </ng-container>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <!-- <label>Date of Joining <span class="text-danger">*</span></label>
              <input type="text" placeholder="Date of Joining" formControlName="date" class="form-control"> -->

              <!-- <label>{{'addStaff.dateofJoining' | translate}}<span class="text-danger">*</span></label>
              <div class="input-group">
                <input [readonly]="true" class="form-control" placeholder="dd/mm/yyyy" [minDate]="minDate" name="d2"
                  ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" formControlName="joining_date">

                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="fa fa-calendar" (click)="d2.toggle()" style="cursor: pointer;" aria-hidden="true"></i>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="form.controls['joining_date'].errors &&
                  (form.get('joining_date').dirty || form.get('joining_date').touched)">
                <small class="text-danger error-msg"
                  *ngIf="form.controls['joining_date'].hasError('required')">{{'addStaff.dateMsg' | translate}}</small>
                <small class="text-danger error-msg"
                  *ngIf="form.controls['joining_date'].hasError('whitespace')">{{'addStaff.spaceMsg' |
                  translate}}</small>
              </ng-container> -->


            </div>
          </div>

        </div>
        <div class="row justify-content-center mt-5 mb-3">
          <div lass="col-md-12 mb-2 mb-md-3 mt-3 mt-md-5 text-center">
            <h5 class="f-700 line-heading d-inline-block p-relative">{{'addStaff.permission' | translate}}</h5>
          </div>
        </div>
        <div class="row p-2 ">
          <div class=" col-12 shadow-box-lg p-lg-5 p-4 rounded border">
            <div class="w-100">
              <div class="row">
                <div class="col-lg-4 mb-4 mb-lg-0">
                  <div class="form-check fs-5 d-flex align-items-center pl-0">
                    <input class="form-check-input" (change)="isAdmin('is_admin',$event)" type="checkbox"
                      formControlName="is_admin" value="" class="checkbox-size">
                    <label class="form-check-label bold" for="flexCheckDefault">
                      <h5 class="mb-0">Select All</h5>
                    </label>
                  </div>
                </div>
                <div class="col-lg-8" formGroupName="permission">
                  <div class="box w-100 p-lg-4 p-2 border rounded">
                    <ul class="list-group list-group-flush">

                      <!-- <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" (change)="updateForm('dashboard_screen',$event)" formControlName="dashboard_screen" value=""  class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>Dashboard</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.dashboard_screen">
                            <h6 class="m-0">View </h6>
                            <div  class="custom-control custom-switch ml-2">
                              <input  type="checkbox" (change)="updateForm('dashboard',$event)" formControlName="dashboard" class="custom-control-input" id="customSwitchdashboard" checked>
                              <label class="custom-control-label" for="customSwitchdashboard"></label>
                            </div>
                            <h6 class="m-0">Write</h6>
                          </div>
                        </div>
                      </li> -->

                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="catalogue_screen"
                              (change)="updateForm('catalogue_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.catalogue' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.catalogue_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="catalogue"
                                (change)="updateForm('catalogue',$event)" class="custom-control-input"
                                id="customSwitchcatalogue" checked>
                              <label class="custom-control-label" for="customSwitchcatalogue"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="product_screen"
                              (change)="updateForm('product_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.products' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.product_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="product" (change)="updateForm('product',$event)"
                                class="custom-control-input" id="customSwitchproduct" checked>
                              <label class="custom-control-label" for="customSwitchproduct"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>


                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="template_screen"
                              (change)="updateForm('template_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.templates' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.template_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="template" (change)="updateForm('template',$event)"
                                class="custom-control-input" id="customSwitch0template" checked>
                              <label class="custom-control-label" for="customSwitch0template"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>


                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="client_screen"
                              (change)="updateForm('client_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.client' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.client_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="client" (change)="updateForm('client',$event)"
                                class="custom-control-input" id="customSwitchclient" checked>
                              <label class="custom-control-label" for="customSwitchclient"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="project_screen"
                              (change)="updateForm('project_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.projects' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.project_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="project" (change)="updateForm('project',$event)"
                                class="custom-control-input" id="customSwitch0project" checked>
                              <label class="custom-control-label" for="customSwitch0project"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="staff_screen"
                              (change)="updateForm('staff_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.staff' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.staff_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}}</h6>
                            <!-- <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="staff" (change)="updateForm('staff',$event)"
                                class="custom-control-input" id="customSwitch0staff" checked>
                              <label class="custom-control-label" for="customSwitch0staff"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6> -->
                          </div>
                        </div>
                      </li>




                      <!-- <li class="list-group-item bg-transprent pl-0 pr-0">
                                  <div class="row align-items-center">
                                    <div class="col-5 d-flex align-items-center">
                                      <input class="form-check-input" type="checkbox" formControlName="timeline_screen" 
                                      (change)="updateForm('timeline_screen',$event)" value=""  class="checkbox-size">
                                      <label class="form-check-label bold" for="flexCheckDefault">
                                        <strong>Timeline</strong>
                                      </label>
                                    </div>
                                    <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.timeline_screen">
                                      <h6 class="m-0">View </h6>
                                      <div  class="custom-control custom-switch ml-2">
                                        <input  type="checkbox" formControlName="timeline" 
                                        (change)="updateForm('timeline',$event)" class="custom-control-input" id="customSwitch0timeline" checked>
                                        <label class="custom-control-label" for="customSwitch0timeline"></label>
                                      </div>
                                      <h6 class="m-0">Write</h6>
                                    </div>
                                  </div>
                                </li> -->

                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="faq_screen"
                              (change)="updateForm('faq_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{'addStaff.fAQ' | translate}}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.faq_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="faq" (change)="updateForm('faq',$event)"
                                class="custom-control-input" id="customSwitch0faq" checked>
                              <label class="custom-control-label" for="customSwitch0faq"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>

                      <!-- <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="document"
                              (change)="updateForm('document',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>Documents</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.document_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="document_screen" (change)="updateForm('document_screen',$event)"
                                class="custom-control-input" id="customSwitch0document" checked>
                              <label class="custom-control-label" for="customSwitch0document"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li> -->
                      <li class="list-group-item bg-transprent pl-0 pr-0">
                        <div class="row align-items-center">
                          <div class="col-5 d-flex align-items-center">
                            <input class="form-check-input" type="checkbox" formControlName="document_screen"
                              (change)="updateForm('document_screen',$event)" value="" class="checkbox-size">
                            <label class="form-check-label bold" for="flexCheckDefault">
                              <strong>{{ "DocCategory.documentcategories" | translate }}</strong>
                            </label>
                          </div>
                          <div class="col-7 d-flex align-items-center" *ngIf="form.value.permission.document_screen">
                            <h6 class="m-0">{{'addStaff.view' | translate}} </h6>
                            <div class="custom-control custom-switch ml-2">
                              <input type="checkbox" formControlName="document" (change)="updateForm('document',$event)"
                                class="custom-control-input" id="customSwitchdocument" checked>
                              <label class="custom-control-label" for="customSwitchdocument"></label>
                            </div>
                            <h6 class="m-0">{{'addStaff.write' | translate}}</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="border-top text-right p-3">
      <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="addStaff()">{{'addStaff.add' |
        translate}}</button>
    </div>
  </div>
</div>