import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from './../project.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss'],
})
export class ListProjectComponent implements OnInit {
  public APP_ROUTES = APP_ROUTES;
  projectList = [];

  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  totalItems: any = 0;
  page: number = 1;

  popoverTitle = 'Delete';
  popoverMessage = 'Do you want to delete this Project?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  permissions: any;
  firstText = '<<';
  lastText = '>>';
  prevText = '<';
  nextText = '>';

  constructor(
    private service: ProjectService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private baseService: BaseService,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.spinner.show();
    this.getProjectList();

    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Delete';
        this.popoverMessage = 'Do you want to delete this Project?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Löschen';
        this.popoverMessage = 'Möchten Sie dieses Projekt löschen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Stornieren';
      }
    });
  }

  pageChanged(event: any): void {
    this.spinner.show();
    this.page = event.page;
    // this.pageScrolling('#scrollingToTop')
    this.loadPage();
  }
  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: '',
    };
    this.getProjectList(payload);
  }

  getProjectList(payload?: any) {
    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search: '',
      };
    }

    this.service.getProjectList(payload).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status === 200) {
          console.log('response ', data);
          // this.projectList = data.body.projectRec;
          this.projectList = [];
          this.purifyList(data.body.projectRec);
          this.totalItems = data.body.totalRecord;
          console.log('this.totalItems ', this.totalItems);
          if (this.totalItems == 0) {
            // this.toastr.error("No Project Found");
            this.toastr.error(
              this.translationService.instant(
                'listProject.noProjFoundMsgTsFile'
              )
            );
          }
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        this.spinner.hide();
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  purifyList(list: any) {
    for (var obj of list) {
      let totalUnits = 0;
      for (var units of obj.ProjectTemplates) {
        totalUnits += units.units;
      }
      let newObj = {
        id: obj.id,
        name: obj.name,
        location: obj.location,
        complete_project_date: obj.complete_project_date,
        totalUnits: totalUnits,
      };
      this.projectList.push(newObj);
    }
    console.log('Final list ', this.projectList);
  }

  editProject(obj: any) {
    console.log('obj.id', obj.id);
    this.router
      .navigate([APP_ROUTES.editProject], { queryParams: { pId: obj.id } })
      .then(() => {});
  }

  viewProject(obj: any) {
    console.log('obj.id', obj.id);
    this.router
      .navigate([APP_ROUTES.editProject], {
        queryParams: { pId: obj.id, op: 'view' },
      })
      .then(() => {});
  }

  deleteProject(str: Boolean, obj: any) {
    if (str) {
      console.log('obj.id', obj);

      let payload = {
        id: obj.id,
      };

      this.service.deleteProject(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.loadPage();
            setTimeout(() => {
              // this.toastr.success('Project Deleted Successfully', '');
              this.toastr.success(
                this.translationService.instant(
                  'listProject.projectDeletedMsgTsFile'
                )
              );
            }, 1000);
          } else {
            this.toastr.error(data.body.message, '');
          }
        },
        (err) => {
          console.log('error', err);
          if (err.error.message) {
            this.toastr.error(err.error.message, '');
          } else {
            // this.toastr.error('Something went wrong. Please try again later', '');
            this.toastr.error(
              this.translationService.instant('login.genricErrorTsFile')
            );
          }
        }
      );
    }
  }

  clearSearch(el: any) {
    el.value = '';
    this.getProjectList();
  }

  search(event: any, cond?: Boolean) {
    let search;
    if (cond) {
      search = event;
    } else {
      search = event.target.value;
    }

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: search,
    };
    this.getProjectList(payload);
  }

 
}
