<div class="card-body m-0 p-0">
    
    <div class="card card-primary m-0 p-0">
      <div class="card-header">
        <div class="w-100 d-flex justify-content-between">
          <span>
            {{ "addProduct.chooseCategory" | translate }}
          </span>
          <div class="card-tools text-right">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="remove"
              data-toggle="tooltip"
              title="Remove"
              (click)="closeModal()"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="pl-3 mt-2">
      <tree-root id="tree2a"  [class.node-content-wrapper-active]="false"
                 [class.node-content-wrapper-focused]="false"
                 #viewNode [focused]="true" [nodes]="nodes" [options]="options"></tree-root>
                </div>
    </div>


                <p class="text-right pt-2 pr-2 pb-0">
                    <button type="button" class="btn btn-default mr-2"  (click)="closeModal()">
                      {{ "addProduct.cancel" | translate }}
                    </button>
                 
                    <button  type="button" class="btn btn-primary"  (click)="save()">
                      {{ "addProduct.save" | translate }}
                    </button>
                  </p>
      

    </div>
