import { Component, OnInit } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ReportsService } from '../reports.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-selected-most',
  templateUrl: './selected-most.component.html',
  styleUrls: ['./selected-most.component.scss']
})
export class SelectedMostComponent implements OnInit {

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  readonly DELIMITER = '-';
  itemsPerPage = environment.itemsPerPage;
  totalItems: any = 0;
  page: number = 1;
  showBoundaryLinks = true;
  maxSize = 4;
  reportList:any;
  searchWord = '';
  firstText="<<";
  lastText=">>";
  prevText="<";
  nextText=">";

  constructor(private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private service : ReportsService,
    private toastr: ToastrService,) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this.getMostSelected();
    this.fromDate= null;
    this.toDate= null;
  }

  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  //   // console.log("From ",this.fromDate)
  //   // console.log("formatted toDate ",this.format(this.parse(this.toDate.day+this.DELIMITER+this.toDate.month+this.DELIMITER+this.toDate.year+this.DELIMITER)));  
  // }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && !date.before(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    let month;
    let day;
    if(!date){
      return '';
    }
    if(date.month<10){
      month = '0' + date.month;
    }
    else{
      month = date.month;
    }

    if(date.day<10){
      day = '0' + date.day;
    }
    else{
      day = date.day;
    }
    
    return date ? day + "/" + month + "/" + date.year : '';
  }

  formatForBackend(date: NgbDateStruct | null): string {
    if(!date){
      return '';
    }
    let month;
    let day;
    if(date.month<10){
      month = '0' + date.month;
    }
    else{
      month = date.month;
    }

    if(date.day<10){
      day = '0' + date.day;
    }
    else{
      day = date.day;
    }
    return date ? date.year + this.DELIMITER + month + this.DELIMITER + day : '';
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.loadPage();
  }
  
  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      from_date : this.formatForBackend(this.fromDate),
      to_date : this.formatForBackend(this.toDate),
      search: "",
      csv_download:"no"
    }
    this.getMostSelected(payload);
  }

  getMostSelected(payload?: any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        from_date : '',
        to_date : '',
        search: "",
        csv_download:"no"
      };
    };

    this.service.mostSelected2(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.reportList = data.body.productwishlistRec;
        this.totalItems = data.body.totalRecord;
        if(this.totalItems == 0 && (payload.from_date.trim()|| payload.search.trim())){
          this.toastr.error("No Report Found");
        } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    });
  }

  clearSearch(el : any){
    el.value = "";
    this.fromDate = null;
    this.toDate = null;
    this.getMostSelected();
  }

  search(event : any , cond ?: Boolean){

    let search ;
    if(cond){
      search = event;
      this.searchWord = search;
    }
    else{
      search = event.target.value;
      this.searchWord = search;
    }

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      from_date : this.formatForBackend(this.fromDate),
      to_date : this.formatForBackend(this.toDate),
      search: search,
      csv_download:"no"
    };
    this.getMostSelected(payload);
  }

  applyFilter(){

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      from_date : this.formatForBackend(this.fromDate),
      to_date : this.formatForBackend(this.toDate),
      search: this.searchWord,
      csv_download:"no"
    };
    this.getMostSelected(payload);
  }

  export(): void {

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      from_date : this.formatForBackend(this.fromDate),
      to_date : this.formatForBackend(this.toDate),
      search: this.searchWord,
      csv_download:"yes"
    }

    this.service.mostSelected2(payload).subscribe(
      fileDownload => {
    //     console.log('fileDownload', fileDownload);
    //     const contentDisp = fileDownload?.headers.get('Content-Disposition');
    //     const filename = contentDisp?.split(';')[1].split('=')[1];
    //  //   filename = filename + '.xlsx';
    //     const blob = new Blob([fileDownload.body], { type: 'text/xlsx' });
    //     const downloadURL = window.URL.createObjectURL(blob);
    //     saveAs(downloadURL, filename);
    //     setTimeout(() => {
    //       window.URL.revokeObjectURL(downloadURL);
    //     }, 1000);
    this.downloadMyFile(fileDownload.body.filepath);
      },
      err => {
        console.log(err.error);
      }
    );
  }

  downloadMyFile(docName : any){
    console.log("docName" ,docName);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', docName);
    link.setAttribute('download', docName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
  

}
