import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor( public _cs: CookieService){}

  intercept(
    req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem("token");
    if (token) {
      req = req.clone({ headers: req.headers.set("Authorization", token) });
    }
    req = req.clone({ headers: req.headers.set("Accept", "application/json").set('app-Lang', this._cs.get('dataLang') || 'de'), });
    
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
  
}
