import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class ManageProfileService {

  constructor(private http: HttpClient) { }

  getAdminProfile(){
    const url = ApiRoutes.getAdminDetail
    return this.http.get(url,{ observe: 'response' })
  }
  updateAdminProfile(body:any){
    const url = ApiRoutes.updateAdminDetail
    return this.http.post(url,body,{ observe: 'response' })
  }
}
