import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from '../../routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class TopNavbarService {

  constructor(private http: HttpClient,) { }

  public getNotifications(): Observable<any> {
    const url: string = ApiRoutes.getNotifications;
    return this.http.get(url, { observe: 'response' });
  }
}
