import { Component, OnInit, ViewChild } from '@angular/core';
import { IActionMapping, TreeNode, TREE_ACTIONS, TreeModel, KEYS, ITreeOptions, TreeComponent } from '@circlon/angular-tree-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-multiple-categories',
  templateUrl: './multiple-categories.component.html',
  styleUrls: ['./multiple-categories.component.scss']
})
export class MultipleCategoriesComponent implements OnInit {
  @ViewChild('viewNode') treeNative!:TreeComponent;
  nodes:any;
  patchValue:any
  mapIds:any[] =[];
  catIds:any;
  actionMapping: IActionMapping = {
    mouse: {
      contextMenu: (tree, node, $event) => {
        $event.preventDefault();
      },
      checkboxClick: (tree, node: TreeNode, $event) => {
        $event.stopPropagation();
       
        if(node.isSelected === undefined){
          node.setIsSelected(true)
          return;
        }

        node.setIsSelected(!node.isSelected);
        // if (node.hasChildren) {
        //   TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        //   node.setIsSelected(false)

        // }
        // console.log('node ==>',node);
        
        // node.toggleSelected();
      },
      dblClick: (tree, node, $event) => {
        if (node.hasChildren) {
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      },
      click: (tree: TreeModel, node: TreeNode, $event) => {},
      expanderClick: (tree, node: TreeNode, $event) => {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    },
    keys: {
      [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
    }
  };
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: true,
    actionMapping: this.actionMapping,
    idField: 'id',
    displayField: 'title',
    childrenField: 'children',
  };
  catListTemp: any[]=[];
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.patchCatIds();
      },50)
  }

  processNestedArrays(data: any[], cat?:any) {
    for (const item of data) {
      if (Array.isArray(item?.children)) {
        this.catListTemp.push(item);
        this.processNestedArrays(item?.children,item);
      }
    }
    return this.catListTemp
  }

  patchCatIds(){
     let ids:any[] = [] 
     if(localStorage?.cat_ids){
      ids = JSON.parse(localStorage.getItem('cat_ids'))?.map(item=> +item?.id) || [];
      // ids = JSON.parse(localStorage.getItem('cat_ids'))|| [];
      // ids = this.processNestedArrays(ids)?.map(item=> +item?.id) || [];
      // ids = [... new Set(ids)]
     }else{
      // console.log(this.patchValue, 'patchValue');
      // if(Array.isArray(this.patchValue?.value?.catalogue_id)){
      //   ids = this.patchValue?.value?.catalogue_id || [];
      // }else{
      //   ids = [this.patchValue?.value?.catalogue_id] || [];
      // }
      ids = this.catIds || [];
     }

     console.log(ids, 'idss');
     
      this.treeNative.treeModel.doForAll((node:TreeNode)=>{
        node.setIsSelected(false)
        if(ids?.includes(+node?.data?.id)){
        node.setActiveAndVisible(true)
        node.setIsSelected(true)
       }
       })
    
   
  }
  closeModal() {
    this.activeModal.close(null);
  }
  save(){
    // console.log(this.treeNative);
    this.treeNative.treeModel.doForAll((node:TreeNode)=>{

      // console.log('node ====>',node);
      if(node.isSelected && !node.isPartiallySelected){
        this.mapIds.push(node?.data)
      }
     });

     setTimeout(()=>{
      this.activeModal.close(this.mapIds);
     }, 500)
  }
}
