import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LocalStorageService } from '../../storage/local-storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { APP_ROUTES } from '../../routes/appRoutes';
import { TopNavbarService } from './top-navbar.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../../services/base.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  language: string = '';

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private service: TopNavbarService,
    private toastr: ToastrService,
    public _cs: CookieService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private baseService: BaseService
  ) {
    if (this._cs.get('dataLang')) {
      this.language = this._cs.get('dataLang');
    } else {
      this.language = 'de';
      this._cs.set('dataLang', this.language);
    }
    this.baseService.setSeletedLangugae(this.language);
    translate.setDefaultLang(this.language);
    this.translate.use(this.language);
  }

  showNavbar = false;
  notificationList = [];
  imgBaseUrl = environment.imgBaseUrl;

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.localStorage.getItem('token')) {
          this.showNavbar = true;
        } else {
          this.showNavbar = false;
        }

        if (this.localStorage.getItem('token')) {
          this.getNotifications();
        }
        setTimeout(() => this.setChanged(), 0);
      }
    });
  }
  public signOut() {
    this.localStorage.clear();
    this.router.navigate([APP_ROUTES.login]);
  }

  getNotifications() {
    this.service.getNotifications().subscribe(
      (data: any) => {
        // console.log("getNotifications response ", data);
        if (data.status === 200) {
          this.notificationList = data.body.messageList;
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          this.toastr.error('Something went wrong. Please try again later', '');
        }
      }
    );
  }

  gotoTimeline(clientId: any) {
    this.router
      .navigate([APP_ROUTES.openTimeline], { queryParams: { rId: clientId } })
      .then(() => {});
  }

  useLanguage(language: string) {
    this.baseService.setSeletedLangugae(language);
    this._cs.set('dataLang', language);
    this.translate.use(language);
    this.language = language;
  }

  setChanged() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
