import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  public addClient(payload: any): Observable<any> {
    const url: string = ApiRoutes.addClient;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public updateClient(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateClient;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public getClientList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getClientList;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public deleteClient(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteClient;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public deleteClientProject(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteClientProject;
    return this.http.post(url, payload ,{ observe: 'response' });
  }

  public blockUnblockClient(payload : any): Observable<any> {
    const url: string = ApiRoutes.blockUnblockClient;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public openClientSideDashboard(payload : any): Observable<any> {
    const url: string = ApiRoutes.openClientSideDashboard;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public uploadImageApi(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadImage;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getClientAllProjectsList(payload?: any): Observable<any> {
    const url: string = ApiRoutes.getClientProjects;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getCartItems(payload: any): Observable<any> {
    const url: string = ApiRoutes.getClientCartItems;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public addNote(payload: any): Observable<any> {
    const url: string = ApiRoutes.addNote;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public updateNote(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateNote;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public deleteNote(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteNote;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
