import { DeleteConfirmationComponent } from './../../../../shared/components/delete-confirmation/delete-confirmation.component';
import { AddFeatureComponent } from './add-feature/add-feature.component';
import { CategoryService } from './category.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TreeComponent } from '@circlon/angular-tree-component';
import { ToastrService } from 'ngx-toastr';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  private showAddNode = false;
  popoverTitle = 'Delete';
  popoverMessage = 'Delete?';
  confirmClicked = false;
  cancelClicked = false;
  @ViewChild('inputEl1')
  myInputVariable: ElementRef;

  @ViewChild('mainEl')
  mainEl: ElementRef;

  public APP_ROUTES = APP_ROUTES;
  showMsg = false;
  permissions: any;

  constructor(
    private service: CategoryService,
    private toastr: ToastrService,
    // public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.getCategories();
  }

  getCategories(): void {
    this.service.getCategories().subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          this.nodes2 = data.body.mainCat;
          this.nodes2 = [...this.nodes2];
          console.log('Final data -->', this.nodes2);
          // setTimeout(() => {
          //   this.toastr.success('Category updated successfully.', '');
          // }, 1000);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  nodes2 = [
    // {
    //   id: -1,
    //   title: 'root1',
    //   className: 'root1Class',
    //   children: [{ id: 23 }, { id: 33 }]
    // },
    // {
    //   id: 2,
    //   title: 'root2',
    //   className: 'root2Class',
    //   children: [
    //     {
    //       id: 3, title: 'child1', className: 'child1Class',
    //       children: [
    //         { id: 4, title: 'sub - child1', className: 'sub - child1Class', children: [] }
    //       ]
    //     }
    //   ]
    // }
  ];

  modifyChildNode(nodeData: any, inputEl: any, operation: any): void {
    // return;
    if (operation === 'delete') {
      console.log('operation 3', operation);
      this.deleteNode(nodeData, this.nodes2);
    } else {
      for (const [key, rows] of Object.entries(this.nodes2)) {
        console.log('ROWS', rows);
        this.recursiveFunction(nodeData, rows, inputEl, operation);
      }
    }
  }

  recursiveFunction(
    node: any,
    obj: any,
    inputEl: any,
    operation: any,
    parentNode?: any
  ): void {
    if (Array.isArray(obj)) {
      for (let innerObj of obj) {
        this.recursiveFunction(node, innerObj, inputEl, operation);
      }
    } else if (node.id == obj.id) {
      //check if id sent is same as id of parent
      if (operation === 'add') {
        if (obj.children) {
          this.addNode(obj.children, inputEl, obj);
        } else if (obj.level >= 5) {
          this.toastr.error('Cannot add after level 5', '');
          return;
        }
      }

      if (operation === 'edit') {
        // obj.title = inputEl;
        // console.log("Edit", this.nodes2);
        // this.nodes2 = [... this.nodes2];
        this.updateNode(inputEl, obj);
      }
    } else {
      // if parent id not same then check if it has children arr
      if (obj.children) {
        // if obj exists repeat abv code
        this.recursiveFunction(node, obj.children, inputEl, operation, obj);
      }
    }
  }

  updateNode(name: any, obj: any): void {
    let payload = {
      name: name,
      id: obj.id,
    };

    this.service.updateCategory(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          console.log('response ', data);
          obj.title = name;
          // this.nodes2 = [... this.nodes2];
          this.getCategories();
          console.log('Final data -->', this.nodes2);
          setTimeout(() => {
            // this.toastr.success('Category updated successfully.', '');
            this.toastr.success(
              this.translationService.instant(
                'addCategory.categoryUpdatedMsgTsFile'
              )
            );
          }, 1000);
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
        }
      }
    );
  }

  deleteNode(nodeData: any, arr: any): void {
    for (let mainCat of arr) {
      if (mainCat.id == nodeData.id) {
        console.log('mainCat', mainCat, 'Array', arr);

        let payload = {
          id: nodeData.id,
        };

        this.service.deleteCategory(payload).subscribe(
          (data: any) => {
            if (data.status === 200) {
              console.log('response ', data);
              var index = arr.findIndex((el) => el.id == mainCat.id);
              arr.splice(index, 1); // first element removed
              // this.nodes2 = [... this.nodes2];
              this.getCategories();
              console.log('Final data -->', this.nodes2);
              setTimeout(() => {
                // this.toastr.success('Category deleted successfully.', '');
                this.toastr.success(
                  this.translationService.instant(
                    'addCategory.categoryDeletedMsgTsFile'
                  )
                );
              }, 1000);
            } else {
              this.toastr.error(data.body.message, '');
            }
          },
          (err) => {
            console.log('error', err);
            if (err.error.error_code == 1) {
              // this.toastr.error('Category does not exist.', '');
              this.toastr.error(
                this.translationService.instant(
                  'addCategory.catNotExistMsgTsFile'
                )
              );
            } else if (err.error.error_code == 2) {
              // this.toastr.error('Please first delete the subcategory in it.', '');
              console.log(
                'GE ',
                this.translationService.instant(
                  'addCategory.delSubCatMsgTsFile'
                )
              );
              let ge = this.translationService.instant(
                'addCategory.delSubCatMsgTsFile'
              );
              this.toastr.error(ge);
            } else if (err.error.error_code == 3) {
              // this.toastr.error('Please firstly delete products under this category', '');
              this.toastr.error(
                this.translationService.instant(
                  'addCategory.delProdCatMsgTsFile'
                )
              );
            }
            // if (err.error.message) {
            //   this.toastr.error(err.error.message, '');
            // }
            else {
              // this.toastr.error('Something went wrong. Please try again later', '');
              this.toastr.error(
                this.translationService.instant('login.genricErrorTsFile')
              );
            }
          }
        );
        break;
      } else if (mainCat.children) {
        this.deleteNode(nodeData, mainCat.children);
      }
    }
  }

  addNode(obj: any, inputEl: any, parentNode: any): void {
    if (parentNode.level >= 5) {
      this.toastr.error('Cannot add after level 5', '');
      return;
    } else {
      let payload = {
        name: inputEl,
        parent_id: parentNode.id,
      };

      this.service.addCategory(payload).subscribe(
        (data: any) => {
          if (data.status === 200) {
            console.log('response ', data);
            obj.push({
              id: data.body.catalogue.id,
              title: inputEl,
              className: 'sub - child1Class',
              level: data.body.catalogue.level,
              children: [],
            });
            // this.tree.treeModel.update();
            // this.nodes2 = [...this.nodes2];
            this.getCategories();
            console.log('Final data -->', this.nodes2);
            setTimeout(() => {
              // this.toastr.success('Category added successfully.', '');
              this.toastr.success(
                this.translationService.instant(
                  'addCategory.categoryAddedMsgTsFile'
                )
              );
            }, 1000);
          } else {
            this.toastr.error(data?.body?.message, '');
          }
        },
        (err) => {
          console.log('error4444', err);
          if (err?.error?.message) {
            this.toastr.error(err?.error?.message, '');
            return 
          }
         
         
         if(Array.isArray(err?.error?.error)){
          if (err?.error?.error[0]?.msg == 'Please enter category name') {
            this.toastr.error(
              this.translationService.instant(
                'addCategory.enterCategoryMsgTsFile'
              )
            );
            // this.toastr.error(err.error.message, '');
            return
          } 
         }
        
                // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(
            this.translationService.instant('login.genricErrorTsFile')
          );
          
        }
      );
    }
  }

  show(): void {
    console.log('Before data -->', this.nodes2);
  }

  addMainCategory(mainCat: any) {
    let obj = { id: 0 };
    this.addNode(this.nodes2, mainCat, obj);
    this.mainEl.nativeElement.value = '';
  }

  addFeature(
    nodeId: any,
    labelName: any,
    controlType: any,
    imgUrl: any,
    surge: any
  ): void {
    let feature = {
      labelName,
      controlType,
      imgUrl,
      surge,
    };

    for (let obj of this.nodes2) {
      console.log('ROWS', obj);
      this.findNode(nodeId, obj, feature);
    }
  }

  findNode(nodeId: any, obj: any, feature: any): void {
    if (Array.isArray(obj)) {
      for (let innerObj of obj) {
        this.findNode(nodeId, innerObj, feature);
      }
    } else if (nodeId == obj.id) {
      //check if id sent is same as id of parent
      obj.feature = feature;
    } else {
      // if parent id not same then check if it has children arr
      if (obj.children) {
        // if obj exists repeat abv code
        this.findNode(nodeId, obj.children, feature);
      }
    }
  }

  dataURItoBlob(dataURI: any, type: any): any {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  }

  showNodeFn(element: any, element2: any, el?: any) {
    // console.log("0 = ",element.classList);
    // console.log("1 = ",element);
    // this.showAddNode = !this.showAddNode;
    // find class list
    // remove d-none
    // console.log(element,element2,el)
    const previousElement = element.classList;
    if (previousElement.contains('d-none')) {
      previousElement.remove('d-none');
    } else {
      previousElement.add('d-none');
    }

    if (element2 != null) {
      console.log('inside null');
      const otherElement = element2.classList;
      if (!otherElement.contains('d-none')) {
        otherElement.add('d-none');
      }
    }

    if (el) {
      // console.log("el",el)
      // console.log("el.id",el.id)
      // el.value = "sanil";
      // console.log("el.value1",el.value)
      el.value = '';
      // console.log("el.value2",el.value);
    }

    this.myInputVariable.nativeElement.value = '';
  }

  callAll(node: any, inputEl: any, operation: any, ref1: any, el: any) {
    // console.log("node data in callAll",node);
    this.modifyChildNode(node, inputEl, operation);
    this.showNodeFn(ref1, null, el);
  }

  editFn(node: any): void {
    const modalRef = this.modalService.open(AddFeatureComponent, {
      size: 'xl',
      windowClass: '',
    });
    modalRef.componentInstance.parentNode = node;
    // modalRef.result.then((result) => {
    //   if (result) {
    //   console.log("OBJ ",result);
    //   }
    //   }).catch(err => {
    //     console.error(err)
    // });

    // modalRef.result.then(
    //   (data) => {
    //     if (data) {
    //       console.log("OBJ ",data);
    //       }
    //       else{
    //         console.log("O2222BJ ");
    //       }
    //      },
    //   (err) => {
    //     console.log("err ",err);
    //   });
  }

  showDeleteLocationPopup(
    str: Boolean,
    node: any,
    inputEl1: any,
    operation: any
  ) {
    if (str) {
      this.modifyChildNode(node, inputEl1, 'delete');
    } else {
    }
  }

  deletConfirmation(nodeData: any, inputEl: any, operation: String): void {
    console.log('operation 1', operation);
    const modalRef = this.modalService
      .open(DeleteConfirmationComponent, {
        size: 'sm',
        windowClass: 'dark-modal',
      })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
          if (result) {
            console.log('operation 2', operation);
            this.modifyChildNode(nodeData, inputEl, operation);
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );

    // modalRef.componentInstance.parentNode = node;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkLength(str: any) {
    if (str && str.length === 4) {
      console.log('YESSSSSSSSsss');
      this.showMsg = true;
    } else {
      console.log('NOOOOOOOOO', str, str.length);
      this.showMsg = false;
    }
  }
}
