import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class PrivacyServiceService {

  constructor(private http: HttpClient) { }

  getPrivacy(data:any){
    const url = ApiRoutes.getPrivacy
    return this.http.post(url,data,{ observe: 'response' })
  }
  
  addPrivacy(data:any){
    const url = ApiRoutes.addPrivacy
    return this.http.post(url,data,{ observe: 'response' })
  }


}
