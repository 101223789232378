import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  public mostSelected(payload?: any): Observable<any> {
    const url: string = ApiRoutes.mostSelected;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public mostSelected2(payload?: any): Observable<any> {
    const url: string = ApiRoutes.mostSelected2;
    return this.http.post(url, payload, { observe: 'response' });
  }
}
