<div class="content-wrapper card-body" style="min-height: 1416.81px;">


    <!-- Content Header (Page header) -->
    <!-- <section class="content-header pl-0 pr-0 pt-0">
      <div class="row justify-content-between">
        <div class="col-md-auto">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="javascript:;" [routerLink]="['../' + APP_ROUTES.dashboard]">Dashboard</a></li>
            <li class="breadcrumb-item active">List FAQ</li>
          </ol>
        </div>

        <div class="col-md-auto">
            <div class="input-group input-group-md  p-0">
                <span class="input-group-append">
                    <button (click)="redirectToAddFaq()" class="btn btn-primary btn-md"><i
                            class="fa fa-plus" ></i> Add FAQ</button>
                </span>
            </div>
          </div>


      </div>
  </section> -->

    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-6 col-12">
                <h1> {{'listFaq.frequentlyaskedquestions' | translate}} </h1>
            </div>

            <div class="col-lg-6 col-12 mt-2 mt-lg-0" *ngIf="permissions.faq == 1">
                <div class="d-lg-flex justify-content-end">
                    <div>
                        <span class="input-group-append">
                                <button (click)="redirectToAddFaq()" class="btn btn-primary btn-md"><i
                                        class="fa fa-plus" ></i> {{'addFaq.addFaq' | translate}}</button>
                            </span>
                    </div>

                </div>
            </div>
        </div>
        <!-- Content Header (Page header)  -->
    </section>

    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span>
                <!-- Faq Listing -->
                    {{'listFaq.faqListing' | translate}}
            </span>
                <!-- <span>Total Staffs</span> -->
            </div>
        </div>



        <div class="card-body table  table-responsive">
            <table class="table table-striped table-bordered w-100">
                <thead class="thead-dark">
                    <tr>
                        <th class="white-space-noswrap">{{'listClient.srNo' | translate}}</th>
                        <th>{{'addFaq.question' | translate}}</th>
                        <th>{{'addFaq.answer' | translate}}</th>
                        <th *ngIf="permissions.faq == 1">
                            <!-- Activate/Deactivate -->
                            {{'listFaq.faqStatus' | translate}}</th>
                        <th *ngIf="permissions.faq == 1" style="width:85px">
                            <!-- Order -->
                            {{'listFaq.faqOrder' | translate}}</th>
                        <th *ngIf="permissions.faq == 1" class="white-space-noswrap">{{'listFaq.actions' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let obj of faqList ; index as i">
                        <td class="align-middle">
                            {{ i + 1}}
                        </td>
                        <td class="align-middle">
                            <div class="form-check pl-0">
                                <label class="form-check-label" for="exampleCheck1">{{obj.question}} </label>
                            </div>
                        </td>


                        <td class="align-middle">
                            <div class="form-check pl-0">
                                <label class="form-check-label" for="exampleCheck1">{{obj.answer}} </label>
                            </div>

                        </td>
                        <td class="align-middle mw-150 mw-150 " *ngIf="permissions.faq == 1">
                            <div class="custom-control custom-switch text-center">
                                <input (change)="enableDisableFaq(obj.id,$event)" type="checkbox" class="custom-control-input" id="customSwitch{{i}}" [checked]="obj.status == 1">
                                <label class="custom-control-label" for="customSwitch{{i}}"></label>
                            </div>
                        </td>
                        <td class="align-middle" *ngIf="permissions.faq == 1">
                            <div class="form-check pl-0 d-inline">
                                <input class="orderFaq" #order [value]="obj.order_by" (keyup)=onKeydownEvent($event,order.value,obj)>{{order_by}}
                            </div>

                        </td>
                        <td class="align-middle white-space-noswrap" *ngIf="permissions.faq == 1">
                            <a href="javascript:;" (click)="editNode(obj)" class="m-1"><i
                                            class="fas fa-pencil-alt"></i></a>

                            <a href="javascript:;" class="m-1" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" [confirmText]="confirmText" [cancelText]="cancelText" placement="left" (confirm)="showDeleteLocationPopup(true,obj.id)" (cancel)="showDeleteLocationPopup(false,obj.id)">
                                <i class="fa fa-trash text-danger"></i>
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>