import { StaffService } from './../staff.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-list-staff',
  templateUrl: './list-staff.component.html',
  styleUrls: ['./list-staff.component.scss']
})
export class ListStaffComponent implements OnInit {
  public APP_ROUTES = APP_ROUTES;
  projectList: any;
  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  totalItems: any = 0;
  page: number = 1;
  popoverTitle = 'Delete';
  popoverMessage = 'Do you want to delete this Staff';
  confirmText="Confirm";
  cancelText="Cancel";
  searchElement ='';
  staffList = [];
  permissions : any;
  firstText="<<";
  lastText=">>";
  prevText="<";
  nextText=">";

  constructor(private service : StaffService,
    private toastr: ToastrService,
    private router: Router,
    private sharedService : SharedService,
    private baseService : BaseService,
    private translationService : TranslateService) { }

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.listStaff();

    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete employee";
        this.popoverMessage="Are you sure you want to delete this employee and not rename him?. All customers created by him will be deleted as well.";
        this.confirmText="Delete";
        this.cancelText="Cancel";
      }
      else{
        this.popoverTitle="Mitarbeiter löschen";
        this.popoverMessage="Sind Sie sicher, dass sie diesen Mitarbeiter löschen möchten und nicht umbenennen? Alle von ihm angelegten Kunden werden dadurch ebenfalls gelöscht.";
        this.confirmText="Löschen";
        this.cancelText="Abbrechen";
      }
    });
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.loadPage();
  }
  
  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search : this.searchElement
    }
    this.listStaff(payload);
  }

  listStaff(payload ?: any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search : this.searchElement
      };
    };

    this.service.listStaff(payload).subscribe((data: any) => {
      console.log("response ", data);
      if (data.status === 200) {
        this.purifyList(data.body.staffRec);
        this.totalItems = data.body.totalRecord;
        if(this.totalItems == 0){
          // this.toastr.error("No Staff Found");
          this.toastr.error(this.translationService.instant('listStaff.staffNotFoundMsgTsFile'));

        }
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }

  purifyList(list : any){
    this.staffList = [];
    for(var obj of list){
      let newObj = {
        id : obj.id,
        name : obj.name,
        firstname : obj.firstname,
        lastname : obj.lastname,
        email : obj.email,
        phone_number : obj.phone_number,
        address : obj.address,
        postal_code : obj.postal_code,
        joining_date : obj.joining_date,
        is_admin : obj.is_admin,
        parent_id : obj.parent_id,
        create_by : obj.create_by,
        level : obj.level,
        last_active_at : obj.last_active_at,
        UserPermissions : this.getProjectCommaSepValues(obj.UserPermissions),
      }
      this.staffList.push(newObj);
    }
  }

  getProjectCommaSepValues(list : any):String{
    let str = '';
    for(var obj of list){
      
      if(obj.product.trim() && (obj.product == 1 || obj.product == 0))
      {
        str = str + "Product" + ",";
      }
      if(obj.catalogue.trim() && (obj.catalogue == 1 || obj.catalogue == 0))
      {
        str = str + "Catalogue" + ",";
      }
      if(obj.project.trim() && (obj.project == 1 || obj.project == 0))
      {
        str = str + "Project" + ",";
      }
      if(obj.client.trim() && (obj.client == 1 || obj.client == 0))
      {
        str = str + "Client" + ",";
      }
      if(obj.staff.trim() && (obj.staff == 1 || obj.staff == 0))
      {
        str = str + "Staff" + ",";
      }
      if(obj.template.trim() && (obj.template == 1 || obj.template == 0))
      {
        str = str + "Template" + ",";
      }
      if(obj.faq.trim() && (obj.faq == 1 || obj.faq == 0))
      {
        str = str + "FAQ" + ",";
      }
    }
    str = str.slice(0, -1); 
    return str;
  }

  deleteStaff(str: Boolean, obj: any) {

    if (str) {
      console.log("obj.id", obj);

      let payload = {
        id: obj.id
      };

      this.service.deleteStaff(payload).subscribe((data: any) => {
        if (data.status === 200) {
          this.loadPage();
          setTimeout(() => {
            // this.toastr.success('Staff Deleted Successfully', '');
            this.toastr.success(this.translationService.instant('listStaff.staffDeletedMsgTsFile'));

          }, 1000);
        }
        else {
          this.toastr.error(data.body.message, '');
        }
      }, err => {
        console.log('error', err)
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
        else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      });
    }
  }

  editStaff(obj: any) {
    console.log("obj.id", obj.id)
    this.router.navigate([APP_ROUTES.editStaff], { queryParams: { sId: obj.id} }).then(() => { });
  }

  viewStaff(obj: any) {
    console.log("obj.id", obj.id)
    this.router.navigate([APP_ROUTES.editStaff], { queryParams: { sId: obj.id , op:'view'} }).then(() => { });
  }

  clearSearch(el : any){
    el.value = "";
    this.searchElement = '';
    this.loadPage();
  }

  search(event : any , cond ?: Boolean){

    let search ;
    if(cond){
      search = event;
    }
    else{
      search = event.target.value;
    }
    this.searchElement = search;
    this.loadPage();
  }

}
