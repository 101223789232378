import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ClientService } from '../client.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BaseService } from 'src/app/shared/services/base.service';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss']
})
export class ListClientsComponent implements OnInit {

  APP_ROUTES = APP_ROUTES;
  clientList : any;
  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  totalItems: any = 0;
  page: number = 1;
  popoverTitle = 'Delete';
  popoverMessage = 'Do you want to delete this Client?';
  confirmText="Confirm";
  cancelText="Cancel";
  permissions : any;
  firstText="<<";
  lastText=">>";
  prevText="<";
  nextText=">";
  cartCount:number;
  constructor( private clientService: ClientService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sharedService : SharedService,
    private translationService : TranslateService,
    private baseService : BaseService ) { }

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.spinner.show();
    this.getClientList();
    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete";
        this.popoverMessage="Do you want to delete this Client?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";
      }
      else{
        this.popoverTitle="Löschen";
        this.popoverMessage="Kunden löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Stornieren";
      }
    });
  }

  pageChanged(event: any): void {
    this.spinner.show();
    this.page = event.page;
    this.loadPage();
  }

getRoomPercentage(obj): number {
  const totalRoom = Number(obj.totalRoom);
  const roomAddedWhist = Number(obj.roomAddedWhist);
  if (!obj.totalRoom || !obj.roomAddedWhist) {
    return 0;
  }
  return (obj.roomAddedWhist / obj.totalRoom) * 100;
 }

  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: ""
    }
    this.getClientList(payload);
  }

  getClientList(payload? : any){

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        search: ""
      };
    };

    this.clientService.getClientList(payload).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        console.log("response ", data);
        this.clientList = data.body.clientRec;
        this.totalItems = data.body.totalRecord;
        if(this.totalItems == 0){
          // this.toastr.error("No Client Found");
          this.toastr.error(this.translationService.instant('listClient.noClientFoundMsgTsFile'));

        } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }

  deleteClient(str: Boolean, obj: any) {

    if (str) {
      console.log("obj.id", obj.id);

      let payload = {
        id: obj.id
      };

      this.clientService.deleteClient(payload).subscribe((data: any) => {
        if (data.status === 200) {
          this.loadPage();
          setTimeout(() => {
            // this.toastr.success('Client Deleted Successfully', '');
            this.toastr.success(this.translationService.instant('listClient.clientDeletedMsgTsFile'));

          }, 1000);
        }
        else {
          this.toastr.error(data.body.message, '');
        }
      }, err => {
        console.log('error', err)
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
        else {
          // this.toastr.error('Something went wrong. Please try again later', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

        }
      });
    }
  }

  editClient(obj: any) {
    console.log("obj.id", obj.id)
    this.router.navigate([APP_ROUTES.editClient], { queryParams: { cId: obj.id} }).then(() => { });
  }

  viewClient(obj: any) {
    console.log("obj.id", obj.id)
    this.router.navigate([APP_ROUTES.editClient], { queryParams: { cId: obj.id , op : 'view'} }).then(() => { });
  }

  search(event : any , cond ?: Boolean){


    let search ;
    if(cond){
      search = event;
    }
    else{
      search = event.target.value;
    }

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      search: search
    };
    this.getClientList(payload);
  }

  clearSearch(el : any){
    el.value = "";
    this.getClientList();
  }

  openTimeline(obj: any) {
    console.log("obj.id", obj.id)
    this.router.navigate([APP_ROUTES.openTimeline], { queryParams: { rId: obj.id} }).then(() => { });
  }

  blockUnblockClient(id : any , event:any){
    console.log("Id ",id," event.target.checked ", event.currentTarget.checked)
    console.log(" !event.target.checked ", !event.currentTarget.checked)

    let payload = {
      id : id,
      status : event.currentTarget.checked == true ? 0 : 1
    };

    this.clientService.blockUnblockClient(payload).subscribe((data: any) => {
			if (data.status === 200) {
        console.log("RESPONSE",data);
        if(payload.status == 0){
          // this.toastr.success('Client blocked successfully.', '');
          this.toastr.success(this.translationService.instant('listClient.clientBlock'));

        }
        else{
          // this.toastr.success('Client unblocked successfully.', '');
          this.toastr.success(this.translationService.instant('listClient.clientUnblock'));
        }
        
      }
      else{
        event.target.checked = !event.target.checked;
      }
		}, err => {
			this.toastr.error(err.error.message, '');
		});

  }

  openClientSideDashboard(id : any){
    
    let payload = {
      client_id : id      
    };

    this.clientService.openClientSideDashboard(payload).subscribe((data: any) => {
      console.log("RESPONSE",data);
			if (data.status === 200) {
        console.log("URL ",environment.clientSideUrl + "admin-login-loader?key=" + encodeURIComponent(data.body.encode));
        window.open(environment.clientSideUrl + "admin-login-loader?key=" + encodeURIComponent(data.body.encode) , "_blank");
      }
		}, err => {
			this.toastr.error(err.error.message, '');
		});
  }
  goToCartPage(client:any){
    localStorage.setItem('selectedClient', client.first_name + ' ' + client.last_name);
    this.router.navigate([APP_ROUTES.clientCartpage + client?.id])
    }
}
