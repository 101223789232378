import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  constructor(private http: HttpClient,
    private _FileSaverService: FileSaverService) { }

  public sendMessage(payload : any): Observable<any> {
    const url: string = ApiRoutes.sendMessage;
    return this.http.post(url, payload , { observe: 'response' });
  }

  public getMessageHistory(payload : any): Observable<any> {
    const url: string = ApiRoutes.getUploadedDocList;
    return this.http.post(url, payload , { observe: 'response' });
  }

  public listDocCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.listDocCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addProjectDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProjectDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateDocByID(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateDocByID;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public updateReadMessageCount(payload : any): Observable<any> {
    const url: string = ApiRoutes.updateReadMessageCount;
    return this.http.post(url, payload , { observe: 'response' });
  }

  public templateWiseDocs(payload: any): Observable<any> {
    const url: string = ApiRoutes.templateWiseDocs;
    return this.http.post(url, payload, { observe: 'response' });
  }

   public getAllClientProjects(): Observable<any> {
    const url: string = ApiRoutes.getAllClientProjects;
    return this.http.get(url, { observe: 'response' });
  }
  public uploadDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getClientDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getClientDetails;
    return this.http.post(url, payload ,{ observe: 'response' });
  }
  public getTempDocByID(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTempDocByID;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public DeleteDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.DeleteDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getUploadedDocList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getUploadedDocList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  onDown(type: string, fromRemote: boolean) {
    const fileName = type;
    if (fromRemote) {
      this.http.get(environment.imgBaseUrl + type, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe(res => {
        this._FileSaverService.save(res.body, fileName);
      });
      return;
    }
  }
}
