<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
  <!-- Content Header (Page header) -->
  <section class="content-header p-0 mb-3">
    <div class="row mb-2 no-gutters">
      <div class="col-lg-6 col-12">
        <h1>
          <!-- Projects -->
          {{ "listProject.Projects" | translate }}
        </h1>
      </div>
      <div class="col-lg-6 col-12 mt-2 mt-lg-0">
        <div class="d-lg-flex justify-content-end">
          <div class="mr-3">
            <div class="input-group">
              <input
                #searchVA
                type="text "
                class="form-control"
                placeholder="{{ 'listProject.searchProject' | translate }}"
                (keyup.enter)="search($event)"
              />
              <div class="input-group-append">
                <span class="input-group-text f-700"
                  ><i
                    class="fa fa-search"
                    (click)="search(searchVA.value, true)"
                  ></i
                ></span>
              </div>
              <div class="input-group-append" (click)="clearSearch(searchVA)">
                <span class="input-group-text f-700 bg-primary-2"
                  ><i class="fas fa-redo-alt"></i
                ></span>
              </div>
            </div>
          </div>
          <div *ngIf="permissions.project == 1">
            <a
              class="btn btn-primary mt-2 mt-lg-0"
              href="javascript:; "
              [routerLink]="['../' + APP_ROUTES.addProject]"
              ><i class="fa fa-plus"></i>
              <!-- Add Project -->
              {{ "addProject.heading" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Content Header (Page header)  -->
  </section>

  <div class="card card-primary">
    <div class="card-header">
      <div class="w-100 d-flex justify-content-between">
        <span>
          <!-- Project Listing -->
          {{ "listProject.srojectListing" | translate }}
        </span>
        <span>
          <!-- Total Projects -->
          {{ "listProject.totalProjects" | translate }} {{ totalItems }}</span
        >
      </div>
    </div>

    <div class="card-body table">
      <table class="table table-bordered table-striped table-hover">
        <thead class="table-dark">
          <tr>
            <th style="width: 70px">
              <!-- Sr No. -->
              {{ "listClient.srNo" | translate }}.
            </th>
            <th>{{ "addProject.projectName" | translate }}</th>
            <th>
              <!-- Project Location -->
              {{ "listProject.projectLocation" | translate }}
            </th>
            <th>
              <!-- Completion Date -->
              {{ "listProject.CompletionDate" | translate }}
            </th>
            <th>
              <!-- Units -->
              {{ "listProject.Units" | translate }}
            </th>
            <th style="width: 100px" class="text-center">
              <!-- Action -->
              {{ "addFeature.actions" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let obj of projectList; index as i">
            <td class="text-center" *ngIf="page === 1">{{ i + 1 }}</td>
            <td *ngIf="page !== 1">{{ page * 10 - 10 + (i + 1) }}</td>
            <td>{{ obj.name }}</td>
            <td>{{ obj.location }}</td>
            <td>
              {{
                obj.complete_project_date
                  .split("-")
                  .reverse()
                  .join("-")
                  .replace("-", "/")
                  .replace("-", "/")
              }}
            </td>
            <td>{{ obj.totalUnits }}</td>

            <td style="width: 150px">
              <div
                class="w-100 d-inline-flex justify-content-between align-items-center"
              >
              
                <a href="javascript:; " (click)="viewProject(obj)"
                  ><i class="fa fa-eye"></i
                ></a>
                <a
                  *ngIf="permissions.project == 1"
                  href="javascript:; "
                  (click)="editProject(obj)"
                >
                  <i class="fa fa-pencil-alt"></i
                ></a>
                <span *ngIf="permissions.project == 1"
                  ><a
                    href="javascript:; "
                    mwlConfirmationPopover
                    [popoverTitle]="popoverTitle"
                    [popoverMessage]="popoverMessage"
                    [confirmText]="confirmText"
                    [cancelText]="cancelText"
                    placement="left"
                    (confirm)="deleteProject(true, obj)"
                    (cancel)="deleteProject(false, obj)"
                  >
                    <i class="fa fa-trash text-danger"></i></a
                ></span>
              </div>
            </td>
          </tr>

          <!-- <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td>lorum lipsum location, Location</td>

                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>

                    <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td>lorum lipsum location, Location</td>

                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>

                    <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td>lorum lipsum location, Location</td>

                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr> -->
        </tbody>
      </table>
    </div>
  </div>
  <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
    <div class="col-md-12 text-right">
      <div class="pagination justify-content-end">
        <div class="page-item">
          <pagination
            [boundaryLinks]="showBoundaryLinks"
            [firstText]="firstText"
            [lastText]="lastText"
            [previousText]="prevText"
            [nextText]="nextText"
            [itemsPerPage]="itemsPerPage"
            [totalItems]="totalItems"
            [(ngModel)]="page"
            (pageChanged)="pageChanged($event)"
            [maxSize]="maxSize"
          ></pagination>
          <!-- <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    Next
                  </ng-container>
                </ng-template>
    
                <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    Previous
                  </ng-container>
                </ng-template>
    
                <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                  Last
                  </ng-container>
                </ng-template>
                 
                <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                  First
                  </ng-container>
                </ng-template> -->
        </div>
      </div>
    </div>
  </div>
</div>
