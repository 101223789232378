<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->
    <!-- <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
                    <div class="col-lg-6 col-12">
                        <h1>Edit Project</h1>
                    </div>
                    <div class="col-lg-6 col-12 mt-2 mt-lg-0">
                        <div class="d-lg-flex justify-content-end">
                            <div class="mr-3">
                                <div class="input-group ">
                                    <input #searchVA type="text " class="form-control " placeholder="Search Project" (keyup.enter)=search($event)>
                                    <div class="input-group-append ">
                                        <span class="input-group-text f-700 "><i class="fa fa-search" (click)=search(searchVA.value,true) ></i></span>
                                    </div>
                                    <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                        <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a class="btn btn-primary " href="javascript:; " [routerLink]="['../' + APP_ROUTES.addProject]"><i
                                    class="fa fa-plus "></i> Add Project</a>
                            </div>
                        </div>
                    </div>
        </div>
     Content Header (Page header)
</section> -->


    <div class="card card-primary" >
        <div class="card-header" >
            <div class="w-100  d-flex justify-content-between">
                <span *ngIf="!operation"> 
                    <!-- Edit Project -->
                        {{'editProject.heading1' | translate}}
                </span>
                <span *ngIf="operation">
                     <!-- View Project -->
                        {{'editProject.heading2' | translate}}
                </span>
                <!-- <span> Add Project</span> -->
                <span *ngIf="operation === 'view'">
                    <!-- Total Unit  -->
                    {{'editProject.heading3' | translate}} {{totalUnits}}</span>
            </div>

        </div>

        <div class="card-body ">
            <form novalidate [formGroup]="form">
                <div class="row">
                    <div class="col-md-6 p-relative z-999">
                        <div class="form-group">
                            <label>
                                <!-- Project Name -->
                                    {{'addProject.projectName' | translate}} <span class="text-danger">*</span></label>
                            <input [readonly]="operation === 'view'" type="text" placeholder="{{'addProject.enterProjectName' | translate}}" formControlName="name" class="form-control">

                            <ng-container *ngIf="form.controls['name'].errors &&
              (form.get('name').dirty || form.get('name').touched)">
                                <small class="text-danger error-msg" *ngIf="form.controls['name'].hasError('required')">* {{'addProject.projectNameRequired' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['name'].hasError('maxlength')">*{{'menu.charLimit80' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['name'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['name'].hasError('whitespace')">*
                                    <!-- Whitespace not allowed -->
                                    {{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                            </ng-container>

                        </div>
                        <div class="form-group">
                            <label>
                                <!-- Location  -->
                                    {{'addProject.location' | translate}}<span class="text-danger">*</span></label>
                            <input [readonly]="operation === 'view'" type="text" placeholder="{{'addProject.enterLocation' | translate}}" formControlName="location" class="form-control">

                            <ng-container *ngIf="form.controls['location'].errors &&
              (form.get('location').dirty || form.get('location').touched)">
                                <small class="text-danger error-msg" *ngIf="form.controls['location'].hasError('required')">*Location is
                                    required.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['location'].hasError('maxlength')">*{{'menu.charLimit80' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['location'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['location'].hasError('whitespace')">*
                                        {{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                            </ng-container>

                        </div>
                        <div class="form-group">
                            <label>
                                <!-- Description  -->
                                    {{'addProject.description' | translate}}<span class="text-danger">*</span></label>
                            <textarea [readonly]="operation === 'view'" class="form-control" placeholder="{{'addProject.enterprojectdescription' | translate}}" formControlName="description"></textarea>


                            <ng-container *ngIf="form.controls['description'].errors &&
              (form.get('description').dirty || form.get('description').touched)">
                                <small class="text-danger error-msg" *ngIf="form.controls['description'].hasError('required')">* {{'addProject.descriptionRequired' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['description'].hasError('maxlength')">*{{'addClient.charLimit300' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['description'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="form.controls['description'].hasError('whitespace')">*{{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                            </ng-container>

                        </div>
                        <!-- <div class="row">
                            <div class="form-group col-md-6">
                                <label>Complete Project Wishlist by <span class="text-danger">*</span></label>
                                <input [readonly]="true" #date formControlName="date" placeholder="yyyy/mm/dd" type="text" ngbDatepicker
                                    #d="ngbDatepicker" (click)="operation === 'view' ? doNothing() : d.toggle()" class="form-control">
                                <i *ngIf="operation !== 'view'" class="fa fa-calendar" (click)="d.toggle()" style="cursor: pointer;"
                                    aria-hidden="true"></i>

                            </div>
                        </div> -->

                        <div class="row">
                            <div class="form-group col-md-6">
                                <label>
                                    <!-- Complete Project Wishlist By -->
                                    {{'addProject.completionDate' | translate}} <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <!-- <input [readonly]="true" #date formControlName="date" placeholder="yyyy/mm/dd" type="text" ngbDatepicker #d="ngbDatepicker" (click)=" operation === 'view' ? doNothing() : d.toggle()" class="form-control"> -->

                                    <input [readonly]="true" class="form-control" placeholder="dd/mm/yyyy"
                                    name="d2"  ngbDatepicker #d2="ngbDatepicker" 
                                    (click)=" operation === 'view' ? doNothing() : d2.toggle()" formControlName="date">


                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <i class="fa fa-calendar" (click)=" operation === 'view' ? doNothing() : d2.toggle()" style="cursor: pointer;" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>




                        </div>
                        <!-- {{selectedTemplates | json}} -->

                        <div class="w-100" formArrayName="projectTemplates">
                            <div class="row" *ngFor="let obj of projectTemplateControls.controls; let i=index" [formGroupName]="i">
                                <div class="form-group col-auto">
                                    <label>
                                        <!-- Number Of Units  -->
                                        {{'addProject.numberOfUnits' | translate}}<span class="text-danger">*</span></label>
                                    <input [readonly]="operation === 'view'" type="text" placeholder="{{'addProject.enterUnits' | translate}}" formControlName="units" class="form-control">

                                    <ng-container *ngIf="obj.get('units').errors &&
                                    (obj.get('units').dirty || obj.get('units').touched)">
                                        <small class="text-danger error-msg" *ngIf="obj.get('units').hasError('pattern')">*
                                            <!-- Only digits allowed -->
                                            {{'addProject.digitsAllowed' | translate}}
                                            .</small>
                                        <small class="text-danger error-msg" *ngIf="obj.get('units').hasError('required')">*{{'addProject.unitsRequired' | translate}}.</small>
                                        <small class="text-danger error-msg" *ngIf="obj.get('units').hasError('maxlength')">*{{'addFeature.charLimit30' | translate}}.</small>
                                        <small class="text-danger error-msg" *ngIf="obj.get('units').hasError('whitespace')">*{{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                                    </ng-container>

                                </div>

                                <div class="form-group col-auto">
                                    <label *ngIf="operation === 'view'">{{'addProject.selectTemplate' | translate}}<span class="text-danger">*</span></label>
                                    <label *ngIf="operation !== 'view'">
                                        <!-- Select Template -->
                                            {{'addProject.selectTemplate' | translate}} <span class="text-danger">*</span></label>
                                    <select formControlName="template" class="input-group-text f-700" [compareWith]="byTypeId" #mySelect (change)='onOptionsSelected(mySelect.value ,i, $event)' [attr.disabled]="operation === 'view' ? true : null ">
                                        <option hidden value="" disabled selected>
                                            <!-- Select Template -->
                                            {{'addProject.selectTemplate' | translate}}</option>
                                         <ng-container *ngFor="let templateObj of templateList">
                                                <option [data-sanilJaspal]="templateObj" [ngValue]="templateObj">
                                                    {{templateObj.name}}
                                                </option>
                                        </ng-container>
                                    </select>
                                    <ng-container *ngIf="obj.get('template').errors &&
                                (obj.get('template').dirty || obj.get('template').touched)">
                                        <small class="text-danger error-msg" *ngIf="obj.get('template').hasError('required')">*
                                            <!-- Template is
                                            required -->
                                            {{'addProject.templateRequired' | translate}}.</small>
                                    </ng-container>
                                </div>

                                <div class="col-auto" *ngIf="!operation">
                                    <div class="form-group">
                                        <label class="w-100">&nbsp;</label>
                                        <button *ngIf="i!==0" type="button" class="btn btn-danger mr-2" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage"
                                        [confirmText]="confirmText"
                                    [cancelText]="cancelText"
                                        placement="left" (confirm)="removeUnit(true,obj,i)" (cancel)="removeUnit(false,obj,i)"><i
                                                class="fa fa-trash"></i></button>
                                        <button *ngIf="getLastIndexOfArray(i)" type="button" (click)="addProjectTemplate()" class="btn btn-success"><i
                                                class="fa fa-plus"></i></button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- <div class="row no-wrap">
                            <div class="form-group col-md-6">
                                <label>Number of Units <span class="text-danger">*</span></label>
                                <input type="text" formcontrolname="name" placeholder="Enter Room Name"
                                    class="form-control">
                                <small class="text-danger error-msg">*Error message.</small>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Select Template <span class="text-danger">*</span></label>
                                <input type="text" formcontrolname="name" placeholder="Enter Room Name"
                                    class="form-control">
                                <small class="text-danger error-msg">*Error message.</small>
                            </div>
                            <div class="form-group">
                                <label class="w-100"> &nbsp;</label>
                                <button type="button" class="btn btn-success"><i class="fa fa-plus"></i></button>
                            </div>

                        </div> -->

                    </div>

                    <div class="col-md-6 text-center">
                        <div class="img-container">

                            <div class="img-priview">
                                <img *ngIf="form.value.image" [src]="imgBaseUrl + form.value.image" />
                                <label *ngIf="!form.value.image"> {{'editProject.noImageUploaded' | translate}}</label>
                            </div>
                            <input *ngIf="operation !== 'view'" id="selectedFile" class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event)" style="display: none;" />
                            <div class="support-format">
                                ( {{'addProject.supportFormat'|translate}} png, jpeg, jpg )
                            </div>
                            <div class="col-md-auto " *ngIf="operation !== 'view'">
                                <a class="btn btn-danger mt-3 " href="javascript:; " onclick="document.getElementById('selectedFile').click();">
                                    <!-- Upload Image -->
                                    {{'addProduct.uploadImage' | translate}}</a>
                            </div>
                            <!-- <input type="button" value="Browse..." onclick="document.getElementById('selectedFile').click();" /> -->
                            <!-- <button class="btn btn-danger mt-3" (click)="h(form.value.image)">Upload Image</button> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer text-right" *ngIf="!operation">
            <button class="btn btn-primary" [disabled]="form.invalid" (click)="onUpdate()">
                <!-- Update Project -->
                    {{'editProject.updateProject' | translate}}
            </button>
        </div>
        <div class="card-footer text-right" *ngIf="operation">
            <button class="btn btn-primary mr-2" (click)="goToProjectList()">
                <!-- Go To Project List -->
                {{'editProject.goToProjectList' | translate}}</button>
            <button *ngIf="permissions.project == 1" class="btn btn-primary" (click)="editProject()">
                <!-- Edit Project -->
                {{'addProject.edit' | translate}}</button>
        </div>
    </div>
    <!-- {{form.value | json}} -->
</div>