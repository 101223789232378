import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ProductService } from '../../product.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.scss'],
})
export class UpdateProductComponent implements OnInit {
  obj: any;
  mainForm: any;
  controlValue: any;

  showInput: Boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private service: ProductService,
    private toastr: ToastrService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    // this.mainForm = this.fb.group({
    //   product_id : [this.obj.product_id],
    //   feature_id: [this.obj.feature_id],
    //   featureName: [this.obj.featureName, [Validators.required, Validators.maxLength(30)]],
    //   controlType : [this.obj.controlType],
    //   controlValue: new FormArray([]),
    //   featureSurcharge: [this.obj.featureSurcharge]
    // });

    // this.controlValue = this.mainForm.get("controlValue") as FormArray;

    // for(let obj of this.obj.controlValue){
    //   let innerForm = this.fb.group({
    //     id : [obj.id],
    //     value: [obj.value],
    //     price: [obj.price,[Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9,.]*$/)]]
    //   });
    //   this.controlValue.push(innerForm);
    // }
    // console.log("mainForm ,", this.mainForm.value,this.controlValue.controls);

    this.mainForm = this.fb.group({
      product_id: [this.obj.product_id],
      feature_id: [this.obj.feature_id],
      featureName: [
        this.obj?.CatalogueFeature?.label_name,
        [Validators.required, Validators.maxLength(30)],
      ],
      controlType: [this.obj.control_type],
      controlValue: new FormArray([]),
      featureSurcharge: [this.obj.CatalogueFeature?.surcharge],
    });

    this.controlValue = this.mainForm.get('controlValue') as FormArray;

    for (let obj of [this.obj?.control_value]) {
      let innerForm = this.fb.group({
        id: [this.obj.id],
        value: [obj?.value || obj],
        price: [
          this.obj.price,
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(/^[0-9,.]*$/),
          ],
        ],
      });
      this.controlValue.push(innerForm);
    }
    console.log('mainForm ,', this.mainForm.value, this.controlValue.controls);
  }

  closeModal() {
    this.activeModal.close();
  }

  updateFeature(inputValue?: any): void {
    let payload = {
      productFeature: this.mainForm.value,
    };
    this.service.updateProductFeature(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          // console.log('updateProductFeature', data);
          this.toastr.success(
            this.ts.instant('updateProduct.productFeatureEditedMsgTsFile'),
            '',
            { timeOut: 5000 }
          );
          this.activeModal.close();
        } else {
          this.toastr.error(data.body.message, '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          this.toastr.error('Something went wrong. Please try again later', '');
        }
        this.activeModal.close(this.mainForm);
      }
    );
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}
