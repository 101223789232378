<div class="card">
  <!-- /.card-header -->
  <!-- form start -->
  <div role="form">
    <div class="card-header">
      <h3 class="card-title" *ngIf="permissions.catalogue == 1">
        <!-- Add Features -->
        {{ "addFeature.heading1" | translate }}
      </h3>
      <h3 class="card-title" *ngIf="permissions.catalogue == 0 || !permissions.catalogue.trim()">
        <!-- List Features -->
        {{ "addFeature.heading2" | translate }}
      </h3>

      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="card-body">
      <h6>{{ parentNode.title }}</h6>
      <hr />
      <form novalidate [formGroup]="controlTypeForm">
        <div class="row" *ngIf="permissions.catalogue == 1">
          <div class="col-md-12">
            <div class="form-group w-100">
              <label for="exampleInputEmail1">
                <!-- Field name -->
                {{ "addFeature.fieldName" | translate }}
              </label>

              <input type="text" class="form-control" placeholder="{{ 'addFeature.fieldNameEnter' | translate }}"
                formControlName="labelName" />
              <!-- <input type="text" #controlType class="form-control" placeholder="controlType" formControlName="controlType"> -->

              <ng-container *ngIf="
                  controlTypeForm.controls['labelName'].errors &&
                  (controlTypeForm.get('labelName').dirty ||
                    controlTypeForm.get('labelName').touched)
                ">
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['labelName'].hasError('required')
                  ">
                  *
                  <!-- Label name is
                                    required. -->
                  {{ "addFeature.labelNameRequired" | translate }}</small>
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['labelName'].hasError('maxlength')
                  ">*
                  <!-- Maximum of 30 characters. -->
                  {{ "addFeature.charLimit30" | translate }}</small>
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['labelName'].hasError('whitespace')
                  ">*
                  <!-- Whitespace not allowed. -->
                  {{ "addFeature.whitespaceNotAllowed" | translate }}</small>
              </ng-container>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['labelName'].hasError('pattern')
                ">*
                <!-- No special character allowed. -->
                {{ "addFeature.specialCharMsg" | translate }}</small>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group w-100">
              <label>
                <!-- Control type -->
                {{ "addFeature.controlType" | translate }}
              </label>

              <select formControlName="controlType" class="form-control" (change)="showVal($event)">
                <option hidden value="" disabled selected>
                  <!-- Choose Control Types -->
                  {{ "addFeature.chooseControlTypes" | translate }}
                </option>
                <option value="dropDown">
                  <!-- Drop Down -->
                  {{ "addFeature.dropDown" | translate }}
                </option>
                <option value="input">
                  <!-- Input -->
                  {{ "addFeature.Input" | translate }}
                </option>
              </select>

              <ng-container *ngIf="
                  controlTypeForm.controls['controlType'].errors &&
                  (controlTypeForm.get('controlType').dirty ||
                    controlTypeForm.get('controlType').touched)
                ">
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['controlType'].hasError('required')
                  ">*
                  <!-- Control Value is
                                    required. -->
                  {{
                  "addFeature.controlValueRequired"
                  | translate
                  : {
                  value: ("addFeature.controlType" | translate)
                  }
                  }}</small>
              </ng-container>
            </div>
          </div>

          <div class="col-md-3" *ngIf="controlTypeForm?.controls?.controlType?.value === 'input'">
            <div class="form-group w-100">
              <label>
                <!-- Control Value -->
                {{ "addFeature.feature" | translate }}</label>

              <input type="text" class="form-control" placeholder="{{ 'addFeature.featureenter' | translate }}"
                formControlName="controlTypeValue" /><small *ngIf="show">
                <!-- Please enter comma separated value -->
                {{ "addFeature.cdvMsg" | translate }}</small>

              <ng-container *ngIf="
                  controlTypeForm.controls['controlTypeValue'].errors &&
                  (controlTypeForm.get('controlTypeValue').dirty ||
                    controlTypeForm.get('controlTypeValue').touched)
                ">
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['controlTypeValue'].hasError(
                      'required'
                    )
                  ">*
                  <!-- Control Value is
                                    required. -->
                  {{
                  "addFeature.controlValueRequired"
                  | translate
                  : {
                  value: ("addFeature.feature" | translate)
                  }
                  }}</small>
                <small class="text-danger error-msg" *ngIf="
                    controlTypeForm.controls['controlTypeValue'].hasError(
                      'maxlength'
                    )
                  ">*
                  <!-- Maximum of 30 characters. -->
                  {{ "addFeature.charLimit30" | translate }}</small>
              </ng-container>
              <small class="text-danger error-msg" *ngIf="
                  controlTypeForm.controls['controlTypeValue'].hasError(
                    'pattern'
                  )
                ">*
                <!-- No special character allowed. -->
                {{ "addFeature.specialCharMsg" | translate }}</small>
            </div>
          </div>
          <div class="col-md-3" *ngIf="
              permissions.catalogue == 1 &&
              controlTypeForm?.controls?.controlType?.value === 'input'
            ">
            <div class="form-group w-100 imgup">
              <label>{{'addFeature.uploadImage' | translate}} <small>(support png, jpeg, jpg)</small></label>
              <input type="file" #myInput class="form-control h-auto" (change)="uploadImage($event, null)" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group w-100">
              <label>
                <!-- Surcharge -->
                {{ "addFeature.surcharge" | translate }}
              </label>
              <!-- <input type="text" #surgeCharge class="form-control" placeholder="surgeCharge" formControlName="surgeCharge"> -->

              <div class="form-group form-check">
                <input type="checkbox" formControlName="surgeCharge" id="acceptTerms" class="form-check-input" />
                <label for="acceptTerms" class="form-check-label">
                  <!-- Apply surge -->
                  {{ "addFeature.applySurge" | translate }}</label>
              </div>

              <!-- <select formControlName="surgeCharge" class="form-control">
                              <option disabled>Choose surgeCharge</option>
                              <option value="1">True</option>
                              <option value="0">False</option>
                          </select> -->
            </div>
          </div>
        </div>

        <div class="add-drop-down-list" [hidden]="
            controlTypeForm?.controls?.controlType?.value !== 'dropDown'
          ">
          <div class="addon-feature-lsiting sss">
            <div class="row" formArrayName="dropdownarray">
              <ng-container *ngFor="let drop of getDropdownList.controls; let i = index">
                <ng-container [formGroupName]="i">
                  <div class="col-md-4">
                    <label>
                      <!-- Control Value -->
                      {{ "addFeature.feature" | translate }}</label>
                    <input type="text" placeholder="{{ 'addFeature.featureenter' | translate }}" class="form-control"
                      formControlName="controlTypeVal" />
                    <ng-container *ngIf="
                        drop.controls['controlTypeVal'].errors &&
                        (drop.get('controlTypeVal').dirty ||
                          drop.get('controlTypeVal').touched)
                      ">
                      <small class="text-danger error-msg" *ngIf="
                          drop.controls['controlTypeVal'].hasError('required')
                        ">*
                        <!-- Control Value is
                                        required. -->
                        {{
                        "addFeature.controlValueRequired"
                        | translate
                        : {
                        value: ("addFeature.feature" | translate)
                        }
                        }}</small>
                      <small class="text-danger error-msg" *ngIf="
                          drop.controls['controlTypeVal'].hasError('maxlength')
                        ">*
                        <!-- Maximum of 30 characters. -->
                        {{ "addFeature.charLimit30" | translate }}</small>
                    </ng-container>
                    <small class="text-danger error-msg" *ngIf="
                        drop.controls['controlTypeVal'].hasError('pattern')
                      ">*
                      <!-- No special character allowed. -->
                      {{ "addFeature.specialCharMsg" | translate }}</small>
                  </div>
                  <div class="col-md-4">
                    <label>{{'addFeature.uploadImage' | translate}}<small>(support png, jpeg, jpg)</small></label>
                    <input type="file" #myInput class="form-control" formControlName="controlTypeImg"
                      (change)="uploadImage($event, i, drop)" />
                  </div>
                  <div class="col-md-4">
                    <label class="d-block">&nbsp;</label>
                    <button type="button" class="removeitem" (click)="removeField(i)">
                      <img src="assets/img/cros.png" />
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <button class="btn btn-primary-new mt-3" (click)="addNewFields()">
                <img src="assets/img/plus.png" />
                {{ "addFeature.addfeaturetype" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>

      <hr *ngIf="permissions.catalogue == 1" />
      <p class="w-100 text-right">
        <button *ngIf="permissions.catalogue == 1" [disabled]="this.isUploadCLick ? !this.isUploaded : false"
          type="button" class="btn btn-primary" (click)="addFeature()">
          <!-- [disabled]="!controlTypeForm.valid || booleanCondition" -->
          <!-- Add field -->
          {{ "addFeature.addField" | translate }}
        </button>
      </p>

      <div class="card mt-3">
        <div class="card-body table-responsive">
          <table class="table table-striped table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>{{ "addFeature.name" | translate }}</th>
                <th>{{ "addFeature.feature" | translate }}</th>
                <th>{{ "" }}</th>
                <th>{{ "addFeature.surcharge" | translate }}</th>

                <th *ngIf="permissions.catalogue == 1">
                  {{ "addFeature.actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let obj of featurelist">
                <td class="align-middle">
                  <div class="form-check pl-0">
                    <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" /> -->
                    <label class="form-check-label" for="exampleCheck1">{{ obj.label_name }}
                    </label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="ft-wrapper">
                    <div class="drop-feature" *ngFor="let opt of getPureStringArr(obj); let id = index">
                      <img [src]="opt?.img" class="ft-img" *ngIf="opt?.img" />
                      <span>{{ opt?.val }}</span>
                    </div>
                  </div>
                </td>
                <!-- <td class="align-middle" *ngIf="obj.control_type === 'input'">
                  <div *ngIf="obj.control_type === 'dropDown'">
                    <select class="form-control">
                      <option
                        *ngFor="let opt of getPureString(obj.control_value)"
                      >
                        <ng-container>{{ opt }}</ng-container>
                      </option>
                    </select>
                  </div>
                  <div *ngIf="obj.control_type === 'input'">
                    <label class="form-check-label"
                      >{{ obj.control_value }}
                    </label>
                  </div>
                </td>
                <td class="align-middle" *ngIf="obj.control_type === 'input'">
                  <div *ngIf="obj.image" class="thumb-image">
                    <img [src]="obj.image" />
                  </div>
                  <div *ngIf="!obj.image" class="thumb-image">
                    {{ "addFeature.noImage" | translate }}
                  </div>
                </td> -->

                <td *ngIf="obj.surcharge === 1">
                  {{ "addFeature.yes" | translate }}
                </td>
                <td *ngIf="obj.surcharge === 0">
                  {{ "addFeature.no" | translate }}
                </td>

                <td class="align-middle mw-150 mw-150" *ngIf="permissions.catalogue == 1">
                  <button (click)="editNode(obj)" class="btn-sm-round btn-edit bg-grey text-dark">
                    <i class="fas fa-pencil-alt"></i>
                  </button>
                  <button (click)="viewFeature(obj)" class="btn-sm-round btn-edit bg-grey text-dark">
                    <i class="fa fa-eye"></i>
                  </button>

                  <!-- <a href="javascript:; " (click)="viewProject(obj)"
                  ></a> -->

                  <!-- <button (click)="deleteNode(obj.id)" class="btn-delete bg-red"><i class="fa fa-minus"></i></button> -->

                  <button class="btn-sm-round btn-delete bg-red" mwlConfirmationPopover [confirmText]="confirmText"
                    [cancelText]="cancelText" [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage"
                    placement="top" (confirm)="showDeleteLocationPopup(true, obj.id)"
                    (cancel)="showDeleteLocationPopup(false, obj.id)">
                    <i class="fa fa-minus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>

      <!-- <ul class="prod-list d-none">
        <li *ngFor="let obj of featurelist">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">{{obj.label_name}} </label>
          </div>
          <div *ngIf="obj.control_type==='dropDown'">
            <select class="form-control mx-2">
              <option *ngFor="let opt of obj.control_value.split(',')">{{opt}}</option>
           
            </select>
          </div>
          <div *ngIf="obj.control_type==='input'">
            <label class="form-check-label">{{obj.control_value}} </label>
          </div>
          <div class="thumb-image mx-2"><img [src]="obj.image" /></div>
          <button (click)="editNode(obj)" class="btn-sm-round btn-edit bg-grey text-dark"><i class="fas fa-pencil-alt"></i></button>
          <button (click)="deleteNode(obj.id)" class="btn-sm-round btn-delete bg-red"><i class="fa fa-minus"></i></button>

          

        </li>
      </ul>-->
    </div>
    <!-- /.card-body -->

    <p class="text-danger ml-2">
      *
      <!-- Surcharge would be applicable -->
      {{ "addFeature.surchargeApplicable" | translate }}
    </p>
  </div>
</div>