import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  
  private setlanguage = new BehaviorSubject('en');
  currentlanguage = this.setlanguage.asObservable()
  
  constructor() { }

  setSeletedLangugae(lang){    
    this.setlanguage.next(lang);
  }

}
