import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { DocCategoryService } from './doc-category.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-doc-category',
  templateUrl: './doc-category.component.html',
  styleUrls: ['./doc-category.component.scss'],
})
export class DocCategoryComponent implements OnInit {
  DocCategorylist:any[]= [];
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Document Category?';
  confirmText = 'Confirm';
  cancelText = 'Cancel';
  docCatId: number;
  showBoundaryLinks = true;
  itemsPerPage = environment.itemsPerPage;
  maxSize = 4;
  // totalItems: any;
  totalItems: any = 0;
  page: number = 1;
  permissions: any;
  searchElement = '';
  firstText = '<<';
  lastText = '>>';
  prevText = '<';
  nextText = '>';
  pagetoload = 1;
  startPage = 1;
  constructor(
    private docservice: DocCategoryService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private baseService: BaseService,
    private sharedService: SharedService,
    private ts: TranslateService
  ) {}
  docForm = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      this.noWhitespaceValidator,
      Validators.pattern(/^[a-zA-Z0-9_ÄäÖöÜüÆæß,\s]*$/),
    ]),
  });

  ngOnInit(): void {
    this.permissions = this.sharedService.getPermissions();
    this.pagetoload = 1;
    this.getDocList();

    // this.spinner.show();
    this.baseService.currentlanguage.subscribe((val) => {
      if (val == 'en') {
        this.popoverTitle = 'Delete';
        this.popoverMessage = 'Delete Document Category?';
        this.confirmText = 'Confirm';
        this.cancelText = 'Cancel';
      } else {
        this.popoverTitle = 'Löschen';
        this.popoverMessage = 'Dokumentenkategorie löschen?';
        this.confirmText = 'Bestätigen';
        this.cancelText = 'Stornieren';
      }
    });
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  pageChanged(event: any): void {
    this.spinner.show();
    this.pagetoload = event.page;
    this.loadPage();
  }
  loadPage() {
    let payload = {
      page_start: this.pagetoload,
      page_limit: this.itemsPerPage,
      // search: this.searchElement,
      search: '',
    };
    this.getDocList(payload);
  }
  search(event: any, cond?: Boolean) {
    let search;
    if (cond) {
      search = event;
    } else {
      search = event.target.value;
    }
    let payload = {
      page_start: this.pagetoload,
      page_limit: this.itemsPerPage,
      search: search,
    };
    // this.searchElement = search;
    // this.loadPage();
    this.getDocList(payload);
  }
  clearSearch(el: any) {
    el.value = '';
    this.getDocList();
  }
  clearForm() {
    this.docForm.reset();
  }
  getDocListId(data: any) {
    // this.docForm.patchValue(data);
    let val = {
      title: data.title,
    };
    this.docForm.patchValue(val);
    this.docCatId = data.id;
    console.log(data, 'docdata');
  }
  getDocList(payload?: any) {
    if (!payload) {
      this.page = 1;
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        // search: this.searchElement,
        search: '',
      };
    }
    this.docservice.getDocs(payload).subscribe(
      (res) => {
        if (res.status === 200) {
          this.DocCategorylist = [];
          this.spinner.hide();
          this.totalItems = res.body.catalogue_count;
          this.page = this.pagetoload;
          this.DocCategorylist = res.body.catalogue;
          // this.totalItems = res.body.catalogue_count;

          console.log(this.DocCategorylist, 'list');
          console.log(this.totalItems, 'count');
          if (this.totalItems == 0) {
            this.toastr.error('No category Found');
          }
        } else {
          this.toastr.error(res.body.message, '');
        }
      },
      (err) => {
        this.spinner.hide();
        console.log('error', err);
        if (err.error.message) {
          this.toastr.error(err.error.message, '');
        }
      }
    );
  }

  deleteDoclist(str: Boolean, id: number) {
    if (str) {
      let payload = {
        id: id,
      };

      this.docservice.deleteDoc(payload).subscribe((data) => {
        if (data?.body?.status) {
          setTimeout(() => {
            this.toastr.success(
              this.ts.instant('DocCategory.Deletedsuccessfullytsfile'),
              ''
            );
          }, 1000);
          this.getDocList();
        }else{
          setTimeout(() => {
            this.toastr.error(
              data?.body?.message,
              ''
            );
          }, 1000);
        }
        // console.log(data, 'id');
     
      },(err)=>{
        this.toastr.error(this.ts.instant('login.genricErrorTsFile'));
      });
    }
  }

  addDocCategory(data: string) {
    let payload = {
      title: data,
    };
    this.docservice.addDocCategory(payload).subscribe((res) => {
      console.log(res, 'docres');

      if (res.body.status) {
        this.pagetoload = 1;
        this.getDocList();
        setTimeout(() => {
          this.toastr.success(
            this.ts.instant('DocCategory.Addedsuccessfullytsfile'),
            ''
          );
        }, 1000);
      } else {
        this.toastr.error(res.body.message, '');
      }
      // console.log(res, 'addeddata');
    });

    // console.log(data, 'documentadd');
  }

  updateDocCat() {
    let data = this.docForm.value;
    let payload = {
      title: data.title,
      id: this.docCatId,
    };

    this.docservice.updateDocCategory(payload).subscribe((res) => {
      console.log(res, 'response');

      if (res.body.status) {
        this.getDocList();
        setTimeout(() => {
          this.toastr.success(
            this.ts.instant('DocCategory.updatedsuccessfullytsfile'),
            ''
          );
        }, 1000);
      } else {
        this.toastr.error(res.body.message, '');
      }
      console.log(res, 'res1');
    });
  }
}
