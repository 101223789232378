import { TranslateService } from '@ngx-translate/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { TemplateService } from '../template.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageUploadService } from 'src/app/shared/services/image-upload.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit {


  @ViewChild('cropImageModal') cropModal: any;
  templateForm: FormGroup;
  templateId : any;
  operation : any;
  showNextBtn = false;
  diableSaveAsDraft = false;
  isSaved:boolean=false;
  imgBaseUrl = environment.imgBaseUrl;
  private unsubscribe$ = new Subject();
  // cropped imaage 
  
  imageChangedEvent: any = '';
  croppedImage: any;
  fileName: string;
  imageFormat: string;
  imageIndex:any;
  imageWidth:any = 0;
  imageHeight:any = 0;
  isImageScaleDown:any = false;
  imageOrigin:any;

  constructor(private fb:FormBuilder,
    private tempService : TemplateService,
    private toastr: ToastrService,
    private cd:ChangeDetectorRef,
    private modal:NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private translationService : TranslateService
    ) {
    this.templateForm = this.fb.group({
      id : ['',[]],
      name: ['', [Validators.required, Validators.maxLength(60),this.noWhitespaceValidator]],
      templateType: this.fb.array([]) ,
    });
   }

   APP_ROUTES = APP_ROUTES;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.templateId = params['tId'];
      this.operation = params['op']
    });
    localStorage.removeItem('curTempStep');
    if(this.operation === 'edit' || this.operation ===  'save'){
      this.spinner.show();
      this.getTemplateDetails(this.templateId);
    }
    else{
      this.addTemplateType();
    }


  //   this.templateForm.valueChanges.subscribe(x => {
  //     if(this.diableSaveAsDraft===true){
  //       this.diableSaveAsDraft=false;
  //       this.templateForm.patchValue({
  //         id : this.templateId
  //       })
  //     }
  // })


  }

  templateType() : FormArray {
    return this.templateForm.get("templateType") as FormArray
  }

  newTemplateType(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
      description: ['', [Validators.required, Validators.maxLength(300),this.noWhitespaceValidator]],
      image : ['']
    })
  }
   
  addTemplateType() {
    this.templateType().push(this.newTemplateType());
  }
   
  removeTemplateType(i:number) {
    console.log("Remove index ",i)
    let obj = this.templateType().at(i);
    if(this.operation == 'edit' && obj.value.id){
      console.log("Fetched Value ",obj);
      this.deleteTemplateType(obj.value.id,i);
    }
    else{
      this.templateType().removeAt(i);
    }
  }

  deleteTemplateType(id : any, deleteIndex : any){
    let payload = {
      id : id
    }
    this.tempService.deleteTemplateType(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("responseweew ", data);

        this.templateType().removeAt(deleteIndex);
        setTimeout(() => {
          // this.toastr.success('Template type deleted successfully', '');
          this.toastr.success(this.translationService.instant('addTemplate.tempDelMsgTsFile'));

        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }
   
  onSubmit(fromNextStep:boolean) {
    this.diableSaveAsDraft = true;
    console.log(this.templateForm.value);
    
    this.tempService.addTemplate(this.templateForm.value).subscribe((data: any) => {
      if (data.status === 200) {
        this.showNextBtn = true;
        console.log("responseweew ", data);
        this.templateId = data.body.templateRec.id;
        this.isSaved = true;
        // this.router.navigate([APP_ROUTES.addTemplateType],{ queryParams: { tId: data.body.templateRec.id,op : 'save' }}).then(() => {});
        if(!fromNextStep){
          setTimeout(() => {
            // this.toastr.success('Template added successfully', '');
            this.toastr.success(this.translationService.instant('addTemplate.templateAddedMsgTsFile'));
          }, 1000);
        }else{
          let opr;
          if(!this.operation){
            opr = 'save';
          }
          else{
            opr = this.operation;
          }
          this.router.navigate([APP_ROUTES.addTemplateType],{ queryParams: { tId: this.templateId,op : opr }}).then(() => {});
        }
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.showNextBtn = false;
      this.diableSaveAsDraft = false;
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });

  }

  getTemplateDetails(id : any){
    let payload = {
      id : id
    }
    this.tempService.getTemplate(payload).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        console.log("responseweew ", data);

        this.prepareEditForm(data.body.templateRec);
        // setTimeout(() => {
        //   this.toastr.success('Template added successfully', '');
        // }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  prepareEditForm(data : any){
    this.showNextBtn = true;
    this.templateForm.patchValue({
      id : data.id,
      name : data.name
    });

    for(var val of data.TemplateTypes){
      let innerForm =  this.fb.group({
        id : [val.id, [Validators.required]],
        name: [val.name, [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
        description: [val.description, [Validators.required, Validators.maxLength(300),this.noWhitespaceValidator]],
        image : [val.image]
      })
      this.templateType().push(innerForm);
    }

    console.log("FINAL EDIT FORM = ",this.templateForm);

  }

  getRefresh() {
    console.log('refresh');
    
    let url = this.router.url;
    console.log(url);
    // return
    url = url.split('?')[0]
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url],{
        queryParams:{
          tId:this.templateId,
          op:this.operation
        }
      }).then(()=>{
      }));
  }

  onUpdate(fromNextStep:boolean){

    let payload = {
      templateRec: {
        id : this.templateForm.value.id,
        name : this.templateForm.value.name
      },
      templateTypeRec : this.templateForm.value.templateType
    };


    this.tempService.updateTemplate(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("21323 ", data);
        // this.router.navigate([APP_ROUTES.addTemplateType],{ queryParams: { tId: this.templateId,op : 'edit' }}).then(() => {});
        if(fromNextStep){
          let opr;
          if(!this.operation){
            opr = 'save';
          }
          else{
            opr = this.operation;
          }
          this.router.navigate([APP_ROUTES.addTemplateType],{ queryParams: { tId: this.templateId,op : opr }}).then(() => {});
        }else{
        this.getRefresh();
          setTimeout(() => {
            // this.toastr.success('Template updated successfully', '');
            this.toastr.success(this.translationService.instant('addTemplate.templateDeletedMsgTsFile'));
          
          }, 1000);
        }
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }

  getLastIndexOfArray(index : any) : Boolean{
    if(this.templateType().controls.length - 1 == index){
      return true;
    }
    return false;
  }

  nextStep(){
    if(this.operation){
      this.onUpdate(true)
    }else{
      if(!this.isSaved){
        this.onSubmit(true)
      }else{
    let opr;
      if(!this.operation){
        opr = 'save';
      }
      else{
        opr = this.operation;
      }
      this.router.navigate([APP_ROUTES.addTemplateType],{ queryParams: { tId: this.templateId,op : opr }}).then(() => {});
      }
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  uploadImage(event : any, index : any){

    console.log("-----------------Index------------- ",index)
    this.uploadImage2(event,index,true);
    
  }
  
  uploadImage2(event: any, index :any ,dimesnionCheck ?: Boolean): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        this.fileName = '';
        this.imageFormat = '';
        this.fileName = event.target.files[0]?.name;
        this.imageFormat = event.target.files[0]?.type;
        this.imageIndex= null;
        this.imageIndex = index;
        
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject('Please select file less than 5 mb');
        }

        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject('Please upload a valid document.');
        }
      } else {
        reject('Please select a file');
      }
    });
    promise
      .then(resp => {
        this.imageOrigin  = event.target.files[0]
        const isSizeExceed = event.target.files[0].size >= 1024 * 1024;
        this.isImageScaleDown = isSizeExceed ? true : false;
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
            img.onload = () => {
              this.imageWidth = isSizeExceed
                ? +img.width - (+img.width / 100) * 80
                : img.width;
              this.imageHeight = isSizeExceed
                ? +img.height - (+img.height / 100) * 80
                : img.height;
            };
        // this.imageChangedEvent=event;
        this.cd.detectChanges();
        this.open();
      })
      .catch(err => {
        this.toastr.error(err, '');
      });
  }
  

  handleImageAPI(result: any,index : any): any {
    this.spinner.show();
    let input = new FormData();
    input.append('id', result);

    this.tempService
      .uploadImageApi(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          console.log("Patching at index ",index ," img ",resp1.body.filename)
            this.templateType().controls[index].patchValue({
              image: resp1?.body?.filename,
            });
            this.spinner.hide();
            this.cd.detectChanges();
            this.closeModal()
            console.log("Value after patching ",this.templateType().value);
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Something went wrong with image upload', '');
          return null;
        }
      );
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
}



fileChangeEvent(event: any): void {
  // this.imageChangedEvent = event;
}
imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
  this.cd.detectChanges();
  setTimeout(()=>{this.spinner.hide()}, 500)
  this.hideSpinner();
  const imageBlob = this.dataURItoBlob(event.base64 || '');
    const imageFile = new File([imageBlob], this.fileName, {
      type: this.imageFormat || 'image/png',
    });
    this.croppedImage = imageFile;
    console.log(imageFile);
    console.log(
      `Cropped image size before compression: ${
        imageFile.size >= 1024 * 1024
          ? (imageFile.size / (1024 * 1024)).toFixed(2) + ' MB.'
          : (imageFile.size / 1024).toFixed(0) + ' KB.'
      }`
    );
    
    
}
dataURItoBlob(dataURI: string) {
  let url = dataURI.toString();
  const byteString = window.atob(
    url.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
  );
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], {
    type: this.imageFormat || 'image/png',
  });
  return blob;
}
imageLoaded(image: LoadedImage) {
  // show cropper
  setTimeout(()=>{this.spinner.hide()}, 500)
}

imageCroppedStarted(event:any){
  setTimeout(() => {
    this.spinner.show();
  }, 50);
}

imageLoadFailed(event: any) {
  this.hideSpinner();
  this.closeModal();
  this.modal.dismissAll();
  console.log('loading failed...');
  
}
saveImage(modal?: any) {
  this.handleImageAPI(this.croppedImage,this.imageIndex);
}

closeModal(){
  this.hideSpinner();
  this.modal.dismissAll();
  this.imageChangedEvent = null;
  this.croppedImage = null;
  var input:any = document.getElementsByTagName('input');
  for (let index = 0; index < input.length; index++) {
    const element = input[index];
    if(element.type === 'file'){
      element.value = '';
    }
   }
  return;
}

hideSpinner(){
 
  setTimeout(()=>{this.spinner.hide()}, 500)
  
}

open() {
    this.cd.detectChanges();
    this.modal
    .open(this.cropModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
        );
      }

      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
    
    }