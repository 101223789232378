import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../client.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/shared/services/base.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  projectList : any;
  totalItems : any;
  clientId : any;
  operation : any;
  selectedProjects = [];
  popoverTitle = 'Delete';
  popoverMessage = 'Delete Project?';
  confirmText="Confirm";
  cancelText="Cancel";
  permissions : any;
  private unsubscribe$ = new Subject();
  imgBaseUrl = environment.imgBaseUrl;

  form = this.fb.group({
    id : ['',[Validators.required]],
    first_name: ['', [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
    last_name: ['', [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
    email: ['', [Validators.required, Validators.maxLength(60),Validators.email,this.noWhitespaceValidator]],
    contact_number: [''],
    mobile_number: ['', [Validators.required, Validators.pattern(/^[0-9\s]*$/), this.noWhitespaceValidator]],
    address: ['', [Validators.required, Validators.maxLength(300),this.noWhitespaceValidator]],
    postal_code: ['', [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
    city : ['',Validators.required],
    image : [''],
    clientProjects: this.fb.array([]),
    lang_type:[2, [
      Validators.required,
    ]]
  });
 statusList = [
  {name:'Completed', id:1},
  {name:'Active', id:0},
 ]
  constructor(public fb: FormBuilder,
    private clientService: ClientService,
    private toastr: ToastrService,
    private router: Router, 
    private projectService : ProjectService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private baseService : BaseService,
    private sharedService : SharedService,
    private translationService : TranslateService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.permissions = this.sharedService.getPermissions();
    this.route.queryParams.subscribe(params => {
      this.clientId = params['cId'];
      this.clientId = parseInt(this.clientId, 10);
      this.operation = params['op'];
  });


    this.getProjectList();
    this.getClientDetails();

    this.baseService.currentlanguage.subscribe((val)=>{
      if(val=='en'){
        this.popoverTitle="Delete";
        this.popoverMessage="Delete Project?";
        this.confirmText="Confirm";
        this.cancelText="Cancel";
      }
      else{
        this.popoverTitle="Löschen";
        this.popoverMessage="Projekt löschen?";
        this.confirmText="Bestätigen";
        this.cancelText="Stornieren";
      }
    });
  }

  getClientDetails(){

    let payload = {
      id : this.clientId
    }

    this.projectService.getClientDetails(payload).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        console.log("response2222222222222 ", data);
        let formData = data.body.clientRec;
        this.createForm(formData);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  createForm(data : any){
    
    this.form.patchValue({
      id : data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      contact_number: data.contact_number,
      mobile_number: data.mobile_number,
      address: data.address,
      postal_code: data.postal_code,
      city : data?.city,
      image : data.image,
      lang_type:+data?.lang_type
    });
    data.ClientProjects.forEach((obj, index) => {
      console.log("index" ,index); // 0, 1, 2
      console.log("value222" , obj); // 9, 2, 5
        var newObj = this.createClientProject(obj,index);

        // initialise the selected proj arr
        let selectedProjArrObj = {
          oldProjId: '',
          oldProjName: '',
          newProjId: obj.Project.id,
          newProjName: obj.Project.name,
          formIndex: index
        };
        
        console.log(`selectedProjArrObj[${index}]`,selectedProjArrObj)
        this.selectedProjects.push(selectedProjArrObj);


        this.projectsArrayControls().push(newObj);
  });

    console.log("FINAL VALUE", this.form.value)

  }

  createClientProject(obj: any, index?): FormGroup {
    console.log(obj, 'objrrr');
    
    this.getProjectDetails(obj.project_id, index);
    return this.fb.group({
      id : [obj.id ? obj.id : ''],
      project_id: [obj.project_id,
                  [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,\s]*$/),this.noWhitespaceValidator]],
      template_id: [Number(obj.Template ? obj.Template.id : ''), [Validators.required,
                                          Validators.maxLength(30), 
                                          Validators.pattern(/^[0-9_,\s]*$/),
                                          this.noWhitespaceValidator]],
      apartment_name: [obj.apartment_name, [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
      flat_id: [obj.flat_id],
      apartment_id: [obj.apartment_id],
      template_list: [],
      is_completed:[obj.is_completed ,[Validators.required]],
      project_status:[0,[Validators.required]]
    });
  }


  getProjectDetails(id: any, index): void {
    let payload = {
      id : id
    };
    this.spinner.show();
    this.projectService.getProjectDetails(payload).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        const { ProjectTemplates } = data.body.projectRec;
        if (ProjectTemplates) {
          (this.form.get('clientProjects') as FormArray).controls[index].get('template_list').setValue([]);
          (this.form.get('clientProjects') as FormArray).controls[index].get('template_list')
          .setValue(ProjectTemplates.map((d) => d.Template));
          console.log(this.form.value);
        }
        }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      console.log('error', err);
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      } else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  getProjectList(payload?: any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: "100000",
        search: ""
      };
    };

    this.projectService.getProjectList(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.projectList = data.body.projectRec;
        this.totalItems = data.body.totalRecord;
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));
      }
    });
  }

  projectsArrayControls(): FormArray {
    return this.form.get("clientProjects") as FormArray;
  }

  newClientProject(): FormGroup {
    return this.fb.group({
      project_id: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[0-9_,\s]*$/),this.noWhitespaceValidator]],
      apartment_name: ['', [Validators.required, Validators.maxLength(30),this.noWhitespaceValidator]],
      flat_id: [''],
      apartment_id: [''],
      template_id: ['', [Validators.required,
        Validators.maxLength(30),
        Validators.pattern(/^[0-9_,\s]*$/)
      ]],
        template_list: [[]],
        is_completed:[null ,[Validators.required]],
        project_status:[0 ,[Validators.required]]
    });
  }

  addClientProject() {
    this.projectsArrayControls().push(this.newClientProject());
  }

  removeClientProject(i: number,str: Boolean, obj: any) {

    if (str) {
      console.log("str",str,"obj",obj.value.id,"Index",i)

      if(obj.value.id){
        this.deleteClientProject(obj.value.id , i);
      }
      else{
        this.projectsArrayControls().removeAt(i);
      }

    // Delete the value form selected proj arr also
    let indexForDelete;
    for (let proj of this.selectedProjects) {
      if (proj.formIndex == i) {
        indexForDelete = this.selectedProjects.indexOf(proj);
        break;
      };
    };
    if (indexForDelete !== -1) {
      this.selectedProjects.splice(indexForDelete, 1);
    }

    // This method updates the formIndex values in the selectedTemplateList
    this.updateSelectedArrayAfterDelete();}
  }

  updateSelectedArrayAfterDelete(){
    // forarr ka loop ghumao
    // har element ko get kro selected arr me se and oska formIndex upfate kro

    let formArr = this.projectsArrayControls();
    console.log("formArr",formArr)

    console.log("BEFORE SelectedProjects",this.selectedProjects)

    formArr.value.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete" ,index); 
      console.log("value updateSelectedArrayAfterDelete" , obj); 
      
      this.updateIndexesInSelectedProjects(obj,index);
    });

    console.log("AFTER SelectedProjects",this.selectedProjects)

  }

  updateIndexesInSelectedProjects(proj : any , currentIndex : any){
    this.selectedProjects.forEach((obj, index) => {
      console.log("index updateSelectedArrayAfterDelete selectedProjects" ,index); 
      console.log("value updateSelectedArrayAfterDelete selectedProjects" , obj); 

      if(obj.newProjId == proj.project_id){
        obj.formIndex = currentIndex;
        // return
      }

    });
  }

  deleteClientProject(id : any , i : any) {
    let payload = {
      id : id
    };
    this.clientService.deleteClientProject(payload).subscribe((data: any) => {
      if (data.status === 200) {
        this.projectsArrayControls().removeAt(i);
        setTimeout(() => {
          // this.toastr.success('Template Deleted Successfully', '');
          this.toastr.success(this.translationService.instant('editClient.templateDeletedMsgTsFile'));
        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });
  }


  getLastIndexOfArray(index: any): Boolean {
    if (this.projectsArrayControls().controls.length - 1 == index) {
      return true;
    }
    return false;
  }

  onUpdate(): void {
    for (const cProject of this.form.value.clientProjects) {
     
    if (this.form.value.clientProjects.filter
      (
        (formValues) =>
        {
          return (Number(formValues.project_id) === Number(cProject.project_id) &&
        Number(formValues.template_id) === Number(cProject.template_id) &&
        String(formValues.apartment_name).trim().toLocaleLowerCase() ===
        String(cProject.apartment_name).trim().toLocaleLowerCase());
        }
        ).length > 1 ) {
        this.toastr.error(this.translationService.instant('addClient.dublicationErrorMsg'));
        return;
    }
  }

    this.form.patchValue({
      contact_number : this.form.value.contact_number.trim(),
      mobile_number : this.form.value.mobile_number.trim()
    });


    const sendData = {...this.form.value};

    const projects = sendData.clientProjects.map((d) => ({...d}));
    projects.forEach(projectData => delete projectData.template_list );
    sendData.clientProjects = projects;
    this.spinner.show();
    this.clientService.updateClient(sendData).subscribe((data: any) => {
      this.spinner.hide();
      if (data.status === 200) {
        this.router.navigate([APP_ROUTES.listClient]).then(() => { });
        setTimeout(() => {
          // this.toastr.success('Client updated successfully', '');
          this.toastr.success(this.translationService.instant('editClient.clientUpdatedMsgTsFile'));
        }, 1000);
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      this.spinner.hide();
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        // this.toastr.error('Something went wrong. Please try again later', '');
        this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

      }
    });

  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  
  goToClientList() {
    this.router.navigate([APP_ROUTES.listClient]);
  }

  onOptionsTemplateSelected(value: any, index: any, event: any) {
    console.log("Template selected");
  }
  onOptionsSelectedStatus(value: any, index: any, event: any, obj:any){
    let selectedProject = event.target.selectedOptions[0]["data-sanilJaspal"];
    console.log("value", value);
    console.log("index", index)
    console.log("selectedProject ", selectedProject);
    console.log("obj ", obj);
    obj?.controls['project_status'].patchValue(selectedProject?.id === 1 ? 0 : 1);
    obj?.controls['project_status'].updateValueAndValidity();
  }
  onOptionsSelected(value: any, index: any, event: any) {

    let selectedProject = event.target.selectedOptions[0]["data-sanilJaspal"];
    console.log("value", value);
    console.log("index", index)
    console.log("selectedProject ", selectedProject);
    (this.form.get('clientProjects') as FormArray).controls[index].get('template_id').setValue('');
    this.getProjectDetails(selectedProject.id, index);
    this.projectsArrayControls().controls[index].patchValue({
      project_id: selectedProject.id,
    });

    let valueFromProjList;
    let obj;
    let indexForDelete: number;

    if (this.selectedProjects.length > 0) {

      // Check if modified on same index
      for (let proj of this.selectedProjects) {
        if (proj.formIndex == index) {
          valueFromProjList = proj;
          indexForDelete = this.selectedProjects.indexOf(proj);
          break;
        };
      };
    };

    // If mofified on same index then delete the prev obj and push new obj to arr
    if (valueFromProjList) {
      let oldProjId = valueFromProjList.newProjId;
      let oldProjName = valueFromProjList.newProjName;

      obj = {
        oldProjId: oldProjId,
        oldProjName: oldProjName,
        newProjId: selectedProject.id,
        newProjName: selectedProject.name,
        formIndex: index
      };

      if (indexForDelete !== -1) {
        this.selectedProjects.splice(indexForDelete, 1);
        console.log("ARR after del ", this.selectedProjects)
      }
    }
    else {

      obj = {
        oldProjId: '',
        oldProjName: '',
        newProjId: selectedProject.id,
        newProjName: selectedProject.name,
        formIndex: index
      };

    }
    console.log("obj before push ", obj)
    this.selectedProjects.push(obj);
    console.log("ARR after push ", this.selectedProjects)

  }

  checkIfProjAlreadySelected(selectedProject: any, currentIndex: any) {

    let projIsSelectedAgain = false;

    let formArr = this.projectsArrayControls();
    console.log("formArr", formArr)
    console.log("SELECTED PROJ ", selectedProject, " currentIndex ", currentIndex);

    formArr.value.forEach((obj, index) => {
      console.log("index", index);
      console.log("value", obj);
      if (selectedProject.id == obj.project_id && index !== currentIndex) {
        projIsSelectedAgain = true;
        this.projectsArrayControls().at(currentIndex).patchValue({
          project_id: "",
        });
        this.toastr.error(`Project is already selected at ${index + 1} position`, '');
      }
    });

    return projIsSelectedAgain;

  }

  editClient() {
    this.router.navigate([APP_ROUTES.editClient], { queryParams: { cId: this.clientId} }).then(() => { });
  }

  uploadImage(event: any) {
    this.uploadImage2(event);
  }

  uploadImage2(event: any, dimesnionCheck?: Boolean): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject(this.translationService.instant('toastmsg.imagesizelimit', { value: 5 }));
        }


        if (dimesnionCheck) {
          var filesAmount = event.target.files.length;
          const img = new Image();
          img.src = window.URL.createObjectURL(event.target.files[0]);
          for (let i = 0; i < filesAmount; i++) {
            var reader = new FileReader();
            reader.onload = (e: any) => {
              const width = img.naturalWidth;
              const height = img.naturalHeight;
              window.URL.revokeObjectURL(img.src);
              // console.log(width + '*' + height);
              if (width > 1179 && height > 404) {
                event.srcElement.value = null;
                this.toastr.error("Image should be less or equal to 1179 X 404")
                this.form.patchValue({
                  image: '',
                });
                return;
              } else {
              }
            }
            reader.readAsDataURL(event.target.files[i]);
          }
        }


        const allowedArr = ['png', 'jpeg', 'jpg'];
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        if (allowedArr.includes(extension)) {
          const reader = new FileReader();

          reader.onload = (): void => {
            // this.imgURL = reader.result;
            let payload = {
              file,
              contentType
            };
            resolve(payload);
          };
          reader.readAsDataURL(file);
        } else {
          reject(this.translationService.instant('toastmsg.validDoc'));
        }
      } else {
        reject(this.translationService.instant('toastmsg.selectfile'));
      }
    });
    promise
      .then(resp => {
        this.handleImageAPI(resp);
      })
      .catch(err => {
        this.toastr.error(err, '');
      });
  }

  handleImageAPI(result: any): any {
    let input = new FormData();
    input.append('id', result.file);

    this.clientService
      .uploadImageApi(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.form.patchValue({
            image: resp1.body.filename,
          });
        },
        err => {
          // this.toastr.error('Something went wrong with image upload', '');
          this.toastr.error(this.translationService.instant('login.genricErrorTsFile'));

          return null;
        }
      );
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
}

}
