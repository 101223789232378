<div class="content-wrapper card-body">
    <!-- Content Header (Page header) -->
    <!-- <section class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <div class="col-md-auto">
                    <ol class=" breadcrumb ">
                         <li class="breadcrumb-item "><a href="javascript:; ">Dashboard</a></li>
                        <li class="breadcrumb-item active ">List Templates</li>
                    </ol>
                </div>
                <div class="col-md-auto">
                    <div class="row justify-content-end ">
                        <div class="col-md-auto">
                            <div class="w-100">
                                <div class="input-group ">
                                    <input type="text " #searchVA class="form-control " placeholder="Search Template " (keyup.enter)=search($event)>
                                    <div class="input-group-append " (click)=search(searchVA.value,true)>
                                        <span class="input-group-text f-700 "><i class="fa fa-search " aria-hidden="true "></i></span>
                                    </div>
                                    <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                        <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-auto ">
                            <a class="btn btn-primary " href="javascript:; " [routerLink]="[ '../' + APP_ROUTES.addTemplate] "><i
                                class="fa fa-plus "></i> Add Template</a>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </section> -->


    <section class="content-header p-0 mb-3">
        <div class="row mb-2 no-gutters">
            <div class="col-lg-6 col-12">
                <h1>
                    <!-- Template -->
                    {{'listTemplate.temp' | translate}}
                </h1>
            </div>

            <div class="col-lg-6 col-12 mt-2 mt-lg-0">
                <div class="d-lg-flex justify-content-end">
                    <div>
                        <div class="input-group ">
                            <input type="text " #searchVA class="form-control " placeholder="{{'listTemplate.searchTemplate' | translate}}" (keyup.enter)=search($event)>
                            <div class="input-group-append " (click)=search(searchVA.value,true)>
                                <span class="input-group-text f-700 "><i class="fa fa-search " aria-hidden="true "></i></span>
                            </div>
                            <div class="input-group-append " (click)="clearSearch(searchVA) ">
                                <span class="input-group-text f-700 bg-primary-2"><i class="fas fa-redo-alt "></i></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-auto" *ngIf="permissions.template == 1">
                        <a class="btn btn-primary mr-2" href="javascript:; " [routerLink]="[ '../' + APP_ROUTES.addTemplate] "><i
                                    class="fa fa-plus "></i> 
                                    <!-- Add Template -->
                                    {{'listTemplate.addTemp' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Content Header (Page header)  -->
    </section>



    <div class="card card-primary ">
        <div class="card-header">
            <div class="w-100  d-flex justify-content-between">
                <span> 
                    <!-- Templates Listing -->
                    {{'listTemplate.templatesListing' | translate}}</span>
                <span>
                    <!-- Total Templates -->
                        {{'listTemplate.totalTemps' | translate}} <strong>{{totalItems}}</strong></span>
            </div>

        </div>

        <div class="card-body table ">
            <table class="table table-bordered table-striped table-hover ">
                <thead class="table-dark ">
                    <tr>
                        <th style="width:70px ">
                            <!-- Sr No. -->
                            {{'listClient.srNo' | translate}}.</th>
                        <th>
                            <!-- Template Name -->
                            {{'addTemplate.templateName' | translate}}</th>
                        <th>
                            <!-- Published -->
                            {{'listTemplate.published' | translate}}
                        </th>
                        <th style="width:100px " class="text-center " *ngIf="permissions.template == 1">
                            <!-- Action -->
                            {{'addFeature.actions' | translate}}</th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let obj of templateList;index as i ">
                        <td class="text-center" *ngIf="page === 1">{{(i+1)}}</td>
                        <td *ngIf="page !== 1">{{(page*10)-10 + (i+1)}}</td>
                        <td>{{ obj.name }}</td>
                        <td>{{ obj.is_publish === 1 ? "Yes " : "No " }}</td>
                        <td *ngIf="permissions.template == 1">
                            <div class="w-100 text-center ">
                                <!-- <a href="javascript:; "><i class="fa fa-eye "></i></a> -->
                                <a href="javascript:; " (click)="copyTemplate(obj.id) " class="mr-3 "> <i class="fa fa-copy"></i></a>
                                <a href="javascript:; " (click)="editTemplate(obj.id) " class="mr-3 "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; " mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" [confirmText]="confirmText" [cancelText]="cancelText" placement="left " (confirm)="deleteTemplate(true,obj.id) " (cancel)="deleteTemplate(false,obj.id)
                    "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>

                    <!-- <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>

                    <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr>

                    <tr>
                        <td>1</td>
                        <td>2BHK</td>
                        <td style="width:150px ">
                            <div class="w-100 d-inline-flex justify-content-between align-items-center ">
                                <a href="javascript:; "><i class="fa fa-eye "></i></a>
                                <a href="javascript:; "> <i class="fa fa-pencil-alt "></i></a>
                                <a href="javascript:; "> <i class="fa fa-trash text-danger "></i></a>
                            </div>

                        </td>

                    </tr> -->

                </tbody>


            </table>
        </div>
    </div>
    <div class="row pagination-row mt-5 mb-0" [hidden]="totalItems <= itemsPerPage">
        <div class="col-md-12 text-right">
            <div class="pagination justify-content-end">
                <div class="page-item">
                    <pagination [boundaryLinks]="showBoundaryLinks" [firstText]="firstText" [lastText]="lastText" [previousText]="prevText" [nextText]="nextText" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [(ngModel)]="page" (pageChanged)="pageChanged($event)"
                        [maxSize]="maxSize"></pagination>
                </div>
            </div>
        </div>
    </div>

</div>