<ng-container *ngIf="showMenu">
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="javascript:;" class="brand-link">
      <img
        src="/assets/img/logo.png"
        style="max-height: 60px"
        alt="Logo"
        class="brand-image"
      />
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <!-- <img src="/assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
        </div>
        <div class="info">
          <a routerLink="/manage-profile" class="d-block">
            <!-- Admin -->
            <span>{{userName?.firstname +' '+ userName?.lastname }}</span>
           </a
          >
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item has-treeview">
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../' + APP_ROUTES.dashboard]"
              [class.active]="matchUrl('dashboard')"
            >
              <i class="far fa-circle nav-icon"></i>
              <p>
                <!-- Dashboard -->
                {{ "menu.overview" | translate }}
              </p>
            </a>
          </li>
          <li class="nav-item" *ngIf="activeScreens.project_screen == 1">
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../' + APP_ROUTES.ProjectOverview]"
              [class.active]="matchUrl('overview')"
            >
              <i class="fas fa-search-plus nav-icon"></i>
              <p>
                <!-- Project Overview -->
                {{ "menu.ProjectOverview" | translate }}
              </p>
            </a>
          </li>

          <li class="nav-item" *ngIf="activeScreens.template_screen == 1">
            <a
              href="javascript:;"
              [routerLink]="['../' + APP_ROUTES.listTemplate]"
              [class.active]="matchUrl('template')"
              class="nav-link"
            >
              <i class="far fa-window-restore nav-icon"></i>
              <p>
                <!-- Templates -->
                {{ "menu.Templates" | translate }}
              </p>
            </a>
          </li>

          <li class="nav-item has-treeview">
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../' + APP_ROUTES.listProject]"
              [class.active]="matchUrl('project')"
            >
              <i class="fas fa-th-list nav-icon"></i>
              <p>
                <!-- Projects -->
                {{ "menu.Projects" | translate }}
              </p>
            </a>
          </li>

          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.catalogue_screen == 1"
          >
            <a
              href="javascript:;"
              [routerLink]="['../' + APP_ROUTES.categories]"
              routerLinkActive="active"
              class="nav-link"
            >
              <i class="fas fa-book nav-icon"></i>
              <p>
                <!-- Catalogue -->
                {{ "menu.Catalogue" | translate }}
              </p>
            </a>
          </li>

          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.product_screen == 1"
          >
            <a
              href="javascript:;"
              routerLink="/list-product"
              [class.active]="matchUrl('product')"
              class="nav-link"
            >
              <i class="nav-icon fas fa fa-cubes"></i>
              <p>
                <!-- Products -->
                {{ "menu.Products" | translate }}
              </p>
            </a>
          </li>

          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.client_screen == 1"
          >
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../' + APP_ROUTES.listClient]"
              [class.active]="matchUrl('client')"
            >
              <i class="nav-icon fas fa-user"></i>
              <p>
                <!-- Clients -->
                {{ "menu.Clients" | translate }}
              </p>
            </a>
          </li>

          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.document_screen == 1"
          >
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../document-category']"
              [class.active]="matchUrl('document-category')"
            >
              <i class="nav-icon fas fa-file-pdf"></i>
              <p>
                {{ "DocCategory.documentcategory" | translate }}
              </p>
            </a>
          </li>
          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.staff_screen == 1"
          >
            <a
              href="javascript:;"
              class="nav-link"
              [routerLink]="['../' + APP_ROUTES.listStaff]"
              [class.active]="matchUrl('staff')"
            >
              <i class="nav-icon fas fa-users"></i>
              <p>
                <!-- Staff -->
                {{ "menu.Staff" | translate }}
              </p>
            </a>
          </li>

          <li  class="nav-item has-treeview">
            <a routerLink="/privacy"
               class="nav-link">
              <i class="nav-icon fas fa-shield-alt"></i>

              <p>
                <!-- Staff -->
                {{'privacy.privacyTitle'|translate}}
              </p>
            </a>
          </li>
          <li  class="nav-item has-treeview">
            <a routerLink="/imprint"
               class="nav-link">
              <i class="nav-icon fas fa-shield-alt"></i>

              <p>
                <!-- Staff -->
                {{'privacy.imprintTitle'|translate}}
              </p>
            </a>
          </li>
          <li  class="nav-item has-treeview">
            <a routerLink="/data-privacy"
               class="nav-link">
              <i class="nav-icon fas fa-shield-alt"></i>

              <p>
                <!-- Staff -->
                {{'privacy.dataPrivacyTitle'|translate}}
              </p>
            </a>
          </li>
          <li
            class="nav-item has-treeview"
            *ngIf="activeScreens.faq_screen == 1"
          >
            <a
              href="javascript:;"
              [routerLink]="['../' + APP_ROUTES.listFaq]"
              [class.active]="matchUrl('faq')"
              class="nav-link"
            >
              <i class="nav-icon fa fa-cog"></i>
              <p>
                <!-- FAQ Management -->
                {{ "menu.FAQ" | translate }}
              </p>
            </a>
          </li>

          <!-- <li class="nav-item has-treeview">
                        <a href="javascript:;" routerLink="/timeline" [class.active]="matchUrl('timeline')" class="nav-link">
                            <i class="nav-icon fa fa-clock"></i>
                            <p>
                                Timeline
                            </p>
                        </a>
                    </li> -->

          <!-- <li class="nav-item has-treeview">
                        <a href="javascript:;" class="nav-link">
                            <i class="nav-icon fa fa-cog"></i>
                            <p>Settings
                            </p>
                        </a>
                    </li> -->

          <!-- <li class="nav-item has-treeview">
                            <a href="javascript:;"  class="nav-link" [routerLink]="['../' + APP_ROUTES.MostSelectedReport]" [class.active]="matchUrl('most-selected')">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Reports
                                </p>
                            </a>
                        </li> -->

          <li class="nav-item has-treeview">
            <a
              href="javascript:;"
              class="nav-link"
              [class.active]="matchUrl('most-selected')"
            >
              <i class="nav-icon fa fa-circle"></i>
              <p>
                <!-- Reports -->
                {{ "menu.Reports" | translate }}
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>

            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a
                  href="javascript:;"
                  class="nav-link"
                  [routerLink]="['../' + APP_ROUTES.MostSelectedReport]"
                  [class.active]="matchUrl('most-selected')"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>
                    <!-- Report 1 -->
                    {{ "menu.Report1" | translate }}
                  </p>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:;"
                  class="nav-link"
                  [routerLink]="['../' + APP_ROUTES.CustomerProcessTime]"
                  [class.active]="matchUrl('customer-process')"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>
                    <!-- Report 2 -->
                    {{ "menu.Report2" | translate }}
                  </p>
                </a>
              </li>
            </ul>
          </li>

          <!-- <li class="nav-item has-treeview">
            <a href="javascript:;" class="nav-link">
              <i class="nav-icon fa fa-cog"></i>
              <p>
                Settings
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="javascript:;" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>FAQ</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="javascript:;" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Reports</p>
                </a>
              </li>

            </ul>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</ng-container>
