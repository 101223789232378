<div class="card-header">
  <h3 class="card-title">
    <!-- Edit FAQ -->
    {{ "editFaq.editFAQ" | translate }}
  </h3>
  <button
    (click)="closeModall()"
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="card-body">
  <form novalidate [formGroup]="faqForm">
    <div class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12 bg-transparent">
        <label>{{'addClient.lang'|translate}} <span class="required-asterisk">*</span></label>
        <select style="border: 0;border-bottom: 1px solid #dee2e6;" formControlName="language_type"
          class="pl-0 form-control  bg-transparent shadow-none fw-regular fs-6"
       >
          <option value="DE">DE</option>
          <option value="EN">EN</option>
        </select>
        <!-- <app-validation-errors [control]="lang" [controlName]="'privacyPolicy.language' | translate">
        </app-validation-errors> -->
      </div>
      <div class="col-md-12">
        <div class="form-group w-100">
          <label>
            <!-- Question -->
            {{ "addFaq.question" | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'addFaq.enterQuestion' | translate }}"
            formControlName="question"
          />
          <ng-container
            *ngIf="
              faqForm.controls['question'].errors &&
              (faqForm.get('question').dirty || faqForm.get('question').touched)
            "
          >
            <small
              class="text-danger error-msg"
              *ngIf="faqForm.controls['question'].hasError('required')"
              >*{{ "addFaq.questionRequired" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="faqForm.controls['question'].hasError('maxlength')"
              >*{{ "addClient.charLimit300" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="
                !faqForm.controls['question'].hasError('required') &&
                faqForm.controls['question'].hasError('whitespace')
              "
              >*{{ "addClient.whitespaceNotAllowed" | translate }}.</small
            >
          </ng-container>
          <small
            class="text-danger error-msg"
            *ngIf="faqForm.controls['question'].hasError('pattern')"
            >*{{ "addClient.specialCharMsg" | translate }}.</small
          >
        </div>
      </div>

      <div class="col-md-12">
        <div class="form-group w-100">
          <label>{{ "addFaq.answer" | translate }}</label>

          <textarea
            type="text"
            class="form-control"
            placeholder="{{ 'addFaq.answer' | translate }}"
            formControlName="answer"
          ></textarea>
          <ng-container
            *ngIf="
              faqForm.controls['answer'].errors &&
              (faqForm.get('answer').dirty || faqForm.get('answer').touched)
            "
          >
            <small
              class="text-danger error-msg"
              *ngIf="faqForm.controls['answer'].hasError('required')"
              >*{{ "addFaq.answerRequired" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="faqForm.controls['answer'].hasError('maxlength')"
              >*{{ "addClient.charLimit1000" | translate }}.</small
            >
            <small
              class="text-danger error-msg"
              *ngIf="
                !faqForm.controls['answer'].hasError('required') &&
                faqForm.controls['answer'].hasError('whitespace')
              "
              >*{{ "addClient.whitespaceNotAllowed" | translate }}.</small
            >
          </ng-container>
          <small
            class="text-danger error-msg"
            *ngIf="faqForm.controls['answer'].hasError('pattern')"
            >* {{ "addClient.specialCharMsg" | translate }}.</small
          >
        </div>
      </div>
    </div>
  </form>
  <p class="w-100 text-right">
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!faqForm.valid"
      (click)="updateFaq()"
    >
      {{ "editFaq.saveFaq" | translate }}
    </button>
  </p>
</div>
