<div class="content-wrapper card-body">
  <div class="card card-primary mb-0">
    <div class="card-header mb-0">
      <div class="w-100 d-flex justify-content-between">
        <!-- <a class="ml-2 cursor-pointer" routerLink="/list-client">
          <i class="fa fa-arrow-left mr-1"></i>
          {{ "productCart.back" | translate }}
        </a> -->
        <span *ngIf="clientName">
          {{ "productCart.client" | translate }} : {{ clientName }}
        </span>
        <span *ngIf="totalCost">
          {{ "productCart.totalCost" | translate }} :
          {{ totalCost | number : "1.0-0" : "de-DE" }} €
        </span>
      </div>
    </div>
  </div>

  <section class="py-md-5 pb-5 pt-3 card-body" style="background: white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-12" style="max-width: 1200px">
          <div class="cart-items-wrapper me-0 me-lg-4">
            <div
              class="cart-title d-block d-md-flex justify-content-between align-items-center"
            >
              <h2 class="mb-3 mb-md-0">{{ "productCart.cart" | translate }}</h2>
              <div class="dropdown">
                <button
                  class="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="droptext">
                    <span>{{ selectedData }}</span> {{ selectedData2 }}
                  </span>
                  <svg
                    class="select-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="7.54"
                    viewBox="0 0 14 7.54"
                  >
                    <path
                      id="chevron-down-dropdown"
                      d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                      transform="translate(15.499 -4.499) rotate(90)"
                      fill="#4c89d3"
                      fill-rule="evenodd"
                    />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let p of updatedProjectList; let pi = index">
                    <a
                      class="dropdown-item onhover"
                      (click)="OnClickupdatedata(p, pi)"
                    >
                      <span>{{ p?.project?.name }}</span> |
                      {{ p?.details?.Template?.name }} |
                      {{ p?.details?.apartment_name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <ng-container *ngFor="let cat of wishList">
              <h3 class="ctg-title mt-4" *ngIf="cat?.products?.length > 0">
                {{ cat?.name }} <span class="titlebar"></span>
              </h3>
              <ng-container *ngFor="let prod of cat?.products">
                <div class="cart-items mt-4">
                  <div class="cart-item-wrapper row mx-0 mt-3">
                    <div class="col-12 col-md-4 col-lg-3 px-0">
                      <div class="cart-img-wrapper">
                        <img
                          src="{{
                            prod.Product.image
                              ? imgBaseUrl + prod.Product.image.split(',')[0]
                              : 'assets/img/placeholder.png'
                          }}"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-8 col-lg-9 px-0">
                      <div class="d-lg-flex d-block cart-info">
                        <div class="item-info">
                          <h4>{{ prod?.Product?.product_name }}</h4>
                          <p>{{ prod?.cat_name }}</p>
                          <div
                            class="extras"
                            *ngIf="prod?.ProductFeature?.feature_id"
                          >
                            <h5>{{ "productCart.extra" | translate }}:</h5>
                            <div class="d-flex flex-wrap">
                              <span class="selected"
                                >{{
                                  prod?.ProductFeature?.CatalogueFeature
                                    ?.label_name
                                }}
                                {{
                                  prod?.ProductFeature?.control_value
                                    ? "(" +
                                      prod?.ProductFeature?.control_value +
                                      ")"
                                    : ""
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="item-pricing">
                          <div class="price" *ngIf="!prod.Product.price">
                            {{ "productCart.includedinstandard" | translate }}
                          </div>
                          <div
                            class="price d-flex align-items-center d-lg-block"
                            *ngIf="prod.Product.price"
                          >
                            {{
                              prod?.product_price
                                ? (prod?.product_price
                                    | number : "1.0-0" : "de-DE") + " €"
                                : ""
                            }}
                            <span class="ms-3 ms-lg-0">
                              {{ "productCart.quantity" | translate }}:
                              {{ prod?.quantity }}</span
                            >
                          </div>
                          <div class="cart-quantity">
                            <div class="d-flex align-items-center mt-2">
                              <button
                                [title]="'productCart.addnote' | translate"
                                *ngIf="!prod?.isNotedAdded"
                                data-toggle="modal"
                                data-target="#addNotes"
                                class="btn btn-main ms-3"
                                type="button"
                                (click)="addNoteModal(prod, cat)"
                              >
                                <i class="fa fa-plus mr-2"></i
                                >{{ "productCart.addnote" | translate }}
                              </button>
                              <button
                                [title]="'productCart.viewnote' | translate"
                                *ngIf="prod?.isNotedAdded"
                                data-toggle="modal"
                                data-target="#editNotes"
                                class="btn btn-main ms-3"
                                type="button"
                                (click)="viewNoteModal(prod, cat)"
                              >
                                <i class="fa fa-eye mr-2"></i>
                                {{ "productCart.viewnote" | translate }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="areAllProductsExists && isContentLoaded">
    <div
      class="cart-items d-flex flex-column justify-content-center align-items-center mt-5 pt-5"
    >
      <h1>{{ "productCart.notfound" | translate }}</h1>
      <p>{{ "productCart.notfounddesc" | translate }}</p>
    </div>
  </ng-container>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="addNotes"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-top">
    <div class="modal-content border-0">
      <div class="modal-body p-0">
        <div class="car card-primary">
          <div class="card-header">
            <div class="w-100 d-flex justify-content-between">
              <span> {{ "productCart.addnote" | translate }} </span>
              <span>
                <i
                  class="fa fa-times-circle cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="cancelModal()"
                ></i>
              </span>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <textarea
                formControlName="notes"
                placeholder="{{ 'productCart.placeholderwrite' | translate }}"
                style="
                  width: 100%;
                  border: 1px solid rgb(204, 204, 204);
                  padding: 20px;
                  border-radius: 4px;
                  outline: 0px;
                  height: 221px;
                "
              ></textarea>
              <div class="w-100 row">
                <div class="col-11">
                  <ng-container
                    *ngIf="
                      form.controls['notes'].errors && form.get('notes').touched
                    "
                  >
                    <small
                      class="text-danger error-msg"
                      *ngIf="form.controls['notes'].hasError('required')"
                    >
                      {{ "productCart.notesrequired" | translate }}</small
                    >
                    <small
                      class="text-danger error-msg"
                      *ngIf="form.controls['notes'].hasError('maxlength')"
                      >*

                      {{ "productCart.charLimit500" | translate }}</small
                    >

                    <small
                      class="text-danger error-msg"
                      *ngIf="
                        form.controls['notes'].hasError('whitespace') &&
                        !form.controls['notes'].hasError('required')
                      "
                      >*

                      {{ "productCart.whitespaceNotAllowed" | translate }}
                    </small>
                  </ng-container>
                </div>
                <div
                  class="w-100 p-0 m-0 col-1 align-items-end text-align-end char-limit"
                >
                  <small class="w-100 p-0 m-0 text-align-end">
                    {{ getNotes?.value?.length + "/500" }}
                  </small>
                </div>
              </div>
            </form>
            <div class="d-flex justify-content-center mt-3">
              <button
                class="btn btn btn-light mr-2"
                data-dismiss="modal"
                aria-label="Close"
                id="cancelAdd"
                style="
                  background: rgb(204, 204, 204);
                  padding: 7px 20px;
                  border: 0;
                  font-weight: 600;
                "
                (click)="cancelModal()"
              >
                {{ "productCart.cancel" | translate }}
              </button>
              <button
                [disabled]="form.invalid"
                class="btn btn-main "
              
                (click)="submitAddNote()"
              >
                {{ "productCart.addnote" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editNotes"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-top">
    <div class="modal-content border-0">
      <div class="modal-body p-0">
        <div class="car card-primary">
          <div class="card-header">
            <div class="w-100 d-flex justify-content-between">
              <span> {{ "productCart.editnote" | translate }} </span>
              <span>
                <i
                  class="fa fa-times-circle cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="cancelModal()"
                ></i>
              </span>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <textarea
                formControlName="notes"
                placeholder="{{ 'productCart.placeholderwrite' | translate }}"
                style="
                  width: 100%;
                  border: 1px solid rgb(204, 204, 204);
                  padding: 20px;
                  border-radius: 4px;
                  outline: 0px;
                  height: 221px;
                "
              >
              </textarea>
              <div class="w-100 row">
                <div class="col-11">
                  <ng-container
                    *ngIf="
                      form.controls['notes'].errors && form.get('notes').touched
                    "
                  >
                    <small
                      class="text-danger error-msg"
                      *ngIf="form.controls['notes'].hasError('required')"
                    >
                      {{ "productCart.notesrequired" | translate }}</small
                    >
                    <small
                      class="text-danger error-msg"
                      *ngIf="form.controls['notes'].hasError('maxlength')"
                      >*

                      {{ "productCart.charLimit500" | translate }}</small
                    >

                    <small
                      class="text-danger error-msg"
                      *ngIf="
                        form.controls['notes'].hasError('whitespace') &&
                        !form.controls['notes'].hasError('required')
                      "
                      >*

                      {{ "productCart.whitespaceNotAllowed" | translate }}
                    </small>
                  </ng-container>
                </div>
                <div
                  class="w-100 p-0 m-0 col-1 align-items-end text-align-end char-limit"
                >
                  <small class="w-100 p-0 m-0 text-align-end">
                    {{ form.controls["notes"].value.length + "/500" }}
                  </small>
                </div>
              </div>
            </form>
            <div class="d-flex justify-content-center mt-3">
              <button
                class="btn btn btn-light mr-2"
                data-dismiss="modal"
                aria-label="Close"
                id="cancelEdit"
                style="
                  background: rgb(204, 204, 204);
                  padding: 7px 20px;
                  border: 0;
                  font-weight: 600;
                "
                (click)="cancelModal()"
              >
                {{ "productCart.cancel" | translate }}
              </button>
              <button
                *ngIf="!isEditable"
                [disabled]="form.invalid"
                class="btn btn-main ml-2"
                style="padding: 7px 20px"
                (click)="EditNotes()"
              >
                <i class="fa fa-pen mr-2"></i>
                {{ ButtonText }}
              </button>
              <button
                *ngIf="isEditable"
                [disabled]="form.invalid"
                class="btn btn-primary ml-2"
                style="padding: 7px 20px"
                (click)="submitUpdateNotes()"
              >
                {{ ButtonText }}
              </button>
              <button
                class="btn btn-primary ml-2"
                mwlConfirmationPopover
                [popoverTitle]="'productCart.delete' | translate"
                [popoverMessage]="'productCart.popoverMessage' | translate"
                [confirmText]="'productCart.confirmText' | translate"
                [cancelText]="'productCart.cancel' | translate"
                placement="top"
                (confirm)="deleteNote(true, selectedCurrentProduct)"
                (cancel)="deleteNote(false, selectedCurrentProduct)"
                style="
                  padding: 7px 20px;
                  background: rgb(179, 52, 52);
                  border-color: rgb(179, 52, 52);
                "
              >
                <i class="fa fa-trash mr-2"></i>
                {{ "productCart.delete" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
