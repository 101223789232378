<div class="card card-dark mb-0">
    <div class="card-header">
        <h3 class="card-title">
            <!-- Edit Feature -->
            {{'customizeFeature.editFeature' | translate}}</h3>

        <div class="card-tools">

            <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove"
            (click)="closeModal()">
          <i class="fas fa-times"></i></button>
        </div>
    </div>
    <div class="card-body">

        <form [formGroup]="mainForm">

            <div class="row editFeatureUi">
                <div class="col-12 d-flex flex-wrap ">
                    <input formControlName="featureName" class="inputLabel" disabled/>
                    <input value="Price" class="inputLabel mr-0" disabled/>
                </div>


                <div class="col-12" formArrayName="controlValue">
                    <div *ngFor="let obj of controlValue.controls; index as i">
                        <div [formGroupName]="i" class="productEdit-list">

                            <input *ngIf="!showInput" formControlName="value" disabled/>
                            <input *ngIf="showInput" value="Please enter price" disabled/>
                            <input formControlName="price" ngInputNumberI18n=".2-2" />
                            <p class="text-right mb-0">
                                <ng-container *ngIf="obj.get('price').errors &&
                      (obj.get('price').dirty || obj.get('price').touched)">
                                    <small class="text-danger error-msg" *ngIf="obj.get('price').hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                    <small class="text-danger error-msg" *ngIf="obj.get('price').hasError('required')">*Price is
                        required.</small>
                                    <small class="text-danger error-msg" *ngIf="obj.get('price').hasError('maxlength')">*{{'addProduct.charLimit10' | translate}}.</small>
                                </ng-container>
                            </p>

                        </div>
                    </div>
                </div>
            </div>

        </form>



    </div>
    <!-- /.card-body -->
    <div class="card-footer text-right">
        <button class="btn btn-success" [disabled]="!mainForm.valid" (click)="updateFeature()">
            <!-- Update -->
            {{'customizeFeature.update' | translate}}</button>
    </div>
    <!-- /.card-footer-->
</div>







<!-- <div *ngIf="showInput">
      <input #myInput/>
      <button class="btn btn-primary" (click)="updateFeature(myInput.value)"></button>
    </div> -->

<!-- {{getAllErrors(mainForm) | json}} -->