<!-- <section class="form-pages pt-120 pb-280">

	<div class="container">
		<div class="row align-items-center main-row">
			<div class="col-md-7 form-left-content">
				<h2 class="font-bold font42">Thank you for deciding <br>
					to create a <span class="text-red">Social App Hub</span> <br>
					Account.</h2>
				<p class="font-20">You are warmly welcomed to our community. We invite you to follow our social media
					feeds in addition to regularly visiting this website to stay informed about the social good sector:
				</p>
			</div>
			<div class="col-md-5 form-right-content">

				<div class="form-wrap">
					<form class="signin-form" novalidate [formGroup]="forgotForm">
						<div class="form-heading">
							<h3 class="font30 form-color1">Forgot Password!</h3>
							<p class="form-color2">Please enter your email</p>
						</div>

						<div class="row basic-info">
							<div class="col-md-12">
								<div class="form-group">
									<span class="outer-label">Email</span>
									<input type="Email" name="" class="form-control" placeholder="Enter Email"
										formControlName="email" autocomplete="off">
								</div>
								<ng-container
									*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
									<small class="text-danger error-msg"
										[hidden]="!forgotForm.controls['email'].errors.required">*Email is
										required.</small>
									<small class="text-danger error-msg"
										[hidden]="!forgotForm.controls['email'].errors.pattern">*Email is
										invalid.</small>
								</ng-container>
							</div>
						</div>

						<div class="row action-btn">
							<div class="col-md-6 act-btn-left">
								<div class="form-group">
									<button type="submit" class="btn btn-primary rounded-pill w-100"
										(click)="doForgot($event)">Submit</button>
								</div>
							</div>
							<div class="col-md-6 act-btn-right">
								<div class="form-group">
									<button type="submit" class="btn rounded-pill btn-default w-100 grey-border"
										[routerLink]="['../' + APP_ROUTES.login]">Login</button>
								</div>
							</div>
						</div>
						<p *ngIf="isForgotSuccess" class="sent-message">Please follow instructions as sent on your email
						</p>
					</form>
				</div>
			</div>

		</div>
	</div>

</section>-->



<div class="login-page">

<div class="login-box">
	<div class="login-logo mb-4">
		<a  href="javascript:;"><img src="/assets/img/logo.svg" alt="logo"/></a>
	</div>
	<div class="card">
		<div _ngcontent-lqd-c127="" class="card-header text-center f-700">
			<!-- Forget password -->
			{{'forgot-password.heading1' | translate}}
		</div>
	  <div class="card-body login-card-body">
		<p class="login-box-msg">
			<!-- You forgot your password? Here you can easily retrieve a new password. -->
			{{'forgot-password.heading2' | translate}}
		</p>

		<form novalidate [formGroup]="forgotForm">
			<div class="form-group">
			<label>
				<!-- Forgot Password -->
				{{'forgot-password.heading3' | translate}}
			</label>
		  <div class="input-group">
			<input type="email" class="form-control" placeholder="{{'forgot-password.emailPlaceholder' | translate}}"
			formControlName="email" autocomplete="off">



			<div class="input-group-append">
			  <div class="input-group-text">
				<span class="fas fa-envelope"></span>
			  </div>
			</div>

		  </div>

		  <div class="col-md-12">
			<ng-container
				*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
				<small class="text-danger error-msg"
					[hidden]="!forgotForm.controls['email'].errors.required">
					<!-- *Email is required. -->
					{{'forgot-password.emailRequired' | translate}}
				</small>
				<small class="text-danger error-msg"
					[hidden]="!forgotForm.controls['email'].errors.pattern">
					<!-- *Email isinvalid. -->
					{{'forgot-password.emailInvalid' | translate}}
				</small>
			</ng-container>
		</div>

			</div>


		  <div class="row">
			<div class="col-12">
			  <button type="submit" class="btn btn-primary btn-block" (click)="doForgot($event)">
				  <!-- Submit -->
				  {{'forgot-password.submit' | translate}}
				</button>
			</div>
			<!-- /.col -->
		  </div>
		</form>

		<!-- <p class="mt-3 mb-1">
		  <a class="btn btn-block btn-outline-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
		</p> -->
	  </div>
	  <!-- /.login-card-body -->
	</div>
  </div>
</div>



  <!-- /.login-box -->





