<ngx-spinner></ngx-spinner>
<div class="content-wrapper card-body" style="min-height: 1416.81px;">
    <!-- Content Header (Page header) -->
 
    <div class="card">
            <div class="card card-primary mb-0">
                <div class="card-header">
                    <span class="f-600 mb-0" *ngIf="!operation">
                        <!-- Create Template -->
                        {{'addTemplate.createTemplate' | translate}}</span>
                    <span class="f-600 mb-0" *ngIf="operation">
                        <!-- Edit Template -->
                            {{'addTemplate.editTemplate' | translate}}
                    </span>
                </div>
            </div>
            <form novalidate [formGroup]="templateForm">
                    <div class="card-header header-primary text-left f-700">
                        <!-- STEP 1 -->
                        {{'addTemplate.step1' | translate}}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                
                            </div>
                        </div>

                        <div class="row">
                        <div class="col-6">
                            <div class="form-group w-100">
                                <label>
                                    <!-- Template Name -->
                                        {{'addTemplate.templateName' | translate}}
                                <span class="text-danger">*</span>
                            </label>
                            <input  [readonly]="diableSaveAsDraft"  type="text" class="form-control" placeholder="{{'addTemplate.enterTemplateName' | translate}}" formControlName="name" />
                            <ng-container *ngIf="templateForm.controls['name'].errors &&
                            (templateForm.get('name').dirty || templateForm.get('name').touched)">
                                <small class="text-danger error-msg" *ngIf="templateForm.controls['name'].hasError('required')">*Template name is
                                    required.</small>
                                <small class="text-danger error-msg" *ngIf="templateForm.controls['name'].hasError('maxlength')">*{{'addFeature.charLimit30' | translate}}.</small>
                                <small class="text-danger error-msg" *ngIf="templateForm.controls['name'].hasError('pattern')">*{{'addFeature.specialCharMsg' | translate}}.</small>
                                    <small class="text-danger error-msg"
                                    *ngIf="templateForm.controls['name'].hasError('whitespace')" >*
                                    <!-- Whitespace not allowed -->
                                    {{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                            </ng-container>
                            </div>
                          
                        </div>
                    </div>

                        <div class="row" formArrayName="templateType">
                            <div class="col-md-12">
                                <div class="row row-alternative rounded" *ngFor="let quantity of templateType().controls; let i=index" [formGroupName]="i">
                                        <div class="col-md-4 col-xxl-3">
                                            <div class="img-container">
                
                                                <div class="img-priview" >
                                                    
                                                    
                                                <img class="img-fluid" *ngIf="quantity.value.image" [src]="imgBaseUrl + quantity.value.image" />
                                                
                                                </div>
                                                <input class="btn btn-danger mt-3" type="file" #myInput class="h-auto form-control" (change)="uploadImage($event,i)" style="display: none;" id="selectedFile" />
                                                <div class="support-format">
                                                    ( {{'addProject.supportFormat'|translate}} png, jpeg, jpg )
                                                </div>
                                                <div class="col-md-auto pl-0 pr-0">
                                                    <a class="btn btn-danger mt-3 " href="javascript:; " (click)="myInput.click()">
                                                        <!-- Upload Image -->
                                                        {{'addProduct.uploadImage' | translate}}</a>
                                                </div>
                                           
                                        </div>

                                        </div>

                                        <div class="col-md-8 col-xxl-9">
                            

                                            <div class="row">
                                                
                                                <div class="col-12">
                                                     <!-- text input -->
                                                     <div class="form-group">
                                                        <label>
                                                            <!-- Room Name -->
                                                                {{'addTemplate.roomName' | translate}} 
                                                        <span class="text-danger">*</span></label>
                                                        <input [readonly]="diableSaveAsDraft" type="text" 
                                                        formControlName="name" class="form-control" 
                                                        placeholder="{{'addTemplate.enterRoomName' | translate}}">
                                                        <ng-container *ngIf="quantity.get('name').errors &&
                                            (quantity.get('name').dirty || quantity.get('name').touched)">
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('name').hasError('pattern')">*
                                                                <!-- No special
                                                                character
                                                                allowed -->
                                                                {{'addFeature.specialCharMsg' | translate}}.</small>
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('name').hasError('required')">*Name is
                                                                required.</small>
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('name').hasError('maxlength')">*{{'addFeature.charLimit30' | translate}}.</small>
                                                                <small class="text-danger error-msg"
                                                        *ngIf="quantity.get('name').hasError('whitespace')" >*
                                                        <!-- Whitespace not allowed -->
                                                        {{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                                                        </ng-container>
                                                    </div>
                                                    
                                                </div>

                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>
                                                            <!-- Room Description  -->
                                                                {{'addTemplate.roomDescription' | translate}}
                                                                <span class="text-danger">*</span></label>
                                                        <textarea [readonly]="diableSaveAsDraft" type="text" formControlName="description" class="form-control resize-none" placeholder=" {{ 'addTemplate.enterroomdescription' | translate}}"></textarea>
                                                        <ng-container *ngIf="quantity.get('description').errors &&
                                            (quantity.get('description').dirty || quantity.get('description').touched)">
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('description').hasError('pattern')">*
                                                                <!-- No special
                                                                character
                                                                allowed -->
                                                                {{'addFeature.specialCharMsg' | translate}}.</small>
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('description').hasError('required')">*
                                                                <!-- Description is
                                                                required -->
                                                                {{'addProject.descriptionRequired' | translate}}.</small>
                                                            <small class="text-danger error-msg" *ngIf="quantity.get('description').hasError('maxlength')">
                                                                *
                                                                <!-- Maximum
                                                                of 300
                                                                characters -->
                                                                {{'addClient.charLimit300' | translate}}.</small>
                                                                <small class="text-danger error-msg"
                                                        *ngIf="quantity.get('description').hasError('whitespace')" >*{{'addFeature.whitespaceNotAllowed' | translate}}.</small>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-12">
                                            <div class="col-auto text-right" >
                                                <!-- text input -->
                                                <div class="form-group mb-1">
                                                    <button *ngIf="i!==0" type="button" (click)="removeTemplateType(i)" class="btn btn-danger mr-2"><i class="fa fa-trash"></i></button>
                                                    <button *ngIf="getLastIndexOfArray(i)" type="button" (click)="addTemplateType()" class="btn btn-success"><i
                                                            class="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button class="btn btn-primary mr-3" [disabled]="!templateForm.valid" (click)="nextStep()"><i></i>
                            <!-- Next Step -->
                            {{'addTemplate.nextStep' | translate}}</button>
                        <button *ngIf="!operation" class="btn btn-dark mr-3" [disabled]="!templateForm.valid || diableSaveAsDraft" (click)="onSubmit(false)"><i></i>
                            <!-- Save As Draft -->
                            {{'addTemplate.saveAsDraft' | translate}}</button>
                        <button *ngIf="operation" class="btn btn-success" [disabled]="!templateForm.valid" (click)="onUpdate(false)"><i></i>
                            <!-- Save -->
                            {{'addTemplate.save' | translate}}</button>
                       
                    </div>
                    <!-- <div class="w-100 text-right card-footer">
                        <button type="button" class="btn btn-primary" [disabled]="!templateForm.valid" (click)="onSubmit()">Add Template</button>
                    </div> -->
            </form>
    </div>
    <!-- {{this.templateForm.value | json}} -->
    <!-- {{getAllErrors(templateForm) | json}} -->
</div>

<ng-template #cropImageModal let-modal>
    <div
      class="modal-body bookappointment-modal "
    >
      <image-cropper
      [imageChangedEvent]="null"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="true"
        [aspectRatio]="1.6 / 1"
        format="png"
        [imageQuality]="100"
        [imageFile]="imageOrigin"
        [resizeToWidth]="imageWidth"
        [resizeToHeight]="imageHeight"
        [onlyScaleDown]="isImageScaleDown"
        [backgroundColor]="'#ffffff'"
  (imageLoaded)="imageLoaded($event)"
  (imageCropped)="imageCropped($event)"
  (startCropImage)="imageCroppedStarted($event)"
  (loadImageFailed)="imageLoadFailed($event)"
  ></image-cropper>
      </div>
    <div class="modal-footer justify-content-between mb-3 px-md-5 px-3">
      <button
        type="button"
        class="btn btn-transparent text-black mw-180 mx-0"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          closeModal();
        "
      >
        Cancel
      </button>
      <button
    
        type="button"
        class="btn btn-dark mw-180 mx-0"
        (click)="saveImage(cropImageModal)"
      >
        Update
      </button>
    </div>
  </ng-template>
