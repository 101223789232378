<ng-container *ngIf="showNavbar">
<!-- Navbar -->
 <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="javascript:;" role="button"><i class="fas fa-bars"></i></a>
      </li>
      
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="javascript:;">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="javascript:;" class="dropdown-item">
            <div class="media">
              <img src="/assets/img/user2-160x160.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                 
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          
          </a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">
            
            <div class="media">
              <img src="/assets/img/user2-160x160.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
           
          </a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">
           
            <div class="media">
              <img src="/assets/img/user2-160x160.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                 
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
           
          </a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item dropdown-footer">See All Messages</a>
        </div>
      </li> -->
      
       <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="javascript:;">
          <i class="far fa-bell"></i>
          <span *ngIf="notificationList.length>0" class="badge badge-danger navbar-badge">&nbsp;</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span *ngIf="notificationList.length>0" class="dropdown-item dropdown-header"><strong>{{'header.notifications'|translate}}</strong></span>
          <span *ngIf="notificationList.length==0" class="dropdown-item dropdown-header"><strong>No New Notifications</strong></span>

          <ng-container *ngFor="let obj of notificationList;index as i">
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item" (click)="gotoTimeline(obj.Client.id)">
            <div class="row g-0">
              <div class="col-md-3 pl-0 pr-0">
                <img *ngIf='obj.Client.image' [src]="imgBaseUrl + obj.Client.image" alt="thumb" class="img-fluid rounded border">
              </div>
              <div class="col-md-9 pr-0">
                  <h6 class="mb-0">{{obj.Client.first_name | titlecase}} {{obj.Client.last_name | titlecase}}</h6>
                  <p >{{obj.message | slice:0:10}}</p>
                  <p class="f-12"><small class="text-muted">{{obj.createdAt.split('T')[0].split("-").reverse().join("-")}}</small></p>
              </div>
            </div>
          </a>
        </ng-container>

          <!-- <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">
            <div class="row g-0">
              <div class="col-md-3 pl-0 pr-0">
                <img src="/assets/img/user2-160x160.jpg" alt="thumb" class="img-fluid rounded border">
              </div>
              <div class="col-md-9 pr-0">
                  <h6 class="mb-0">User Name</h6>
                  <p >This is a wider card with supporting.</p>
                  <p class="f-12"><small class="text-muted">3 mins</small></p>
              </div>
            </div>
          </a> -->
        
          <!-- <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item dropdown-footer">See All Notifications</a> -->
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="javascript:;" role="button" (click)="signOut()">
         
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </li>
      <li class="nav-item dropdown Language-drop">
        <a class="nav-link dropdown-toggle onhover"  data-toggle="dropdown">
          <!-- <span [translate]="'demo.title'" href="#" id="navbardrop"></span> -->
          <ng-container *ngIf="language == 'de'">
            German
          </ng-container>
          <ng-container *ngIf="language == 'en'">
            English
          </ng-container>
        </a>
        <div class="dropdown-menu sub-menu-drop">
          <a *ngIf="language === 'de'" class="dropdown-item onhover" (click)="useLanguage('en')"> <img src="./assets/img/enlish-language.jpg"> English</a>
          <a *ngIf="language === 'en'" class="dropdown-item onhover" (click)="useLanguage('de')"><img src="./assets/img/german.jpg"> German</a>
        </div>
      </li>
      <li>
        <!-- {{'demo.title' | translate}} -->
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</ng-container>