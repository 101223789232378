import { ClientService } from './../clients/client.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectOverviewService } from './project-overview.service';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormBuilder } from '@angular/forms';
import { ProjectService } from '../project/project.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {

  constructor(private service: ProjectOverviewService,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private projectService : ProjectService,
    private clientService : ClientService) { }

    people$: Observable<any[]>;
  form : FormGroup;
  itemsPerPage = environment.itemsPerPage;
  totalItems: any = 0;
  page: number = 1;
  showBoundaryLinks = true;
  maxSize = 4;
  public APP_ROUTES = APP_ROUTES;
  projectList = [];
  dropDownProjectList : any;
  dropDownClientList: any;
  firstText="<<";
  lastText=">>";
  prevText="<";
  nextText=">";

  ngOnInit(): void {
    this.form = this.fb.group({
      project_id: [''],
      client_id: [[],[]],
    });

    this.getProjectList();
    this.getProjectOverviewList();
    this.getClientList();
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.loadPage();
  }
  
  loadPage() {
    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      project_id : '',
      client_id : [],
      search: "",
      csv_download:'no',
    }
    this.getProjectOverviewList(payload);
    this.getProjectList();
    this.getClientList();
  }

  getProjectOverviewList(payload?: any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: this.itemsPerPage,
        project_id : '',
        client_id : [],
        search: "",
        csv_download:'no',
      };
    };

    this.service.getProjectOverviewList(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        // this.projectList = data.body.productwishlistRec;
        this.purifyList(data.body.productwishlistRec);
        this.totalItems = data.body.totalRecord;
        console.log("this.totalItems ",this.totalItems)
        if(this.totalItems == 0){
          this.toastr.error("No Project Found");
        } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    });
  }

  purifyList(list : any){
    this.projectList = [];
    for(var obj of list){
      let newObj = {
        total_product : obj.total_product,
      client_name : obj.clientRec.first_name + ' ' + obj.clientRec.last_name,
      client_address : obj.clientRec.address,
      prod_detail : this.getCsv(obj.productRec),
      projectNames : this.getProjectCommaSepValues(obj.projectRec),
      }
      this.projectList.push(newObj);
    }
  }

  getCsv(list : any):String{
    let str = '';
    for(var obj of list){
      str = str + obj.product_name + ",";
    }
    str = str.slice(0, -1); 
    return str;
  }

  getProjectCommaSepValues(list : any):String{
    let str = '';
    for(var obj of list){
      str = str + obj.name + ",";
    }
    str = str.slice(0, -1); 
    return str;
  }


  getProjectList(payload?: any) {

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: 2000,
        search: ""
      };
    };

    this.projectService.getProjectList(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.dropDownProjectList = data.body.projectRec;
        // this.totalItems = data.body.totalRecord;
        // if(this.totalItems == 0){
        //   this.toastr.error("No Project Found");
        // } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    });
  }

  getClientList(payload? : any){

    if (!payload) {
      payload = {
        page_start: 1,
        page_limit: 2000,
        search: ""
      };
    };

    this.clientService.getClientList(payload).subscribe((data: any) => {
      if (data.status === 200) {
        console.log("response ", data);
        this.dropDownClientList = data.body.clientRec;
        // this.totalItems = data.body.totalRecord;
        // if(this.totalItems == 0){
        //   this.toastr.error("No Client Found");
        // } 
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    });
  }

  applyFilter(){

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      project_id : this.form.value.project_id,
      client_id : this.form.value.client_id,
      csv_download:'no',
    };
    this.getProjectOverviewList(payload);
  }

  export(): void {

    let payload = {
      page_start: this.page,
      page_limit: this.itemsPerPage,
      project_id : this.form.value.project_id,
      client_id : this.form.value.client_id,
      csv_download:'yes',
    };

    this.service.getProjectOverviewList(payload).subscribe(
      fileDownload => {
    this.downloadMyFile(fileDownload.body.filepath);
      },
      err => {
        console.log(err.error);
      }
    );
  }

  downloadMyFile(docName : any){
    console.log("docName" ,docName);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', docName);
    link.setAttribute('download', docName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

clearSearch(){
  this.form.patchValue({
    project_id: '',
    client_id: [],
  });
  this.getProjectOverviewList();
}


}