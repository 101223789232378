<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper pl-3 pr-3">
  <!-- Content Header (Page header) -->
  <section class="content-header pb-2">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>
          {{'privacy.dataPrivacyTitle'|translate}}</h1>
      </div>
      <div class="col-sm-6">
      </div>
    </div>
  </section>
  <!-- Main content -->
  <section class="content">
    <!-- Default box -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- <div class="card-header">
            <h3 class="card-title"> {{'privacyPolicy.title' | translate }}</h3>

          </div> -->
          <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="false">
              <div class="row">
                <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12 bg-transparent">
                  <label>{{'addClient.lang'|translate}} <span class="required-asterisk">*</span></label>
                  <select style="border: 0;border-bottom: 1px solid #dee2e6;" formControlName="lang"
                    class="pl-0 form-control  bg-transparent shadow-none fw-regular fs-6"
                    (change)="languageChange($event.target.value)">
                    <option value="gm">DE</option>
                    <option value="en">EN</option>
                  </select>
                  <!-- <app-validation-errors [control]="lang" [controlName]="'privacyPolicy.language' | translate">
                  </app-validation-errors> -->
                </div>
                <div class="form-group col-lg-4 col-md-4 col-sm-4 col-12">
                  <label>{{'privacy.title'|translate}} </label>
                  <div class="input-group input-group-style border-bottom bg-transparent">
                    <input formControlName="title" type="text"
                      class="pl-0 form-control border-0 bg-transparent shadow-none fw-regular fs-6"
                      [placeholder]="'Title'">
                     
                  </div>
                  <ng-container *ngIf="
                  form.controls['title'].errors &&
                  (form.get('title').dirty ||
                    form.get('title').touched)
                ">
                <small class="text-danger error-msg" *ngIf="
                    form.controls['title'].hasError(
                      'required'
                    )
                  ">*
                  <!-- Label name is
                            required. -->
                title is required</small>
                <small class="text-danger error-msg" *ngIf="
                    form.controls['title'].hasError(
                      'maxlength'
                    )
                  ">*
                  <!-- Maximum of 30
                            characters. -->
                  </small>
              </ng-container>
                  <!-- <app-validation-errors [control]="title" [controlName]="'privacyPolicy.pageTitle' | translate">
                  </app-validation-errors> -->
                </div>

                

                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                  <label>{{'addProduct.description'|translate}} <span class="required-asterisk">*</span></label>
                  <div [style.width.%]="100" class="input-group input-group-style border-bottom bg-transparent">
                    <angular-editor [style.width.%]="100" [style.display]="'block'" formControlName="description"
                      [config]="editorConfig" [placeholder]="'Description'"></angular-editor>
                     
                  </div>
                  <ng-container *ngIf="
                  form.controls['description'].errors &&
                  (form.get('description').dirty ||
                    form.get('description').touched)
                ">
                <small class="text-danger error-msg" *ngIf="
                    form.controls['description'].hasError(
                      'required'
                    )
                  ">*
                  <!-- Label name is
                            required. -->
                description is required</small>
                <small class="text-danger error-msg" *ngIf="
                    form.controls['description'].hasError(
                      'maxlength'
                    )
                  ">*
                  <!-- Maximum of 30
                            characters. -->
                  </small>
              </ng-container>
                </div>
                <!-- <app-validation-errors [control]="description" [controlName]="'privacyPolicy.description' | translate">
                </app-validation-errors> -->
              </div>

              <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                <button type="submit" class="btn btn-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block mr-2">
                  {{'privacy.submit'|translate}}</button>
                <!-- <a [routerLink]="['/',APP_ROUTES.dashboard]"
                  class="btn btn-outline-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block">{{'common.Cancel'|
                  translate}}</a> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>